import React, { Component } from 'react';
import styled from 'styled-components';
import moment from "moment";
import ReactSelect from 'react-select';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { enGB, enUS, de } from 'date-fns/locale';

import ReportIcon from '@material-ui/icons/Assessment';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CustomerIcon from '@material-ui/icons/PermContactCalendarRounded';
import SupervisorIcon from '@material-ui/icons/SupervisorAccount';
import CrewIcon from '@material-ui/icons/Group';
import MixingPlantIcon from '@material-ui/icons/BusinessRounded';
import ProjectIcon from '@material-ui/icons/AccountTreeRounded';
import CostCenterIcon from '@material-ui/icons/AccountBalanceRounded';
import DriverIcon from '@material-ui/icons/LocalShipping';
import InvoiceIcon from '@material-ui/icons/Description';
import ViewIcon from '@material-ui/icons/Visibility';
import CancelIcon from '@material-ui/icons/Cancel';
//2db icon: company,  equipment
import StoreIcon from '@material-ui/icons/Store';
import BuildIcon from '@material-ui/icons/Build';

import { /* Tooltip, */ Button } from '@material-ui/core';

import RESOURCES from '../../../../api/resources';
import { INVOICED_TYPES, CANCELLED_TYPES } from '../../../../config/constants';
import { rgbToHex, getServiceUrl } from '../../../../utils';

import { Link, Redirect } from "react-router-dom";

import './index.css'

const Container = styled.div`
width: ${document.body.scrollWidth}px;
height: 12%;
position: -webkit-sticky;
position: sticky;
left: 0;
z-index: 4;
background: #f7f5f5;
`

const FilterContainer = styled.div`
width: 86.2%;
min-height: 95px;
margin: 0 auto;
padding: 1%;
border-radius: 5px;
font-family: "Open Sans";
`

const optionStyles = {
    control: (styles) => {
        return {
            ...styles,
            fontSize: 13,
            borderRadius: 3
        }
    },
    option: (styles, { data, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused
            ? data.colorBg
            ? data.colorBg
            : 'rgba(38, 132, 255, 0.08)'
            : null,
            color: data.colorText || '#223b67',
            fontSize: 14
            
        };
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor:
            data.colorBg
            ? data.colorBg
            : 'rgba(38, 132, 255, 0.08)'
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color:
        data.colorText
        ? data.colorText
        : '#223b67',
        fontSize: 13
    }),
    singleValue: (styles) => ({
        ...styles,
        color: 'rgb(55,55,55)'
    }),
    clearIndicator: (styles) => ({
        ...styles,
        cursor: 'pointer'
    })
};

class Filter extends Component {
    constructor(props) {
        super(props);

        /* this.makeGantChart = this.makeGantChart.bind(this); */
        
        const { t, view } = props
        const views = [
            { id: 1, name: t('construction-supervisor') },
            { id: 2, name: t('crew-leader') },
            { id: 3, name: t('dispatcher') }
        ]
        this.state = {
            views,
            selectedView: views.find(obj => obj.id === view.id),
            selectedFromDate: null,
            selectedToDate: null,
            selectedCustomer: [],
            selectedSupervisor: [],
            selectedCrews: [],
            selectedMixingPlant: [],
            selectedProject: [],
            selectedCostCenter: [],
            selectedDriver: [],
            //2new filters
            selectedCompany: [],
            selectedTypeofEquipment: [],
            isMounted: false,
        }
        
        this.viewSelectRef = React.createRef()
        this.invoiceSelectRef = React.createRef()
    }
    
    componentDidMount() {
        const { selectedFromDate, selectedToDate } = this.props
        this.setState({
            selectedFromDate,
            selectedToDate,
            isMounted: true
        })
    }
    
    componentWillReceiveProps(nextProps) {
   /*      console.log("nextProps",nextProps) */
        if (nextProps.view !== this.state.view) {
            const { view } = nextProps
            const { views } = this.state
            const selectedView = views.find(obj => obj.id === view.id)
            this.setState({ view, selectedView })
        }
        if (nextProps.selectedFromDate !== this.state.selectedFromDate) {
            const { selectedFromDate } = nextProps
            this.setState({ selectedFromDate });
        }
        if (nextProps.selectedToDate !== this.state.selectedFromDate) {
            const { selectedToDate } = nextProps
            this.setState({ selectedToDate });
        }
        if (nextProps.customer !== this.state.customer) {
            const { customers, customer } = nextProps
            this.setState({ selectedCustomer: customers.filter(obj => Array.from(customer).includes(obj.ID)) })
        }
        if (nextProps.supervisor !== this.state.supervisor) {
            const { employees, supervisor } = nextProps
            this.setState({ selectedSupervisor: employees.filter(obj => Array.from(supervisor).includes(obj.ID)) })
        }
        if (nextProps.selectedCrew !== this.state.selectedCrew) {
            const { crews, view, userCrewName, selectedCrew } = nextProps
            this.setState({
                selectedCrew,
                selectedCrews: crews.filter(obj =>
                    view.id === 2 && userCrewName && obj.Name.toLowerCase() === userCrewName.toLowerCase()
                    || (view.id !== 2 && Array.from(selectedCrew).includes(obj.ID))
                    )
                })
            }
        if (nextProps.mixingPlant !== this.state.mixingPlant) {
            const { mixingPlants, mixingPlant } = nextProps
            this.setState({ selectedMixingPlant: mixingPlants.filter(obj => Array.from(mixingPlant).includes(obj.ID)) })
        }
        if (nextProps.selectedProjects !== this.state.selectedProjects) {
            const { selectedProjects, projects } = nextProps
            this.setState({ selectedProject: projects.filter(obj => Array.from(selectedProjects).includes(obj.ID)) })
        }
        if (nextProps.selectedCostCenters !== this.state.selectedCostCenters) {
            const { selectedCostCenters, costCenters } = nextProps
            this.setState({ selectedCostCenter: costCenters.filter(obj => Array.from(selectedCostCenters).includes(obj.ID)) })
        }
        if (nextProps.selectedDrivers !== this.state.selectedDrivers) {
            const { selectedDrivers, drivers } = nextProps
            this.setState({ selectedDriver: drivers.filter(obj => Array.from(selectedDrivers).includes(obj.ID)) })
        }
        //2new filters
        if (nextProps.selectedCompanies !== this.state.selectedCompanies) {
            const { selectedCompanies, company } = nextProps
        this.setState({ selectedCompany: company.filter(obj => Array.from(selectedCompanies).includes(obj.ID)) })
  
        }
        if (nextProps.selectedTypeofEquipments !== this.state.selectedTypeofEquipments) {
            const { selectedTypeofEquipments, equipment } = nextProps
            this.setState({ selectedTypeofEquipment: equipment.filter(obj => Array.from(selectedTypeofEquipments).includes(obj.ID)) })

        }          
    }
    downloadMixtureExcel = (e) => {
        e.preventDefault();
        const link = document.getElementById(`downloadMixingPlantReport`);
        link.click();
    }
    //chart func
    makeGantChart = (e) => {

        /* e.preventDefault(); */
       /*  console.log("makeGantChart lefutott") */
        /* return <Redirect to='/highchart' />; */
      /*   const link = document.getElementById(`container`);
        link.click(); */
    }
    
    selectLocale(language) {
        let languages = new Map();
        languages.set("de-DE", de);
        languages.set("en-GB", enGB);
        languages.set("en-US", enUS);
    
        let res = languages.get(language);
        return res != null ? res : languages["de-DE"];
    }

    handleViewChange = (selectedView) => {
        const { handleViewChange } = this.props
        handleViewChange(selectedView)
        this.viewSelectRef.current.blur()
    }

    handleInvoicedChange = (selectedInvoiced) => {
        const { handleInvoicedChange } = this.props
        handleInvoicedChange(selectedInvoiced)
        this.invoiceSelectRef.current.blur()
    }

    render() {
        const {
            t,
            lng,
            user: { token: userToken },
            filteredPensums,
            handleFromDateChange,
            handleToDateChange,
            handleCustomerChange,
            handleSupervisorChange,
            handleCrewChange,
            handleMixingPlantChange,
            handleProjectChange,
            handleCostCenterChange,
            handleDriverChange,
            handleCancelledChange,
            //2new
            handleCompanyChange,
            handleTypeofEquipmentChange,
            crews,
            projects,
            costCenters,
            drivers,
            equipment,
            company,
            customers,
            employees,
            mixingPlants,
            filterMixingPlantIDs,
            selectedInvoiced,
            selectedCancelled,
            selectedCompanies,
            selectedTypeofEquipments,
            /* 
            handleSaveFilter,
            saveFilterDisabled */
        } = this.props
        const {
            views,
            selectedFromDate,
            selectedToDate,
            selectedCustomer,
            selectedSupervisor,
            selectedCrews,
            selectedMixingPlant,
            selectedProject,
            selectedCostCenter,
            selectedDriver,
            selectedCompany,
            selectedTypeofEquipment,
            selectedView
        } = this.state
        const locale = this.selectLocale(lng);

        const MixtureEntryIDs = []
        if (filteredPensums) {
            filteredPensums.forEach(obj => {
                if (obj.MixtureEntries) {
                    obj.MixtureEntries.forEach(entry => {
                        if (
                            (filterMixingPlantIDs && filterMixingPlantIDs.includes(entry.Mixture.Order.MixingPlant.ID))
                            || (filterMixingPlantIDs && filterMixingPlantIDs.length === 0)
                            || filterMixingPlantIDs === undefined
                            || filterMixingPlantIDs === null
                        ) {
                            MixtureEntryIDs.push(entry.ID)
                        }
                    })
                }
            })
        }

        let selectedInvoicedType;
        switch (selectedInvoiced) {
            case INVOICED_TYPES.INVOICED:
                selectedInvoicedType = { value: 1, label: `${t('invoiced')}` }
                break;
            case INVOICED_TYPES.NOT_INVOICED:
                selectedInvoicedType = { value: 2, label: `${t('not')} ${t('invoiced')}` }
                break;
        
            default:
                break;
        }
        let selectedCancelledType;
        switch (selectedCancelled) {
            case CANCELLED_TYPES.CANCELLED:
                selectedCancelledType = { value: 1, label: `${t('cancelled')}` }
                break;
            case CANCELLED_TYPES.NOT_CANCELLED:
                selectedCancelledType = { value: 2, label: `${t('not')} ${t('cancelled')}` }
                break;
            case CANCELLED_TYPES.ALL:
                selectedCancelledType = { value: 3, label: `${t('all')}` }
                break;
        
            default:
                break;
        }

        return (
            <Container id="filterContainer">
                <div className="filter-menu">
                    <span>
                        <a
                            hidden
                            id={`downloadMixingPlantReport`}
                            target="_blank"
                            href={`${getServiceUrl()}${RESOURCES.MixtureEntries}/${RESOURCES.CreateMixingPlantReport}?$filter=ID in (${MixtureEntryIDs})&timeZone=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}&download=false&token=${userToken}`}
                            rel="noopener noreferrer"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={this.downloadMixtureExcel}
                            startIcon={<ReportIcon />}
                            disabled={MixtureEntryIDs.length <= 0}
                        >
                        {t('asphalt-mixing-plant')} Report
                        </Button >
                    </span>
                    <span>
                        <a
                            hidden
                            id={`downloadMixingPlantReport`}
                            target="_blank"
                            href={`${getServiceUrl()}${RESOURCES.MixtureEntries}/${RESOURCES.CreateMixingPlantReport}?$filter=ID in (${MixtureEntryIDs})&timeZone=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}&download=false&token=${userToken}`}
                            rel="noopener noreferrer"
                        />
                        {/*
                        <Button
                            component={Link}
                            to="/highchart"
                            style={{marginLeft: "10px"}}
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={this.makeGantChart}
                            startIcon={<TrendingUpIcon />}
                            //disabled={MixtureEntryIDs.length <= 0}
                        >
                        ZOOM
                        </Button>
                        */}
                    </span>
                </div>
                <FilterContainer>
                    <div className="date-filters">
                        <div className="date-filter">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={locale}
                            >
                                <KeyboardDatePicker
                                    margin="normal"
                                    format="dd.MM.yyyy"
                                    disableToolbar
                                    value={selectedFromDate || null}
                                    onChange={handleFromDateChange}
                                    locale={locale}
                                    error={moment(selectedToDate).diff(selectedFromDate, "days") < 0}
                                    okLabel={t('date-picker-label-ok')}
                                    cancelLabel={t('date-picker-label-cancel')}
                                    invalidDateMessage={t('date-picker-label-invalid-date-format')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="date-filter">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={locale}
                            >
                                <KeyboardDatePicker
                                    margin="normal"
                                    format="dd.MM.yyyy"
                                    disableToolbar
                                    value={selectedToDate || null}
                                    onChange={handleToDateChange}
                                    locale={locale}
                                    error={moment(selectedToDate).diff(selectedFromDate, "days") < 0}
                                    okLabel={t('date-picker-label-ok')}
                                    cancelLabel={t('date-picker-label-cancel')}
                                    invalidDateMessage={t('date-picker-label-invalid-date-format')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="filter float-right">
                            <div className="filter-label">
                                <ViewIcon style={{ fontSize: 22, marginBottom: -5, marginRight: 2 }} />
                                {t('view')}
                            </div>
                            <ReactSelect
                                ref={this.viewSelectRef}
                                value={{ value: selectedView.value, label: selectedView.name }}
                                className="basic-multi-select"
                                placeholder={t('view')}
                                onChange={this.handleViewChange}
                                styles={optionStyles}
                                options={
                                    views.map(userView => {
                                        return { value: userView.id, label: `${userView.name}` }
                                    })
                                }
                            />
                        </div>
                        <div className="filter float-right spaced-right">
                            <div className="filter-label">
                                <CancelIcon style={{ fontSize: 22, marginBottom: -5, marginRight: 2 }} />
                                {t('cancelledFilterTitle')}
                            </div>
                            <ReactSelect
                                value={selectedCancelledType}
                                className="basic-multi-select"
                                placeholder={t('cancelledFilterTitle')}
                                onChange={handleCancelledChange}
                                styles={optionStyles}
                                options={
                                    [
                                        {
                                            id: CANCELLED_TYPES.ALL,
                                            label: `${t('all-option')}`
                                        },
                                        {
                                            id: CANCELLED_TYPES.CANCELLED,
                                            label: `${t('cancelled')}`
                                        },
                                        {
                                            id: CANCELLED_TYPES.NOT_CANCELLED,
                                            label: `${t('not')} ${t('cancelled')}`
                                        },
                                    ].map(type => {
                                        return { value: type.id, label: `${type.label}` }
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="select-filters">
                        <div className="filter">
                            <div className="filter-label">
                                <CustomerIcon style={{ marginBottom: -5, marginRight: 2 }} />
                                {t('customer')}
                            </div>
                            <ReactSelect
                                value={selectedCustomer.map(obj => ({ value: obj.ID, label: obj.Name }))}
                                className="basic-multi-select"
                                placeholder={t('customer')}
                                onChange={handleCustomerChange}
                                isClearable
                                isMulti
                                closeMenuOnSelect={true}
                                styles={optionStyles}
                                options={
                                    customers
                                    .sort((a, b) => {
                                        var result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
                                        if (result !== 0)
                                            return result;
                                        return a.ID - b.ID
                                    })
                                    .map((filterCustomer) => {
                                        return { value: filterCustomer.ID, label: `${filterCustomer.Name}`}
                                    })
                                }
                            />
                        </div>
                        {/* //========================================================================================================================== */}
                        <div className="filter">
                            <div className="filter-label">
                                <StoreIcon style={{ marginBottom: -5, marginRight: 2 }} />
                                {t('company')}
                            </div>
                            <ReactSelect
                                value={selectedCompany.map(obj => ({ value: obj.ID, label: obj.Name }))}
                                className="basic-multi-select"
                                placeholder={t('company')}
                                onChange={handleCompanyChange}
                                isClearable
                                isMulti
                                closeMenuOnSelect={true}
                                styles={optionStyles}
                                options={
                                    company
                                    .sort((a, b) => {
                                        var result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
                                        if (result !== 0)
                                            return result;
                                        return a.ID - b.ID
                                    })
                                    .map((filterCompany) => {
                                        return { value: filterCompany.ID, label: `${filterCompany.Name}`}
                                    })
                                }
                            />
                        </div>
                            {/* //===============================    ========================== */}
                        <div className="filter">
                            <div className="filter-label">
                                <BuildIcon style={{ marginBottom: -5, marginRight: 2 }} />
                                {t('equipment')}
                            </div>
                            <ReactSelect
                                value={selectedTypeofEquipment.map(obj => ({ value: obj.ID, label: obj.Name }))}
                                className="basic-multi-select"
                                placeholder={t('equipment')}
                                onChange={handleTypeofEquipmentChange}
                                isClearable
                                isMulti
                                closeMenuOnSelect={true}
                                styles={optionStyles}
                                options={
                                    equipment
                                    .sort((a, b) => {
                                        var result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
                                        if (result !== 0)
                                            return result;
                                        return a.ID - b.ID
                                    })
                                    .map((filterEquipment) => {
                                        return { value: filterEquipment.ID, label: `${filterEquipment.Name}`}
                                    })
                                }
                            />
                        </div>
                        {/* //========================================================================================================================== */}
                        <div className="filter">
                            <div className="filter-label">
                                <SupervisorIcon style={{ fontSize: 26, marginBottom: -5, marginRight: 2 }} />
                                {t('construction-supervisor')}
                            </div>
                            <ReactSelect
                                value={selectedSupervisor.map(obj => ({
                                    value: obj.ID,
                                    label: `${obj.LastName} ${obj.FirstNames || ''}`.trim(),
                                    colorText: obj.ColorRgb ? `${rgbToHex(obj.ColorRgb)}` : null,
                                    colorBg: obj.ColorRgb ? `${rgbToHex(obj.ColorRgb)}2c` : null
                                }))}
                                className="basic-multi-select"
                                placeholder={t('construction-supervisor')}
                                onChange={handleSupervisorChange}
                                isClearable
                                isMulti
                                closeMenuOnSelect={true}
                                styles={optionStyles}
                                options={
                                    employees
                                        .filter(employee => employee.IsSupervisor)
                                        .sort((a, b) => {
                                            var result = a.LastName.localeCompare(b.LastName, undefined, {sensitivity: 'base'});
                                            if (result !== 0)
                                                return result;
                                            result = a.FirstNames.localeCompare(b.FirstNames, undefined, {sensitivity: 'base'});
                                            if (result !== 0)
                                                return result;
                                            return a.ID - b.ID
                                        })
                                        .map(employee => {
                                            return {
                                                value: employee.ID,
                                                label: `${employee.LastName} ${employee.FirstNames || ''}`.trim(),
                                                colorText: employee.ColorRgb ? `${rgbToHex(employee.ColorRgb)}` : null,
                                                colorBg: employee.ColorRgb ? `${rgbToHex(employee.ColorRgb)}18` : null
                                            }
                                        }
                                    )
                                }
                            />
                        </div>
                        <div className="filter">
                            <div className="filter-label">
                                <CrewIcon style={{ marginBottom: -5, marginRight: 2 }} />
                                {t('crew')}
                            </div>
                            <ReactSelect
                                value={selectedCrews.map(obj => ({ value: obj.ID, label: obj.Name }))}
                                className="basic-multi-select"
                                placeholder={t('crew')}
                                onChange={handleCrewChange}
                                isClearable
                                isMulti
                                closeMenuOnSelect={true}
                                styles={optionStyles}
                                options={
                                    crews
                                    .sort((a, b) => {
                                        if (a.DefaultSortOrder - b.DefaultSortOrder !== 0) {
                                            return a.DefaultSortOrder - b.DefaultSortOrder
                                        } else {
                                            return a.ID - b.ID
                                        }
                                    })
                                    .map(crew => {
                                        return { value: crew.ID, label: `${crew.Name}` }
                                    })
                                }
                            />
                        </div>
                        <div className="filter">
                            <div className="filter-label">
                                <MixingPlantIcon style={{ marginBottom: -5, marginRight: 2 }} />
                                {t('asphalt-mixing-plant')}
                            </div>
                            <ReactSelect
                                value={selectedMixingPlant.map(obj => ({ value: obj.ID, label: obj.Name }))}
                                className="basic-multi-select"
                                placeholder={t('asphalt-mixing-plant')}
                                onChange={handleMixingPlantChange}
                                isClearable
                                isMulti
                                closeMenuOnSelect={true}
                                styles={optionStyles}
                                options={
                                    mixingPlants
                                    .sort((a, b) => {
                                        var result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
                                        if (result !== 0)
                                            return result;
                                        return a.ID - b.ID
                                    })
                                    .map(mix => {
                                        return { value: mix.ID, label: `${mix.Name}` }
                                    })
                                }
                            />
                        </div>
                        <div className="filter">
                            <div className="filter-label">
                                <ProjectIcon style={{ marginBottom: -5, marginRight: 2 }} />
                                {t('project')}
                            </div>
                            <ReactSelect
                                value={selectedProject.map(obj => ({ value: obj.ID, label: obj.Name }))}
                                className="basic-multi-select"
                                placeholder={t('project')}
                                onChange={handleProjectChange}
                                isClearable
                                isMulti
                                closeMenuOnSelect={true}
                                styles={optionStyles}
                                options={
                                    projects
                                    .sort((a, b) => {
                                        var result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
                                        if (result !== 0)
                                            return result;
                                        return a.ID - b.ID
                                    })
                                    .map(proj => {
                                        return { value: proj.ID, label: `${proj.Name}` }
                                    })
                                }
                            />
                        </div>
                        <div className="filter">
                            <div className="filter-label">
                                <CostCenterIcon style={{ marginBottom: -5, marginRight: 2 }} />
                                {t('cost-center')}
                            </div>
                            <ReactSelect
                                value={selectedCostCenter.map(obj => ({ value: obj.ID, label: obj.Name }))}
                                className="basic-multi-select"
                                placeholder={t('cost-center')}
                                onChange={handleCostCenterChange}
                                isClearable
                                isMulti
                                closeMenuOnSelect={true}
                                styles={optionStyles}
                                options={
                                    costCenters
                                    .sort((a, b) => {
                                        var result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
                                        if (result !== 0)
                                            return result;
                                        return a.ID - b.ID
                                    })
                                    .map(obj => {
                                        return { value: obj.ID, label: `${obj.Name}` }
                                    })
                                }
                            />
                        </div>
                        <div className="filter">
                            <div className="filter-label">
                                <DriverIcon style={{ marginBottom: -5, marginRight: 2 }} />
                                {t('lkw-driver')}
                            </div>
                          {/*   {console.log("selectedDriver",selectedDriver)} */}
                            <ReactSelect
                                value={selectedDriver.map(obj => ({ value: obj.ID, label: `${obj.DriverCompany ? obj.DriverCompany.Name : ''} ${obj.Name}` }))}
                                className="basic-multi-select"
                                placeholder={t('lkw-driver')}
                                onChange={handleDriverChange}
                                isClearable
                                isMulti
                                closeMenuOnSelect={true}
                                styles={optionStyles}
                                options={
                                    Array.from(drivers)
                                        .filter(driver => !driver.IsDeleted)
                                        .sort((a, b) => {
                                            if (a.DriverCompany && b.DriverCompany) {
                                            var result = a.DriverCompany.Name.localeCompare(b.DriverCompany.Name, undefined, {sensitivity: 'base'});
                                            if (result !== 0)
                                                return result;
                                            result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
                                            if (result !== 0)
                                                return result;
                                            return a.SortOrder - b.SortOrder
                                            }
                                        })
                                        .map((driver) => {
                                            if (driver.ID === null) {
                                                return { value: null, label: ''}
                                            }
                                            return { value: driver.ID, label: `${driver.DriverCompany ? driver.DriverCompany.Name : ''} ${driver.Name}`}
                                        })
                                }
                            />
                        </div>
                        <div className="filter">
                            <div className="filter-label">
                                <InvoiceIcon style={{ fontSize: 23, marginBottom: -5, marginRight: 2 }} />
                                {t('invoice')}
                            </div>
                            <ReactSelect
                                ref={this.invoiceSelectRef}
                                value={selectedInvoicedType}
                                className="basic-multi-select"
                                placeholder={t('invoice')}
                                onChange={this.handleInvoicedChange}
                                isClearable
                                styles={optionStyles}
                                options={
                                    [
                                        {
                                            id: INVOICED_TYPES.INVOICED,
                                            label: `${t('invoiced')}`
                                        },
                                        {
                                            id: INVOICED_TYPES.NOT_INVOICED,
                                            label: `${t('not')} ${t('invoiced')}`
                                        }
                                    ].map(type => {
                                        return { value: type.id, label: `${type.label}` }
                                    })
                                }
                            />
                        </div>
                    </div>
                </FilterContainer>
            </Container>
        )
    }
}

export default Filter;