import getEmployee from "./getEmployee";
import createEmployee from "./createEmployee";
import updateEmployee from "./updateEmployee";
import deleteEmployee from "./deleteEmployee";
import getEmployees from "./getEmployees";
import getEmployeeCrew from "./getEmployeeCrew";
import createEmployeeCrew from "./createEmployeeCrew";
import deleteEmployeeCrew from "./deleteEmployeeCrew";
import getEmployeeMixingPlant from "./getEmployeeMixingPlant";
import createEmployeeMixingPlant from "./createEmployeeMixingPlant";
import deleteEmployeeMixingPlant from "./deleteEmployeeMixingPlant";
import getEmployeeOrganisation from "./getEmployeeOrganisation";
import createEmployeeOrganisation from "./createEmployeeOrganisation";
import deleteEmployeeOrganisation from "./deleteEmployeeOrganisation";

export {
    getEmployee, createEmployee, updateEmployee, deleteEmployee, getEmployees, getEmployeeCrew, createEmployeeCrew, deleteEmployeeCrew,
    getEmployeeMixingPlant, createEmployeeMixingPlant, deleteEmployeeMixingPlant,
    getEmployeeOrganisation, createEmployeeOrganisation, deleteEmployeeOrganisation,
};

export default {
    getEmployee, createEmployee, updateEmployee, deleteEmployee, getEmployees, getEmployeeCrew, createEmployeeCrew, deleteEmployeeCrew,
    getEmployeeMixingPlant, createEmployeeMixingPlant, deleteEmployeeMixingPlant,
    getEmployeeOrganisation, createEmployeeOrganisation, deleteEmployeeOrganisation,
};
