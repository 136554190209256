import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import userActions from "../../../actions/user";
import settingsActions from "../../../actions/settings";

import AppContainer from "./AppContainer";

const mapStateToProps = ({ user, notification, settings }) => ({
  user: user,
  isLoggedIn: user.isLoggedIn,
  isPasswordExpired: user.isPasswordExpired,
  isSetNewPassword: user.isSetNewPassword,
  userLanguage: user.userLanguage,
  notification: notification.notification,
  settings: settings.settings
});

const mapDispatchToProps = dispatch => ({
  ...userActions,
  ...settingsActions,
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
