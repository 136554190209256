import { CHECK_POINT_DEFINITION_TYPES, CHECK_POINT_DEFINITION_VISIBILITY_TYPES } from "../../config/constants";

const allowEditStateCodes = [0, 10, 20, 30];
const requiredStateCodes = [10, 20, 30];

export const convertRecord2CheckpointDefinition = (record) => {
  var res = {
    Name: record.name,
    IsDeleted: record.isDeleted,
    SortOrder: record.sortOrder,
    AllowEditStateCodes: allowEditStateCodes,
    RequiredStateCodes: record.isRequired ? requiredStateCodes : [],
    TypeCode:
      (record.TypeCodeText ? CHECK_POINT_DEFINITION_TYPES.Text : CHECK_POINT_DEFINITION_TYPES.None) |
      (record.TypeCodeFlag ? CHECK_POINT_DEFINITION_TYPES.Flag : CHECK_POINT_DEFINITION_TYPES.None) |
      (record.TypeCodeDate ? CHECK_POINT_DEFINITION_TYPES.Date : CHECK_POINT_DEFINITION_TYPES.None) |
      (record.TypeCodeTime ? CHECK_POINT_DEFINITION_TYPES.Time : CHECK_POINT_DEFINITION_TYPES.None),
    VisibilityCode: (record.dispositionMonitor ? CHECK_POINT_DEFINITION_VISIBILITY_TYPES.DispositionMonitor : CHECK_POINT_DEFINITION_VISIBILITY_TYPES.None) | CHECK_POINT_DEFINITION_VISIBILITY_TYPES.Checklist,
    Organisation: { ID: record.organisation.ID }
  };

  if (record.id !== undefined)
    res.ID = record.id;

  return res;
};

export const convertCheckpointDefinition2Record = (checkpointDefinition) => {
  return {
    id: checkpointDefinition.ID,
    name: checkpointDefinition.Name,
    isDeleted: checkpointDefinition.IsDeleted,
    sortOrder: checkpointDefinition.SortOrder,
    isRequired: checkpointDefinition.RequiredStateCodes && checkpointDefinition.RequiredStateCodes.length > 0,
    TypeCodeText: (checkpointDefinition.TypeCode & CHECK_POINT_DEFINITION_TYPES.Text) != 0,
    TypeCodeFlag: (checkpointDefinition.TypeCode & CHECK_POINT_DEFINITION_TYPES.Flag) != 0,
    TypeCodeDate: (checkpointDefinition.TypeCode & CHECK_POINT_DEFINITION_TYPES.Date) != 0,
    TypeCodeTime: (checkpointDefinition.TypeCode & CHECK_POINT_DEFINITION_TYPES.Time) != 0,
    dispositionMonitor: checkpointDefinition.VisibilityCode && (checkpointDefinition.VisibilityCode & 2) != 0,
    organisation: { ID: checkpointDefinition.Organisation.ID }
  }
}