import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";

import projectActions from "../../actions/project";
import organisationActions from "../../actions/organisation";
import employeeActions from "../../actions/employee";
import customerActions from "../../actions/customer";
import mixingPlantActions from "../../actions/mixingPlant";
import mixtureOrderActions from "../../actions/mixtureOrder";
import projectActivitiesActions from "../../actions/projectActivities";
import projectInvoicesActions from "../../actions/projectInvoices";
import companyActions from "../../actions/company";
import { setDataIsObject } from "../../actions/setDataIsObject";
import {
  importOfferDocument, exportOfferDocument, deleteOfferDocument,
  importTenderDocument, exportTenderDocument, deleteTenderDocument
} from "../../actions/project";
import projectCorrespondenceActions from "../../actions/projectCorrespondenceEntries";

import Projects from "./Projects";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ user, project, organisation, employee, customer, mixingPlant, mixtureOrder, projectActivity, projectInvoices, company, projectCorrespondenceEntries }) => ({
  project: project.project,
  projects: project.projects,
  organisations: organisation.organisations,
  employee: employee.employee,
  employees: employee.employees,
  customer: customer.customer,
  mixingPlant: mixingPlant.mixingPlant,
  mixtureOrder: mixtureOrder.mixtureOrder,
  projectActivity: projectActivity.projectActivity,
  projectInvoices: projectInvoices.projectInvoices,
  projectCorrespondenceEntries: projectCorrespondenceEntries.projectCorrespondenceEntries,
  user: user,
  company: company.company,
  isFetching: isStateFetching([
    project, organisation, employee, customer, mixingPlant, mixtureOrder, projectActivity
  ])
});

const mapDispatchToProps = {
  ...projectActions,
  ...organisationActions,
  ...employeeActions,
  ...customerActions,
  ...mixingPlantActions,
  ...mixtureOrderActions,
  ...projectActivitiesActions,
  ...projectInvoicesActions,
  ...companyActions,
  ...projectCorrespondenceActions,
  importOfferDocument,
  exportOfferDocument,
  deleteOfferDocument,
  importTenderDocument,
  exportTenderDocument,
  deleteTenderDocument,
  setDataIsObject
};

const connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Projects));
export default withNamespaces(["common"])(connected);
