import odata from 'odata-client';
import {
  GET_DRIVER_ENTRY_REQUEST,
  GET_DRIVER_ENTRY_ERROR,
  GET_DRIVER_ENTRY_SUCCESS,
  TOKEN_EXPIRED
} from '../../types';
import { getServiceUrl, getDefaultHeader } from '../../../utils';
import RESOURCES from '../../../api/resources';
import { showNotification } from '../../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const getDriverEntryRequest = () => ({
    type: GET_DRIVER_ENTRY_REQUEST,
});

export const getDriverEntryError = errorOrResults => ({
    type: GET_DRIVER_ENTRY_ERROR,
    error: errorOrResults.message,
});

export const getDriverEntrySuccess = payload => ({
    type: GET_DRIVER_ENTRY_SUCCESS,
    payload,
});

export default (ID) => async (dispatch, getState) => {
    dispatch(getDriverEntryRequest());

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.DriverEntries, ID);
    query.expand("Driver($select=ID,Name,DriverCompany)")
    query.expand("MixingPlant")
    query.expand("Pensum($select=ID)")
    const errorOrResult = await query.get({
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const body = JSON.parse(res.body);
                const { Error: { Code, Message } } = body
                let errorKey = "GENERAL_SERVER_ERROR"
                if (Code === "MessageCorrelationFailedTryAgain" || Code === "MessageCorrelationFailed") {
                    errorKey = Message
                }
                return new Error(errorKey);
            } else if (!ok) {
                console.log('get driver entry api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const { "@odata.context": omit, ...payload } = body;

                return payload;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(getDriverEntryError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return;
    }

    dispatch(getDriverEntrySuccess(errorOrResult));

    return errorOrResult;
};