import odata from 'odata-client';
import {
    CREATE_PENSUM_REQUEST,
    CREATE_PENSUM_ERROR,
    CREATE_PENSUM_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const createPensumsRequest = () => ({
    type: CREATE_PENSUM_REQUEST,
});

export const createPensumsError = errorOrResults => ({
    type: CREATE_PENSUM_ERROR,
    error: errorOrResults.message,
});

export const createPensumsSuccess = (payload, fullState) => ({
    type: CREATE_PENSUM_SUCCESS,
    payload,
    fullState
});

export default (pensum, AddDefaultEntries = true) => async (dispatch, getState) => {
    dispatch(createPensumsRequest());

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.Pensums);
    if (AddDefaultEntries === false) query.custom("AddDefaultEntries", "0")
    const errorOrResult = await query.post(pensum, {
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            "X-IAS-SessionID": `${getState().user.changeMessageToken}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;
            if (res.statusCode === 500) {
                const body = JSON.parse(res.body);
                const { Error: { Code, Message } } = body
                let errorKey = "GENERAL_SERVER_ERROR"
                if (Code === "MessageCorrelationFailedTryAgain" || Code === "MessageCorrelationFailed") {
                    errorKey = Message
                }
                return new Error(errorKey);
            } else if (!ok) {
                console.log('create pensum api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const { "@odata.context": omit, ...payload } = body;
                
                return payload;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(createPensumsError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return;
    }

    dispatch(createPensumsSuccess({ ...pensum, ...errorOrResult }, getState()));

    return errorOrResult;
};