import odata from 'odata-client';
import {
    GET_MIXTURE_ENTRIES_REQUEST,
    GET_MIXTURE_ENTRIES_ERROR,
    GET_MIXTURE_ENTRIES_SUCCESS,
    TOKEN_EXPIRED
} from '../../types';
import { getServiceUrl, getDefaultHeader } from '../../../utils';
import RESOURCES from '../../../api/resources';
import { showNotification } from '../../notification';
/*import getToken from '../user/getToken';*/

export const tokenExpired = () => ({    
    type: TOKEN_EXPIRED
});

export const getMixtureEntriesRequest = () => ({
    type: GET_MIXTURE_ENTRIES_REQUEST,
});

export const getMixtureEntriesError = errorOrResults => ({
    type: GET_MIXTURE_ENTRIES_ERROR,
    error: errorOrResults.message,
});

export const getMixtureEntriesSuccess = payload => ({
    type: GET_MIXTURE_ENTRIES_SUCCESS,
    payload,
});

export default (pensumIds) => async (dispatch, getState) => {
    dispatch(getMixtureEntriesRequest());
    
    const query = odata({service: getServiceUrl()}).resource(RESOURCES.MixtureEntries);
    query.expand(`Mixture($select=ID,Name,;$expand=Order($expand=MixingPlant))`);
    query.expand(`Pensum($select=ID)`);
    if (pensumIds && pensumIds.length > 0) {
        query.filter(`Pensum/ID in (${pensumIds})`)
    }
    const errorOrResult = await query.get({
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('get equipment entries api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const payload = body.value;
                const mixtureEntriesById = {}
                payload.forEach(obj => {
                    mixtureEntriesById[obj.ID] = obj
                })

                return mixtureEntriesById;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(getMixtureEntriesError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return;
    }

    dispatch(getMixtureEntriesSuccess(errorOrResult));

    return errorOrResult;
};