import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import crewActions from "../../actions/crew";
import organisationActions from "../../actions/organisation";
import { setDataIsObject } from "../../actions/setDataIsObject";
import Crew from "./Crew";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ user, crew, organisation }) => ({
  user: user,
  crew: crew.crew,
  organisations: organisation.organisations,
  isFetching: isStateFetching([
    crew,
    organisation
  ])
});

const mapDispatchToProps = {
  ...crewActions,
  ...organisationActions,
  setDataIsObject,
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Crew);
export default withNamespaces(["common"])(connected);
