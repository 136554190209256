import odata from 'odata-client';
import {
  CREATE_EMPLOYEE_CREW_REQUEST,
  CREATE_EMPLOYEE_CREW_ERROR,
  CREATE_EMPLOYEE_CREW_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const CreateEmployeeCrewRequest = () => ({
  type: CREATE_EMPLOYEE_CREW_REQUEST
});

export const CreateEmployeeCrewError = errorOrResult => ({
  type: CREATE_EMPLOYEE_CREW_ERROR,
  error: errorOrResult.message
});

export const CreateEmployeeCrewSuccess = payload => ({
  type: CREATE_EMPLOYEE_CREW_SUCCESS,
  payload
});

export default (employee, crew) => async (dispatch, getState) => {

  dispatch(CreateEmployeeCrewRequest());

  const url = `${getServiceUrl()}${RESOURCES.Crews}(${crew.ID})`;
  const query = odata({ service: getServiceUrl() }).resource(RESOURCES.Employees, employee.ID).resource(RESOURCES.Crews).resource(RESOURCES.Ref);
  const errorOrResult = await query.put({ "@odata.id": url }, {
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    },
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;
      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        console.log('Create employee crew api error', res.toJSON());
        const body = JSON.parse(res.body);
        const { Error: { Message } } = body
        const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
        return new Error(errorKey);
      } else {
        return crew;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    })

  if (errorOrResult instanceof Error) {
    dispatch(CreateEmployeeCrewError(errorOrResult, CREATE_EMPLOYEE_CREW_ERROR));
    console.log("CODE ERROR: ", errorOrResult.message);
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_CREATE_EMPLOYEE_CREW", "success"))
  }

  dispatch(CreateEmployeeCrewSuccess(errorOrResult));

  return errorOrResult;
};