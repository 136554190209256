import odata from 'odata-client';
import {
    GET_PENSUM_REQUEST,
    GET_PENSUM_ERROR,
    GET_PENSUM_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const getPensumsRequest = () => ({
    type: GET_PENSUM_REQUEST,
});

export const getPensumsError = errorOrResults => ({
    type: GET_PENSUM_ERROR,
    error: errorOrResults.message,
});

export const getPensumsSuccess = payload => ({
    type: GET_PENSUM_SUCCESS,
    payload,
});

export default (ID) => async (dispatch, getState) => {
    dispatch(getPensumsRequest());

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.Pensums, ID);
    query.select('ID,Day,WorkDuration,SortOrder,TotalMixtureAmount,HasInvoice,StateCode,Comment,FlagsCode,IsStateChangePending,PartitionIndex,StartTime');
    query.expand(RESOURCES.PensumQuery)
    const errorOrResult = await query.get({
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const body = JSON.parse(res.body);
                const { Error: { Code, Message } } = body
                let errorKey = "GENERAL_SERVER_ERROR"
                if (Code === "MessageCorrelationFailedTryAgain" || Code === "MessageCorrelationFailed") {
                    errorKey = Message
                }
                return new Error(errorKey);
            } else if (!ok) {
                console.log('get pensum api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const { "@odata.context": omit, ...payload } = body;
                if (payload.ProjectActivityEntries) {
                    payload.ProjectActivityEntries = payload.ProjectActivityEntries.map(obj => obj.ID)
                }
                if (payload.EmployeeEntries) {
                    payload.EmployeeEntries = payload.EmployeeEntries.map(obj => obj.ID)
                }
                if (payload.EquipmentEntries) {
                    payload.EquipmentEntries = payload.EquipmentEntries.map(obj => obj.ID)
                }
                if (payload.MixtureEntries) {
                    payload.MixtureEntries = payload.MixtureEntries.map(obj => obj.ID)
                }
                if (payload.DriverEntries) {
                    payload.DriverEntries = payload.DriverEntries.map(obj => obj.ID)
                }
                return payload;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(getPensumsError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return;
    }

    dispatch(getPensumsSuccess(errorOrResult));

    return errorOrResult;
};