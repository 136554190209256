import odata from 'odata-client';
import {
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_ERROR,
    GET_PROJECTS_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';
/*import getToken from '../user/getToken';*/

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const getProjectsRequest = () => ({
    type: GET_PROJECTS_REQUEST,
});

export const getProjectsError = errorOrResults => ({
    type: GET_PROJECTS_ERROR,
    error: errorOrResults.message,
});

export const getProjectsSuccess = payload => ({
    type: GET_PROJECTS_SUCCESS,
    payload,
});

export default () => async (dispatch, getState) => {
    dispatch(getProjectsRequest())
    const storeState = getState()

    const query = odata({ service: getServiceUrl() }).resource(RESOURCES.Projects).select("ID, CostCenter, Name, WorkExecutionEstimatePercentage, WorkExecutionEstimateOrderTotalAmount, RemainingAmountToInvoice, WorkExecutionEstimateActualInvoicedAmount, OrderTotalAmount, StateCode, ConstructionBeginTimeUtc, AcceptanceTimeUtc, FinalInvoiceTimeUtc, WarrantyEndTimeUtc, OrderTotalAmountNextYear, StageOfCompletion, RemainingAmountToInvoiceCurrentYear");
    query.expand("Organisation($select=ID, ShortName, Name)");
    query.expand("Supervisor($select=ID, LastName, FirstNames)");
    query.expand("Customer($select=ID, Name)");
    //query.expand(RESOURCES.DefaultMixingPlant);
    //query.expand(RESOURCES.DefaultMixtureOrder);
    /*if (!storeState.project.dataIsObject) {
        query.expand(RESOURCES.OfferDocument);
        query.expand(RESOURCES.TenderDocument);
        query.expand(RESOURCES.Invoices);
    }*/
    query.orderby("Name, ID");
    const errorOrResult = await query.get({
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('get projects api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const payload = body.value;
                if (storeState.project.dataIsObject) {
                    const projectsById = {}
                    payload.forEach(obj => {
                        projectsById[obj.ID] = obj
                    })
                    return projectsById;
                }

                return payload
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(getProjectsError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return;
    }

    dispatch(getProjectsSuccess(errorOrResult));
};