import React, { Component } from "react";
import EmployeeTable from "../Employee/EmployeeTable";
import EmployeeDetailsForm from "../Employee/EmployeeDetailsForm";

import _ from 'lodash';
import Mode from "../Projects/ModeEnum";
import Loader from '../../core/components/Loader/LinearProgress'
import { filterOrganisations, filterEntities, getOrganisation } from "../Common/common";

class Employee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      mode: Mode.Uninit,
      dataRow: null,
      employee: null,
      employees: null,
      onRowAdd: props.createEmployee,
      onRowUpdate: props.updateEmployee,
      onRowDelete: props.deleteEmployee,
      editable: true,
      showTable: true
    };

    this.onChangeMode = this.onChangeMode.bind(this)
  }

  async onChangeMode(newMode, newDataRow, redirected) {

    const { getEmployee, getEmployeeCrew, getEmployeeMixingPlant } = this.props;
    const { dataRow } = this.state;

    var employee = null;
    if ((newDataRow !== undefined && newDataRow !== null)) {
      if (newMode === Mode.Update) {
        await getEmployee(newDataRow.ID);
        employee = this.props.employee && this.props.employee !== null && this.props.employee.length > 0 ? this.props.employee[0] : null;
        var errorOrResult = await getEmployeeCrew(employee)
        if (!(errorOrResult instanceof Error)) {
          if (employee.Crews === undefined || employee.Crews === null)
            employee.Crews = [];
          Object.values(errorOrResult).forEach(c => employee.Crews.push(c));
        }
        var errorOrResult = await getEmployeeMixingPlant(employee)
        if (!(errorOrResult instanceof Error)) {
          if (employee.MixingPlants === undefined || employee.MixingPlants === null)
            employee.MixingPlants = [];
          Object.values(errorOrResult).forEach(c => employee.MixingPlants.push(c));
        }
      }
      else if (newMode === Mode.Uninit) {
        employee = newDataRow; //{ ...dataRow, Crews: newDataRow.Crews, MixingPlants: newDataRow.MixingPlants };
      }
    }
    else {
      await getEmployee(undefined, "ID eq 0");
      employee = null;
    }
    this.setState({ mode: newMode, dataRow: employee, redirected });
  }

  deleteEmployeeCrewHandler = (employee, crew) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (employee && crew) {
          const errorOrResult = await this.props.deleteEmployeeCrew(employee, crew);
          if (!(errorOrResult instanceof Error)) {
            if (employee.Crews !== null) {
              employee.Crews.splice(employee.Crews.indexOf(crew), 1);
            }
            this.setState(prevState => {
              const employees = [...prevState.employees];
              employees[employees.indexOf(employee)] = employee;
              return { ...prevState, employees: employees, dataRow: employee };
            });
            await this.onChangeMode(Mode.Update, employee);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  createEmployeeCrewHandler = (employee, crew) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (employee && crew) {
          const errorOrResult = await this.props.createEmployeeCrew(employee, crew);
          if (!(errorOrResult instanceof Error)) {
            if (employee.Crews === null)
              employee.Crews = [];
            employee.Crews.push(errorOrResult);
            this.setState(prevState => {
              const employees = [...prevState.employees];
              employees[employees.indexOf(employee)] = employee;
              return { ...prevState, employees: employees, dataRow: employee };
            });
            await this.onChangeMode(Mode.Update, employee);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  deleteEmployeeMixingPlantHandler = (employee, entity) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (employee && entity) {
          const errorOrResult = await this.props.deleteEmployeeMixingPlant(employee, entity);
          if (!(errorOrResult instanceof Error)) {
            if (employee.MixingPlants !== null) {
              employee.MixingPlants.splice(employee.MixingPlants.indexOf(entity), 1);
            }
            this.setState(prevState => {
              const employees = [...prevState.employees];
              employees[employees.indexOf(employee)] = employee;
              return { ...prevState, employees: employees, dataRow: employee };
            });
            await this.onChangeMode(Mode.Update, employee);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  createEmployeeMixingPlantHandler = (employee, entity) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (employee && entity) {
          const errorOrResult = await this.props.createEmployeeMixingPlant(employee, entity);
          if (!(errorOrResult instanceof Error)) {
            if (employee.MixingPlants === null)
              employee.MixingPlants = [];
            employee.MixingPlants.push(errorOrResult);
            this.setState(prevState => {
              const employees = [...prevState.employees];
              employees[employees.indexOf(employee)] = employee;
              return { ...prevState, employees: employees, dataRow: employee };
            });
            await this.onChangeMode(Mode.Update, employee);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  deleteEmployeeOrganisationHandler = (employee, organisation) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (employee && organisation) {
          const errorOrResult = await this.props.deleteEmployeeOrganisation(employee, organisation);
          if (!(errorOrResult instanceof Error)) {
            if (employee.Organisations !== null) {
              employee.Organisations.splice(employee.Organisations.indexOf(organisation), 1);
            }
            this.setState(prevState => {
              const employees = [...prevState.employees];
              employees[employees.indexOf(employee)] = employee;
              return { ...prevState, employees: employees, dataRow: employee };
            });
            await this.onChangeMode(Mode.Update, employee);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  createEmployeeOrganisationHandler = (employee, organisation) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (employee && organisation) {
          const errorOrResult = await this.props.createEmployeeOrganisation(employee, organisation);
          if (!(errorOrResult instanceof Error)) {
            if (employee.Organisations === null)
              employee.Organisations = [];
            employee.Organisations.push(errorOrResult);
            this.setState(prevState => {
              const employees = [...prevState.employees];
              employees[employees.indexOf(employee)] = employee;
              return { ...prevState, employees: employees, dataRow: employee };
            });
            await this.onChangeMode(Mode.Update, employee);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })


  rowUpdateHandler = (newData, oldData) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (oldData) {
          const errorOrResult = await this.state.onRowUpdate({
            ...newData
          });
          if (!(errorOrResult instanceof Error)) {
            this.setState(prevState => {
              const employees = [...prevState.employees];
              employees[employees.indexOf(oldData)] = newData;
              return { ...prevState, employees: employees, dataRow: newData };
            });
            await this.onChangeMode(Mode.Update, newData);
            this.setNewRow(errorOrResult.ID)
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  async componentDidMount() {
    const { getEmployees, getOrganisations, getCrew, getMixingPlant, setDataIsObject } = this.props;
    await setDataIsObject(false);
    await getEmployees();
    await getOrganisations();
    await getCrew();
    await getMixingPlant();

    const { employees, employee } = this.props

    const organisationFilter = document.getElementsByClassName("MuiSelect-selectMenu")[0];
    const organisationSelect = document.getElementsByClassName("MuiSelect-selectMenu")[1];
    const organisationFilterContainer = document.getElementsByClassName("MuiInputBase-formControl")[1];
    if (organisationFilter !== undefined) {
      organisationFilter.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationSelect !== undefined) {
      organisationSelect.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationFilterContainer !== undefined) {
      organisationFilterContainer.setAttribute("id", "org-select")
    }

    const crewFilterContainer = document.getElementsByClassName("MuiInputBase-formControl")[2];
    const tableHeader = document.getElementsByClassName("MuiToolbar-gutters")[1];
    if (crewFilterContainer !== undefined) {
      crewFilterContainer.setAttribute("id", "crew-select")
    }
    if (tableHeader !== undefined) {
      tableHeader.style.marginBottom = "35px";
    }

    let redirected = false
    // const { history } = this.props;
    // const { location: { pathname } } = history;
    // const paths = pathname.split("/")
    // let redirected = false
    // if (paths.length > 1 && employees && employees.length > 0) {
    //   const data = employees.find(obj => obj.ID === Number(paths[2]))
    //   if (data) {
    //     redirected = true
    //     this.onChangeMode(Mode.Update, data, redirected)
    //   }
    // }

    this.setState({ isMounted: true, employees: employees, /*dataRow: employee,*/ redirected });
  }

  componentWillUnmount() {
    if (document.getElementsByClassName("MuiSelect-selectMenu")[0] !== undefined) {
      document.getElementsByClassName("MuiSelect-selectMenu")[0].removeEventListener("click", this.expandPopup);
    }
  }

  expandPopup = async () => {
    await new Promise(resolve => setTimeout(() => resolve(), 100));
    const { organisations } = this.props;
    const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
    /*const waitUntilPopupIsMounted = async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 100));
      if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }
    };*/

    if (organisationPopup) {
      const orgsList = organisationPopup.childNodes[0].childNodes;
      if (orgsList) {
        const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
        let i = 0;
        orgsList.forEach(orgElement => {
          const orgObj = organisations.find(item => item.ShortName === orgElement.getAttribute("data-value"));
          const orgText = document.getElementsByClassName("MuiTypography-body1")[i];

          if (orgObj) {
            orgText.setAttribute("data-value", orgObj.ShortName);
            if (orgObj.Level === topLevel) {
              orgText.style.marginLeft = "0px";
            } else if (orgObj.Level > topLevel) {
              const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 5;
              orgText.style.paddingLeft = `${margin}px`;
            }
          }

          i++;
        })
      }
      organisationPopup.setAttribute("style", organisationPopup.getAttribute("style").replace(" width: 250px;", ""));
    } /*else if (!organisationPopup) {
      await waitUntilPopupIsMounted();
    }*/
  }

  sortData = (data) => {
    if (data) {
      return data.sort((a, b) => {
        const resultLastName = a.LastName.localeCompare(b.LastName, undefined, { sensitivity: 'base' })
        if (resultLastName !== 0)
          return resultLastName
        if (a.FirstNames && b.FirstNames) {
          const resultFirstNames = a.FirstNames.localeCompare(b.FirstNames, undefined, { sensitivity: 'base' })
          if (resultFirstNames !== 0)
            return resultFirstNames
        }
        return a.ID - b.ID
      })
    }
    return null
  }

  setNewRow = (id) => this.setState({ newRow: id })

  toggleTable = (open) => {
    this.setState({ showTable: open })
    this.setState({ showTable: true })
  }

  render() {
    const { isMounted, showTable, mode, dataRow, ...rest } = this.state;
    const { employees, organisations, crews, mixingPlants, settings, t, isFetching } = this.props;
    const sortedEmployee = employees && employees.length > 0
      ? this.sortData(employees) : [];

    var filteredCrews = [];
    if (crews && crews != null && crews.length > 0) {
      filteredCrews = filterEntities(crews, this.props.user.organisation, this.props.user.IsParentLogin);
    }

    var filteredMixingPlants = [];
    if (mixingPlants && mixingPlants != null && mixingPlants.length > 0) {
      filteredMixingPlants = filterEntities(mixingPlants, this.props.user.organisation, this.props.user.IsParentLogin);
    }

    var filteredOrganisations = [];
    if (organisations && organisations != null && organisations.length > 0) {
      filteredOrganisations = filterOrganisations(organisations, this.props.user.organisation, this.props.user.IsParentLogin);
    }

    return (

      <div
        style={{
          margin: "0 auto",
          width: "100%",
          marginTop: isFetching && !isMounted ? 0 : 30,
        }}
      >
        {
          isMounted ? (
            showTable && mode !== Mode.Update ?
              <EmployeeTable
                title={t('employee')}
                data={sortedEmployee}
                organisations={organisations}
                crews={crews}
                settings={settings}
                toggleTable={this.toggleTable}
                setNewRow={this.setNewRow}
                onChangeMode={this.onChangeMode}
                t={t}
                {...rest}
                {...this.props}
              /> :

              <EmployeeDetailsForm
                {...rest}
                title={t('employee')}
                dataRow={dataRow}
                mode={mode}
                crews={filteredCrews}
                mixingPlants={filteredMixingPlants}
                organisations={filteredOrganisations}
                onChangeMode={this.onChangeMode}
                createEmployeeCrewHandler={this.createEmployeeCrewHandler}
                deleteEmployeeCrewHandler={this.deleteEmployeeCrewHandler}
                createEmployeeMixingPlantHandler={this.createEmployeeMixingPlantHandler}
                deleteEmployeeMixingPlantHandler={this.deleteEmployeeMixingPlantHandler}
                t={t}
              //lng={lng}
              //user={user}
              />

          ) : <Loader />
        }

      </div>

    );
  }
}

export default Employee;
