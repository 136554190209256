import React from "react";
import { isEqualMasterData, isValidEmail } from "../../utils";
import { COMMUNICATION_CHANNELS } from "../../config/constants";
import Mode from "../Projects/ModeEnum";

import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
/* import InputLabel from '@material-ui/core/InputLabel'; */
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FolderSharedIcon from '@material-ui/icons/FolderShared';

import ListItemText from "@material-ui/core/ListItemText";
import Input from '@material-ui/core/Input';

import './index.css';
import { expandPopupOrganisation, filterOrganisations, isEditable, filterEntities, getOrganisation } from "../Common/common";

class EmployeeTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      organisations: props.organisations ? props.organisations : '',
      crews: props.crews ? props.crews : '',
      settings: props.settings ? props.settings : '',
      editable: props.editable ? props.editable : '',
      selectedFilters: [],
      newData: null,
      error: null,
    };
  }

  componentDidMount() {
    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }

    const {
      organisations,
      crews,
      settings,
      selectedFilters,
    } = this.state;
    const { t } = this.props;
    const lookupOrgs = {};
    const lookupColors = {};
    const filteredLookupOrgs = {};

    var lookupCrews = new Map();
    var filteredLookupCrews = new Map();

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
      var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
      var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
      filteredOrgs.forEach(org => {
        filteredLookupOrgs[org.Name] = org.Name;
      })
    }

    lookupCrews.set(null, '\xA0');
    if (crews.length > 0) {
      crews.forEach(crew => {
        lookupCrews.set(crew.Name, crew.Name);
      })
      var filteredCrews = filterEntities(crews, this.props.user.organisation, this.props.user.IsParentLogin);
      filteredLookupCrews.set(null, '\xA0');
      filteredCrews.forEach(crew => {
        filteredLookupCrews.set(crew.Name, crew.Name);
      })
    }

    lookupColors[""] = t('select-option-no-selection');
    if (settings !== "" && settings.SupervisorColors !== undefined && settings.SupervisorColors.length > 0) {
      var colors = settings.SupervisorColors.replace('[', '').replace(']', '').split(',')
      if (colors.length > 0) {
        colors.forEach(color => {
          var c = parseInt(color);
          lookupColors[c] = '\xA0';
        })
      }
    }

    const columns = [
      {
        title: `${t('organisation')} *`, field: 'Organisation.Name', lookup: lookupOrgs, defaultFilter: this.state.selectedFilters,
        initialEditValue: selectedFilters.length > 0 ? selectedFilters : null,
        render: rowData => (
          rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
        ),
        validate: ({ Organisation }) => Organisation !== undefined && Organisation !== null,
        editComponent: props => {
          return (
            <FormControl>
              <Select
                labelId="org-label"
                id="organisation"
                className="organisationSelect"
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                autoFocus
                error={props.value === null || props.value === undefined}
                onClick={async () => {
                  await new Promise(resolve => setTimeout(() => resolve(), 100));
                  const { organisations } = this.state;
                  var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
                  var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
                  expandPopupOrganisation(filteredOrgs, "MuiListItem-button");
                }}
              >
                {Object.keys(props.columnDef.lookup).map(key => (
                  (selectedFilters.length === 0 || selectedFilters.includes(key)) && filteredLookupOrgs[key] !== undefined ?
                    <MenuItem
                      value={key}
                    >
                      <em>{props.columnDef.lookup[key]}</em>
                    </MenuItem>
                    : null
                ))}
              </Select>
            </FormControl>
          )
        }
      },
      {
        title: t('construction-crew'), field: 'Crew.Name', filtering: true, lookup: lookupCrews,
        customFilterAndSearch: (term, rowData) =>
          //search
          (
            (typeof term) === "string" &&
            (
              term === '' ||
              (rowData.Crew != null && rowData.Crew.Name.includes(term))
            )
          ) ||
          //filter
          (
            (typeof term) !== "string" &&
            (
              term.length === 0 ||
              (rowData.Crew == null && term.includes(null)) ||
              (rowData.Crew != null && term.includes(rowData.Crew.Name))
            )
          ),
        render: rowData => (
          rowData.Crew != null ? <>{rowData.Crew.Name}</> : <>{' '}</>
        ),
        filterComponent: props => (
          <Select
            multiple
            value={props.columnDef.tableData.filterValue || []}
            renderValue={(selecteds) => Object.keys(selecteds).map((key) => selecteds[key]).join(", ")}
            onChange={(event) => {
              props.columnDef.tableData.filterValue = event.target.value;
              if (props.columnDef.filterOnItemSelect === true)
                props.onFilterChanged(
                  props.columnDef.tableData.id,
                  event.target.value
                );
              else
                props.onFilterChanged(
                  props.columnDef.tableData.id,
                  event.target.value
                );
            }}
            input={
              <Input id={"select-multiple-checkbox" + props.columnDef.tableData.id} />
            }
          >
            {Array.from(props.columnDef.lookup, ([key, value]) => (
              filteredLookupCrews.get(key) !== undefined ?
                <MenuItem key={key} value={key}>
                  <Checkbox checked={props.columnDef.tableData.filterValue !== undefined ? props.columnDef.tableData.filterValue.indexOf(key) > -1 : false} />
                  <ListItemText primary={value} />
                </MenuItem> : null
            ))}
          </Select>
        ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Array.from(props.columnDef.lookup, ([key, value]) => (
              filteredLookupCrews.get(key) !== undefined ?
                <MenuItem
                  value={key}
                >
                  <em>{value}</em>
                </MenuItem>
                : null
            ))}
          </Select>
        )
      },
      {
        title: t('construction-supervisor-color'), field: 'ColorRgb', sorting: false, filtering: false, lookup: lookupColors,
        render: rowData => (rowData.IsSupervisor ? <div style={{ backgroundColor: this.rgbToHex(rowData.ColorRgb), width: '75%' }}>&nbsp;</div> : null),
        editComponent: props => (
          <FormControl>
            {
              props.rowData.IsSupervisor ?
                <Select
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  renderValue={value => <div style={{ backgroundColor: this.rgbToHex(value), width: '75%' }}>{lookupColors[value]}</div>}
                >
                  {Object.keys(props.columnDef.lookup).map(key => (
                    <MenuItem style={{ paddingLeft: '6px', paddingRight: '6px', paddingBottom: '3px', paddingTop: '3px' }}
                      value={key}
                    >
                      <div style={{ backgroundColor: this.rgbToHex(key), height: '100%', width: '110px', paddingLeft: '6px' }}>
                        <em>{props.columnDef.lookup[key]}</em>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
                : null
            }
          </FormControl>
        )
      },
      {
        title: `${t('last-name')} *`, field: 'LastName', sorting: true,
        validate: ({ LastName }) => LastName && LastName.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              id="last-name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('first-names')} *`, field: 'FirstNames', sorting: true,
        validate: (rowData) => (rowData.FirstNames && rowData.FirstNames.trim() !== "")/* || !rowData.hasOwnProperty("FirstNames") || rowData.FirstNames == null*/,
        editComponent: props => (
          <FormControl>
            <TextField
              id="first-names-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('communication-channel-code'), field: 'CommunicationChannelCode', filtering: false,
        render: rowData => (
          t(`communicationChannels.${Object.keys(COMMUNICATION_CHANNELS).find(key => COMMUNICATION_CHANNELS[key] === rowData.CommunicationChannelCode)}`)
        ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Object.entries(COMMUNICATION_CHANNELS).map((channel, i) => {
              const name = t(`communicationChannels.${channel[0]}`)
              const value = channel[1]
              return (
                <MenuItem value={value}>
                  <em>{name}</em>
                </MenuItem>
              )
            })}
          </Select>
        )
      },

      //=========================================================================================================================================================================================================================================================================
      {
        title: t('communication-supervisor-channel-code'), field: 'SupervisorCommunicationChannelCode', filtering: false,
        render: rowData => (rowData.IsSupervisor ?
          t(`communicationChannels.${Object.keys(COMMUNICATION_CHANNELS).find(key => COMMUNICATION_CHANNELS[key] === rowData.SupervisorCommunicationChannelCode)}`) : null
        ),
        editComponent: props => (props.rowData.IsSupervisor ?
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Object.entries(COMMUNICATION_CHANNELS).map((channel, i) => {
              const name = t(`communicationChannels.${channel[0]}`)
              const value = channel[1]
              return (
                <MenuItem value={value} key={i}>
                  <em>{name}</em>
                </MenuItem>
              )
            })}
          </Select>
          : null
        )
      } //=========================================================================================================================================================================================================================================================================
      ,
      {
        title: t('email-address'), field: 'EmailAddress', filtering: false,
        validate: ({ EmailAddress }) => EmailAddress === null || EmailAddress === undefined || (EmailAddress && isValidEmail(EmailAddress)),
        editComponent: props => (
          <FormControl className="input-short">
            <TextField
              id="email-input"
              value={props.value}
              type="text"
              error={props.value && !isValidEmail(props.value)}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        )
      },
      {
        title: t('phone-number'), field: 'PhoneNumber', filtering: false,
        editComponent: props => (
          <FormControl className="input-short">
            <TextField
              id="phone-input"
              value={props.value}
              type="text"
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        )
      },
      {
        title: t('crew-leader'), field: 'IsCrewLeader', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsCrewLeader} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('construction-supervisor'), field: 'IsSupervisor', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsSupervisor} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('dispatcher'), field: 'IsDispatcher', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsDispatcher} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('administration'), field: 'IsAdministration', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsAdministration} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('supplier'), field: 'IsSupplier', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsSupplier} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('key-resource-manager'), field: 'IsKeyResourceManager', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsKeyResourceManager} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('is-deleted'), field: 'IsDeleted', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsDeleted} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('maximum-planning-day-count'), field: "MaximumPlanningDayCount", filtering: false,
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined}
              inputProps={{ maxLength: 5 }}
              onChange={e => this.handleOnChange(props, e)}
            />
          </FormControl>
        ),
      },
    ];

    const actions =
      [
        {
          icon: () => <FolderSharedIcon />,
          tooltip: 'Details',
          onClick: (event, rowData) => this.props.onChangeMode(Mode.Update, rowData),
        }
      ];

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ isMounted: true, initPage, pingedRowIndex: indexOfNewRow, columns: columns, actions: actions })
    }
    else {
      this.setState({ isMounted: true, columns: columns, actions: actions })
    }
  }

  rgbToHex(rgb) {
    if (rgb === null || rgb === "null" || rgb === "")
      rgb = 0x00FFFFFF;

    var hex = (0x00FFFFFF & Number(rgb)).toString(16).padStart(6, '0');
    return "#" + hex;
  }

  handleOnChange(props, e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      props.onChange(e.target.value);
    }
  }

  render() {
    const {
      isMounted,
      organisations,
      initPage,
      pingedRowIndex,
      editable
    } = this.state;
    const { t } = this.props;


    return (
      isMounted ? (
        <MaterialTable
          title={this.state.title}
          columns={this.state.columns}
          data={this.state.data}
          style={{
            paddingLeft: !editable && '2%',
            paddingRight: !editable && '2%',
            fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
            fontSize: `0.875rem`
          }}
          options={{
            pageSize: this.state.data.length > 5 ? 10 : 5,
            pageSizeOptions: this.state.data.length > 5 ? [5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [5],
            paginationType: "normal",
            initialPage: initPage,
            addRowPosition: "first",
            draggable: false,
            filtering: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "left",
            rowStyle: rowData => ({
              backgroundColor: (pingedRowIndex === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
          }}

          onRowClick={((evt, rowData) => { this.setState({ selectedRow: rowData }) })}
          onChangePage={() => this.setState({ pingedRowIndex: undefined })}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            body: {
              emptyDataSourceMessage: t('no-records-to-display'),
              addTooltip: t('add'),
              deleteTooltip: t('delete'),
              editTooltip: t('edit'),
              editRow: {
                saveTooltip: t('save'),
                cancelTooltip: t('cancel'),
                deleteText: t('deleteText')
              }
            },
            pagination: {
              firstTooltip: t('first-page'),
              previousTooltip: t('previous-page'),
              nextTooltip: t('next-page'),
              lastTooltip: t('last-page')
            }
          }}
          editable={editable && {
            isEditable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
            isDeletable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
            onRowAdd: newData =>
              new Promise(resolve => {
                this.setState({
                  newData: newData
                });
                setTimeout(async () => {
                  resolve();
                  const { organisations, crews } = this.state;
                  if (organisations) {
                    const crew = crews ? crews.find(c => newData.Crew != null && c.Name === newData.Crew.Name) : null;
                    const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                    if (newData.EmailAddress && !isValidEmail(newData.EmailAddress)) {
                      const { t, showNotification } = this.props
                      showNotification(t('email-address-invalid'), "error")
                      return
                    }
                    if (newData.IsCrewLeader && (crew === null || crew === undefined)) {
                      const { t, showNotification } = this.props
                      showNotification(t('CrewLeaderRequiresCrew'), "error")
                      return
                    }
                    const errorOrResult = await this.props.onRowAdd({
                      LastName: newData.LastName,
                      FirstNames: newData.FirstNames,
                      IsDeleted: newData.IsDeleted,
                      IsCrewLeader: newData.IsCrewLeader,
                      IsSupervisor: newData.IsSupervisor,
                      IsDispatcher: newData.IsDispatcher,
                      IsAdministration: newData.IsAdministration,
                      IsSupplier: newData.IsSupplier,
                      IsKeyResourceManager: newData.IsKeyResourceManager,
                      ColorRgb: (newData.ColorRgb ? (0xFF000000 | newData.ColorRgb) : newData.ColorRgb),
                      Organisation: {
                        ID: org ? org.ID : null,
                        Name: org ? org.Name : null,
                        ShortName: org ? org.ShortName : null
                      },
                      Crew: crew ? { ID: crew.ID } : null,
                      EmailAddress: newData.EmailAddress,
                      PhoneNumber: newData.PhoneNumber,
                      CommunicationChannelCode: newData.CommunicationChannelCode,
                      //SupervisorCommunicationChannelCode
                      MaximumPlanningDayCount: newData.MaximumPlanningDayCount
                    });
                    if (errorOrResult && !(errorOrResult instanceof Error)) {
                      const { setNewRow, toggleTable } = this.props
                      const { ID } = errorOrResult
                      if (org) {
                        newData.Organisation = org;
                      }
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        newData.ID = ID;
                        data.push(newData);
                        return { ...prevState, data };
                      });
                      setNewRow(ID)
                      toggleTable()
                    }
                  }
                }, 100);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  if (oldData && newData && !isEqualMasterData("Employee", oldData, newData)) {
                    const { organisations, crews } = this.state;
                    if (organisations) {
                      const crew = crews ? crews.find(c => newData.Crew != null && c.Name === newData.Crew.Name) : null;
                      const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                      if (newData.EmailAddress && !isValidEmail(newData.EmailAddress)) {
                        const { t, showNotification } = this.props
                        showNotification(t('email-address-invalid'), "error")
                        return
                      }
                      if (newData.IsCrewLeader && (crew === null || crew === undefined)) {
                        const { t, showNotification } = this.props
                        showNotification(t('CrewLeaderRequiresCrew'), "error")
                        return
                      }
                      const errorOrResult = await this.props.onRowUpdate({
                        ID: newData.ID,
                        LastName: newData.LastName,
                        FirstNames: newData.FirstNames,
                        IsDeleted: newData.IsDeleted,
                        IsCrewLeader: newData.IsCrewLeader,
                        IsSupervisor: newData.IsSupervisor,
                        IsDispatcher: newData.IsDispatcher,
                        IsAdministration: newData.IsAdministration,
                        IsKeyResourceManager: newData.IsKeyResourceManager,
                        IsSupplier: newData.IsSupplier,
                        ColorRgb: (newData.ColorRgb ? (0xFF000000 | newData.ColorRgb) : newData.ColorRgb),
                        Organisation: {
                          ID: org ? org.ID : null,
                          Name: org ? org.Name : null,
                          ShortName: org ? org.ShortName : null
                        },
                        Crew: crew ? { ID: crew.ID } : null,
                        Crews: newData.Crews,
                        MixingPlants: newData.MixingPlants,
                        EmailAddress: newData.EmailAddress,
                        PhoneNumber: newData.PhoneNumber,
                        CommunicationChannelCode: newData.CommunicationChannelCode,
                        //SupervisorCommunicationChannelCode
                        SupervisorCommunicationChannelCode: newData.SupervisorCommunicationChannelCode,
                        MaximumPlanningDayCount: newData.MaximumPlanningDayCount
                      }, oldData.tableData);
                      if (!(errorOrResult instanceof Error)) {
                        const { setNewRow, toggleTable } = this.props
                        if (org) {
                          newData.Organisation = org;
                        }
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }
                  }

                }, 100);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  const errorOrResult = await this.props.onRowDelete(oldData);
                  if (!(errorOrResult instanceof Error)) {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data, pingedRowIndex: undefined };
                    });
                  }
                }, 100);
              })
          }}

          actions={this.state.actions}
        />
      ) : <></>
    );
  }
}

export default EmployeeTable;