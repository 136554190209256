import React from "react";
import moment from 'moment';
import _ from 'lodash';

import MaterialTable, { MTableEditRow } from 'material-table';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { enGB, enUS, de } from 'date-fns/locale'

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { InputAdornment } from "@material-ui/core";
import NumberInput from "../../core/components/Inputs/NumberInput/numberInput";
import { INVOICE_TYPES } from "../../config/constants";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import './index.css';

class ProjectActivityTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.invoices ? props.invoices : [],
      editable: props.editable ? props.editable : '',
      projectRow: props.projectRow ? props.projectRow : null,
      newData: null,
      error: null,
      draftData: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state.data) {
      this.setState({ data: nextProps.invoices ? nextProps.invoices : [], });
    }
  }


  calcCurrentSortOrder(data) {
    if (data === undefined || data === null || data.length === 0)
      return 0;

    const step = 16;
    var maxSortOrder = 0;
    data.forEach(d => { maxSortOrder = (d.SortOrder < maxSortOrder ? maxSortOrder : d.SortOrder); });
    return maxSortOrder + step;
  }

  selectLocale(language) {

    let languages = new Map();
    languages.set("de-DE", de);
    languages.set("en-GB", enGB);
    languages.set("en-US", enUS);

    let res = languages.get(language);
    return res != null ? res : languages["de-DE"];
  }

  handleOnChange(props, e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      props.onChange(e.target.value);
    }
  }

  formatDateMonth = dateTime => moment(dateTime).format('MM.YYYY');

  validateDate(date) {
    const isValidDate = date && `${date}`.toLowerCase() !== "invalid date"

    if (!isValidDate) {
      return false
    }

    return true
  }

  defaultDate = () => {
    let date = new Date()
    let month = date.getMonth();
    let today = date.getDate();
    if (today < 12)
      date.setMonth(month - 1)
    date.setDate(1);
    return date;
  }

  sortData = (data) => {
    data.sort(function (a, b) {
      if (typeof a.Date !== "string") a.Date = moment(typeof a.Date).format("YYYY-MM-DD")
      if (typeof b.Date !== "string") b.Date = moment(typeof b.Date).format("YYYY-MM-DD")
      return a.Date.localeCompare(b.Date);
    });
    return data;
  }

  dataCheck = (data) => {
    let valid = false;
    valid = Boolean(data.Name && data.InvoicedAmount && data.CustomerNumber && data.TypeCode);
    this.setState({ isDataValid: valid });
    return valid;
  }

  render() {
    const { t, lng } = this.props;
    const locale = this.selectLocale(lng);
    const { data } = this.state;
    const sortedData = data && data.length > 0
      ? this.sortData(data) : [];

    /* if (!isDataValid) {
      const title = t('add-invoice');
      const addButton = document.querySelector(`[title=${title}]`);
      addButton && addButton.click();
    } */

    const columns = [
      {
        title: t('date'), field: "Date", type: "date", filtering: false,
        render: rowData => (<p> {this.formatDateMonth(rowData.Date)} </p>),
        editComponent: props => (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={locale}>
            <KeyboardDatePicker
              views={["year", "month"]}
              margin="normal"
              format="MM.yyyy"
              value={props.value || this.defaultDate()}
              defaultValue={this.defaultDate()}
              onChange={e => props.onChange(e)}
              locale={locale}
              okLabel={t('date-picker-label-ok')}
              cancelLabel={t('date-picker-label-cancel')}
              invalidDateMessage={t('date-picker-label-invalid-date-format')}
            />
          </MuiPickersUtilsProvider>
        )
      },
      {
        title: t('customer-number'), field: "CustomerNumber",
        editComponent: props => (
          <FormControl>
            <TextField
              id="customernumber-input"
              value={props.value}
              type="text"
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('type')} *`, field: 'TypeCode', filtering: false,
        validate: ({ TypeCode }) => Number.isInteger(TypeCode),
        render: rowData => (
          t(`invoiceTypes.${Object.keys(INVOICE_TYPES).find(key => INVOICE_TYPES[key] === rowData.TypeCode)}`)
        ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            error={props.value === null || props.value === undefined}
          >
            {Object.entries(INVOICE_TYPES).map((invoice) => {
              const name = t(`invoiceTypes.${invoice[0]}`)
              const value = invoice[1]
              if (value > 0)
                return (
                  <MenuItem value={value}>
                    <em>{name}</em>
                  </MenuItem>
                )
            })}
          </Select>
        )
      },
      {
        title: `${t('name')} *`, field: "Name",
        validate: ({ Name }) => Name && Name.length > 0 && Name.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              onChange={e => props.onChange(e.target.value)}
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('invoiced-amount')} *`, field: "InvoicedAmount", filtering: false,
        validate: ({ InvoicedAmount }) => (InvoicedAmount && InvoicedAmount.length > 0) || typeof InvoicedAmount === "number",
        cellStyle: rowData => ({ textAlign: "left", /*backgroundColor: rowData.TypeCode === 0 ? 'grey' : 'white'*/ }), headerStyle: { textAlign: "left" },
        render: rowData => (
          <p><span className={rowData.TypeCode === 1 && rowData.CustomerNumber ? "invoice-grey-background" : null}>{`${(rowData.InvoicedAmount !== undefined && rowData.InvoicedAmount !== null ? rowData.InvoicedAmount + " €" : '')}`}</span></p>
        ),
        editComponent: props => (
          <FormControl>
            <NumberInput
              id="InvoicedAmount"
              value={props.value}
              type="number"
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              handleChange={e => props.onChange(e.target.value)}
              error={props.value === null || props.value === undefined || props.value.length < 1}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        )
      },
      {
        title: t('actual-amount'), field: "ActualAmount", filtering: false, editable: false,
        render: rowData => (
          <p>{`${(rowData.ActualAmount !== undefined && rowData.ActualAmount !== null ? rowData.ActualAmount + " €" : '')}`}</p>
        ),
      },
      {
        title: t('unbilled-amount-short'), field: "UnbilledAmount", filtering: false,
        render: rowData => (
          <p>{`${(rowData.UnbilledAmount !== undefined && rowData.UnbilledAmount !== null ? rowData.UnbilledAmount + " €" : '')}`}</p>
        ), editComponent: props => (
          <NumberInput
            id="unbilledamount"
            value={Number(props.value)}
            type="number"
            inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
            handleChange={e => props.onChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€</InputAdornment>
              )
            }}
          />
        )
      },
      {
        title: t('precharged-amount-short'), field: "PreChargedAmount", filtering: false,
        render: rowData => (
          <p>{`${(rowData.PreChargedAmount !== undefined && rowData.PreChargedAmount !== null ? rowData.PreChargedAmount + " €" : '')}`}</p>
        ),
        editComponent: props => (
          <NumberInput
            id="prechargedamount"
            value={Number(props.value)}
            type="number"
            inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
            handleChange={e => props.onChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€</InputAdornment>
              )
            }}
          />
        )
      },
      {
        title: t('adjustment-value-short'), field: "AdjustmentValue", filtering: false,
        render: rowData => (
          <p>{`${(rowData.AdjustmentValue !== undefined && rowData.AdjustmentValue !== null ? rowData.AdjustmentValue + " €" : '')}`}</p>
        ),
        editComponent: props => (
          <NumberInput
            id="adjustmentvalue"
            value={Number(props.value)}
            type="number"
            inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
            handleChange={e => props.onChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€</InputAdornment>
              )
            }}
          />
        )
      },
      {
        title: t('invoice-comment'), field: "Comment", filtering: false,
        editComponent: props => (
          <FormControl>
            <InputLabel className="trans-label-name" ></InputLabel>
            <TextField
              id="comment"
              value={props.value}
              type="text"
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        )
      },
      {
        title: t('fifth-workday-report-amount-short'), field: "FifthWorkdayReportAmount", filtering: false, editable: false, render: rowData => (
          <p>{`${(rowData.FifthWorkdayReportAmount !== undefined && rowData.FifthWorkdayReportAmount !== null ? rowData.FifthWorkdayReportAmount + " €" : '')}`}</p>
        )
      },
    ];

    return (
      <MaterialTable
        title={this.state.title}
        columns={columns}
        data={sortedData}
        style={{
          fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
          fontSize: `0.875rem`
        }}
        options={{
          pageSize: 12,
          paginationType: "normal",
          addRowPosition: "first",
          draggable: false,
          filtering: true,
          searchFieldAlignment: "left",
          toolbarButtonAlignment: "left",
        }}
        localization={{
          header: {
            actions: t('actions')
          },
          toolbar: {
            searchTooltip: t('search'),
            searchPlaceholder: t('search')
          },
          body: {
            emptyDataSourceMessage: t('no-records-to-display'),
            addTooltip: t('add-invoice'),
            deleteTooltip: t('delete'),
            editTooltip: t('edit'),
            editRow: {
              saveTooltip: t('save'),
              cancelTooltip: t('cancel'),
              deleteText: t('deleteText')
            }
          },
          pagination: {
            firstTooltip: t('first-page'),
            previousTooltip: t('previous-page'),
            nextTooltip: t('next-page'),
            lastTooltip: t('last-page')
          }
        }}
        components={{
          EditRow: (props) =>
            <MTableEditRow {...props}
              onEditingCanceled={(mode) => {
                this.setState({ draftData: {} });
                props.onEditingCanceled(mode);
              }} />
        }}
        editable={this.props.editable && {
          isDeletable: rowData => true,
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const newDraftData = { ...this.state.draftData, ...newData };
              this.setState({
                newData: newData,
                draftData: newDraftData
              });
              setTimeout(async () => {
                /*  const isDataValid = this.dataCheck(newData) || this.dataCheck(this.state.draftData);
                 if (isDataValid) {
                 if (!this.dataCheck(newData)){
                   newData = this.state.draftData;
                 } */
                const errorOrResult = await this.props.onProjectInvoiceRowAdd({
                  ID: newData.ID,
                  Name: newData.Name,
                  InvoicedAmount: Number(newData.InvoicedAmount),
                  CustomerNumber: newData.CustomerNumber,
                  UnbilledAmount: Number(newData.UnbilledAmount) || 0,
                  PreChargedAmount: Number(newData.PreChargedAmount) || 0,
                  AdjustmentValue: Number(newData.AdjustmentValue) || 0,
                  Comment: newData.Comment,
                  TypeCode: Number(newData.TypeCode),
                  Project: {
                    ID: this.props.projectRow.ID
                  },
                  Date: newData.Date ? moment(newData.Date).format("YYYY-MM-DD") : moment(this.defaultDate()).format("YYYY-MM-DD"),
                });
                if (!(errorOrResult instanceof Error)) {
                  const { ID } = errorOrResult
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    newData.ID = ID;
                    data.push(newData);
                    return { ...prevState, data };
                  });
                  const { getProject } = this.props;
                  await getProject(null, null, "ID eq " + this.props.projectRow.ID);
                  this.forceUpdate();
                  this.setState({ draftData: {} });
                }
                resolve();
                /*  } 
                 if (!isDataValid) {
                   const newDraftData = {...this.state.draftData, ...newData};
                   this.setState({draftData: newDraftData});
                   reject();
                   const title = t('add-invoice');
                   // const addButton = document.querySelector(`[title=${title}]`);
                   // addButton && addButton.click();
                 } */
              }, 100);

            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(async () => {
                if (oldData) {
                  if (newData) {
                    const { Date } = newData
                    if (this.validateDate(Date)) {
                      const errorOrResult = await this.props.onProjectInvoiceRowUpdate({
                        ID: newData.ID,
                        Name: newData.Name,
                        InvoicedAmount: Number(newData.InvoicedAmount),
                        CustomerNumber: newData.CustomerNumber,
                        UnbilledAmount: Number(newData.UnbilledAmount),
                        PreChargedAmount: Number(newData.PreChargedAmount),
                        AdjustmentValue: Number(newData.AdjustmentValue),
                        Comment: newData.Comment,
                        TypeCode: Number(newData.TypeCode),
                        Project: {
                          ID: this.props.projectRow.ID
                        },
                        Date: newData.Date ? moment(newData.Date).format("YYYY-MM-DD") : moment(this.defaultDate()).format("YYYY-MM-DD"),
                      }, oldData.tableData);
                      if (!(errorOrResult instanceof Error)) {
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                        const { getProject } = this.props;
                        await getProject(null, null, "ID eq " + this.props.projectRow.ID);
                        this.forceUpdate();
                      }
                    }
                  }
                }
                resolve();
              }, 100);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(async () => {
                const errorOrResult = await this.props.onProjectInvoiceRowDelete(oldData);
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    if (data.indexOf(oldData) >= 0)
                      data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                  const { getProject } = this.props;
                  await getProject(null, null, "ID eq " + this.props.projectRow.ID);
                }
                resolve();
              }, 100);
            })
        }}
      />
    );
  }
}

export default ProjectActivityTable;