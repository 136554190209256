import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { Droppable } from 'react-beautiful-dnd';

import { PROJECT_STATE_CODES } from '../../../../config/constants';
import { checkIsValidStartTime } from '../../../../utils';

import Pensum from '../Pensum/Pensum';

import ReactSelect from 'react-select';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddListIcon from '@material-ui/icons/PlaylistAdd';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';


import './index.css'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    /*'&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },*/
  },
}))(MenuItem);

const workloadIndications = {
  "grey": "#D9D5D5",
  "red": "#BB9292B3",
  "yellow": "#CEC7046E",
  "green": "#8BB594D1"
}

const Container = styled.div`
  width: 340px;
  min-height: 30px;
  margin-right: 3px;
  margin-bottom: 3px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`

const PensumList = styled.div`
  padding: 6px;
  padding-bottom: 0px;
  transition: background-color 0.2s ease;
  border-radius: 3px 3px 0px 0px;
  background-color: ${props => props.bgColor };
  opacity: ${props => props.isDraggingOver && "0.75"}
  ${props => props.isEmpty ? 'height: 50px;' : 'min-height: 82px;' };
  flex-grow: 1;
`

const AddContainer = styled.div`
  padding: 6px;
  padding-top: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props => props.bgColor};
`

const AddPensum = styled.div`
  padding: 9px;
  padding-top: 17px;
  padding-bottom: 8px;
  background-color: #fcfcfc;
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 1px 1px rgba(100,100,100,0.7);
  -moz-box-shadow: 0px 1px 1px 1px rgba(100,100,100,0.7);
  box-shadow: 0px 1px 1px 1px rgba(100,100,100,0.7);
`

const optionStyles = {
  singleValue: (styles) => ({
      ...styles,
      color: 'rgb(55,55,55)'
  }),
  clearIndicator: (styles) => ({
      ...styles,
      cursor: 'pointer'
  })
};

export default class PensumsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedProjectID: null,
      isOpenAdd: false,
      anchorEl: null,
      pensumStartTime: props.isPensumStartTimeAllowed ? "00:00" : null,
      hint: this.props.hint
    }
  }

  componentDidMount() {
    const { partition } = this.props
    let isValidStartTime = true
  
    if (partition && partition.StartTime && partition.EndTime) {
      const { columnId } = this.props
      const columnDay = moment(columnId.split("/")[1])
      isValidStartTime = checkIsValidStartTime(columnDay, partition.StartTime, partition.EndTime, "00:00")
    }
    this.setState({ isValidStartTime })

    document.addEventListener("keydown", this.escFunction.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    if(event.keyCode === 27) {
      this.closePopup()
    }
  }

  closePopup = () => {
    this.setState({ isOpenAdd: false })
  }

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = () => {
    const { partition, isPensumStartTimeAllowed } = this.props
    let isValidStartTime = true
  
    if (isPensumStartTimeAllowed && partition && partition.StartTime && partition.EndTime) {
      const { columnId } = this.props
      const columnDay = moment(columnId.split("/")[1])
      isValidStartTime = checkIsValidStartTime(columnDay, partition.StartTime, partition.EndTime, "00:00")
    }

    this.setState({
      isOpenAdd: !this.state.isOpenAdd,
      selectedProjectID: null,
      pensumStartTime: isPensumStartTimeAllowed ? "00:00" : null,
      isValidStartTime,
      anchorEl: null
    })
  };

  handleProjectChange = selectedProject => {
    let projectID = null;
    if (selectedProject) {
      projectID = selectedProject.value;
    }
    this.setState({ selectedProjectID: projectID });
  };

  handlePaste = async () => {
    const {
      copiedPensum,
      createPensum,
      createEntry,
      columnId,
      getPensum,
      pensums
    } = this.props
    const pensumsInColumn = pensums.filter(pensum => pensum.Day === columnId.split("/")[1] && pensum.Crew.ID === Number(columnId.split("/")[0]))
    const lastPensumInColumn = pensumsInColumn[pensumsInColumn.length - 1]
    const sortOrder = lastPensumInColumn ? lastPensumInColumn.SortOrder + 16 : 0

    const errorOrResult = await createPensum(
      {
        CreationTimeUtc: moment().utc().format(),
        Day: columnId.split("/")[1],
        Project: { ID: copiedPensum.Project.ID },
        Crew: { ID: parseInt(columnId.split("/")[0]) },
        PartitionIndex: Number(copiedPensum.PartitionIndex),
        StartTime: copiedPensum.StartTime,
        SortOrder: sortOrder,
        Comment: copiedPensum.Comment,
        StateCode: 0
      },
      false
    )

    if (errorOrResult.ID) {
      const waitUntilStateIsSet = async () => {
        await new Promise(resolve => setTimeout(() => resolve(), 100));
        const { updatePensumObj } = this.props
        const pensumCopy = await getPensum(errorOrResult.ID)
        if (pensumCopy && pensumCopy.IsStateChangePending) {
          await waitUntilStateIsSet();
        } else {
          if (pensumCopy) {
            const {
              MixtureEntries,
              DriverEntries,
              ProjectActivityEntries,
              EmployeeEntries,
              EquipmentEntries
            } = copiedPensum
            
            if (MixtureEntries.length > 0) {
              MixtureEntries.forEach(async mix => {
                const resultMixtureEntry = await createEntry("MixtureEntries", {
                  Mixture: mix.Mixture,
                  Pensum: { ID: errorOrResult.ID },
                  MixtureAmount: mix.MixtureAmount,
                  Comment: mix.Comment,
                  SortOrder: mix.SortOrder
                })
                if (resultMixtureEntry && !(resultMixtureEntry instanceof Error)) {
                  const newPensum = await getPensum(errorOrResult.ID)
                  updatePensumObj({
                    ...newPensum,
                    MixtureEntries: [...newPensum.MixtureEntries, resultMixtureEntry.ID],
                  })
                }
              })
            }
            if (DriverEntries.length > 0) {
              DriverEntries.forEach(async mix => {
                const resultDriverEntry = await createEntry("DriverEntries", {
                  Driver: mix.Driver,
                  Pensum: { ID: errorOrResult.ID },
                  MixingPlant: mix.MixingPlant && mix.MixingPlant.ID ? mix.MixingPlant : null,
                  Payload: mix.Payload !== null && mix.Payload !== undefined ? Number(mix.Payload) : null,
                  StartTime: mix.StartTime,
                  Comment: mix.Comment,
                  SortOrder: mix.SortOrder
                })
                if (resultDriverEntry && !(resultDriverEntry instanceof Error)) {
                  const newPensum = await getPensum(errorOrResult.ID)
                  updatePensumObj({
                    ...newPensum,
                    DriverEntries: [...newPensum.DriverEntries, resultDriverEntry.ID]
                  })
                }
              })
            }
            if (ProjectActivityEntries.length > 0) {
              ProjectActivityEntries.forEach(async mix => {
                const resultProjectActivityEntry = await createEntry("ProjectActivityEntries", {
                  ProjectActivity: mix.ProjectActivity,
                  Pensum: { ID: errorOrResult.ID },
                  SortOrder: mix.SortOrder
                })
                if (resultProjectActivityEntry && !(resultProjectActivityEntry instanceof Error)) {
                  const newPensum = await getPensum(errorOrResult.ID)
                  updatePensumObj({
                    ...newPensum,
                    ProjectActivityEntries: [...newPensum.ProjectActivityEntries, resultProjectActivityEntry.ID]
                  })
                }
              })
            }
            if (EmployeeEntries.length > 0) {
              EmployeeEntries.forEach(async mix => {
                const resultEmployeeEntry = await createEntry("EmployeeEntries", {
                  Employee: mix.Employee,
                  Pensum: { ID: errorOrResult.ID },
                  SortOrder: mix.SortOrder
                })
                if (resultEmployeeEntry && !(resultEmployeeEntry instanceof Error)) {
                  const newPensum = await getPensum(errorOrResult.ID)
                  updatePensumObj({
                    ...newPensum,
                    EmployeeEntries: [...newPensum.EmployeeEntries, resultEmployeeEntry.ID]
                  })
                }
              })
            }
            if (EquipmentEntries.length > 0) {
              EquipmentEntries.forEach(async mix => {
                const resultEquipmentEntry = await createEntry("EquipmentEntries", {
                  Equipment: mix.Equipment,
                  Pensum: { ID: errorOrResult.ID },
                  SortOrder: mix.SortOrder
                })
                if (resultEquipmentEntry && !(resultEquipmentEntry instanceof Error)) {
                  const newPensum = await getPensum(errorOrResult.ID)
                  updatePensumObj({
                    ...newPensum,
                    EquipmentEntries: [...newPensum.EquipmentEntries, resultEquipmentEntry.ID]
                  })
                }
              })
            }
          }
        }
      };
      await waitUntilStateIsSet();
    }
  }

  handleMove = () => {
    const { onDragEnd, columnId, movedPensum, tasks, pensumSource, handleMove} = this.props;
    const destinationIndex = tasks.length;
    const dragPensumID = "pensum-" + movedPensum.ID;
    onDragEnd({source: {droppableId: pensumSource.columnId, index: pensumSource.index}, destination: {droppableId: columnId, index: destinationIndex}, draggableId: dragPensumID, reason: "DROP", type: "pensum"})
    handleMove(null, {});
  }

  handleCreatePensum = async () => {
    const {
      createPensum,
      columnId,
      getPensum,
      tasks
    } = this.props
    const { selectedProjectID, pensumStartTime } = this.state
    const lastTaskObj = tasks[tasks.length - 1]
    const lastPensumSortOrder = lastTaskObj && lastTaskObj.pensum && lastTaskObj.pensum.SortOrder
    const sortOrder = lastPensumSortOrder || lastPensumSortOrder === 0 ? lastPensumSortOrder + 16 : 0

    if (selectedProjectID !== null && columnId.split("/")[0] && columnId.split("/")[1] && columnId.split("/")[2]) {
      const errorOrResult = await createPensum({
        CreationTimeUtc: moment().utc().format(),
        Day: columnId.split("/")[1],
        Project: { ID: selectedProjectID },
        Crew: { ID: parseInt(columnId.split("/")[0]) },
        SortOrder: sortOrder,
        StartTime: pensumStartTime !== null ? moment.duration(pensumStartTime).toISOString() : null,
        PartitionIndex: Number(columnId.split("/")[2]),
        StateCode: 0
      })

      if (errorOrResult && errorOrResult.ID) {
        const newPensum = await getPensum(errorOrResult.ID)
        if (newPensum && newPensum.ID) {
          const { getDriverEntry } = this.props
          if (newPensum.DriverEntries) {
            newPensum.DriverEntries.forEach(ID => {
              getDriverEntry(ID)
            })
          }
        }
      }

      this.handleClick()
    }
  }

  handlePensumStartTimeChange = event => {
    const { partition } = this.props
    const { value } = event.target
    let isValidStartTime = true

    if (partition && partition.StartTime && partition.EndTime) {
      const { columnId } = this.props
      const columnDay = moment(columnId.split("/")[1])
      isValidStartTime = checkIsValidStartTime(columnDay, partition.StartTime, partition.EndTime, value)
    }

    this.setState({ pensumStartTime: value, isValidStartTime })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.anchorEl !== this.state.anchorEl
      || nextState.isOpenAdd !== this.state.isOpenAdd
      || nextState.pensumStartTime !== this.state.pensumStartTime
      || nextState.selectedProjectID !== this.state.selectedProjectID
      || nextProps.view !== this.props.view
      || nextProps.activeDNDPensum !== this.props.activeDNDPensum
      || nextProps.hint !== this.state.hint
    ) {
      return true
    }
    if (_.isEqual(nextProps.tasks, this.props.tasks)) {
      return false
    }
    return true
  }

  render() {
    const {
      columnId,
      projects,
      crewsEmpty,
      tasks,
      t,
      specialDaysInColumn,
      workloadIndication,
      copiedPensum,
      movedPensum,
      isPensumStartTimeAllowed,
      partition,
      activeDNDPensum,
    } = this.props
    const { selectedProjectID, isOpenAdd, anchorEl, pensumStartTime, isValidStartTime } = this.state
    const bgColor = workloadIndications[workloadIndication]
    const filteredProjects = projects.filter(project => project.StateCode !== PROJECT_STATE_CODES.Archived)
    const partitionIndex = columnId.split("/")[2]
    let createIsDisabled = selectedProjectID === null

    if (isPensumStartTimeAllowed && !isValidStartTime) {
      createIsDisabled = true
    }

    filteredProjects.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    
    return (
      <Container>
        <Droppable droppableId={columnId} partitionIndex={partitionIndex} type="pensum">
          {(provided, snapshot) => (
            <PensumList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
              bgColor={bgColor}
              isEmpty={tasks.length < 1}
            >
              {specialDaysInColumn.length > 0 ? (
                <div className="specialday">
                  {specialDaysInColumn.map(day => (
                    <Chip
                      label={day.name}
                      color={day.isPublicHoliday ? "primary" : "default"}
                      style={{ marginRight: 3, marginTop: 3, fontSize: 12, fontFamily: 'Open Sans', border: 'thin solid #b8b8b8' }}
                      size="small"
                    />
                  ))}
                </div>
              ) : null}
              {tasks.map((task, i) => (
                <Pensum
                  key={task.ID}
                  index={i}
                  task={task}
                  columnId={columnId}
                  activeDNDPensum={activeDNDPensum}
                  isPensumStartTimeAllowed={isPensumStartTimeAllowed}
                  partition={partition}
                  partitionIndex={partitionIndex}
                  projectActivities={this.props.projectActivities}
                  employee={this.props.employee}
                  mixingPlants={this.props.mixingPlants}
                  mixtureOrders={this.props.mixtureOrders}
                  mixtures={this.props.mixtures}
                  equipment={this.props.equipment}
                  crews={this.props.crews}
                  drivers={this.props.drivers}
                  createEquipmentEntry={this.props.createEquipmentEntry}
                  createMixtureEntry={this.props.createMixtureEntry}
                  createDriverEntry={this.props.createDriverEntry}
                  createEmployeeEntry={this.props.createEmployeeEntry}
                  createProjectActivityEntry={this.props.createProjectActivityEntry}
                  createEntry={this.props.createEntry}
                  editEntry={this.props.editEntry}
                  deleteEntry={this.props.deleteEntry}
                  getPensum={this.props.getPensum}
                  createPensum={this.props.createPensum}
                  updatePensum={this.props.updatePensum}
                  deletePensum={this.props.deletePensum}
                  handleCopy={this.props.handleCopy}
                  showNotification={this.props.showNotification}
                  handleNewObjectInHistory={this.props.handleNewObjectInHistory}
                  sendMessage={this.props.sendMessage}
                  setPensumState={this.props.setPensumState}
                  selectedFromDate={this.props.selectedFromDate}
                  selectedToDate={this.props.selectedToDate}
                  view={this.props.view}
                  t={this.props.t}
                  duplicatedhint={this.props.duplicatedhint}
                  handleMove={this.props.handleMove}
                  movedPensum={this.props.movedPensum}
                />
              ))}
              {provided.placeholder}
            </PensumList>
          )}
        </Droppable>
        {
          isOpenAdd ? (
            <AddContainer bgColor={bgColor}>
              <AddPensum isEmpty={tasks.length < 1}>
                  <ReactSelect
                    className="basic-multi-select"
                    placeholder={t('project')}
                    onChange={this.handleProjectChange}
                    isClearable
                    styles={optionStyles}
                    options={
                      filteredProjects.map(project => {
                        return { value: project.ID, label: `${project.Name}` }
                      })
                    }
                  />
                  {isPensumStartTimeAllowed && (
                    <div style={{ marginTop: 32}}>
                      <InputLabel className="transformed-label" style={{ color: !isValidStartTime && 'red' }}>
                          {t('time')}
                      </InputLabel>
                      <TextField
                        id="pensumStartTime"
                        type="time"
                        value={pensumStartTime}
                        defaultValue="00:00"
                        onChange={this.handlePensumStartTimeChange}
                        style={{ width: '100%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          style: { textAlign: 'right', color: !isValidStartTime && 'red' },
                          step: 300, // 5 min
                        }}
                      />
                    </div>
                  )}
                <div className="popup-bottom">
                  <Tooltip title={t('close')}>
                    <Button
                      onClick={this.handleClick}
                      variant="text"
                      color="inherit"
                      size="small"
                      className="button-default"
                      startIcon={<CloseIcon style={{ marginLeft: 8 }} />}
                    />
                  </Tooltip>
                  <Tooltip title={t('save')}>
                    <Button
                      onClick={this.handleCreatePensum}
                      variant="text"
                      className="button-default"
                      color="primary"
                      size="small"
                      disabled={createIsDisabled}
                      startIcon={<SaveIcon style={{ marginLeft: 8 }} />}
                    />
                  </Tooltip>
                </div>
                <div className="clear" />
              </AddPensum>
            </AddContainer>
          ) : null
        }

        <div className="bottom-container">
          <div id="addIconContainer" className="bottom-container-addButton" style={{ backgroundColor: bgColor }}>
            {!crewsEmpty && (
              <AddIcon
                id="addIcon"
                color="action"
                className="pensumButton"
                aria-label="add"
                onClick={(event) => { event.stopPropagation(); this.handleOpen(event); }}
              />
            )}
            <div className="clear" />
            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={(event) => { event.stopPropagation(); this.handleClose() }}
            >
              <StyledMenuItem
                onClick={(event) => { event.stopPropagation(); this.handleClick() }}
              >
                <ListItemIcon style={{ minWidth: 25 }}>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                {t('add-pensum')}
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  this.handlePaste();
                  this.handleClose();
                }}
                disabled={!copiedPensum}
              >
                <ListItemIcon style={{ minWidth: 25 }}>
                  <AddListIcon fontSize="small" />
                </ListItemIcon>
                {t('paste-copied-pensum')}
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleMove();
                  this.handleClose();
                }}
                disabled={!movedPensum}
              >
                <ListItemIcon style={{ minWidth: 25 }}>
                  <FlipToFrontIcon fontSize="small" />
                </ListItemIcon>
                {t('paste-moved-pensum')}
              </StyledMenuItem>
            </StyledMenu>
          </div>
        </div>
      </Container>
    )
  }
}
