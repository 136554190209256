import React from "react";
import _ from 'lodash';
import moment from "moment";

import DateFnsUtils from '@date-io/date-fns';
import { enGB, enUS, de } from 'date-fns/locale'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import MaterialTable, { MTableToolbar } from "material-table";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import ReactSelect from 'react-select';

import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/AddBox';
import CommentIcon from '@material-ui/icons/CommentOutlined';
import ReportIcon from '@material-ui/icons/Assessment';

import Mode from "./ModeEnum";
import { PROJECT_STATE_CODES } from "../../config/constants";
import RESOURCES from '../../api/resources';
import { getServiceUrl } from '../../utils';
import { ContactSupportOutlined } from "@material-ui/icons";
import { isParentOrg } from "../Common/common";
/* 
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const optionStyles = {
  control: (styles) => {
      return {
          ...styles,
          fontSize: 13,
          borderRadius: 3,
          maxWidth: 135
      }
  },
  option: (styles, { data, isFocused }) => {
      return {
          ...styles,
          backgroundColor: isFocused
              ? data.colorBg
                  ? data.colorBg
                  : 'rgba(38, 132, 255, 0.08)'
              : null,
          color: data.colorText || '#223b67',
          fontSize: 14
          
      };
  },
  multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor:
          data.colorBg
              ? data.colorBg
              : 'rgba(38, 132, 255, 0.08)'
      };
  },
  multiValueLabel: (styles, { data }) => ({
      ...styles,
      color:
          data.colorText
              ? data.colorText
              : '#223b67',
      fontSize: 13
  }),
  singleValue: (styles) => ({
      ...styles,
      color: 'rgb(55,55,55)'
  }),
  clearIndicator: (styles) => ({
      ...styles,
      cursor: 'pointer'
  })
}; */

const react_select_styles = {
  control: (provided) => {
    return {
      ...provided,
      border: "none",
      borderBottom: '2px solid grey',
      boxShadow: "none",
      borderRadius: 0,
      margin: "1px",
      width: "200px",
      margin: "0px 1px 8px 1px",
      fontSize: 12
    }
  }
};


class ProjectsTable extends React.Component {
  constructor(props) {
    super(props);

    const pFilters = JSON.parse(localStorage.getItem('projectsFilters'))

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      //when refresh happens load the filters to the rendered data
      renderedData: pFilters ? (props.data ? props.data.filter(obj => {
        if (
          ((pFilters.selectedOrganisations.length > 0 && pFilters.selectedOrganisations.includes(obj.Organisation.Name)) || pFilters.selectedOrganisations.length === 0)
          && ((pFilters.selectedSupervisorFilters.length > 0 && pFilters.selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || pFilters.selectedSupervisorFilters.length === 0)
          && ((pFilters.selectedCostCenterFilters.length > 0 && pFilters.selectedCostCenterFilters.includes(obj.CostCenter)) || pFilters.selectedCostCenterFilters.length === 0)
          && ((pFilters.selectedCustomerFilters.length > 0 && pFilters.selectedCustomerFilters.includes(obj.Customer.Name)) || pFilters.selectedCustomerFilters.length === 0)
          && ((pFilters.selectedStateFilters.length > 0 && pFilters.selectedStateFilters.includes(obj.StateCode)) || pFilters.selectedStateFilters.length === 0)
          && ((pFilters.selectedWarrantyEndTimeUtc && obj.WarrantyEndTimeUtc && moment(obj.WarrantyEndTimeUtc.split("T")[0]).format("YYYY-MM") === pFilters.selectedWarrantyEndTimeUtc) || pFilters.selectedWarrantyEndTimeUtc === null)
          && ((pFilters.selectedAcceptanceTimeUtc && obj.AcceptanceTimeUtc && moment(obj.AcceptanceTimeUtc.split("T")[0]).format("YYYY-MM") === pFilters.selectedAcceptanceTimeUtc) || pFilters.selectedAcceptanceTimeUtc === null)
          && ((pFilters.selectedFinalInvoiceTimeUtc && obj.FinalInvoiceTimeUtc && moment(obj.FinalInvoiceTimeUtc.split("T")[0]).format("YYYY-MM") === pFilters.selectedFinalInvoiceTimeUtc) || pFilters.selectedFinalInvoiceTimeUtc === null)
          && ((pFilters.selectedConstructionBeginTimeUtc && obj.ConstructionBeginTimeUtc && moment(obj.ConstructionBeginTimeUtc.split("T")[0]).format("YYYY") === pFilters.selectedConstructionBeginTimeUtc) || pFilters.selectedConstructionBeginTimeUtc === null)
        ) {
          return true
        }
        else {
          return false
        }
      }) : '') : (props.data ? props.data : ''),
      editable: props.editable ? props.editable : '',
      selectedRow: null,
      error: null,
      organisations: props.organisations ? props.organisations : '',
      //selectedOrganisations: localStorage.getItem('selectedOrganisations') ? localStorage.getItem('selectedOrganisations').split(",") : [],
      selectedOrganisations: pFilters && pFilters.selectedOrganisations ? pFilters.selectedOrganisations : [],
      selectedOrgFilters: pFilters && pFilters.selectedOrgFilters ? pFilters.selectedOrgFilters : [],
      selectedSupervisorFilters: pFilters && pFilters.selectedSupervisorFilters ? pFilters.selectedSupervisorFilters : [],
      selectedCostCenterFilters: pFilters && pFilters.selectedCostCenterFilters ? pFilters.selectedCostCenterFilters : [],
      selectedCustomerFilters: pFilters && pFilters.selectedCustomerFilters ? pFilters.selectedCustomerFilters : [],
      selectedStateFilters: pFilters && pFilters.selectedStateFilters ? pFilters.selectedStateFilters : [],
      /*    selectedOrgFilters: localStorage.getItem('selectedOrgFilters') ? localStorage.getItem('selectedOrgFilters').split(",") : [],
            selectedSupervisorFilters: localStorage.getItem('selectedSupervisorFilters') ? localStorage.getItem('selectedSupervisorFilters').split(",") : [],
            selectedCostCenterFilters: localStorage.getItem('selectedCostCenterFilters') ? localStorage.getItem('selectedCostCenterFilters').split(",") : [],
            selectedCustomerFilters: localStorage.getItem('selectedCustomerFilters') ? localStorage.getItem('selectedCustomerFilters').split(",") : [],
            selectedStateFilters: localStorage.getItem('selectedStateFilters') ? localStorage.getItem('selectedStateFilters').split(",") : [], */
      /*      selectedConstructionBeginTimeUtc: null,
           selectedAcceptanceTimeUtc: null,
           selectedFinalInvoiceTimeUtc: null,
           selectedWarrantyEndTimeUtc: null, */
      selectedConstructionBeginTimeUtc: pFilters && pFilters.selectedConstructionBeginTimeUtc ? pFilters.selectedConstructionBeginTimeUtc : null,
      selectedAcceptanceTimeUtc: pFilters && pFilters.selectedAcceptanceTimeUtc ? pFilters.selectedAcceptanceTimeUtc : null,
      selectedFinalInvoiceTimeUtc: pFilters && pFilters.selectedFinalInvoiceTimeUtc ? pFilters.selectedFinalInvoiceTimeUtc : null,
      selectedWarrantyEndTimeUtc: pFilters && pFilters.selectedWarrantyEndTimeUtc ? pFilters.selectedWarrantyEndTimeUtc : null,
      openDeleteEntry: false,
      deleteEntryObj: null,
    };
  }

  //===================================================================================================
  /*   var pFilters = { 
    selectedOrganisations: [],
        selectedOrgFilters: [],
        selectedSupervisorFilters: [],
        selectedCostCenterFilters: [],
        selectedCustomerFilters: [],
        selectedStateFilters: [],}  */
  // localStorage.setItem('projectsFilters', JSON.stringify(pFilters))
  //===================================================================================================
  componentDidUpdate() {


    if (!this.props.redirected) {
      /*const orgFilter = document.getElementsByClassName("MuiInputBase-inputSelect")[0];
      const orgFilterInput = document.getElementById("select-multiple-checkbox0");
      orgFilter.className = `${orgFilter.className} organisation-select`
      const mutationObserver = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          const values = mutation.target.value.split(",");
          const selectedOrgFilters = values.length > 0 && values[0] !== "" ? values : []
          this.setState({ selectedOrgFilters });
        });
      });

      if (orgFilterInput) {
        mutationObserver.observe(orgFilterInput, {
          attributes: true,
          characterData: true,
          childList: true,
          subtree: true,
          attributeOldValue: true,
          characterDataOldValue: true
        });
      } else {
        mutationObserver.disconnect();
      }*/
    }

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ initPage, pingedRowIndex: indexOfNewRow })
    }
    this.setState({ isMounted: true })
  }


  static getDerivedStateFromProps(props, state) {
    const newData = !_.isEqual(props.data, state.data)
    return {
      data: newData ? props.data : state.data,
      renderedData: newData ? state.renderedData.filter(obj => props.data.map(obj => obj.ID).includes(obj.ID)) : state.renderedData
    }
  }

  downloadExcel = (e) => {
    e.preventDefault();
    const link = document.getElementById(`${e.target.parentElement.id}`);
    link.click();
  }

  convertValue(props) {
    return `${props.value.FirstNames || ''} ${props.value.LastName}`.trim()
  }

  compareByProperty(a, b) {
    var p = "value";
    if (a[p] > b[p]) return 1;
    if (b[p] > a[p]) return -1;

    return 0;
  }

  onDeleteRowClickHandler = (entity) => {
    this.setState({ openDeleteEntry: !this.state.openDeleteEntry, deleteEntryObj: entity });
  }

  closeDeleteEntryPopup = () => {
    this.setState({ openDeleteEntry: false, deleteEntryObj: null })
  }

  handleDeleteEntry = async () => {

    const { deleteEntryObj } = this.state;
    if (deleteEntryObj !== null) {
      let errorOrResult = await this.props.onChangeMode(Mode.Delete, deleteEntryObj)
        .then(res => {
          return res;
        }, res => {
          return res;
        }
        );

      // delete was successfull
      if (!(errorOrResult instanceof Error)) {
      }
    }

    this.setState({ openDeleteEntry: !this.state.openDeleteEntry, deleteEntryObj: null });
  }

  selectLocale(language) {

    let languages = new Map();
    languages.set("de-DE", de);
    languages.set("en-GB", enGB);
    languages.set("en-US", enUS);

    let res = languages.get(language);
    return res != null ? res : languages["de-DE"];
  }

  formatDateDay = dateTime => moment(dateTime).format('DD.MM.YYYY')
  formatDateMonth = dateTime => moment(dateTime).format('MM.YYYY')
  formatDateYear = dateTime => moment(dateTime).format('YYYY')

  shouldComponentUpdate(nextProps, nextState) {
    const { isMounted } = this.state
    if (isMounted) {
      if (_.isEqual(nextProps, this.props) && _.isEqual(nextState, this.state)) {
        return false
      }
    }
    return true
  }
  //===========================================================================================================
  updateFilters = () => {
    var pFilters = {
      selectedOrganisations: this.state.selectedOrganisations,
      selectedOrgFilters: this.state.selectedOrgFilters,
      selectedSupervisorFilters: this.state.selectedSupervisorFilters,
      selectedCostCenterFilters: this.state.selectedCostCenterFilters,
      selectedCustomerFilters: this.state.selectedCustomerFilters,
      selectedStateFilters: this.state.selectedStateFilters,
      selectedConstructionBeginTimeUtc: this.state.selectedConstructionBeginTimeUtc,
      selectedAcceptanceTimeUtc: this.state.selectedAcceptanceTimeUtc,
      selectedFinalInvoiceTimeUtc: this.state.selectedFinalInvoiceTimeUtc,
      selectedWarrantyEndTimeUtc: this.state.selectedWarrantyEndTimeUtc,
    }
    localStorage.setItem('projectsFilters', JSON.stringify(pFilters))
    /* localStorage.setItem('selectedOrganisations', this.state.selectedOrganisations);
    localStorage.setItem('selectedOrgFilters', this.state.selectedOrgFilters);
    localStorage.setItem('selectedSupervisorFilters', this.state.selectedSupervisorFilters);
    localStorage.setItem('selectedCostCenterFilters', this.state.selectedCostCenterFilters);
    localStorage.setItem('selectedCustomerFilters', this.state.selectedCustomerFilters);
    localStorage.setItem('selectedStateFilters', this.state.selectedStateFilters); */
  }
  /*   readFilters=()=>{
      const filters = {selectedCostCenterFilters: localStorage.getItem('selectedCostCenterFilters')}
      return filters
    } */
  //===========================================================================================================

  sortingFilters = (arr, name) => {
    arr.sort((a, b) => {
      var nameA = a[name].toUpperCase();
      var nameB = b[name].toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return arr;
  };

  render() {
    this.updateFilters()
    const { data, organisations, openDeleteEntry, editable, initPage, pingedRowIndex } = this.state;
    var lookupOrgs = {};
    var lookupSupervisors = {};
    var lookupCostCenters = {};
    var lookupCustomers = {};

    const { t } = this.props;
    const columns = []
    if (!this.props.redirected) {
      columns.push(
        {
          title: <CommentIcon style={{ fontSize: 23, marginBottom: -11, color: 'rgb(85,85,85)' }} />, field: "Notes",
          filtering: false, sorting: false, headerStyle: { textAlign: "middle" }, cellStyle: { textAlign: "middle", fontSize: 21 },
          render: rowData => rowData.Notes ? <Tooltip title={t('project-has-notes')}><div style={{ marginLeft: 8, marginBottom: -2, cursor: "default" }}>!</div></Tooltip> : null,
        },
        {
          title: t('organisation'), field: "Organisation.Name", lookup: lookupOrgs, defaultFilter: this.state.selectedOrgFilters, filtering: true,
          render: rowData => (
            rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
          ),
          filterComponent: props => {
            const { organisations } = this.props
            const { selectedOrganisations, selectedSupervisorFilters, selectedCostCenterFilters, selectedCustomerFilters, selectedStateFilters } = this.state
            let cc = Object.values(lookupOrgs).map((org, i) => { return { "id": i, "org": org } });
            cc = this.sortingFilters(cc, "org");
            return (
              <ReactSelect
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border: "none",
                    borderBottom: '2px solid grey',
                    boxShadow: "none",
                    borderRadius: 0,
                    margin: "1px",
                    width: "200px",
                    margin: "0px 1px 8px 1px",
                    fontSize: 12,
                  }),
                }}
                placeholder=""
                options={cc.map(obj => {
                  return { value: obj.id, label: `${obj.org}` }
                })}
                isClearable
                isMulti
                value={selectedOrganisations.map((name, id) => ({ value: id, label: name }))}
                onChange={selected => this.setState(prevState => {
                  let ccn = [];
                  if (selected) {
                    ccn = selected.map(obj => { return obj.label });
                  }
                  return {
                    selectedOrganisations: ccn,
                    renderedData: prevState.data.filter(obj => {
                      if (
                        ((ccn.length > 0 && ccn.includes(obj.Organisation.Name)) || ccn.length === 0)
                        && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                        && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                        && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                        && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })

                  }
                })}
              />
              /* <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedOrganisations}
                onChange={e => this.setState(prevState => {
                  return {
                    selectedOrganisations: e.target.value,
                    renderedData: prevState.data.filter(obj => {
                      if (
                        ((e.target.value.length > 0 && e.target.value.includes(obj.Organisation.Name)) || e.target.value.length === 0)
                         && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                         && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                         && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                         && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })
                  }
                })}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: 132, position: 'sticky', top: 0 }}
              >
                {organisations.map((org) => (
                  <MenuItem key={org.Name} value={org.Name}>
                    <Checkbox checked={selectedOrganisations.indexOf(org.Name) > -1} />
                    <ListItemText primary={org.Name} />
                  </MenuItem>
                ))}
              </Select> */
            )
          }
        },
        {
          title: t('construction-supervisor'), field: "Supervisor", lookup: lookupSupervisors, customFilterAndSearch: (term, rowData) => {
            const fullname = `${rowData.Supervisor.FirstNames} ${rowData.Supervisor.LastName}`.trim();
            if (fullname.includes(term)) {
              return true;
            }
            else { return false; }
          },
          render: rowData => (
            rowData.Supervisor && <>{`${rowData.Supervisor.FirstNames || ''} ${rowData.Supervisor.LastName}`.trim()}</>
          ),
          filterComponent: props => {
            const { selectedOrganisations, selectedSupervisorFilters, selectedCostCenterFilters, selectedCustomerFilters, selectedStateFilters } = this.state
            let cc = Object.values(lookupSupervisors).map((supervisor, i) => { return { "id": i, "supervisor": supervisor } });
            cc = this.sortingFilters(cc, "supervisor");
            return (
              <ReactSelect
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border: "none",
                    borderBottom: '2px solid grey',
                    boxShadow: "none",
                    borderRadius: 0,
                    margin: "1px",
                    width: "200px",
                    margin: "0px 1px 8px 1px",
                    fontSize: 12,
                  }),
                }}
                placeholder=""
                options={cc.map(obj => {
                  return { value: obj.id, label: `${obj.supervisor}` }
                })}
                isClearable
                isMulti
                value={selectedSupervisorFilters.map((name, id) => ({ value: id, label: name }))}
                onChange={selected => this.setState(prevState => {
                  let ccn = [];
                  if (selected) {
                    ccn = selected.map(obj => { return obj.label });
                  }
                  return {
                    selectedSupervisorFilters: ccn,
                    renderedData: prevState.data.filter(obj => {
                      if (
                        ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                        && ((ccn.length > 0 && ccn.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || ccn.length === 0)
                        && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                        && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                        && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })

                  }
                })}
              />

              /* <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedSupervisorFilters}
                onChange={e => this.setState(prevState => {
                  return {
                    selectedSupervisorFilters: e.target.value,
                    renderedData: prevState.data.filter(obj => {
                      if (
                        ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                         && ((e.target.value.length > 0 && e.target.value.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || e.target.value.length === 0)
                         && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                         && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                         && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })
                  }
                })}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: 132 }}
              >
                {Object.values(lookupSupervisors).map((supervisorName, i) => (
                  <MenuItem key={i} value={supervisorName}>
                    <Checkbox checked={selectedSupervisorFilters.indexOf(supervisorName) > -1} />
                    <ListItemText primary={supervisorName} />
                  </MenuItem>
                ))}
              </Select> */
            )
          }
        },
        {
          title: t('cost-center'), field: "CostCenter", lookup: lookupCostCenters, filtering: true,
          cellStyle: { width: 80, textAlign: "left" },
          headerStyle: { width: 80 },
          filterComponent: props => {
            const { selectedOrganisations, selectedSupervisorFilters, selectedCostCenterFilters, selectedCustomerFilters, selectedStateFilters } = this.state
            let cc = Object.values(lookupCostCenters).map((costCenterName, i) => { return { "id": i, "costCenterName": costCenterName } });
            cc = this.sortingFilters(cc, "costCenterName");
            return (
              <ReactSelect
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border: "none",
                    borderBottom: '2px solid grey',
                    boxShadow: "none",
                    borderRadius: 0,
                    margin: "1px",
                    width: "160px",
                    margin: "0px 1px 8px 1px",
                    fontSize: 12,
                  }),
                }}
                placeholder=""
                options={cc.map(costCenter => {
                  return { value: costCenter.id, label: `${costCenter.costCenterName}` }
                })}
                isClearable
                isMulti
                value={selectedCostCenterFilters.map((name, id) => ({ value: id, label: name }))}
                onChange={selected => this.setState(prevState => {
                  let ccn = [];
                  if (selected) {
                    ccn = selected.map(obj => { return obj.label });
                  }
                  /* console.log("selectedCostCenterFilters",selectedCostCenterFilters) */
                  return {
                    selectedCostCenterFilters: ccn,
                    renderedData: prevState.data.filter(obj => {
                      if (
                        ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                        && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                        && ((ccn.length > 0 && ccn.includes(obj.CostCenter)) || ccn.length === 0)
                        && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                        && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                      ) {
                        /*                   localStorage.setItem('selectedCostCenterFilters',selectedCostCenterFilters) */
                        return true
                      } else {
                        return false
                      }
                    })

                  }
                })}
              />



              /*<Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedCostCenterFilters}
                onChange={e => this.setState(prevState => {
                  return {
                    selectedCostCenterFilters: e.target.value,
                    renderedData: prevState.data.filter(obj => {
                      if (
                        ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                         && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                         && ((e.target.value.length > 0 && e.target.value.includes(obj.CostCenter)) || e.target.value.length === 0)
                         && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                         && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })
                  }
                })}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: 95 }}
              >
                {Object.values(lookupCostCenters).map((costCenterName, i) => (
                  <MenuItem key={i} value={costCenterName}>
                    <Checkbox checked={selectedCostCenterFilters.indexOf(costCenterName) > -1} />
                    <ListItemText primary={costCenterName} />
                  </MenuItem>
                ))}
              </Select>*/
            )
          },
        },
        {
          title: t('customer'), field: "Customer.Name", lookup: lookupCustomers, filtering: true,
          filterComponent: props => {
            const { selectedOrganisations, selectedSupervisorFilters, selectedCostCenterFilters, selectedCustomerFilters, selectedStateFilters } = this.state
            let cc = Object.values(lookupCustomers).map((customer, i) => { return { "id": i, "customer": customer } });
            cc = this.sortingFilters(cc, "customer");
            return (
              <ReactSelect
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border: "none",
                    borderBottom: '2px solid grey',
                    boxShadow: "none",
                    borderRadius: 0,
                    margin: "1px",
                    width: "200px",
                    margin: "0px 1px 8px 1px",
                    fontSize: 12,
                  }),
                }}
                placeholder=""
                options={cc.map(obj => {
                  return { value: obj.id, label: `${obj.customer}` }
                })}
                isClearable
                isMulti
                value={selectedCustomerFilters.map((name, id) => ({ value: id, label: name }))}
                onChange={selected => this.setState(prevState => {
                  let ccn = [];
                  if (selected) {
                    ccn = selected.map(obj => { return obj.label });
                  }
                  return {
                    selectedCustomerFilters: ccn,
                    renderedData: prevState.data.filter(obj => {
                      if (
                        ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                        && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                        && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                        && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                        && ((ccn.length > 0 && ccn.includes(obj.Customer.Name)) || ccn.length === 0)
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })

                  }
                })}
              />
              /* <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedCustomerFilters}
                onChange={e => this.setState(prevState => {
                  return {
                    selectedCustomerFilters: e.target.value,
                    renderedData: prevState.data.filter(obj => {
                      if (
                        ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                         && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                         && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                         && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                         && ((e.target.value.length > 0 && e.target.value.includes(obj.Customer.Name)) || e.target.value.length === 0)
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })
                  }
                })}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: 132 }}
              >
                {Object.values(lookupCustomers).map((customerName, i) => (
                  <MenuItem key={i} value={customerName}>
                    <Checkbox checked={selectedCustomerFilters.indexOf(customerName) > -1} />
                    <ListItemText primary={customerName} />
                  </MenuItem>
                ))}
              </Select> */
            )
          },
        },
        {
          title: t('description'), field: "Name", filtering: false, defaultSort: "asc"
        },
        {
          title: t('order-total-amount'), field: "OrderTotalAmount", filtering: true,
          render: rowData => (
            <p>{`${(rowData.OrderTotalAmount !== undefined && rowData.OrderTotalAmount !== null ? Math.trunc(rowData.OrderTotalAmount).toLocaleString() + "\u00A0€" : '')}`}</p>
          ),
          cellStyle: {
            textAlign: "right"
          },
          headerStyle: {
            textAlign: "right"
          },
          filterComponent: props => {
            const { renderedData } = this.state
            let Sum = 0
            renderedData.forEach(obj => {
              Sum += obj.OrderTotalAmount
            })
            Sum = Number(`${Sum}`.split(".")[0])
            return (
              <h4 style={{ float: 'right' }}>{`${Sum.toLocaleString()}\u00A0€`}</h4>
            )
          },
        },
        {
          title: t('work-execution-estimate-percentage-short'), field: "WorkExecutionEstimatePercentage", filtering: true,
          render: rowData => (
            <p>{`${(rowData.WorkExecutionEstimatePercentage !== undefined && rowData.WorkExecutionEstimatePercentage !== null ? Math.trunc(rowData.WorkExecutionEstimatePercentage).toLocaleString() + "\u00A0%" : '')}`}</p>
          ),
          cellStyle: {
            textAlign: "right",
            minWidth: 80
          },
          headerStyle: {
            textAlign: "right",
            minWidth: 80,
            whiteSpace: 'nowrap'
          },
          filterComponent: props => {
            const { renderedData } = this.state
            let WorkExecutionEstimateOrderTotalAmountSum = 0
            let OrderTotalAmountSum = 0
            renderedData.forEach(obj => {
              WorkExecutionEstimateOrderTotalAmountSum += obj.WorkExecutionEstimateOrderTotalAmount
              OrderTotalAmountSum += obj.OrderTotalAmount
            })
            const WorkExecutionEstimatePercentage = `${WorkExecutionEstimateOrderTotalAmountSum / OrderTotalAmountSum * 100}`
            if (WorkExecutionEstimatePercentage > 0) {
              if (WorkExecutionEstimatePercentage.split(".")[1]) {
                return (
                  <h4 style={{ textAlign: 'right' }}>
                    {`${WorkExecutionEstimatePercentage.split(".")[0]}.${WorkExecutionEstimatePercentage.split(".")[1].slice(0, 2)}\u00A0%`}
                  </h4>
                )
              } else return <h4 style={{ textAlign: 'right' }}>{`${WorkExecutionEstimatePercentage}\u00A0%`}</h4>
            } else {
              return <h4 style={{ textAlign: 'right' }}>{`0\u00A0%`}</h4>
            }
          },
        },
        {
          title: t('work-execution-estimate-order-total-amount-short'), field: "WorkExecutionEstimateOrderTotalAmount", filtering: true,
          render: rowData => (
            <p>{`${(rowData.WorkExecutionEstimateOrderTotalAmount !== undefined && rowData.WorkExecutionEstimateOrderTotalAmount !== null ? Math.trunc(rowData.WorkExecutionEstimateOrderTotalAmount).toLocaleString() + "\u00A0€" : '')}`}</p>
          ),
          cellStyle: {
            textAlign: "right"
          },
          headerStyle: {
            textAlign: "right",
            whiteSpace: 'nowrap'
          },
          filterComponent: props => {
            const { renderedData } = this.state
            let Sum = 0
            renderedData.forEach(obj => {
              Sum += obj.WorkExecutionEstimateOrderTotalAmount
            })
            Sum = Number(`${Sum}`.split(".")[0])
            return (
              <h4 style={{ float: 'right' }}>{`${Sum.toLocaleString()}\u00A0€`}</h4>
            )
          },
        },
        {
          title: t('remaining-amount-to-invoice-split'), field: "RemainingAmountToInvoice", filtering: true,
          render: rowData => (
            <p>{`${(rowData.RemainingAmountToInvoice !== undefined && rowData.RemainingAmountToInvoice !== null ? Math.trunc(rowData.RemainingAmountToInvoice).toLocaleString() + "\u00A0€" : '')}`}</p>
          ),
          cellStyle: {
            textAlign: "right"
          },
          headerStyle: {
            textAlign: "right"
          },
          filterComponent: props => {
            const { renderedData } = this.state
            let Sum = 0
            renderedData.forEach(obj => {
              Sum += obj.RemainingAmountToInvoice
            })
            Sum = Number(`${Sum}`.split(".")[0])
            return (
              <h4 style={{ float: 'right', whiteSpace: 'nowrap' }}>{`${Sum.toLocaleString()}\u00A0€`}</h4>
            )
          },
        },
        {
          title: t('work-execution-estimate-actual-invoice-amount-short'), field: "WorkExecutionEstimateActualInvoicedAmount", filtering: true,
          render: rowData => (
            <p>{`${(rowData.WorkExecutionEstimateActualInvoicedAmount !== undefined && rowData.WorkExecutionEstimateActualInvoicedAmount !== null ? Math.trunc(rowData.WorkExecutionEstimateActualInvoicedAmount).toLocaleString() + "\u00A0€" : '')}`}</p>
          ),
          cellStyle: {
            textAlign: "right"
          },
          headerStyle: {
            textAlign: "right",
            whiteSpace: 'nowrap'
          },
          filterComponent: props => {
            const { renderedData } = this.state
            let Sum = 0
            renderedData.forEach(obj => {
              Sum += Math.trunc(obj.WorkExecutionEstimateActualInvoicedAmount)
            })
            return (
              <h4 style={{ float: 'right' }}>{`${Sum.toLocaleString()}\u00A0€`}</h4>
            )
          },
        },
        {
          title: t('stage-of-completion-split'), field: "StageOfCompletion", filtering: false, defaultSort: "asc",
          render: rowData => (
            <p>{`${(rowData.StageOfCompletion !== undefined && rowData.StageOfCompletion !== null ? Math.trunc(rowData.StageOfCompletion).toLocaleString() + "\u00A0%" : '')}`}</p>
          ),
          cellStyle: {
            textAlign: "right"
          },
          headerStyle: {
            textAlign: "right"
          },
        },
        {
          title: t('state'), field: 'StateCode', filtering: true,
          cellStyle: { textAlign: "center" }, headerStyle: { textAlign: "center" },
          render: rowData => (
            <div style={{ textAlign: 'center' }}>{t(`projectStates.${Object.keys(PROJECT_STATE_CODES).find(key => PROJECT_STATE_CODES[key] === rowData.StateCode)}-short`)}</div>
          ),
          filterComponent: props => {
            const { selectedOrganisations, selectedSupervisorFilters, selectedCostCenterFilters, selectedCustomerFilters, selectedStateFilters } = this.state
            return (
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={selectedStateFilters}
                style={{ width: 75 }}
                onChange={e => this.setState(prevState => {
                  return {
                    selectedStateFilters: e.target.value,
                    renderedData: prevState.data.filter(obj => {
                      if (
                        ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                        && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                        && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                        && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                        && ((e.target.value.length > 0 && e.target.value.includes(obj.StateCode)) || e.target.value.length === 0)
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })
                  }
                })}
                input={<Input />}
                renderValue={(selected) => {
                  let renderedText = ""
                  selected.forEach((selectedState, i) => {
                    if (i === 0) {
                      renderedText = `${t(`projectStates.${Object.keys(PROJECT_STATE_CODES).find(key => PROJECT_STATE_CODES[key] === selectedState)}-short`)}`
                    } else {
                      renderedText = `${renderedText}, ${t(`projectStates.${Object.keys(PROJECT_STATE_CODES).find(key => PROJECT_STATE_CODES[key] === selectedState)}-short`)}`
                    }
                  })
                  if (renderedText.length > 0) return renderedText
                }}
              /*  style={{ width: 132 }} */
              >
                {Object.entries(PROJECT_STATE_CODES).map((entry, i) => (
                  <MenuItem key={i} value={entry[1]}>
                    <Checkbox checked={selectedStateFilters.indexOf(entry[1]) > -1} />
                    <ListItemText primary={t(`projectStates.${entry[0]}-short`)} />
                  </MenuItem>
                ))}
              </Select>
            )
          },
        },
        {
          title: t('construction-begin'), field: "ConstructionBeginTimeUtc", type: "date", filtering: true,
          render: rowData => (
            rowData.ConstructionBeginTimeUtc ? <div style={{ textAlign: 'center' }}>{this.formatDateYear(rowData.ConstructionBeginTimeUtc)}</div>
              : null
          ),
          filterComponent: props => {
            const {
              selectedOrganisations,
              selectedSupervisorFilters,
              selectedCostCenterFilters,
              selectedCustomerFilters,
              selectedStateFilters,
              selectedFinalInvoiceTimeUtc,
              selectedConstructionBeginTimeUtc,
              selectedAcceptanceTimeUtc,
              selectedWarrantyEndTimeUtc
            } = this.state
            const { lng } = this.props
            const locale = this.selectLocale(lng);
            return (
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}
              >
                <KeyboardDatePicker
                  views={["year"]}
                  margin="normal"
                  id="date-picker-constructionBeginTime"
                  format="yyyy"
                  value={selectedConstructionBeginTimeUtc}
                  onChange={e => this.setState(prevState => {
                    const selectedDate = e === null || moment(e).format("YYYY") === "Invalid date" ? null : moment(e).format("YYYY")
                    return {
                      selectedConstructionBeginTimeUtc: selectedDate,
                      renderedData: prevState.data.filter(obj => {
                        if (
                          ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                          && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                          && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                          && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                          && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                          && ((selectedWarrantyEndTimeUtc && obj.WarrantyEndTimeUtc && moment(obj.WarrantyEndTimeUtc.split("T")[0]).format("YYYY-MM") === selectedWarrantyEndTimeUtc) || selectedWarrantyEndTimeUtc === null)
                          && ((selectedAcceptanceTimeUtc && obj.AcceptanceTimeUtc && moment(obj.AcceptanceTimeUtc.split("T")[0]).format("YYYY-MM") === selectedAcceptanceTimeUtc) || selectedAcceptanceTimeUtc === null)
                          && ((selectedFinalInvoiceTimeUtc && obj.FinalInvoiceTimeUtc && moment(obj.FinalInvoiceTimeUtc.split("T")[0]).format("YYYY-MM") === selectedFinalInvoiceTimeUtc) || selectedFinalInvoiceTimeUtc === null)
                          && ((selectedDate && obj.ConstructionBeginTimeUtc && moment(obj.ConstructionBeginTimeUtc.split("T")[0]).format("YYYY") === selectedDate) || selectedDate === null)
                        ) {
                          return true
                        } else {
                          return false
                        }
                      })
                    }
                  })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )
          },
        },
        {
          title: t('acceptance'), field: "AcceptanceTimeUtc", type: "date", filtering: true,
          render: rowData => (
            rowData.AcceptanceTimeUtc ? <div style={{ textAlign: 'center' }}>{this.formatDateDay(rowData.AcceptanceTimeUtc)}</div>
              : null
          ),
          filterComponent: props => {
            const {
              selectedOrganisations,
              selectedSupervisorFilters,
              selectedCostCenterFilters,
              selectedCustomerFilters,
              selectedStateFilters,
              selectedConstructionBeginTimeUtc,
              selectedFinalInvoiceTimeUtc,
              selectedAcceptanceTimeUtc,
              selectedWarrantyEndTimeUtc
            } = this.state
            const { lng } = this.props
            const locale = this.selectLocale(lng);
            return (
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}
              >
                <KeyboardDatePicker
                  views={["year", "month"]}
                  margin="normal"
                  id="date-picker-acceptanceTime"
                  format="MM.yyyy"
                  value={selectedAcceptanceTimeUtc}
                  onChange={e => this.setState(prevState => {
                    const selectedDate = e === null || moment(e).format("YYYY") === "Invalid date" ? null : moment(e).format("YYYY-MM")
                    return {
                      selectedAcceptanceTimeUtc: selectedDate,
                      renderedData: prevState.data.filter(obj => {
                        if (
                          ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                          && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                          && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                          && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                          && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                          && ((selectedConstructionBeginTimeUtc && obj.ConstructionBeginTimeUtc && moment(obj.ConstructionBeginTimeUtc.split("T")[0]).format("YYYY") === selectedConstructionBeginTimeUtc) || selectedConstructionBeginTimeUtc === null)
                          && ((selectedWarrantyEndTimeUtc && obj.WarrantyEndTimeUtc && moment(obj.WarrantyEndTimeUtc.split("T")[0]).format("YYYY-MM") === selectedWarrantyEndTimeUtc) || selectedWarrantyEndTimeUtc === null)
                          && ((selectedFinalInvoiceTimeUtc && obj.FinalInvoiceTimeUtc && moment(obj.FinalInvoiceTimeUtc.split("T")[0]).format("YYYY-MM") === selectedFinalInvoiceTimeUtc) || selectedFinalInvoiceTimeUtc === null)
                          && ((selectedDate && obj.AcceptanceTimeUtc && moment(obj.AcceptanceTimeUtc.split("T")[0]).format("YYYY-MM") === selectedDate) || selectedDate === null)
                        ) {
                          return true
                        } else {
                          return false
                        }
                      })
                    }
                  })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )
          },
        },
        {
          title: t('final-invoice-time-short'), field: "FinalInvoiceTimeUtc", type: "date", filtering: true,
          headerStyle: { whiteSpace: 'nowrap' },
          render: rowData => (
            rowData.FinalInvoiceTimeUtc ? <div style={{ textAlign: 'center' }}>{this.formatDateDay(rowData.FinalInvoiceTimeUtc)}</div>
              : null
          ),
          filterComponent: props => {
            const {
              selectedOrganisations,
              selectedSupervisorFilters,
              selectedCostCenterFilters,
              selectedCustomerFilters,
              selectedStateFilters,
              selectedFinalInvoiceTimeUtc,
              selectedAcceptanceTimeUtc,
              selectedConstructionBeginTimeUtc,
              selectedWarrantyEndTimeUtc
            } = this.state
            const { lng } = this.props
            const locale = this.selectLocale(lng);
            return (
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}
              >
                <KeyboardDatePicker
                  views={["year", "month"]}
                  margin="normal"
                  id="date-picker-finalInvoiceTime"
                  format="MM.yyyy"
                  value={selectedFinalInvoiceTimeUtc}
                  onChange={e => this.setState(prevState => {
                    const selectedDate = e === null || moment(e).format("YYYY") === "Invalid date" ? null : moment(e).format("YYYY-MM")
                    return {
                      selectedFinalInvoiceTimeUtc: selectedDate,
                      renderedData: prevState.data.filter(obj => {
                        if (
                          ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                          && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                          && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                          && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                          && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                          && ((selectedWarrantyEndTimeUtc && obj.WarrantyEndTimeUtc && moment(obj.WarrantyEndTimeUtc.split("T")[0]).format("YYYY-MM") === selectedWarrantyEndTimeUtc) || selectedWarrantyEndTimeUtc === null)
                          && ((selectedConstructionBeginTimeUtc && obj.ConstructionBeginTimeUtc && moment(obj.ConstructionBeginTimeUtc.split("T")[0]).format("YYYY") === selectedConstructionBeginTimeUtc) || selectedConstructionBeginTimeUtc === null)
                          && ((selectedAcceptanceTimeUtc && obj.AcceptanceTimeUtc && moment(obj.AcceptanceTimeUtc.split("T")[0]).format("YYYY-MM") === selectedAcceptanceTimeUtc) || selectedAcceptanceTimeUtc === null)
                          && ((selectedDate && obj.FinalInvoiceTimeUtc && moment(obj.FinalInvoiceTimeUtc.split("T")[0]).format("YYYY-MM") === selectedDate) || selectedDate === null)
                        ) {
                          return true
                        } else {
                          return false
                        }
                      })
                    }
                  })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )
          },
        },
        {
          title: t('warranty-end-short'), field: "WarrantyEndTimeUtc", type: "date", filtering: true,
          headerStyle: { whiteSpace: 'nowrap' },
          render: rowData => (
            rowData.WarrantyEndTimeUtc ? <div style={{ textAlign: 'center' }}>{this.formatDateDay(rowData.WarrantyEndTimeUtc)}</div>
              : null
          ),
          filterComponent: props => {
            const {
              selectedOrganisations,
              selectedSupervisorFilters,
              selectedCostCenterFilters,
              selectedCustomerFilters,
              selectedStateFilters,
              selectedConstructionBeginTimeUtc,
              selectedFinalInvoiceTimeUtc,
              selectedAcceptanceTimeUtc,
              selectedWarrantyEndTimeUtc
            } = this.state
            const { lng } = this.props
            const locale = this.selectLocale(lng);
            return (
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}
              >
                <KeyboardDatePicker
                  views={["year", "month"]}
                  margin="normal"
                  id="date-picker-warrantyEndTime"
                  format="MM.yyyy"
                  value={selectedWarrantyEndTimeUtc}
                  onChange={e => this.setState(prevState => {
                    const selectedDate = e === null || moment(e).format("YYYY") === "Invalid date" ? null : moment(e).format("YYYY-MM")
                    return {
                      selectedWarrantyEndTimeUtc: selectedDate,
                      renderedData: prevState.data.filter(obj => {
                        if (
                          ((selectedOrganisations.length > 0 && selectedOrganisations.includes(obj.Organisation.Name)) || selectedOrganisations.length === 0)
                          && ((selectedSupervisorFilters.length > 0 && selectedSupervisorFilters.includes(`${obj.Supervisor.FirstNames || ''} ${obj.Supervisor.LastName}`.trim())) || selectedSupervisorFilters.length === 0)
                          && ((selectedCostCenterFilters.length > 0 && selectedCostCenterFilters.includes(obj.CostCenter)) || selectedCostCenterFilters.length === 0)
                          && ((selectedCustomerFilters.length > 0 && selectedCustomerFilters.includes(obj.Customer.Name)) || selectedCustomerFilters.length === 0)
                          && ((selectedStateFilters.length > 0 && selectedStateFilters.includes(obj.StateCode)) || selectedStateFilters.length === 0)
                          && ((selectedConstructionBeginTimeUtc && obj.ConstructionBeginTimeUtc && moment(obj.ConstructionBeginTimeUtc.split("T")[0]).format("YYYY") === selectedConstructionBeginTimeUtc) || selectedConstructionBeginTimeUtc === null)
                          && ((selectedFinalInvoiceTimeUtc && obj.FinalInvoiceTimeUtc && moment(obj.FinalInvoiceTimeUtc.split("T")[0]).format("YYYY-MM") === selectedFinalInvoiceTimeUtc) || selectedFinalInvoiceTimeUtc === null)
                          && ((selectedAcceptanceTimeUtc && obj.AcceptanceTimeUtc && moment(obj.AcceptanceTimeUtc.split("T")[0]).format("YYYY-MM") === selectedAcceptanceTimeUtc) || selectedAcceptanceTimeUtc === null)
                          && ((selectedDate && obj.WarrantyEndTimeUtc && moment(obj.WarrantyEndTimeUtc.split("T")[0]).format("YYYY-MM") === selectedDate) || selectedDate === null)
                        ) {
                          return true
                        } else {
                          return false
                        }
                      })
                    }
                  })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )
          },
        },
        {
          title: t('remaining-amount-to-invoice-current-year-short'), field: "RemainingAmountToInvoiceCurrentYear", filtering: true,
          cellStyle: { textAlign: "right", minWidth: 75 }, headerStyle: { textAlign: "right", minWidth: 75, whiteSpace: 'nowrap' },
          render: rowData => (
            <p>{`${(rowData.RemainingAmountToInvoiceCurrentYear !== undefined && rowData.RemainingAmountToInvoiceCurrentYear !== null ? rowData.RemainingAmountToInvoiceCurrentYear.toLocaleString() + "\u00A0€" : '')}`}</p>
          ),
          filterComponent: props => {
            const { renderedData } = this.state
            let Sum = 0
            renderedData.forEach(obj => {
              Sum += obj.RemainingAmountToInvoiceCurrentYear
            })
            Sum = Number(`${Sum}`.split(".")[0])
            return (
              <h4 style={{ float: 'right' }}>{`${Sum.toLocaleString()}\u00A0€`}</h4>
            )
          },
        },
        {
          title: t('order-total-amount-next-year-short'), field: "OrderTotalAmountNextYear", filtering: true,
          cellStyle: { textAlign: "right", minWidth: 75 }, headerStyle: { textAlign: "right", minWidth: 75, whiteSpace: 'nowrap' },
          render: rowData => (
            <p>{`${(rowData.OrderTotalAmountNextYear !== undefined && rowData.OrderTotalAmountNextYear !== null ? rowData.OrderTotalAmountNextYear.toLocaleString() + "\u00A0€" : '')}`}</p>
          ),
          filterComponent: props => {
            const { renderedData } = this.state
            let Sum = 0
            renderedData.forEach(obj => {
              Sum += obj.OrderTotalAmountNextYear
            })
            Sum = Number(`${Sum}`.split(".")[0])
            return (
              <h4 style={{ float: 'right' }}>{`${Sum.toLocaleString()}\u00A0€`}</h4>
            )
          },
        },
      )
    }

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
    }

    if (data !== null && data.length > 0) {
      data.forEach(s => {
        if (s.Supervisor && s.CostCenter) {
          var key = `${s.Supervisor.FirstNames || ''} ${s.Supervisor.LastName}`.trim();
          lookupSupervisors[key] = key;
        }
        if (s.CostCenter) {
          lookupCostCenters[s.CostCenter] = s.CostCenter;
        }
        if (s.Customer && s.Customer.Name) {
          lookupCustomers[s.Customer.Name] = s.Customer.Name;
        }
      })
    }

    const tableActions = []
    if (editable) {
      tableActions.push(
        {
          icon: () => <AddIcon />,
          tooltip: t('add'),
          isFreeAction: true,
          onClick: (event) => this.props.onChangeMode(Mode.Add, null)
        },
        rowData => ({
          icon: 'edit',
          tooltip: t('edit'),
          onClick: (event, rowData) => this.props.onChangeMode(Mode.Update, rowData),
          disabled: !editable || isParentOrg(this.props.user.organisation, organisations.find(org => org.Name === rowData.Organisation.Name)),
        }),
        rowData => (
          {
            icon: () => <DeleteOutlinedIcon />,
            tooltip: t('delete'),
            onClick: (event, rowData) => this.onDeleteRowClickHandler(rowData),
            disabled: !editable || isParentOrg(this.props.user.organisation, organisations.find(org => org.Name === rowData.Organisation.Name)),
          })
      )
    }

    if (!this.props.redirected) {
      const tableOptions = {
        pageSize: this.props.data.length > 5 ? 10 : 5,
        pageSizeOptions: this.props.data.length > 5 ? [5, 10, 20, 50, 100, 500, 1000, { value: this.state.data.length, label: t('all-option') }] : [5],
        paginationType: "normal",
        addRowPosition: "first",
        initialPage: initPage,
        draggable: false,
        filtering: true,
        searchFieldAlignment: "left",
        toolbarButtonAlignment: "left",
        maxBodyHeight: 620,
      }

      if (pingedRowIndex) {
        tableOptions.rowStyle = rowData => ({
          backgroundColor: (pingedRowIndex === rowData.tableData.id) ? '#EEE' : '#FFF'
        })
      }

      const { renderedData } = this.state

      const renderedProjectIds = renderedData.map(obj => obj.ID)

      return (
        <div>
          <MaterialTable
            title={this.props.title}
            columns={columns}
            data={renderedData}
            onRowClick={((evt, rowData) => { this.setState({ selectedRow: rowData }) })}
            style={{
              paddingLeft: '1%',
              paddingRight: '1%',
              fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
              fontSize: `0.875rem`
            }}
            options={tableOptions}
            onChangePage={() => this.setState({ pingedRowIndex: undefined })}
            localization={{
              header: {
                actions: t('actions')
              },
              toolbar: {
                searchTooltip: t('search'),
                searchPlaceholder: t('search')
              },
              body: {
                emptyDataSourceMessage: t('no-records-to-display'),
                addTooltip: t('add'),
                deleteTooltip: t('delete'),
                editTooltip: t('edit'),
                editRow: {
                  saveTooltip: t('save'),
                  cancelTooltip: t('cancel'),
                  deleteText: t('deleteText')
                }
              },
              pagination: {
                firstTooltip: t('first-page'),
                previousTooltip: t('previous-page'),
                nextTooltip: t('next-page'),
                lastTooltip: t('last-page'),
                labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
                labelRowsSelect: t('rows'),
              }
            }}
            components={{
              Toolbar: props => (
                <div className="search">
                  <MTableToolbar {...props} />
                  <div style={{ padding: 23 }}>
                    <span>
                      <a
                        hidden
                        id={`downloadProjectsReport`}
                        target="_blank"
                        href={`${getServiceUrl()}${RESOURCES.Projects}/${RESOURCES.CreateProjectList}?$filter=ID in (${renderedProjectIds})&$orderby=Name,ID&timeZone=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}&download=false&token=${this.props.user.token}`}
                        rel="noopener noreferrer"
                      />
                      <Button
                        id="downloadProjectsReport"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={this.downloadExcel}
                        startIcon={<ReportIcon />}
                        disabled={renderedProjectIds === null || renderedProjectIds === undefined || (renderedProjectIds && renderedProjectIds.length <= 0)}
                      >
                        {t('projects')} Report
                      </Button>
                    </span>
                    <span>
                      <a
                        hidden
                        id="download5WTMReport"
                        target="_blank"
                        href={`${getServiceUrl()}${RESOURCES.Projects}/${RESOURCES.CreateFifthWorkdayReport}?$filter=ID in (${renderedProjectIds})&ID&timeZone=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}&download=false&token=${this.props.user.token}`}
                        rel="noopener noreferrer"
                      />
                      <Button
                        style={{ marginLeft: 10 }}
                        id={`download5WTMReport`}
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={this.downloadExcel}
                        startIcon={<ReportIcon />}
                        disabled={renderedProjectIds === null || renderedProjectIds === undefined || (renderedProjectIds && renderedProjectIds.length <= 0)}
                      >
                        {t('fifth-workday-report')}
                      </Button>
                    </span>
                  </div>
                </div>
              )
            }}
            actions={tableActions}
          />
          <Modal open={openDeleteEntry} onClose={this.closeDeleteEntryPopup}>
            <div className="delete-popup">
              <div style={{ width: "100%", textAlign: "center", color: "#242424" }}>
                <h2>{t('deleteText')}</h2>
              </div>
              <div className="buttonContainer">
                <Button
                  onClick={this.closeDeleteEntryPopup}
                  style={{ marginRight: 7 }}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  {t('cancel')}
                </Button>
                <Button
                  onClick={this.handleDeleteEntry}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  {t('delete')}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else return null;
  }
}

export default ProjectsTable