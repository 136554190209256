import {
  GET_PENSUMS_REQUEST,
  GET_PENSUMS_ERROR,
  GET_PENSUMS_SUCCESS,
  CREATE_PENSUM_OBJ,
  UPDATE_PENSUM_REQUEST,
  UPDATE_PENSUM_ERROR,
  UPDATE_PENSUM_SUCCESS,
  UPDATE_PENSUM_OBJ,
  CREATE_PENSUM_REQUEST,
  CREATE_PENSUM_ERROR,
  CREATE_PENSUM_SUCCESS,
  DELETE_PENSUM_REQUEST,
  DELETE_PENSUM_ERROR,
  DELETE_PENSUM_SUCCESS,
  GET_PENSUM_REQUEST,
  GET_PENSUM_SUCCESS,
  GET_PENSUM_ERROR
} from "../../actions/types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_PENSUMS_REQUEST:
      return {
        ...state,
        isFetching: !action.isSideEffect,
        error: undefined
      };

    case GET_PENSUMS_SUCCESS:
      return {
        ...state,
        pensumsById: action.payload,
        isFetching: false,
        error: undefined
      };

    case GET_PENSUMS_ERROR:
      return {
        pensumsById: {},
        error: `${action.error}`
      };

    case CREATE_PENSUM_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case CREATE_PENSUM_ERROR:
      return {
        ...state,
        isFetching: false,
        error: `${action.error}`
      };

    case CREATE_PENSUM_SUCCESS:
      const projectsInState = action.fullState.project.projects
      const crewsInState = action.fullState.crew.crews
      const pensumProject = projectsInState[action.payload.Project.ID]
      const pensumCrew = crewsInState[action.payload.Crew.ID]
      return {
        isFetching: false,
        pensumsById: {
          ...state.pensumsById,
          [action.payload.ID]: {
            ...action.payload,
            Project: pensumProject,
            Crew: pensumCrew
          }
        },
        error: undefined
      };

    case UPDATE_PENSUM_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case UPDATE_PENSUM_SUCCESS:
      const { pensumId, changedProps } = action.payload
      return {
        ...state,
        pensumsById: {
          ...state.pensumsById,
          [pensumId]: {
            ...state.pensumsById[pensumId],
            ...changedProps
          }
        },
        isFetching: false,
        error: undefined
      };

    case UPDATE_PENSUM_ERROR:
      return {
        ...state,
        isFetching: false,
        error: `${action.error}`
      };
    
    case DELETE_PENSUM_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case DELETE_PENSUM_SUCCESS:
      let { pensumsById: { [action.payload.ID]: omit, ...newPensumsById } } = state
      return {
        ...state,
        pensumsById: newPensumsById,
        isFetching: false,
        error: undefined
      };

    case DELETE_PENSUM_ERROR:
      return {
        ...state,
        isFetching: false,
        error: `${action.error}`
      };

    case GET_PENSUM_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case GET_PENSUM_SUCCESS:
      return {
        ...state,
        pensumsById: { ...state.pensumsById, [action.payload.ID]: action.payload },
        isFetching: false,
        error: undefined
      };

    case GET_PENSUM_ERROR:
      return {
        ...state,
        isFetching: false,
        error: `${action.error}`
      };

    case UPDATE_PENSUM_OBJ:
      return {
        ...state,
        pensumsById: {
          ...state.pensumsById,
          [action.payload.ID]: {
            ...state.pensumsById[action.payload.ID],
            ...action.payload
          }
        },
        isFetching: false,
        error: undefined
      }

    case CREATE_PENSUM_OBJ:
      return {
        ...state,
        pensumsById: { ...state.pensumsById, [action.payload.ID]: action.payload },
        isFetching: false,
        error: undefined
      }

    default:
      return state;
  }
};
