import {
  CREATE_PROJECT_CORRESPONDENCE_ENTRY_REQUEST,
  CREATE_PROJECT_CORRESPONDENCE_ENTRY_ERROR,
  CREATE_PROJECT_CORRESPONDENCE_ENTRY_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/createEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default entity => (state = initialState, action) => {

  context.init(context);

  context.CREATE_ENTITY_REQUEST = CREATE_PROJECT_CORRESPONDENCE_ENTRY_REQUEST;
  context.CREATE_ENTITY_ERROR = CREATE_PROJECT_CORRESPONDENCE_ENTRY_ERROR;
  context.CREATE_ENTITY_SUCCESS = CREATE_PROJECT_CORRESPONDENCE_ENTRY_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "projectCorrespondenceEntries";
  context.entityLocation = RESOURCES.ProjectCorrespondenceEntries;
  //context.hasOrganisation = true;

  return context.execute(context)(entity)(state, action);
};