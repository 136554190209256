export function isParentOrg(organisation, parentOrganisation) {
    return parentOrganisation.Left < organisation.Left && parentOrganisation.Right > organisation.Left;
}

export function filterOrganisations(organisations, organisation, isParentLogin) {

    var res;
    if (isParentLogin === undefined || !isParentLogin) {
        // current and children
        res = organisations.filter(org => !isParentOrg(organisation, org));
    }
    else {
        // current organisation
        res = organisations.filter(org => org.Left == organisation.Left);
    }

    return res;
}

export function getOrganisation(organisation, baseOrganisation, isParentLogin) {

    return !isParentLogin && baseOrganisation != null ? baseOrganisation : organisation;

}

export function filterEntities(entities, organisation, isParentLogin) {

    var res;
    if (isParentLogin === undefined || !isParentLogin) {
        // no restriction
        res = entities;
    }
    else {
        // current and parent organisation
        res = entities.filter(entity => entity.Organisation.Left == organisation.Left || isParentOrg(organisation, entity.Organisation));
    }

    return res;
}

export function isEditable(organisation, parentOrganisation, isParentLogin) {
    return parentOrganisation && !isParentOrg(organisation, parentOrganisation) &&
        (!isParentLogin || organisation.Left == parentOrganisation.Left);
}

export function canEditKeyResource(organisation, parentOrganisation, isParentLogin) {
    return isParentLogin === true && (parentOrganisation == null || organisation.Left == parentOrganisation.Left);
}

export function expandPopupOrganisation(organisations, orgTextElementClassName, searchCondition) {
    const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
    /*const waitUntilPopupIsMounted = async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 100));
      if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }
    };*/
    if (organisationPopup) {
        if (searchCondition == undefined)
            searchCondition = (item, orgElement) => item.Name === orgElement.getAttribute("data-value");
        const orgsList = organisationPopup.childNodes[0].childNodes;
        if (orgsList) {
            const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
            let i = 0;
            orgsList.forEach(orgElement => {

                const orgObj = organisations.find(item => searchCondition(item, orgElement));
                const orgText = document.getElementsByClassName(orgTextElementClassName)[i];

                if (orgObj) {
                    orgText.setAttribute("data-value", orgObj.ShortName);
                    if (orgObj.Level === topLevel) {
                        orgText.style.marginLeft = "0px";
                    }
                    const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 5;
                    orgText.style.paddingLeft = `${margin}px`;
                }

                i++;
            });
        }
        organisationPopup.setAttribute("style", organisationPopup.getAttribute("style").replace(" width: 250px;", ""));
    }
    /*else if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }*/
}