import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import driverActions from "../../actions/driver";
import organisationActions from "../../actions/organisation";
import companyActions from "../../actions/company";
import crewActions from "../../actions/crew";
import notificationActions from "../../actions/notification";
import { setDataIsObject } from "../../actions/setDataIsObject";
import Driver from "./Driver";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ user, driver, organisation, crew, company }) => ({
  user: user,
  driver: driver.driver,
  organisations: organisation.organisations,
  crews: crew.crew,
  company: company.company,
  isFetching: isStateFetching([
    driver,
    organisation,
    company
  ])
});

const mapDispatchToProps = {
  ...driverActions,
  ...organisationActions,
  ...crewActions,
  ...companyActions,
  ...notificationActions,
  setDataIsObject
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Driver);
export default withNamespaces(["common"])(connected);
