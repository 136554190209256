import React from "react";

import { isEqualMasterData } from "../../utils";

import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
/* import InputLabel from '@material-ui/core/InputLabel'; */
import Checkbox from '@material-ui/core/Checkbox';

import './index.css';

class MixtureOrderTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      editable: props.editable ? props.editable : '',
      mixingPlantRow: props.mixingPlantRow,
      newData: null,
      error: null,
      stateIsUpdated: false
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const columns = [
      {
        title: `${t('order-number')} *`, field: "OrderNumber",
        validate: ({ OrderNumber }) => OrderNumber && OrderNumber.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('order-description'), field: "Description", filtering: false,
        editComponent: props => (
          <FormControl>
            <TextField
              id="description-input"
              value={props.value}
              type="text"
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        )
      },
      {
        title: t("is-deleted"), field: "IsDeleted", type: 'boolean', tableData: { "filterValue": "unchecked" }, width: '50px',
        render: rowData => (
          <Checkbox checked={rowData.IsDeleted} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      }
    ];

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ initPage, selectedRow: indexOfNewRow !== -1 ? data[indexOfNewRow] : null, isMounted: true, columns: columns })
    }
    else {
      this.setState({ isMounted: true, columns: columns })
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.data !== props.data) {
      if (!this.state.stateIsUpdated)
        this.setState({ data: props.data, selectedRow: null })
    }
  }

  rowAdd = newData =>
    new Promise(resolve => {
      this.setState({
        newData: newData
      });
      setTimeout(async () => {
        resolve();
        this.setState({ stateIsUpdated: true });
        const errorOrResult = await this.props.onMixtureOrderRowAdd({
          OrderNumber: newData.OrderNumber,
          Description: newData.Description,
          IsDeleted: newData.IsDeleted,
          MixingPlant: {
            ID: this.props.mixingPlantRow.ID
          }
        });
        this.setState({ stateIsUpdated: false });
        if (!(errorOrResult instanceof Error)) {
          const { setNewRow, toggleTable } = this.props
          const { ID } = errorOrResult
          this.setState(prevState => {
            const data = [...prevState.data];
            newData.ID = ID;
            data.push(newData);
            return { ...prevState, data };
          });
          setNewRow(ID)
          toggleTable()
          this.props.onMixtureOrderRowChanged(errorOrResult)
        }

      }, 100);
    })

  render() {
    const { t } = this.props;
    const { initPage, isMounted, editable } = this.state;

    return (
      isMounted ?
        <MaterialTable
          key={this.state.data.length}
          title={this.state.title}
          columns={this.state.columns}
          data={this.state.data}
          onRowClick={((evt, rowData) => { this.setState({ selectedRow: rowData, initPage: undefined }); this.props.onMixtureOrderRowChanged(rowData); })}
          style={{
            paddingLeft: !editable && '2%',
            paddingRight: !editable && '2%',
            fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
            fontSize: `0.875rem`
          }}
          onChangePage={() => this.setState({ initPage: undefined })}
          options={{
            pageSize: 10,
            pageSizeOptions: this.state.data.length > 10 ? [5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [10],
            paginationType: "normal",
            initialPage: initPage,
            selection: true,
            addRowPosition: "first",
            draggable: false,
            filtering: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "left",
            rowStyle: rowData => ({
              backgroundColor:
                (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id)
                  ? '#EEE' : '#FFF'
            })
          }}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            body: {
              emptyDataSourceMessage: t('no-records-to-display'),
              addTooltip: t('add'),
              deleteTooltip: t('delete'),
              editTooltip: t('edit'),
              editRow: {
                saveTooltip: t('save'),
                cancelTooltip: t('cancel'),
                deleteText: t('deleteText')
              }
            },
            pagination: {
              firstTooltip: t('first-page'),
              previousTooltip: t('previous-page'),
              nextTooltip: t('next-page'),
              lastTooltip: t('last-page')
            }
          }}
          editable={editable && {
            isEditable: rowData => this.props.isEditable,
            isDeletable: rowData => this.props.isEditable,
            onRowAdd: this.props.isEditable ? this.rowAdd : undefined,
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  if (oldData && newData && !isEqualMasterData("MixtureOrder", oldData, newData)) {
                    this.setState({ stateIsUpdated: true });
                    const errorOrResult = await this.props.onMixtureOrderRowUpdate({
                      ...newData,
                      // MixingPlant: {
                      //   ID: org.ID
                      // }
                    }, oldData.tableData);
                    this.setState({ stateIsUpdated: false });
                    if (!(errorOrResult instanceof Error)) {
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                    }
                  }
                }, 100);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  const errorOrResult = await this.props.onMixtureOrderRowDelete(oldData);
                  if (!(errorOrResult instanceof Error)) {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      if (data.indexOf(oldData) >= 0)
                        data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data, selectedRow: (prevState.selectedRow !== undefined && prevState.selectedRow !== null && prevState.selectedRow.ID !== oldData.ID ? prevState.selectedRow : null) };
                    });
                    this.setState({ initPage: undefined });
                    await this.props.onMixtureOrderRowChanged(null);
                  }
                }, 100);
              })
          }}
        /> : null
    );
  }
}

export default MixtureOrderTable;