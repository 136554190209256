import { UPDATE_ENTRY_OBJ } from '../types';

export const updateEntryObj = payload => ({
    type: UPDATE_ENTRY_OBJ,
    payload
});

export default (entryType, entry) => (dispatch, getState) => {
    if (entryType === null || entryType === undefined || entry === null || entry === undefined) {
        return
    }
    const fullState = getState()
    dispatch(updateEntryObj({ entryType, entry, fullState }));
};