import odata from 'odata-client';
import {
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';
/*import getToken from '../user/getToken';*/

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const getCustomerRequest = () => ({
  type: GET_CUSTOMER_REQUEST
});

export const getCustomerError = errorOrResults => ({
  type: GET_CUSTOMER_ERROR,
  error: errorOrResults.message
});

export const getCustomerSuccess = payload => ({
  type: GET_CUSTOMER_SUCCESS,
  payload
});

export default (filterQuery) => async (dispatch, getState) => {
  dispatch(getCustomerRequest());

  const query = odata({ service: getServiceUrl() }).resource(RESOURCES.Customers);
  query.select("ID, Name, ContractTypeCode, IsDeleted");
  query.expand("Organisation($select=ID, ShortName, Name)");
  query.orderby("Name, ID")
  if (filterQuery !== undefined && filterQuery !== null) {
    query.filter(filterQuery);
  }

  const errorOrResult = await query.get({
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;

      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        console.log("get customer api error", res.toJSON());
        const body = JSON.parse(res.body);
        const { Error: { Message } } = body
        const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
        return new Error(errorKey);
      } else {
        const body = JSON.parse(res.body);
        const payload = body.value;
        const storeState = getState()
        if (storeState.customer.dataIsObject) {
          const customersById = {}
          payload.forEach(obj => {
            customersById[obj.ID] = obj
          })
          return customersById;
        }

        return payload
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    });

  if (errorOrResult instanceof Error) {
    dispatch(getCustomerError(errorOrResult, GET_CUSTOMER_ERROR));
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    console.log("CODE ERROR: ", errorOrResult.message);
    dispatch(showNotification(errorOrResult.message, "error"))
    return;
  }

  dispatch(getCustomerSuccess(errorOrResult));

  return errorOrResult;
};