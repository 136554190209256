import {
  GET_PROJECT_CORRESPONDENCE_ENTRIES_REQUEST,
  GET_PROJECT_CORRESPONDENCE_ENTRIES_ERROR,
  GET_PROJECT_CORRESPONDENCE_ENTRIES_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/getEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (selectQuery, filterQuery) => (state = initialState, action) => {

  context.init(context);

  context.GET_ENTITY_REQUEST = GET_PROJECT_CORRESPONDENCE_ENTRIES_REQUEST;
  context.GET_ENTITY_ERROR = GET_PROJECT_CORRESPONDENCE_ENTRIES_ERROR;
  context.GET_ENTITY_SUCCESS = GET_PROJECT_CORRESPONDENCE_ENTRIES_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "projectCorrespondenceEntries";
  context.entityLocation = RESOURCES.ProjectCorrespondenceEntries;
  context.selectQuery = "ID,Name,CreatorDisplayName,CreatorUserNumber,Date";
  context.orderByQuery = "ID,Name";

  if (selectQuery !== undefined && selectQuery !== null)
    context.selectQuery = selectQuery;

  if (filterQuery !== undefined && filterQuery !== null)
    context.filterQuery = filterQuery;

  return context.execute(context)()(state, action);
};