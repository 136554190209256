import odata from 'odata-client';
import { TOKEN_EXPIRED } from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export default (changeMessageObj) => async (dispatch, getState) => {

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.ChangeMessages).resource(RESOURCES.WaitForMessages);
    query.expand("Pensum($expand=Crew($select=ID),Project($select=ID)),ProjectActivityEntry($expand=Pensum($select=ID),ProjectActivity($select=ID)),EmployeeEntry($expand=Pensum($select=ID),Employee($select=ID)),EquipmentEntry($expand=Pensum($select=ID),Equipment($select=ID)),MixtureEntry($expand=Pensum($select=ID),Mixture($select=ID)),DriverEntry($expand=Pensum($select=ID),Driver($select=ID),MixingPlant($select=ID))")
    const errorOrResult = await query.post(changeMessageObj, {
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            "X-IAS-RequestType": "auto",
            "X-IAS-SessionID": `${getState().user.changeMessageToken}`,
            ...getDefaultHeader()
        }
    })
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;
            const needToReload = res.statusCode === 400
            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('change message api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : Message;
                const error = { error: new Error(errorKey) }

                if (needToReload) {
                    error.needToReload = needToReload
                }

                return error;
            } else {
                if (res.statusCode === 204) {
                    return { cancel: true }
                } else {
                    const { headers } = res
                    const body = JSON.parse(res.body)
                    const payload = body.value
                    const lastMessageId = headers['x-ias-lastchangemessageid']

                    return { latestChangeMessageID: lastMessageId, objectsToChange: payload }
                }
                
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("NO_MORE_SYNC", "error"))
        });

    if (errorOrResult && errorOrResult.error) {
        console.log("CODE ERROR: ", errorOrResult.error.message);
        if (errorOrResult.error.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.error.message.split(":")[1], "error"))
        } else {
            dispatch(showNotification(errorOrResult.error.message, "error"))
        }
        return;
    }

    return errorOrResult;
};