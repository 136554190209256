import odata from 'odata-client';
import {
  UPDATE_CHECKPOINT_DEFINITION_REQUEST,
  UPDATE_CHECKPOINT_DEFINITION_ERROR,
  UPDATE_CHECKPOINT_DEFINITION_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const updateCheckpointDefinitionDataRequest = () => ({
  type: UPDATE_CHECKPOINT_DEFINITION_REQUEST
});

export const updateCheckpointDefinitionDataError = errorOrResults => ({
  type: UPDATE_CHECKPOINT_DEFINITION_ERROR,
  error: errorOrResults.message
});

export const updateCheckpointDefinitionDataSuccess = payload => ({
  type: UPDATE_CHECKPOINT_DEFINITION_SUCCESS,
  payload
});

export default (checkpointDefinition, checkpointDefinitionTableData) => async (dispatch, getState) => {

  dispatch(updateCheckpointDefinitionDataRequest());

  const query = odata({ service: getServiceUrl() }).resource(RESOURCES.CheckpointDefinitions, checkpointDefinition.ID);
  const errorOrResult = await query.patch({ ...checkpointDefinition }, {
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;

      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        const response = res.toJSON()
        const error = JSON.parse(response.body).Error
        const errorKey = res.statusCode === 401 ? `401: ${error.Details[0].Message}` : `${error.Details[0].Message}`;
        console.log("update checkpoint definition api error", response);
        return new Error(errorKey);
      } else {
        const payload = checkpointDefinition;
        payload.tableData = checkpointDefinitionTableData;
        return payload;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    });

  if (errorOrResult instanceof Error) {
    dispatch(updateCheckpointDefinitionDataError(UPDATE_CHECKPOINT_DEFINITION_ERROR, errorOrResult.message));
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    console.log("CODE ERROR: ", errorOrResult.message);
    dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_SAVE", "success"))
  }

  dispatch(updateCheckpointDefinitionDataSuccess(errorOrResult));

  return errorOrResult;
};
