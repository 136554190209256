import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import pensumActions from "../../../../actions/pensum";

import { getProjectsSelector } from "../../../../selectors/project"
import { getHeaderBackgroundColorSelector } from "../../../../selectors/settings"

import PensumsContainer from "./PensumsContainer";

const mapStateToProps = ({ project, settings }) => ({
  projects: getProjectsSelector(project),
  headerColor: getHeaderBackgroundColorSelector(settings)
});

const mapDispatchToProps = {
  ...pensumActions
};

const connected = connect(mapStateToProps, mapDispatchToProps)(PensumsContainer);
export default withNamespaces(['common'])(connected);
