import React, { Component } from "react";
/* import { render } from "react-dom";
import {HighChart} from "../../core/components/HighChart/HighChart";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; */

import Loader from '../../core/components/Loader/LinearProgress';

import { Container,Switch,FormGroup,FormControlLabel,Typography  } from '@material-ui/core';

import './index.css'

class Settings extends Component {
  constructor(props) {
    super(props);
    const { user }= this.props;
    this.state = {
      showSwappedTable: user.organisation.IsTimeAndCrewSwapped ? user.organisation.IsTimeAndCrewSwapped : user.baseOrganisation.IsTimeAndCrewSwapped ? user.baseOrganisation.IsTimeAndCrewSwapped : false,
      
    }
    this.toggleChecked = this.toggleChecked.bind(this);
  }
  
  toggleChecked =(ev) =>{
    this.setState({ showSwappedTable: ev.target.checked  });
    localStorage.setItem("table", ev.target.checked)
  };

  componentDidMount() {
    const showSwappedTable = localStorage.getItem("table");
    if(showSwappedTable){
      this.setState({
        showSwappedTable: JSON.parse(showSwappedTable)})}
  };

  render() {
    const { showSwappedTable, ...rest } = this.state;

    return (<>
      <div 
        className="Settings"  
        style={{
        margin: "0 auto",
        minimumHeight: 600,
        width: "620px",
        marginTop:  30,
      }} 
      >
            <Container  className="container" maxWidth="sm" >
            <Typography variant="h6">
              User Settings:
              </Typography>
              <div style={{display: "flex",flexDirection: "column", margin: "20px 0px 0px 5px",alignItems: "flex-start"}}>
              <div style={{display: "flex",flexDirection: "row", margin: "20px 0px 0px 5px",alignItems: "center"}}>
            <Typography style={{marginRight: "10px"}}variant="body1">
              <b style={{marginRight: "20px"}}>Planung Table:</b>  Normal
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Switch name="SwitchTable" checked={showSwappedTable}  onChange={this.toggleChecked} />}
                   label={`Flipped `}  /* (${showSwappedTable}) */
                />
              </FormGroup>
              </div>
              {/* <p>"SupervisorCommunicationChannelCode"</p> */}
  {/*             <div style={{display: "flex",flexDirection: "row", margin: "20px 0px 0px 5px",alignItems: "center"}}>
              <Typography style={{marginRight: "10px"}}variant="body1">
              <b style={{marginRight: "20px"}}>Email reports:</b>  No
              </Typography>
              <FormGroup>
              <FormControlLabel
                  control={<Switch name="SwitchEmail" checked={""}  onChange={""} />}
                   label={`Yes `}
                />
              </FormGroup>
              </div> */}
              </div>
            </Container>
        </div>
{/*               <HighChart/> */}
        </>
    );
  }
}
export default Settings;
