import {
    GET_COMPANY_REQUEST,
    GET_COMPANY_ERROR,
    GET_COMPANY_SUCCESS,
    CREATE_COMPANY_REQUEST,
    CREATE_COMPANY_ERROR,
    CREATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_ERROR,
    UPDATE_COMPANY_SUCCESS,
    DELETE_COMPANY_REQUEST,
    DELETE_COMPANY_ERROR,
    DELETE_COMPANY_SUCCESS,
    SET_DATA_IS_OBJECT
  } from "../../actions/types";
  import reducers from "../common"
  
  const initialState = {};
  export default (state = initialState, action) => {
  
    reducers.init(reducers);
  
    reducers.GET_ENTITY_REQUEST = GET_COMPANY_REQUEST;
    reducers.GET_ENTITY_ERROR = GET_COMPANY_ERROR;
    reducers.GET_ENTITY_SUCCESS = GET_COMPANY_SUCCESS;
    reducers.CREATE_ENTITY_REQUEST = CREATE_COMPANY_REQUEST;
    reducers.CREATE_ENTITY_ERROR = CREATE_COMPANY_ERROR;
    reducers.CREATE_ENTITY_SUCCESS = CREATE_COMPANY_SUCCESS;
    reducers.UPDATE_ENTITY_REQUEST = UPDATE_COMPANY_REQUEST;
    reducers.UPDATE_ENTITY_ERROR = UPDATE_COMPANY_ERROR;
    reducers.UPDATE_ENTITY_SUCCESS = UPDATE_COMPANY_SUCCESS;
    reducers.DELETE_ENTITY_REQUEST = DELETE_COMPANY_REQUEST;
    reducers.DELETE_ENTITY_ERROR = DELETE_COMPANY_ERROR;
    reducers.DELETE_ENTITY_SUCCESS = DELETE_COMPANY_SUCCESS;
    reducers.SET_DATA_IS_OBJECT = SET_DATA_IS_OBJECT;
    reducers.entityName = "company";
  
    return reducers.entity(reducers)(state, action);
  };