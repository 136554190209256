import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Box from '@material-ui/core/Box';
import CssBaseline from "@material-ui/core/CssBaseline";
import { isValidEmail } from '../../utils';

import "./index.css";

const logo = '/content/media/StrabagLogo.png';

export default class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailAddress: '',
            error: ''
        };
    }

    handleSubmit = async (event) => {
        event.preventDefault()

        const { emailAddress } = this.state;
        const errorOrResult = await this.props.resetPassword(
            {
                EmailAddress: emailAddress,
            }
        )
        if (errorOrResult.error & !(errorOrResult.error instanceof Error)) {
            this.setState({ emailAddress: '', error: '' })
        }
        else {
            this.setState({ error: errorOrResult.error.message })
        }
    };

    handleChange = event => {
        const { value } = event.target
        this.setState({ emailAddress: value, error: '' })
    }

    render() {
        const { t, isResetPassword } = this.props
        const { emailAddress, error } = this.state

        return (
            <Container component="div" maxWidth="xs" style={{ marginTop: 40, marginBottom: 40, paddingBottom: 40 }}>
                {
                    true ? (
                        <>
                            <CssBaseline />
                            <div className="logo-container-big">
                                <img className="logo-big" alt="logo" src={logo} />
                            </div>
                            <div className="changePasswordTitle">
                                <h2>{t('reset-password-title')}</h2>
                            </div>
                            <Container component="div" maxWidth="sm" style={{ marginTop: 20, marginBottom: 40, fontFamily: "Sans Serif" }}>
                                <form onSubmit={this.handleSubmit}>
                                    <div>
                                        {t('reset-password-hint')}
                                    </div>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        onChange={this.handleChange}
                                        value={emailAddress}
                                        error={
                                            (emailAddress && emailAddress.length == 0) ||
                                            (emailAddress && emailAddress.length > 0 && !isValidEmail(emailAddress))
                                            || (isResetPassword && (Boolean(!isResetPassword)))
                                        }
                                        required
                                        fullWidth
                                        id="resetPassword"
                                        label={t('email-address')}
                                        name="resetPassword"
                                    />
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="submitButton"
                                            disabled={
                                                (emailAddress && emailAddress === null)
                                                || (emailAddress === "")
                                            }
                                        >
                                            {t('send')}
                                        </Button>
                                        <div style={{ width: "20px" }}></div>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className="submitButton"
                                            href="\"
                                        >
                                            {t('back')}
                                        </Button>
                                    </div>
                                </form>
                                {error && <div className="password-note">
                                    <Box mt={5}>
                                        {t('error') + ' : ' + error}
                                    </Box>
                                </div>}
                            </Container>
                        </>
                    ) : null
                }
            </Container>

        )
    }
}
