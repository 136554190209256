import getOrganisations from "./getOrganisations.js";
import getOrganisationData from "./getOrganisationData.js";
import updateOrganisationData from "./updateOrganisationData.js";

import getCheckpointDefinitions from "./getCheckpointDefinitions";
import createCheckpointDefinition from "./createCheckpointDefinition";
import updateCheckpointDefinition from "./updateCheckpointDefinition";

export {
    getOrganisations, getOrganisationData, updateOrganisationData,
    getCheckpointDefinitions, createCheckpointDefinition, updateCheckpointDefinition
};

export default {
    getOrganisations, getOrganisationData, updateOrganisationData,
    getCheckpointDefinitions, createCheckpointDefinition, updateCheckpointDefinition
};