import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import equipmentActions from "../../actions/equipment";
import organisationActions from "../../actions/organisation";
import companyActions from "../../actions/company";
import { setDataIsObject } from "../../actions/setDataIsObject";
import Equipment from "./Equipment";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ user, equipment, organisation, company }) => ({
    user: user,
    equipment: equipment.equipment,
    organisations: organisation.organisations,
    company: company.company,
    isFetching: isStateFetching([
        equipment,
        organisation,
        company
    ])
});

const mapDispatchToProps = {
    ...equipmentActions,
    ...organisationActions,
    ...companyActions,
    setDataIsObject
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Equipment)
export default withNamespaces(['common'])(connected);
