import React from "react";
import _ from 'lodash';
import moment from "moment";

import ProjectActivityTable from "./ProjectActivityTable";
import ProjectInvoiceTable from "./ProjectInvoiceTable";
import ProjectCorrespondenceTable from "./ProjectCorrespondenceTable";
/* 
import { makeStyles } from '@material-ui/core/styles';
 */
import ReactSelect from 'react-select';

import Modal from '@material-ui/core/Modal';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
/* import Checkbox from '@material-ui/core/Checkbox'; */
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import InputAdornment from '@material-ui/core/InputAdornment';
import NumberInput from '../../core/components/Inputs/NumberInput/numberInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { enGB, enUS, de } from 'date-fns/locale'

import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";

import Mode from "./ModeEnum";
import RESOURCES from '../../api/resources';
import routes from "../../config/routes";
import { getServiceUrl } from '../../utils';

import './index.css';
import { PROJECT_STATE_CODES } from "../../config/constants";
import { FormLabel, Radio, RadioGroup, Tooltip, Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { filterOrganisations, expandPopupOrganisation, getOrganisation } from "../Common/common";

const styles = theme => ({
  formControl: {
    '& .MuiInput-formControl': {
      width: '100% !important',
    },
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
    width: "50%",
  },
  label: {
    fontSize: "1em",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    display: "flex",
    alignItems: "center",
    width: "300px"
  },
  label2: {
    fontSize: "1em",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    display: "flex",
    alignItems: "center",
  },
  thumbnail: {
    width: "150px",
    height: "170px",
    margin: "5px",
    display: "inline-block",
  },
  thumbnailImage: {
    width: "auto",
    height: "auto",
    maxWidth: "120px",
    maxHeight: "120px",
    border: "1px solid #ddd", /* Gray border */
    boxShadow: "3px 5px 5px #888888",
    cursor: "pointer",
  },
  thumbnailLabel: {
    height: "30px",
    width: "140px",
    fontSize: "15px",
    marginBlockStart: "0px",
    marginBlockEnd: "0px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  attachmentDeleteButton: {
    width: 17,
    marginLeft: 4,
  },
});

const selectStyles = {
  control: (styles) => ({
    ...styles,
    background: 'none'
  }),
  input: (styles, state) => {
    return {
      ...styles
    }
  },
  singleValue: (styles, { error }) => {
    return {
      ...styles,
      fontWeight: 400,
      fontSize: 16,
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    }
  },
}

// const input = React.forwardRef((props, ref) => (
//   <input ref={ref} className="input">
//     {props.children}
//   </input>
// ));

class ProjectEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',

      projects: props.projects ? props.projects.filter(e => !e.IsDeleted) : null,
      organisations: props.organisations ? props.organisations.filter(e => !e.IsDeleted) : null,
      supervisors: props.employees ? props.employees.filter(e => e.IsSupervisor && !e.IsDeleted) : null,
      customers: props.customers ? props.customers.filter(e => !e.IsDeleted) : null,
      mixingPlants: props.mixingPlants ? props.mixingPlants.filter(e => !e.IsDeleted) : null,

      editable: props.editable ? props.editable : '',
      mode: props.mode,
      selectedFilters: [],
      error: null,

      dataRow: props.dataRow ? props.dataRow : null,
      name: props.dataRow ? props.dataRow.Name : '',
      costCenter: props.dataRow ? props.dataRow.CostCenter : '',
      externalReferenceNumber: props.dataRow ? props.dataRow.ExternalReferenceNumber : '',
      workExecutionEstimatePercentage: props.dataRow && props.dataRow.WorkExecutionEstimatePercentage !== null ? props.dataRow.WorkExecutionEstimatePercentage : 0,
      orderTotalAmount: props.dataRow ? props.dataRow.OrderTotalAmount : '',
      actualInvoicedAmount: props.dataRow ? props.dataRow.ActualInvoicedAmount : '',
      finalInvoiceTimeUtc: props.dataRow ? props.dataRow.FinalInvoiceTimeUtc : '',
      constructionBeginTimeUtc: props.dataRow ? props.dataRow.ConstructionBeginTimeUtc : '',
      acceptanceTimeUtc: props.dataRow ? props.dataRow.AcceptanceTimeUtc : '',
      warrantyEndTimeUtc: props.dataRow ? props.dataRow.WarrantyEndTimeUtc : '',
      invoices: props.dataRow ? props.dataRow.Invoices : [],
      stateCode: props.dataRow ? props.dataRow.StateCode : -10, //default stateCode
      Notes: props.dataRow ? props.dataRow.Notes : null,
      WorkExecutionEstimateOrderTotalAmount: props.dataRow ? props.dataRow.WorkExecutionEstimateOrderTotalAmount : null,
      WorkExecutionEstimateActualInvoicedAmount: props.dataRow ? props.dataRow.WorkExecutionEstimateActualInvoicedAmount : null,
      RemainingAmountToInvoice: props.dataRow ? props.dataRow.RemainingAmountToInvoice : null,
      OrderTotalAmountNextYear: props.dataRow ? props.dataRow.OrderTotalAmountNextYear : null,

      organisation: props.dataRow ? props.dataRow.Organisation : null,
      supervisor: props.dataRow ? props.dataRow.Supervisor : null,
      customer: props.dataRow ? props.dataRow.Customer : null,
      mixingPlant: props.dataRow && props.dataRow.DefaultMixingPlant !== undefined ? props.dataRow.DefaultMixingPlant : null,
      mixtureOrder: props.dataRow && props.dataRow.DefaultMixtureOrder !== undefined ? props.dataRow.DefaultMixtureOrder : null,
      offerDocument: props.dataRow && props.dataRow.OfferDocument !== undefined ? props.dataRow.OfferDocument : null,
      tenderDocument: props.dataRow && props.dataRow.TenderDocument !== undefined ? props.dataRow.TenderDocument : null,
      images: props.dataRow && props.dataRow.Images !== undefined ? props.dataRow.Images : null,
      documents: props.dataRow && props.dataRow.Documents !== undefined ? props.dataRow.Documents : null,

      offerDocumentFileName: props.dataRow && props.dataRow.OfferDocument ? props.dataRow.OfferDocument.FileName : null,
      tenderDocumentFileName: props.dataRow && props.dataRow.TenderDocument ? props.dataRow.TenderDocument.FileName : null,

      orderTotalAmountCurrentYear: props.dataRow ? props.dataRow.OrderTotalAmountCurrentYear : '',
      OrderTotalAmountPreviousYear: props.dataRow ? props.dataRow.OrderTotalAmountPreviousYear : null,
      actualInvoicedAmountCurrentYear: props.dataRow ? props.dataRow.ActualInvoicedAmountCurrentYear : '',
      remainingAmountToInvoiceCurrentYear: props.dataRow ? props.dataRow.RemainingAmountToInvoiceCurrentYear : '',
      stageOfCompletion: props.dataRow ? props.dataRow.StageOfCompletion : '',
      engineeringOffice: props.dataRow ? props.dataRow.EngineeringOffice : null,
      company: props.company ? props.company.filter(c => { if (c.TypeCode == 3) { return true } else { return false } }) : null,
      constructionEndTimeUtc: props.dataRow ? props.dataRow.ConstructionEndTimeUtc : '',
      termsOfPayment: props.dataRow ? props.dataRow.TermsOfPayment : '',
      notesLinks: props.dataRow ? props.dataRow.NotesLinks : [],
      correspondencies: props.dataRow ? props.dataRow.Correspondence : [],
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.onFileUploadClickHandler = this.onFileUploadClickHandler.bind(this);
    this.onFileDownloadClickHandler = this.onFileDownloadClickHandler.bind(this);
    this.onDocumentChangeHandler = this.onDocumentChangeHandler.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.fileDowloadLink = this.fileDowloadLink.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dataRow) {
      if (nextProps.dataRow.Documents !== this.state.documents)
        this.setState({ documents: nextProps.dataRow.Documents && nextProps.dataRow.Documents !== undefined ? nextProps.dataRow.Documents : null });
      if (nextProps.dataRow.Images !== this.state.images)
        this.setState({ images: nextProps.dataRow.Images && nextProps.dataRow.Images !== undefined ? nextProps.dataRow.Images : null });
      if (nextProps.dataRow.NotesLinks !== this.state.notesLinks)
        this.setState({ notesLinks: nextProps.dataRow.NotesLinks && nextProps.dataRow.NotesLinks !== undefined ? nextProps.dataRow.NotesLinks : null })
      if (nextProps.dataRow.Invoices !== this.state.invoices) {
        this.setState({ invoices: nextProps.dataRow.Invoices && nextProps.dataRow.Invoices !== undefined ? nextProps.dataRow.Invoices : [] })
      }
    }
    this.setState({
      dataRow: nextProps.dataRow ? nextProps.dataRow : null,
      actualInvoicedAmount: nextProps.dataRow && nextProps.dataRow.ActualInvoicedAmount,
      RemainingAmountToInvoice: nextProps.dataRow && nextProps.dataRow.RemainingAmountToInvoice,
      WorkExecutionEstimateOrderTotalAmount: nextProps.dataRow && nextProps.dataRow.WorkExecutionEstimateOrderTotalAmount,
      orderTotalAmountCurrentYear: nextProps.dataRow && nextProps.dataRow.OrderTotalAmountCurrentYear,
      OrderTotalAmountPreviousYear: nextProps.dataRow && nextProps.dataRow.OrderTotalAmountPreviousYear,
      actualInvoicedAmountCurrentYear: nextProps.dataRow && nextProps.dataRow.ActualInvoicedAmountCurrentYear,
      OrderTotalAmountNextYear: nextProps.dataRow && nextProps.dataRow.OrderTotalAmountNextYear,
      remainingAmountToInvoiceCurrentYear: nextProps.dataRow && nextProps.dataRow.RemainingAmountToInvoiceCurrentYear,
    });
  }

  componentWillMount() {
    if (this.state.mixingPlant !== null) {
      var mp = this.props.mixingPlants.find(e => e.ID === this.state.mixingPlant.ID);
      if (mp !== null)
        this.setState(state => ({ mixingPlant: mp }));
    }
    // new row
    if (this.state.dataRow === null && this.props.user.employeeID !== null) {
      const supervisor = this.state.supervisors.find(e => e.ID === this.props.user.employeeID);
      if (supervisor !== null) {
        this.setState(state => ({ organisation: this.props.user.organisation, supervisor: supervisor }));
      }
    }
  }

  componentDidMount() {

    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }

  }

  async handleSaveClick(e) {
    e.preventDefault();

    const { dataRow, ...rest } = this.state;
    var newDataRow = {}
    var errorOrResult = null;

    if (this.state.mode === Mode.Add) {
      newDataRow = {
        Name: rest.name,
        CostCenter: this.convertToNullIfEmptyString(rest.costCenter),
        ExternalReferenceNumber: this.convertToNullIfEmptyString(rest.externalReferenceNumber),
        WorkExecutionEstimatePercentage: this.convertToFloat(rest.workExecutionEstimatePercentage),
        OrderTotalAmount: this.convertToFloat(rest.orderTotalAmount),
        ActualInvoicedAmount: this.convertToFloat(rest.actualInvoicedAmount),
        FinalInvoiceTimeUtc: this.convertToNullIfEmptyString(rest.finalInvoiceTimeUtc),
        ConstructionBeginTimeUtc: this.convertToNullIfEmptyString(rest.constructionBeginTimeUtc),
        AcceptanceTimeUtc: this.convertToNullIfEmptyString(rest.acceptanceTimeUtc),
        WarrantyEndTimeUtc: this.convertToNullIfEmptyString(rest.warrantyEndTimeUtc),
        Organisation: rest.organisation,
        Supervisor: rest.supervisor,
        Customer: rest.customer,
        DefaultMixingPlant: rest.mixingPlant,
        DefaultMixtureOrder: rest.mixtureOrder,
        StateCode: rest.stateCode,
        Notes: rest.Notes,
        OrderTotalAmountNextYear: rest.OrderTotalAmountNextYear,
        //OrderTotalAmountCurrentYear: rest.OrderTotalAmountCurrentYear,
        OrderTotalAmountPreviousYear: rest.OrderTotalAmountPreviousYear,
        //ActualInvoicedAmountCurrentYear: rest.ActualInvoicedAmountCurrentYear,
        //RemainingAmountToInvoiceCurrentYear: rest.RemainingAmountToInvoiceCurrentYear,
        StageOfCompletion: rest.StageOfCompletion,
        EngineeringOffice: rest.engineeringOffice,
        ConstructionEndTimeUtc: this.convertToNullIfEmptyString(rest.constructionEndTimeUtc),
        TermsOfPayment: rest.termsOfPayment,
      };
      errorOrResult = await this.props.rowAddHandler(newDataRow);
      if (!(errorOrResult instanceof Error)) {
        errorOrResult.OfferDocument = null;
        errorOrResult.TenderDocument = null;
        errorOrResult.Images = null;
        errorOrResult.Documents = null;
        this.setState({ mode: Mode.Update, dataRow: errorOrResult });
      }
    } else if (this.state.mode === Mode.Update) {
      newDataRow = {
        ID: dataRow.ID,
        Name: rest.name,
        CostCenter: this.convertToNullIfEmptyString(rest.costCenter),
        ExternalReferenceNumber: this.convertToNullIfEmptyString(rest.externalReferenceNumber),
        WorkExecutionEstimatePercentage: this.convertToFloat(rest.workExecutionEstimatePercentage),
        OrderTotalAmount: this.convertToFloat(rest.orderTotalAmount),
        FinalInvoiceTimeUtc: this.convertToNullIfEmptyString(rest.finalInvoiceTimeUtc),
        ConstructionBeginTimeUtc: this.convertToNullIfEmptyString(rest.constructionBeginTimeUtc),
        AcceptanceTimeUtc: this.convertToNullIfEmptyString(rest.acceptanceTimeUtc),
        WarrantyEndTimeUtc: this.convertToNullIfEmptyString(rest.warrantyEndTimeUtc),
        Organisation: rest.organisation,
        Supervisor: rest.supervisor,
        Customer: rest.customer,
        DefaultMixingPlant: rest.mixingPlant,
        DefaultMixtureOrder: rest.mixtureOrder,
        OfferDocument: rest.offerDocument,
        TenderDocument: rest.tenderDocument,
        Images: rest.images,
        Documents: rest.documents,
        StateCode: rest.stateCode,
        Notes: rest.Notes,
        OrderTotalAmountNextYear: rest.OrderTotalAmountNextYear,
        //OrderTotalAmountCurrentYear: rest.OrderTotalAmountCurrentYear,
        OrderTotalAmountPreviousYear: rest.OrderTotalAmountPreviousYear,
        //ActualInvoicedAmountCurrentYear: rest.ActualInvoicedAmountCurrentYear,
        //RemainingAmountToInvoiceCurrentYear: rest.RemainingAmountToInvoiceCurrentYear,
        StageOfCompletion: rest.StageOfCompletion,
        EngineeringOffice: rest.engineeringOffice,
        ConstructionEndTimeUtc: this.convertToNullIfEmptyString(rest.constructionEndTimeUtc),
        TermsOfPayment: rest.termsOfPayment,
      };
      errorOrResult = await this.props.rowUpdateHandler(newDataRow, dataRow);
      if (!(errorOrResult instanceof Error))
        this.setState({ mode: Mode.Update, dataRow: errorOrResult });
    }
  }

  convertToFloat(value) {
    return value !== null && value !== '' ? parseFloat(value) : null;
  }

  convertToNullIfEmptyString(value) {
    return value === '' ? null : value;
  }

  handleBackClick(e, redirected) {
    if (redirected) {
      window.location.href = routes.PLANNING2;
    } else {
      e.preventDefault();
      window.history.replaceState({}, document.title, routes.PROJECT)
      this.props.onChangeMode(Mode.Uninit, redirected);
    }
  }

  fileDowloadLink = linkId => (e) => {
    e.preventDefault();
    const link = document.getElementById(linkId);
    link.click();
  }

  handleChange = (prop, isSelect) => evt => {
    if (isSelect) {
      this.setState(state => {
        return { ...state, [prop]: evt ? evt.value : null };
      });
    } else {
      const value = evt.target.value === '' ? null : evt.target.value;
      this.setState(state => {
        return { ...state, [prop]: prop === "stateCode" ? Number(value) : value };
      });
    }
  }

  handleChangeNumericInput = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value === null || value === undefined || value === "" ? null : Number(value)
    });
  }

  handleChangeNumericInputWithValidate = validate => event => {
    const { name, value } = event.target;
    var newValue = value;
    if (value !== "") {
      newValue = validate(value);
      event.target.value = newValue;
    }
    this.setState({
      [name]: newValue
    });
  }

  validateMinMax = (min, max) => value => {
    var res = parseFloat(value)
    return res < min ? min : (res > max ? max : res);
  }

  handleChangeWithAdjust = (props, adjust) => value => {
    const newValue = adjust(value);
    this.setState({
      [props]: newValue
    });
  }

  handleCheckBoxChange = prop => evt => {
    const value = evt.target.checked;
    this.setState(state => {
      return { ...state, [prop]: value };
    }
    );
  }

  handleMixingPlantChange = mixingPlant => {
    this.setState(state => {
      return { ...state, mixingPlant: mixingPlant ? mixingPlant.value : null, mixtureOrder: null };
    });
  }

  fillLookup(entries, lookup) {
    if (entries.length > 0) {
      entries.forEach(entry => {
        lookup[entry.ID] = entry;
      })
    }
  }

  selectLocale(language) {

    let languages = new Map();
    languages.set("de-DE", de);
    languages.set("en-GB", enGB);
    languages.set("en-US", enUS);

    let res = languages.get(language);
    return res != null ? res : languages["de-DE"];
  }

  dateRemoveTimePart(date) {
    return moment(date).startOf('day');
  }

  onDocumentChangeHandler = isOfferDocument => async (event) => {
    var files = event.target.files
    //if(this.maxSelectFile(event) && this.checkMimeType(event) &&  this.checkFileSize(event)) { 
    // if return true allow to setState
    if (files !== undefined && files.length > 0) {
      if (isOfferDocument)
        this.setState({
          offerDocumentFileName: files[0].name,
          selectedFile: files,
          loaded: 0
        })
      else
        this.setState({
          tenderDocumentFileName: files[0].name,
          selectedFile: files,
          loaded: 0
        })
    }
    //}
  }

  onFileUploadClickHandler = isOfferDocument => async (e) => {

    const { importOfferDocumentHandler, importTenderDocumentHandler } = this.props;
    var importDocumentHandler = isOfferDocument ? importOfferDocumentHandler : importTenderDocumentHandler;

    const { dataRow, selectedFile } = this.state;
    const errorOrResult = await importDocumentHandler(dataRow, selectedFile[0])
      .then(res => {
        return res;
      }, res => {
        return res;
      }
      );

    if (!(errorOrResult instanceof Error)) {
      if (isOfferDocument)
        this.setState({ offerDocument: errorOrResult, offerDocumentFileName: errorOrResult.FileName });
      else
        this.setState({ tenderDocument: errorOrResult, tenderDocumentFileName: errorOrResult.FileName });
    }
  }

  onFileDownloadClickHandler = isOfferDocument => async e => {

    const { exportOfferDocumentHandler, exportTenderDocumentHandler } = this.props;
    const { offerDocument, tenderDocument } = this.state;

    var doc = isOfferDocument ? offerDocument : tenderDocument;
    var exportDocumentHandler = isOfferDocument ? exportOfferDocumentHandler : exportTenderDocumentHandler;

    const errorOrResult = await exportDocumentHandler(doc)
      .then(res => {
        return res;
      }, res => {
        return res;
      }
      );

    if (!(errorOrResult instanceof Error)) {
      var fileName = doc.FileName;
      var tempEl = document.createElement("a");
      document.body.appendChild(tempEl);
      tempEl.style = "display: none";
      var url = window.URL.createObjectURL(errorOrResult);
      tempEl.href = url;
      tempEl.download = fileName;
      tempEl.click();
      window.URL.revokeObjectURL(url);
    }
  }

  onDeleteClickHandler = (isDocument, isTenderOrderDocument, entity) => async e => {
    this.setState({ openDeleteEntry: !this.state.openDeleteEntry, deleteEntryObj: entity, isDocument, isTenderOrderDocument });
  }

  handleAddClick = (isDocument) => (e) => {
    e.preventDefault();

    if (this.state.dataRow == null)
      return;

    const input = isDocument ? document.getElementById("inputDocuments") : document.getElementById("inputImages");
    input.click();
  }

  onDocumentAddHandler = isDocument => async (event) => {
    var files = event.target.files
    //if(this.maxSelectFile(event) && this.checkMimeType(event) &&  this.checkFileSize(event)) { 
    // if return true allow to setState
    if (files !== undefined && files.length > 0) {

      const { importDocumentHandler, importImageHandler } = this.props;
      var importHandler = isDocument ? importDocumentHandler : importImageHandler;

      const { dataRow } = this.state;
      const errorOrResult = await importHandler(dataRow, Array.from(files))
        .then(res => {
          return res;
        }, res => {
          return res;
        }
        );

      if (!(errorOrResult instanceof Error)) {

      }
    }
    //}
  }

  isValid = () => {

    const { organisation, supervisor, customer, name, costCenter, orderTotalAmount, workExecutionEstimatePercentage, stateCode } = this.state;

    return !((name === null || name === undefined || name === '') ||
      (organisation === null || organisation === undefined || organisation === '') ||
      (supervisor === null || supervisor === undefined || supervisor === '') ||
      (customer === null || customer === undefined || customer === '') ||
      (orderTotalAmount === null || orderTotalAmount === undefined || orderTotalAmount === '') ||
      (costCenter === null || costCenter === undefined || costCenter === '') ||
      (workExecutionEstimatePercentage === null || workExecutionEstimatePercentage === undefined || workExecutionEstimatePercentage === '') ||
      (stateCode === null || stateCode === undefined || stateCode === ''));

  }

  closeDeleteEntryPopup = () => {
    this.setState({ openDeleteEntry: false, deleteEntryObj: null })
  }

  handleDeleteEntry = async () => {

    const { dataRow, deleteEntryObj, isDocument, isTenderOrderDocument } = this.state;
    const { deleteDocumentHandler, deleteImageHandler, deleteTenderDocumentHandler, deleteOfferDocumentHandler } = this.props;
    var deleteHandler = isTenderOrderDocument ?
      (isDocument/*isOfferDocument*/ ? deleteOfferDocumentHandler : deleteTenderDocumentHandler) :
      (isDocument ? deleteDocumentHandler : deleteImageHandler);

    if (deleteEntryObj !== null) {
      const errorOrResult = await deleteHandler(dataRow, deleteEntryObj)
        .then(res => {
          return res;
        }, res => {
          return res;
        }
        );

      if (isTenderOrderDocument && !(errorOrResult instanceof Error)) {
        if (isDocument)
          this.setState({ offerDocument: null, offerDocumentFileName: null });
        else
          this.setState({ tenderDocument: null, tenderDocumentFileName: null });
      }
    }

    this.setState({ openDeleteEntry: !this.state.openDeleteEntry, deleteEntryObj: null });
  }

  handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    this.setState({ setSnackbarOpen: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ setSnackbarOpen: false });
  };

  render() {

    const {
      organisations, supervisors, customers, mixingPlants,
      organisation, supervisor, customer, mixingPlant, mixtureOrder,
      offerDocument, tenderDocument, offerDocumentFileName, tenderDocumentFileName,
      name,
      costCenter,
      externalReferenceNumber,
      workExecutionEstimatePercentage,
      orderTotalAmount,
      actualInvoicedAmount,
      selectedFilters,
      mode,
      openDeleteEntry,
      orderTotalAmountCurrentYear,
      actualInvoicedAmountCurrentYear,
      remainingAmountToInvoiceCurrentYear,
      stageOfCompletion,
      engineeringOffice,
      company,
      termsOfPayment,
      notesLinks,
    } = this.state;
    const { t, lng } = this.props;
    var locale = this.selectLocale(lng);

    const lookupOrgs = {};
    const lookupMixingPlants = {};
    const lookupMixtureOrders = {};
    const filteredLookupOrgs = {};

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org;
      })
      var _org = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
      var filteredOrgs = filterOrganisations(organisations, _org, this.props.user.IsParentLogin);
      filteredOrgs.forEach(org => {
        filteredLookupOrgs[org.Name] = org.Name;
      })
    }

    this.fillLookup(mixingPlants, lookupMixingPlants);

    if (mixingPlant !== null && mixingPlant !== undefined && mixingPlant.Orders !== undefined && mixingPlant.Orders !== null)
      this.fillLookup(mixingPlant.Orders, lookupMixtureOrders);

    const { classes, ...rest } = this.props;

    return (
      <div>
        <div className="rowC">
          <Button onClick={this.handleSaveClick}
            style={{ padding: "5px", width: "120px", margin: "5px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submitButton"
            disabled={!this.isValid()}
          >
            {t('save')}
          </Button>
          <Button onClick={this.handleBackClick}
            style={{ padding: "5px", width: "120px", margin: "5px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submitButton"
          >
            {this.props.redirected ? t('back-to-projects') : t('back')}
          </Button>
          {this.props.redirected &&
            <Button onClick={() => this.handleBackClick(null, true)}
              style={{ padding: "5px", width: "120px", margin: "5px" }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submitButton"
            >
              {t('back-to-planning')}
            </Button>
          }
        </div>

        <div className="rowC translated-input" style={{ width: "100%" }}>

          <FormControl style={{ padding: "5px", width: "50%", minWidth: "500px", margin: "5px" }}>

            <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
              <TextField
                required
                id="name-input"
                value={name}
                type="text"
                inputProps={{ maxLength: 255 }}
                label={t('name')}
                error={name === null || name === undefined || name === ''}
                onChange={this.handleChange('name')}
              />
            </FormControl>

            <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
              <InputLabel className="org-label" id="org-label">{t('organisation')}*</InputLabel>
              <Select
                labelId="org-label"
                id="organisation"
                value={organisation}
                onChange={this.handleChange('organisation')}
                error={organisation === null || organisation === undefined || organisation === ''}
                renderValue={selected => selected.ShortName ? selected.ShortName : selected.Name}
                onClick={async () => {
                  await new Promise(resolve => setTimeout(() => resolve(), 100));
                  const { organisations } = this.state;
                  var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
                  var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
                  expandPopupOrganisation(filteredOrgs, "MuiListItem-button", (item, orgElement) => item.ID === JSON.parse(orgElement.getAttribute("data")).ID);
                }}
              >
                {Object.keys(lookupOrgs).map(key => (
                  (selectedFilters.length === 0 || selectedFilters.includes(key)) && filteredLookupOrgs[key] !== undefined ?
                    <MenuItem
                      value={lookupOrgs[key]}
                      data={JSON.stringify(lookupOrgs[key])}
                    >
                      <em>{lookupOrgs[key].ShortName ? lookupOrgs[key].ShortName : lookupOrgs[key].Name}</em>
                    </MenuItem>
                    : null
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
              <TextField
                required
                id="costCenter-input"
                value={costCenter}
                type="text"
                inputProps={{ maxLength: 255 }}
                label={t('cost-center')}
                error={costCenter === null || costCenter === undefined || costCenter === ''}
                onChange={this.handleChange('costCenter')}
              />
            </FormControl>

            <FormControl className={classes.formControl} style={{ paddingTop: 4, paddingLeft: 3, width: "47%", margin: "5px" }}>
              <div className="filter-label"
                style={{
                  color: (supervisor === '' || supervisor === null || supervisor === undefined) ? '#f44336' : 'rgba(0, 0, 0, 0.54)',
                  fontSize: 13,
                  fontWeight: 400,
                  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
                }}
              >
                {t('construction-supervisor')}
              </div>
              <ReactSelect
                value={
                  supervisor
                    ? {
                      value: supervisor,
                      label: `${supervisor.LastName} ${supervisor.FirstNames || ''}`.trim()
                    } : null
                }
                defaultValue={
                  supervisor
                    ? {
                      value: supervisor,
                      label: `${supervisor.LastName} ${supervisor.FirstNames || ''}`.trim()
                    } : null
                }
                className="basic-multi-select"
                onChange={this.handleChange('supervisor', true)}
                placeholder={t('construction-supervisor')}
                isClearable
                styles={{
                  control: (styles) => ({
                    ...styles,
                    background: 'none',
                    border: (supervisor === '' || supervisor === null || supervisor === undefined) && 'thin solid #f44336'
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    color: (supervisor === '' || supervisor === null || supervisor === undefined) && '#f44336'
                  }),
                  singleValue: (styles) => {
                    return {
                      ...styles,
                      fontWeight: 400,
                      fontSize: 16,
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif"
                    }
                  },
                }}
                options={
                  supervisors.map(supervisor => {
                    return { value: supervisor, label: `${supervisor.LastName} ${supervisor.FirstNames || ''}`.trim() }
                  })
                }
              />
            </FormControl>

            <div className="rowC" style={{ width: "100%" }}>
              <FormControl className={classes.formControl} style={{ paddingLeft: 3, width: "47%", margin: "5px" }}>
                <div className="filter-label"
                  style={{
                    color: (customer === '' || customer === null || customer === undefined) ? '#f44336' : 'rgba(0, 0, 0, 0.54)',
                    fontSize: 13,
                    fontWeight: 400,
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
                  }}
                >
                  {t('customer')}
                </div>
                <ReactSelect
                  value={
                    customer
                      ? {
                        value: customer,
                        label: `${customer.Name}`
                      } : null
                  }
                  defaultValue={
                    customer
                      ? {
                        value: customer,
                        label: customer.Name
                      } : null
                  }
                  className="basic-multi-select"
                  onChange={this.handleChange('customer', true)}
                  placeholder={t('customer')}
                  isClearable
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      background: 'none',
                      border: (customer === '' || customer === null || customer === undefined) && 'thin solid #f44336'
                    }),
                    placeholder: (styles) => ({
                      ...styles,
                      color: (customer === '' || customer === null || customer === undefined) && '#f44336'
                    }),
                    singleValue: (styles) => {
                      return {
                        ...styles,
                        fontWeight: 400,
                        fontSize: 16,
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif"
                      }
                    },
                  }}
                  options={
                    customers.map(customer => {
                      return { value: customer, label: customer.Name }
                    })
                  }
                />
              </FormControl>

              <FormControl className={classes.formControl} style={{ paddingLeft: 3, width: "50%", margin: "5px" }}>
                <div className="filter-label"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: 13,
                    fontWeight: 400,
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
                  }}
                >
                  {t('companyTypes.EngineeringOffice')}
                </div>
                <ReactSelect
                  value={
                    engineeringOffice
                      ? {
                        value: engineeringOffice,
                        label: engineeringOffice.Name
                      } : null
                  }
                  defaultValue={
                    engineeringOffice
                      ? {
                        value: engineeringOffice,
                        label: engineeringOffice.Name
                      } : null
                  }
                  className="basic-multi-select"
                  onChange={this.handleChange('engineeringOffice', true)}
                  placeholder={t('companyTypes.EngineeringOffice')}
                  isClearable
                  styles={selectStyles}
                  options={company ?
                    company.map(company => {
                      return { value: company, label: company.Name }
                    }) : null
                  }
                />
              </FormControl>
            </div>

            <div className="rowC" style={{ width: "100%" }}>

              <FormControl className={classes.formControl} style={{ paddingLeft: 3, width: "47%", margin: "5px" }}>
                <div className="filter-label"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: 13,
                    fontWeight: 400,
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
                  }}
                >
                  {t('asphalt-mixing-plant')}
                </div>
                <ReactSelect
                  value={
                    mixingPlant
                      ? {
                        value: mixingPlant.ID,
                        label: mixingPlant.Name
                      } : null
                  }
                  defaultValue={
                    mixingPlant
                      ? {
                        value: mixingPlant.ID,
                        label: mixingPlant.Name
                      } : null
                  }
                  className="basic-multi-select"
                  onChange={this.handleMixingPlantChange}
                  placeholder={t('asphalt-mixing-plant')}
                  isClearable
                  styles={selectStyles}
                  options={
                    Object.keys(lookupMixingPlants).map(key => {
                      return { value: lookupMixingPlants[key], label: lookupMixingPlants[key].Name }
                    })
                  }
                />
              </FormControl>

              <FormControl className={classes.formControl} style={{ paddingLeft: 3, width: "50%", margin: "5px" }}>
                <div className="filter-label"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: 13,
                    fontWeight: 400,
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
                  }}
                >
                  {t('asphalt-mixture-order')}
                </div>
                <ReactSelect
                  value={
                    mixtureOrder
                      ? {
                        value: mixtureOrder.ID,
                        label: `${mixtureOrder.OrderNumber} ${mixtureOrder.Description}`
                      } : null
                  }
                  defaultValue={
                    mixtureOrder
                      ? {
                        value: mixtureOrder.ID,
                        label: `${mixtureOrder.OrderNumber} ${mixtureOrder.Description}`
                      } : null
                  }
                  className="basic-multi-select"
                  onChange={this.handleChange('mixtureOrder', true)}
                  placeholder={t('asphalt-mixture-order')}
                  isClearable
                  styles={selectStyles}
                  isDisabled={_.isEmpty(lookupMixtureOrders)}
                  options={
                    Object.keys(lookupMixtureOrders).map(key => {
                      return { value: lookupMixtureOrders[key], label: `${lookupMixtureOrders[key].OrderNumber} ${lookupMixtureOrders[key].Description}` }
                    })
                  }
                />
              </FormControl>

            </div>

            <div className="rowC" style={{ width: "100%" }}>
              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  required
                  id="orderTotalAmountCurrentYear"
                  value={orderTotalAmountCurrentYear}
                  type="number"
                  label={t('order-total-amount-current-year')}
                  handleChange={() => { }}
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, readOnly: true, useSeparators: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput style={{ width: "100%" }}
                  required
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
                  id="workExecutionEstimatePercentage"
                  value={workExecutionEstimatePercentage}
                  label={t('work-execution-estimate-percentage')}
                  error={workExecutionEstimatePercentage === null || workExecutionEstimatePercentage === undefined || workExecutionEstimatePercentage === ''}
                  handleChange={this.handleChangeNumericInputWithValidate(value => this.validateMinMax(-100, 100)(value))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </FormControl>

              {/* <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  id="actualInvoicedAmount"
                  value={actualInvoicedAmount}
                  type="number"
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, readOnly: true, useSeparators: true }}
                  label={t('actual-invoiced-amount')}
                  handleChange={() => {}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl> */}
            </div>

            <div className="rowC" style={{ width: "100%" }}>
              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  id="actualInvoicedAmountCurrentYear"
                  value={actualInvoicedAmountCurrentYear}
                  type="number"
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, readOnly: true, useSeparators: true }}
                  handleChange={() => { }}
                  label={t('actual-invoiced-amount-current-year')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl>


              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  id="WorkExecutionEstimateOrderTotalAmount"
                  name="WorkExecutionEstimateOrderTotalAmount"
                  value={this.state.WorkExecutionEstimateOrderTotalAmount}
                  type="number"
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, readOnly: true, useSeparators: true }}
                  handleChange={() => { }}
                  label={t('work-execution-estimate-order-total-amount')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl>

            </div>

            <div className="rowC" style={{ width: "100%" }}>
              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  id="remainingAmountToInvoiceCurrentYear"
                  name="remainingAmountToInvoiceCurrentYear"
                  value={remainingAmountToInvoiceCurrentYear}
                  type="number"
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, readOnly: true, useSeparators: true }}
                  handleChange={() => { }}
                  label={t('remaining-amount-to-invoice-current-year')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  id="WorkExecutionEstimateActualInvoicedAmount"
                  name="WorkExecutionEstimateActualInvoicedAmount"
                  value={this.state.WorkExecutionEstimateActualInvoicedAmount}
                  type="number"
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, readOnly: true, useSeparators: true }}
                  handleChange={() => { }}
                  label={t('work-execution-estimate-actual-invoice-amount')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl>
              {/* <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  id="RemainingAmountToInvoice"
                  name="RemainingAmountToInvoice"
                  value={this.state.RemainingAmountToInvoice}
                  type="number"
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, readOnly: true, useSeparators: true }}
                  label={t('remaining-amount-to-invoice')}
                  handleChange={() => {}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl> */}
            </div>
            <div className="rowC" style={{ width: "100%" }}>
              <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  id="OrderTotalAmountPreviousYear"
                  name="OrderTotalAmountPreviousYear"
                  value={this.state.OrderTotalAmountPreviousYear}
                  type="number"
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
                  label={t('order-total-amount-previous-year')}
                  handleChange={this.handleChangeNumericInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  id="OrderTotalAmountNextYear"
                  name="OrderTotalAmountNextYear"
                  value={this.state.OrderTotalAmountNextYear}
                  type="number"
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
                  label={t('order-total-amount-next-year')}
                  handleChange={this.handleChangeNumericInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </div>

            <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
              <NumberInput
                style={{ width: "100%" }}
                required
                id="orderTotalAmount"
                value={orderTotalAmount}
                type="number"
                label={t('order-total-amount')}
                inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
                error={orderTotalAmount === null || orderTotalAmount === undefined || orderTotalAmount === ''}
                handleChange={this.handleChangeNumericInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  )
                }}
              />
            </FormControl>

            <FormControl
              className={classes.formControl}
              style={{ padding: "5px", width: "100%", margin: "5px" }}
            >
              <FormLabel component="legend">{t('state')} *</FormLabel>
              <RadioGroup
                aria-label="StateCode"
                name="StateCode"
                style={{ flexDirection: 'row' }}
                value={this.state.stateCode}
                onChange={this.handleChange('stateCode')}
              >
                {Object.entries(PROJECT_STATE_CODES).map((state, i) => {
                  const name = t(`projectStates.${state[0]}`)
                  const value = Number(state[1])

                  return <FormControlLabel value={value} control={<Radio />} label={`${name}`} />
                })}
              </RadioGroup>
            </FormControl>
            <div className="rowC" style={{ width: "100%" }}>
              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <TextField
                  style={{ width: '95%' }}
                  id="externalReferenceNumber-input"
                  value={externalReferenceNumber}
                  type="text"
                  inputProps={{ maxLength: 255 }}
                  label={t('external-reference-number')}
                  onChange={this.handleChange('externalReferenceNumber')}
                />
              </FormControl>

            </div>

            <div className="date-row" style={{ width: "100%", display: "flex", paddingLeft: 10 }}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}>
                <KeyboardDatePicker
                  label={t('construction-begin')}
                  style={{ width: '100%' }}
                  margin="normal"
                  format="dd.MM.yyyy"
                  value={this.state.constructionBeginTimeUtc || null}
                  onChange={date => this.setState({ constructionBeginTimeUtc: this.dateRemoveTimePart(date) })}
                  locale={locale}
                  okLabel={t('date-picker-label-ok')}
                  cancelLabel={t('date-picker-label-cancel')}
                  invalidDateMessage={t('date-picker-label-invalid-date-format')}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}>
                <KeyboardDatePicker
                  label={t('construction-end')}
                  style={{ width: '100%' }}
                  margin="normal"
                  format="dd.MM.yyyy"
                  value={this.state.constructionEndTimeUtc || null}
                  onChange={date => this.setState({ constructionEndTimeUtc: this.dateRemoveTimePart(date) })}
                  locale={locale}
                  okLabel={t('date-picker-label-ok')}
                  cancelLabel={t('date-picker-label-cancel')}
                  invalidDateMessage={t('date-picker-label-invalid-date-format')}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="date-row" style={{ width: "100%", display: "flex", paddingLeft: 10 }}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}>
                <KeyboardDatePicker
                  label={t('acceptance')}
                  style={{ width: '100%' }}
                  margin="normal"
                  format="dd.MM.yyyy"
                  value={this.state.acceptanceTimeUtc || null}
                  onChange={date => this.setState({ acceptanceTimeUtc: this.dateRemoveTimePart(date) })}
                  locale={locale}
                  okLabel={t('date-picker-label-ok')}
                  cancelLabel={t('date-picker-label-cancel')}
                  invalidDateMessage={t('date-picker-label-invalid-date-format')}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}>
                <KeyboardDatePicker
                  views={["year", "month", "date"]}
                  label={t('warranty-end')}
                  style={{ width: '100%' }}
                  margin="normal"
                  format="dd.MM.yyyy"
                  value={this.state.warrantyEndTimeUtc || null}
                  onChange={date => this.setState({ warrantyEndTimeUtc: this.dateRemoveTimePart(date) })}
                  locale={locale}
                  okLabel={t('date-picker-label-ok')}
                  cancelLabel={t('date-picker-label-cancel')}
                  invalidDateMessage={t('date-picker-label-invalid-date-format')}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="date-row" style={{ width: "100%", display: "flex", paddingLeft: 10, marginBottom: 40 }}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}>
                <KeyboardDatePicker
                  label={t('final-invoice-time')}
                  style={{ width: '52%' }}
                  margin="normal"
                  format="dd.MM.yyyy"
                  value={this.state.finalInvoiceTimeUtc || null}
                  onChange={date => this.setState({ finalInvoiceTimeUtc: this.dateRemoveTimePart(date) })}
                  locale={locale}
                  okLabel={t('date-picker-label-ok')}
                  cancelLabel={t('date-picker-label-cancel')}
                  invalidDateMessage={t('date-picker-label-invalid-date-format')}
                />
              </MuiPickersUtilsProvider>
              <FormControl className={classes.formControl} style={{ paddingTop: "10px", width: "50%", margin: "5px" }}>
                <TextField
                  style={{ width: '100%' }}
                  id="termsOfPayment"
                  value={termsOfPayment}
                  type="text"
                  inputProps={{ maxLength: 1000 }}
                  label={t('terms-of-payment')}
                  onChange={this.handleChange('termsOfPayment')}
                />
              </FormControl>
            </div>
          </FormControl>

          <div>

            <div className="rowC" style={{ width: "100%", pointerEvents: mode === Mode.Add ? "none" : "inherit", opacity: mode === Mode.Add ? "0.4" : "inherit" }} >

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "100%", margin: "5px" }}>
                <div style={{ marginBottom: "5px" }} >
                  <label className={classes.label} id="labelOfferDocument">{`${t('offer-document')}:`}</label>
                </div>

                <div className="rowC">
                  <input accept=".pdf" className={classes.input} id="offerDocument" type="file" onChange={this.onDocumentChangeHandler(true)} />
                  <label className="upload-label" style={{ fontSize: "0.8em" }} for="offerDocument">
                    {(offerDocument == null && offerDocumentFileName !== null) || (offerDocument != null && offerDocumentFileName !== offerDocument.FileName) ? offerDocumentFileName : t('select-file-to-upload')}
                  </label>
                  <div style={{ margin: "2px", marginLeft: "10px" }}>
                    <Button style={{ textTransform: "none", width: "125px" }} disabled={!(offerDocumentFileName !== null && (offerDocument === null || offerDocumentFileName !== offerDocument.FileName))} variant="contained" color="primary"
                      onClick={this.onFileUploadClickHandler(true)}>{t('upload')}</Button>
                  </div>
                </div>

                <div className="rowC">
                  {
                    offerDocument !== null ?
                      <div className={classes.thumbnail} style={{ width: "auto" }}>
                        <div>
                          <img className={classes.thumbnailImage}
                            onClick={this.fileDowloadLink("offerDocumentDownloadLink" + offerDocument.ID)}
                            src={`${getServiceUrl() + RESOURCES.Attachments + "(" + offerDocument.ID + ")/ShowThumbnail?allowFallback=true&token=" + this.props.user.token}`}
                            title={`${offerDocument.FileName}`}
                            alt={`${offerDocument.FileName}`}
                          />
                          <a hidden id={`${"offerDocumentDownloadLink" + offerDocument.ID}`} href={`${getServiceUrl() + RESOURCES.Attachments + "(" + offerDocument.ID + ")/DownloadFile?token=" + this.props.user.token}`} />
                          <DeleteIcon
                            className="entry-button {classes.attachmentDeleteButton}"
                            onClick={this.onDeleteClickHandler(true, true, offerDocument)}
                          />
                        </div>
                        <p className={classes.thumbnailLabel} style={{ width: "auto" }} title={`${offerDocument.FileName}`}>{offerDocument.FileName}</p>
                      </div> : null
                  }
                </div>

              </FormControl>

            </div>

            <div className="rowC" style={{ width: "100%", pointerEvents: mode === Mode.Add ? "none" : "inherit", opacity: mode === Mode.Add ? "0.4" : "inherit" }} >

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "100%", margin: "5px" }}>
                <div style={{ marginBottom: "5px" }} >
                  <label className={classes.label} id="labelTenderDocument">{`${t('tender-document')}:`}</label>
                </div>

                <div className="rowC" style={{}}>
                  <input accept=".D83" className={classes.input} id="tenderDocument" type="file" onChange={this.onDocumentChangeHandler(false)} />
                  <label className="upload-label" style={{ fontSize: "0.8em" }} for="tenderDocument">
                    {(tenderDocument == null && tenderDocumentFileName !== null) || (tenderDocument != null && tenderDocumentFileName !== tenderDocument.FileName) ? tenderDocumentFileName : t('select-file-to-upload')}
                  </label>
                  <div style={{ margin: "2px", marginLeft: "10px" }}>
                    <Button style={{ textTransform: "none", width: "125px" }} disabled={!(tenderDocumentFileName !== null && (tenderDocument === null || tenderDocumentFileName !== tenderDocument.FileName))} variant="contained" color="primary"
                      onClick={this.onFileUploadClickHandler(false)}>{t('upload')}</Button>
                  </div>
                </div>

                <div className="rowC">
                  {
                    tenderDocument !== null ?
                      <div className={classes.thumbnail} style={{ width: "auto" }}>
                        <div>
                          <img className={classes.thumbnailImage}
                            onClick={this.fileDowloadLink("tenderDocumentDownloadLink" + tenderDocument.ID)}
                            src={`${getServiceUrl() + RESOURCES.Attachments + "(" + tenderDocument.ID + ")/ShowThumbnail?allowFallback=true&token=" + this.props.user.token}`}
                            title={`${tenderDocument.FileName}`}
                            alt={`${tenderDocument.FileName}`}
                          />
                          <a hidden id={`${"tenderDocumentDownloadLink" + tenderDocument.ID}`} href={`${getServiceUrl() + RESOURCES.Attachments + "(" + tenderDocument.ID + ")/DownloadFile?token=" + this.props.user.token}`} />
                          <DeleteIcon
                            className="entry-button {classes.attachmentDeleteButton}"
                            onClick={this.onDeleteClickHandler(false, true, tenderDocument)}
                          />
                        </div>
                        <p className={classes.thumbnailLabel} style={{ width: "auto" }} title={`${tenderDocument.FileName}`}>{tenderDocument.FileName}</p>
                      </div> : null
                  }
                </div>

              </FormControl>

            </div>

            <div className="rowC notes-container" style={{ width: "100%", marginTop: 123, paddingLeft: 10 }} >

              <TextField
                onChange={this.handleChange('Notes')}
                value={this.state.Notes}
                id="Notes"
                name="Notes"
                label={t('notes')}
                InputLabelProps={{
                  style: { zIndex: 99, marginLeft: 5, marginTop: -5 }
                }}
                style={{ width: 437 }}
                InputProps={{
                  style: { width: '200%', border: 'thin solid rgba(0, 0, 0, 0.42)', borderRadius: 3, padding: 8 }
                }}
                rows={7}
                multiline
              />

            </div>
            <Snackbar open={this.state.setSnackbarOpen} onClose={this.handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} message={t("copied-toclipboard")} autoHideDuration={5000}>
              <SnackbarContent style={{ backgroundColor: 'green', }} message="Copied to clipboard" action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>,]}>
              </SnackbarContent>
            </Snackbar>
            <div style={{ marginTop: 50, paddingLeft: 10 }}>
              <div style={{ marginBottom: 20 }}>
                <label className={classes.label2} id="notesLinks">Links:</label>
              </div>
              {notesLinks && notesLinks.map(link => {
                let displayText = link.DisplayText ? link.DisplayText : (link.CopyText ? link.CopyText : (link.Url ? link.Url : null))
                return (
                  <div className="rowC">
                    {link.Url ? (
                      <a
                        href={link.Url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <label>{displayText}</label>
                      </a>
                    ) :
                      <label>{displayText}</label>
                    }
                    {link.CopyText && (
                      <Tooltip title={t('copy-to-clipboard')}>
                        <FileCopyIcon className="add-button" color="action" aria-label="copy" style={{ height: 20, marginLeft: 10 }} onClick={e => this.handleCopyToClipboard(link.CopyText)}></FileCopyIcon>
                      </Tooltip>
                    )}
                  </div>)
              }
              )}




            </div>
          </div>

        </div>

        <div>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            < div >
              <ProjectCorrespondenceTable
                {...rest}
                editable={mode !== Mode.Add}
                title={t('project-correspondencies')}
                correspondencies={this.state.correspondencies || []}
                t={t}
                projectRow={this.state.dataRow}
              />
            </div>
          </FormControl>
        </div>

        <div>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            < div >
              <ProjectActivityTable
                {...rest}
                editable={mode !== Mode.Add}
                title={t('project-activities')}
                data={this.props.projectActivities}
                t={t}
                projectRow={this.state.dataRow}
              />
            </div>
          </FormControl>
        </div>

        <div>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            <div>
              <ProjectInvoiceTable
                {...rest}
                editable={mode !== Mode.Add}
                title={t('project-invoices')}
                invoices={this.state.invoices || []}
                t={t}
                projectRow={this.state.dataRow}
              />
            </div>
          </FormControl>
        </div>

        <div>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            <div>
              <div style={{ margin: "5px", display: "flex" }} >
                <div>
                  <label className={classes.label2} id="labelImages">{`${t('images')}:`}</label>
                </div>
                <div>
                  <AddIcon id="addIcon" className="add-button" color="action" aria-label="add" onClick={this.handleAddClick(false)} />
                  <input accept=".jpg,.jpeg,.jpe,*.jfif,.gif,.tif,.png" className={classes.input} id="inputImages" type="file" multiple onChange={this.onDocumentAddHandler(false)} />
                </div>
              </div>
              <div>
                {
                  this.state.dataRow !== null && this.state.images !== null ?
                    (
                      this.state.images.map((file) => {
                        return (
                          <div className={classes.thumbnail}>
                            <div >
                              <img className={classes.thumbnailImage}
                                onClick={this.fileDowloadLink("imageDownloadLink" + file.ID)}
                                src={`${getServiceUrl() + RESOURCES.Attachments + "(" + file.ID + ")/ShowThumbnail?allowFallback=true&token=" + this.props.user.token}`}
                                title={`${file.FileName}`}
                                alt={`${file.FileName}`}
                              />
                              <a hidden id={`${"imageDownloadLink" + file.ID}`} href={`${getServiceUrl() + RESOURCES.Attachments + "(" + file.ID + ")/DownloadFile?token=" + this.props.user.token}`} />
                              <DeleteIcon
                                className="entry-button {classes.attachmentDeleteButton}"
                                onClick={this.onDeleteClickHandler(false, false, file)}
                              />
                            </div>
                            <p className={classes.thumbnailLabel} title={`${file.FileName}`}>{file.FileName}</p>
                          </div>)
                      })
                    )
                    : null
                }
              </div>
            </div>
          </FormControl>
        </div>

        <div>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            <div>
              <div style={{ margin: "5px", display: "flex" }} >
                <div style={{}}>
                  <label className={classes.label2} id="labelDocuments">{`${t('documents')}:`}</label>
                </div>
                <div style={{}}>
                  <AddIcon id="addIcon" className="add-button" color="action" aria-label="add" onClick={this.handleAddClick(true)} />
                  <input accept=".pdf,.xls,.xlsx,.doc,.docx,.txt" className={classes.input} id="inputDocuments" type="file" multiple onChange={this.onDocumentAddHandler(true)} />
                </div>
              </div>
              <div>
                {
                  this.state.dataRow !== null && this.state.documents !== null ?
                    (
                      this.state.documents.map((file) => {
                        return (
                          <div className={classes.thumbnail}>
                            <div >
                              <img className={classes.thumbnailImage}
                                onClick={this.fileDowloadLink("documentDownloadLink" + file.ID)}
                                src={`${getServiceUrl() + RESOURCES.Attachments + "(" + file.ID + ")/ShowThumbnail?allowFallback=true&token=" + this.props.user.token}`}
                                title={`${file.FileName}`}
                                alt={`${file.FileName}`}
                              />
                              <a hidden id={`${"documentDownloadLink" + file.ID}`} href={`${getServiceUrl() + RESOURCES.Attachments + "(" + file.ID + ")/DownloadFile?token=" + this.props.user.token}`} />
                              <DeleteIcon
                                className="entry-button {classes.attachmentDeleteButton}"
                                onClick={this.onDeleteClickHandler(true, false, file)}
                              />
                            </div>
                            <p className={classes.thumbnailLabel} title={`${file.FileName}`}>{file.FileName}</p>
                          </div>)
                      })
                    )
                    : null
                }
              </div>
            </div>
          </FormControl>
        </div>

        <Modal open={openDeleteEntry} onClose={this.closeDeleteEntryPopup}>
          <div className="delete-popup">
            <div style={{ width: "100%", textAlign: "center", color: "#242424" }}>
              <h2>{t('deleteText')}</h2>
            </div>
            <div className="buttonContainer">
              <Button
                onClick={this.closeDeleteEntryPopup}
                style={{ marginRight: 7 }}
                variant="contained"
                color="primary"
                size="small"
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={this.handleDeleteEntry}
                variant="contained"
                color="secondary"
                size="small"
              >
                {t('delete')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ProjectEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectEditForm);