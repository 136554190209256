import odata from 'odata-client';
import {
    UPDATE_PENSUM_REQUEST,
    UPDATE_PENSUM_ERROR,
    UPDATE_PENSUM_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';
/*import getToken from '../user/getToken';*/

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const updatePensumsRequest = () => ({
    type: UPDATE_PENSUM_REQUEST,
});

export const updatePensumsError = errorOrResults => ({
    type: UPDATE_PENSUM_ERROR,
    error: errorOrResults.message,
});

export const updatePensumsSuccess = payload => ({
    type: UPDATE_PENSUM_SUCCESS,
    payload,
});

export default (queryData) => async (dispatch, getState) => {
    dispatch(updatePensumsRequest());
    const ID = Object.keys(queryData)[0]

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.Pensums, parseInt(ID));
    const patchObj = queryData[ID]
    delete patchObj.FlagsCode
    delete patchObj.HasInvoice
    delete patchObj.IsStateChangePending
    delete patchObj.StateCode

    const errorOrResult = await query.patch(queryData[ID], {
        headers: {
          Authorization: `Bearer ${getState().user.token}`,
          "X-IAS-SessionID": `${getState().user.changeMessageToken}`,
          ...getDefaultHeader()
        }
    })
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const body = JSON.parse(res.body);
                const { Error: { Code, Message } } = body
                let errorKey = "GENERAL_SERVER_ERROR"
                if (Code === "MessageCorrelationFailedTryAgain" || Code === "MessageCorrelationFailed") {
                errorKey = Message
                }
                return new Error(errorKey);
            } else if (!ok) {
                console.log('update pensum api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            }

            return true
        })
        .catch(() => {
            return new Error()
        })

    if (errorOrResult instanceof Error) {
        dispatch(updatePensumsError(errorOrResult, UPDATE_PENSUM_ERROR));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        if (errorOrResult.message && errorOrResult.message.length > 0 && errorOrResult.message !== "") {
            console.log("CODE ERROR: ", errorOrResult.message);
            dispatch(showNotification(errorOrResult.message, "error"))
        } else {
            dispatch(showNotification("UNSUCCESSFUL_UPDATE_PENSUM", "error"))
        }
        return errorOrResult;
    }

    dispatch(updatePensumsSuccess(
        {
            pensumId: Object.keys(queryData)[0],
            changedProps: Object.values(queryData)[0]
        }
    ));

    return errorOrResult;
};