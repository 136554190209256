import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { PENSUM_FLAGS, PENSUM_STATE_CODES, PENSUM_STATE_COLORS } from '../../../../config/constants';

import CircularProgress from '@material-ui/core/CircularProgress';

import NumberInput from '../../../../core/components/Inputs/NumberInput/numberInput';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ReactSelect from 'react-select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Modal from '@material-ui/core/Modal';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputAdornment from '@material-ui/core/InputAdornment';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeletePensumIcon from '@material-ui/icons/DeleteForever';
import CopyIcon from '@material-ui/icons/FileCopy';
import CustomerIcon from '@material-ui/icons/PermContactCalendar';
import LocationIcon from '@material-ui/icons/Room';
import EmployeeIcon from '@material-ui/icons/Person';
import EquipmentIcon from '@material-ui/icons/Build';
import MixtureIcon from '@material-ui/icons/LineStyle';
import DriverIcon from '@material-ui/icons/LocalShipping';
import InvoiceIcon from '@material-ui/icons/Euro';
import ArrowDropUpIcon  from '@material-ui/icons/UnfoldMore';
import ArrowDropDownIcon from '@material-ui/icons/UnfoldLess';
import ActivityIcon from '@material-ui/icons/LocalActivity';
import OptionsIcon from '@material-ui/icons/MoreVert';
import CommentIcon from '@material-ui/icons/Comment';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import GoIcon from '@material-ui/icons/ExitToApp';
import EmailIcon from '@material-ui/icons/Email';
import CheckIcon from '@material-ui/icons/CheckBox';
import UnCheckIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CancelIcon from '@material-ui/icons/Cancel';
import ClockIcon from '@material-ui/icons/QueryBuilder';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';

import { withStyles } from "@material-ui/core/styles";

import './index.css';
import { Checkbox, Divider, Grid } from '@material-ui/core';
import { checkIsValidStartTime, getDurationInMs, getTimeFromMs, rgbToHex, hexToRgb } from '../../../../utils';

const styles = theme => ({
  input: {
    
  },
  singleWideInput: {
    width: 180
  }
});

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    /*'&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },*/
  },
}))(MenuItem);

const Container = styled.div`
  color: ${props => props.isDragging ? '#969696' : props.popupIsOpen ? '#1f1f1f' : '' };
  min-height: 55px;
  border-radius: 5px 5px 4px 4px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  box-shadow: ${props => props.isDragging ? '' : '0 1px 0 rgba(9,30,66,.55)'};
  background-color: ${props =>
    props.isDragDisabled
    ? '#ececec'
    : props.isDragging
    ? '#fff'
    : props.popupIsOpen ? '#f0f0f0' : '#fff'
  };
`

const optionStyles = {
  clearIndicator: (styles) => ({
      ...styles,
      cursor: 'pointer'
  })
};

class Pensum extends Component {
  constructor(props) {
    super(props)

    const { task } = props
    const { pensum } = task

    this.state = {
      selectedProjectID: null,
      selectedProjectActivity: null,
      projectActivityEntries: null,
      filteredMixtureOrders: [],
      filteredMixtures: [],
      selectedEmployee: null,
      selectedEquipment: null,
      selectedMixture: null,
      selectedMixtureQuantity: null,
      selectedMixingPlant: null,
      selectedMixtureOrderNumber: null,
      selectedDriver: null,
      selectedDriverPayload: null,
      selectedDriverStartTime: "06:30",
      selectedDriverMixingPlant: null,
      open: false,
      anchorEl: null,
      isMounted: false,
      totalTons: pensum.TotalMixtureAmount
    }
  }

  componentDidMount() {
    const { partition } = this.props
    let isValidStartTime = true
  
    if (partition && partition.StartTime && partition.EndTime) {
      const { columnId } = this.props
      const columnDay = moment(columnId.split("/")[1])
      isValidStartTime = checkIsValidStartTime(columnDay, partition.StartTime, partition.EndTime, "00:00")
    }
    this.setState({ isValidStartTime })

    document.addEventListener("keydown", this.escFunction.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps, this.props)) {
      return true
    }
    if (_.isEqual(nextState, this.state)) {
      return false
    }
    return true
  }

  escFunction(event) {
    if(event.keyCode === 27) {
      this.closePopup()
    }
  }

  closePopup = () => {
    this.setState({ open: false, openForEditEntry: false })
  }

  handleClick = (entryName, entry, realEntry, popupIsOpen, driverMixingPlant, readOnly) => {
    if (!readOnly) {
      const {
        task: { pensum },
        projectActivities,
        employee,
        mixingPlants,
        equipment,
        activeDNDPensum
      } = this.props
      const { open } = this.state
      let defaultDriverMixingPlant = undefined
  
      if (pensum.MixtureEntries) {
        let i
        for (i = 0; i < pensum.MixtureEntries.length; i++) {
          const mixtureEntry = pensum.MixtureEntries[i]
          if (
            mixtureEntry.Mixture
            && mixtureEntry.Mixture.Order
            && mixtureEntry.Mixture.Order.MixingPlant
            && mixingPlants.some(mixingPlant => mixingPlant.ID === mixtureEntry.Mixture.Order.MixingPlant.ID)
          ) {
            defaultDriverMixingPlant = mixtureEntry.Mixture.Order.MixingPlant.ID
            break;
          }
        }
      }
  
      if (!defaultDriverMixingPlant) {
        if (pensum.Project.DefaultMixingPlant !== null && !pensum.Project.DefaultMixingPlant.IsDeleted) {
          defaultDriverMixingPlant = pensum.Project.DefaultMixingPlant.ID
        }
      }
  
      if (entryName && entry) {
        const { mixtureOrders, mixtures } = this.props
        let driverTime = entry.StartTime && entry.StartTime.replace("PT","").replace("H",":").replace("M","")
        if (
          entry.StartTime
          && entry.StartTime.includes("M")
          && driverTime.split(":")[1]
          && driverTime.split(":")[1].length === 1
        )
          driverTime = `${driverTime.split(":")[0]}:0${driverTime.split(":")[1]}`
        if (entry.StartTime && entry.StartTime.includes("H") && !entry.StartTime.includes("M") && driverTime.split(":")[1] === "")
          driverTime = `${driverTime.split(":")[0]}:00`
        if (entry.StartTime && !entry.StartTime.includes("H") && !entry.StartTime.includes("M"))
          driverTime = "00:00"
  
        this.setState({
          open: activeDNDPensum ? false : !open,
          openDelete: false,
          openForEditEntry: true,
  
          openAssignEntity: entryName === "activity" || entryName === "employee" || entryName === "equipment" ? true : false,
          openAssignMixtureEntity: entryName === "mixture" ? true : false,
          openAssignDriverEntity: entryName === "driver" ? true : false,
  
          selectedProjectActivity: entryName === "activity" ? projectActivities.find(obj => obj.ID === entry.ID) : null,
          selectedEmployee: entryName === "employee" ? employee.find(obj => obj.ID === entry.ID) : null,
          selectedEquipment: entryName === "equipment" ? equipment.find(obj => obj.ID === entry.ID) : null,
  
          editSingleEntry: {
            entryId: realEntry && realEntry.ID,
            entryType:
              entryName === "activity" ? "ProjectActivityEntries"
              : entryName === "employee" ? "EmployeeEntries"
              : entryName === "equipment" ? "EquipmentEntries"
              : null,
            entry: entryName === "activity" ? projectActivities.find(obj => obj.ID === entry.ID)
            : entryName === "employee" ? employee.find(obj => obj.ID === entry.ID)
            : entryName === "equipment" ? equipment.find(obj => obj.ID === entry.ID) : null
          },
  
          filteredMixtureOrders: entryName === "mixture" ? Array.from(mixtureOrders).filter(mix => mix.MixingPlant.ID === entry.Mixture.Order.MixingPlant.ID) : [],
          filteredMixtures: entryName === "mixture" ? Array.from(mixtures).filter(mix => mix.Order.OrderNumber === entry.Mixture.Order.OrderNumber) : [],
          selectedMixture: entryName === "mixture" ? entry.Mixture && entry.Mixture.ID : null,
          selectedMixingPlant: entryName === "mixture" ? entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.MixingPlant && entry.Mixture.Order.MixingPlant.ID : null,
          selectedMixtureQuantity: entryName === "mixture" ? entry.MixtureAmount : null,
          mixtureComment: entryName === "mixture" ? entry.Comment : null,
          selectedMixtureOrderNumber: entryName === "mixture" ? entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.OrderNumber : null,
          
          selectedDriverEntry: entryName === "driver" ? entry : null,
          selectedDriver: entryName === "driver" ? entry.Driver && entry.Driver.ID : null,
          selectedDriverStartTime: entryName === "driver" ? driverTime : null,
          selectedDriverPayload: entryName === "driver" ? entry.Payload : null,
          selectedDriverMixingPlant: entryName === "driver" ? entry.MixingPlant && entry.MixingPlant.ID : null,
          driverComment: entryName === "driver" ? entry.Comment : null,
  
          editComplexEntry:
            entryName === "mixture" ? {
              entryId: entry.ID,
              entryType: "MixtureEntries",
              mixture: entry.Mixture,
              mixingPlant: entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.MixingPlant && entry.Mixture.Order.MixingPlant.ID,
              mixtureOrderNumber: entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.OrderNumber,
              mixtureQuantity: entry.Mixture && entry.MixtureAmount,
              mixtureComment: entry.Comment
            } : entryName === "driver" ? {
              entryId: entry.ID,
              entryType: "DriverEntries",
              driver: entry.Driver,
              mixingPlant: entry.MixingPlant && entry.MixingPlant.ID,
              driverPayload: entry && entry.Payload,
              driverComment: entry.Comment
            } : null
        })
      } else {
        const { mixtureOrders } = this.props
        const defaultMixingPlant = pensum.Project.DefaultMixingPlant !== null && !pensum.Project.DefaultMixingPlant.IsDeleted ? pensum.Project.DefaultMixingPlant.ID : null
        let filteredMixtureOrders = []
        
        if (defaultMixingPlant) {
          filteredMixtureOrders = Array.from(mixtureOrders).filter(mix => mix.MixingPlant.ID === defaultMixingPlant)
        }
  
        this.setState({
          open: popupIsOpen !== null && popupIsOpen !== undefined ? popupIsOpen : activeDNDPensum ? false : true,
          openDelete: false,
          selectedProjectActivity: null,
          selectedEquipment: null,
          selectedEmployee: null,
          selectedMixture: null,
          selectedMixingPlant: pensum.Project.DefaultMixingPlant !== null && !pensum.Project.DefaultMixingPlant.IsDeleted ? pensum.Project.DefaultMixingPlant.ID : null,
          selectedMixtureQuantity: null,
          selectedMixtureOrderNumber: null,
          mixtureComment: null,
          selectedDriverEntry: null,
          selectedDriver: null,
          selectedDriverPayload: null,
          selectedDriverMixingPlant: defaultDriverMixingPlant || driverMixingPlant,
          driverComment: null,
          filteredMixtureOrders: filteredMixtureOrders,
          openForEditEntry: false,
          openAssignEntity: true,
          openAssignMixtureEntity: true,
          openAssignDriverEntity: true,
        })
      }
    }
  }

  handleSave = async (e) => {
    e.preventDefault()
    const {
      task,
      createEquipmentEntry,
      createMixtureEntry,
      createDriverEntry,
      createEmployeeEntry,
      createProjectActivityEntry,
      getPensum,
      showNotification,
      handleNewObjectInHistory
    } = this.props
    const {
      selectedProjectActivity,
      selectedEmployee,
      selectedEquipment,
      selectedMixture,
      selectedMixtureQuantity,
      selectedMixtureOrderNumber,
      selectedMixingPlant,
      mixtureComment,
      selectedDriver,
      selectedDriverStartTime,
      selectedDriverPayload,
      selectedDriverMixingPlant,
      driverComment,
      openForEditEntry,
      selectedDriverEntry,
    } = this.state
    const { pensum } = task
    const startTimeForDriver =
      selectedDriverStartTime === null || selectedDriverStartTime === undefined
        ? "06:30"
        : selectedDriverStartTime

    /*
      Edit Entries of Pensum
    */
    if (openForEditEntry) {
      const { editEntry } = this.props
      const { editSingleEntry, editComplexEntry } = this.state

      if (editSingleEntry && editSingleEntry.entryId) {
        let newSingleEntry, entityId

        if (editSingleEntry.entryType === "ProjectActivityEntries") {
          newSingleEntry = { ProjectActivity: selectedProjectActivity }
          entityId = selectedProjectActivity.ID
        }
        
        if (editSingleEntry.entryType === "EmployeeEntries") {
          newSingleEntry = { Employee: selectedEmployee }
          entityId = selectedEmployee.ID
        }
        
        if (editSingleEntry.entryType === "EquipmentEntries") {
          newSingleEntry = { Equipment: selectedEquipment }
          entityId = selectedEquipment.ID
        }

        if (editSingleEntry.entry.ID !== entityId) {
          const errorOrResult = await editEntry(editSingleEntry.entryId, editSingleEntry.entryType, newSingleEntry)

          if (errorOrResult !== null) {
            if (!(errorOrResult instanceof Error)) {
              /*handleNewObjectInHistory(
                pensum,
                {...pensum, EmployeeEntries: [...pensum.EmployeeEntries, selectedEmployee]},
                "EmployeeEntry",
                errorOrResult,
                selectedEmployee.ID,
                1
              )*/

              showNotification("SUCCESSFUL_SAVE", "success")
              await getPensum(pensum.ID)
              this.handleClick(null, null, null, false)
            } else {
              await new Promise(resolve => setTimeout(() => resolve(), 2000));
              showNotification(`editEntryError: ${errorOrResult.message}`, "error")
            }
          }
        }
      }

      if (editComplexEntry && (editComplexEntry.entryType === "MixtureEntries" || editComplexEntry.entryType === "DriverEntries")) {
        /*const { mixtures, mixingPlants } = this.props
        const newMixture = mixtures.find(mix => mix.ID === selectedMixture)
        const newMixingPlant = mixingPlants.find(mix => mix.ID === selectedMixingPlant)*/
        let newComplexEntryForAction

        /*delete newMixingPlant.Orders
        delete newMixingPlant.Organisation*/

        if (editComplexEntry.entryType === "MixtureEntries") {
          const { mixtures } = this.props
          newComplexEntryForAction = {
            Mixture: mixtures.find(obj => obj.ID === Number(selectedMixture)),
            MixtureAmount: Number(selectedMixtureQuantity),
            Comment: mixtureComment
          }
        }

        let shouldUpdateSuccededDriverEntries = false;
        if (editComplexEntry.entryType === "DriverEntries") {
          const { drivers, mixingPlants } = this.props
          if (selectedDriverEntry.Interval && selectedDriverEntry.Interval > 0){
            shouldUpdateSuccededDriverEntries = true;
            this.handleDriverEntryStartTimes();
          }
          else {
          newComplexEntryForAction = {
            Driver: drivers.find(obj => obj.ID === selectedDriver),
            Payload: Number(selectedDriverPayload),
            StartTime: `PT${startTimeForDriver.split(":")[0]}H${startTimeForDriver.split(":")[1]}M`,
            MixingPlant: mixingPlants.find(obj => obj.ID === selectedDriverMixingPlant),
            Comment: driverComment,
          }
        }
        }
        if (!shouldUpdateSuccededDriverEntries){
          await editEntry(editComplexEntry.entryId, editComplexEntry.entryType, newComplexEntryForAction)
        }
        this.handleClick(null, null, null, false)
      }

      return
    }

    /*
      Create Entries for Pensum
    */
    let pensumProActitvies
    if (selectedProjectActivity) {
      if (pensum.ProjectActivityEntries && pensum.ProjectActivityEntries.length > 0) {
        pensumProActitvies = pensum.ProjectActivityEntries
        pensumProActitvies.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const projectActivityErrorOrResult =
      selectedProjectActivity !== null
        ? await createProjectActivityEntry({
            ProjectActivity: selectedProjectActivity,
            Pensum: { ID: pensum.ID },
            SortOrder: pensumProActitvies ? pensumProActitvies[pensumProActitvies.length - 1].SortOrder + 16 : 0
          })
        : null

    let employees
    if (selectedEmployee) {
      if (pensum.EmployeeEntries && pensum.EmployeeEntries.length > 0) {
        employees = pensum.EmployeeEntries
        employees.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const employeeErrorOrResult =
      selectedEmployee !== null
        ? await createEmployeeEntry({
            Employee: selectedEmployee,
            Pensum: { ID: pensum.ID },
            SortOrder: employees ? employees[employees.length - 1].SortOrder + 16 : 0
          })
        : null

    let equipments
    if (selectedEquipment) {
      if (pensum.EquipmentEntries && pensum.EquipmentEntries.length > 0) {
        equipments = pensum.EquipmentEntries
        equipments.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const equipmentErrorOrResult =
      selectedEquipment !== null
        ? await createEquipmentEntry({
            Equipment: selectedEquipment,
            Pensum: { ID: pensum.ID },
            SortOrder: equipments ? equipments[equipments.length - 1].SortOrder + 16 : 0
          })
        : null

    let mixturesOnPensum
    if (selectedMixture && selectedMixtureQuantity && selectedMixtureQuantity !== ""
      && Number(selectedMixtureQuantity) >= 0 && selectedMixtureOrderNumber !== null) {
      if (pensum.MixtureEntries && pensum.MixtureEntries.length > 0) {
        mixturesOnPensum = pensum.MixtureEntries
        mixturesOnPensum.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const { mixtures } = this.props
    const mixtureErrorOrResult =
      selectedMixture !== null
      && selectedMixtureQuantity !== null && selectedMixtureQuantity !== "" && Number(selectedMixtureQuantity) >= 0
      && selectedMixtureOrderNumber !== null ?
        await createMixtureEntry({
          Mixture: mixtures.find(obj => obj.ID === Number(selectedMixture)),
          MixtureAmount: Number(selectedMixtureQuantity),
          Comment: mixtureComment,
          Pensum: { ID: pensum.ID },
          SortOrder: mixturesOnPensum ? mixturesOnPensum[mixturesOnPensum.length - 1].SortOrder + 16 : 0
        })
      : null

    let driversOnPensum
    if (selectedDriver && selectedDriverPayload && selectedDriverPayload !== "" && Number(selectedDriverPayload) >= 0 && selectedDriverMixingPlant) {
      if (pensum.DriverEntries && pensum.DriverEntries.length > 0) {
        driversOnPensum = pensum.DriverEntries
        driversOnPensum.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const { drivers, mixingPlants } = this.props
    const driverErrorOrResult =
      selectedDriver !== null
      && selectedDriverPayload !== null && selectedDriverPayload !== "" && Number(selectedDriverPayload) >= 0
      && selectedDriverMixingPlant !== null ?
        await createDriverEntry({
          Driver: drivers.find(obj => obj.ID === selectedDriver),
          MixingPlant: mixingPlants.find(obj => obj.ID === selectedDriverMixingPlant),
          Pensum: { ID: pensum.ID },
          Payload: Number(selectedDriverPayload),
          StartTime: `PT${startTimeForDriver.split(":")[0]}H${startTimeForDriver.split(":")[1]}M`,
          Comment: driverComment,
          SortOrder: driversOnPensum ? driversOnPensum[driversOnPensum.length - 1].SortOrder + 16 : 0
        })
      : null

    if (selectedProjectActivity !== null && projectActivityErrorOrResult !== null) {
      if (!(projectActivityErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, ProjectActivityEntries: [...pensum.ProjectActivityEntries, selectedProjectActivity]},
          "ProjectActivityEntry",
          projectActivityErrorOrResult,
          selectedProjectActivity.ID,
          1
        )

        showNotification("SUCCESSFUL_SAVE_projectactivity", "success")
      } else {
        showNotification(`CreateProjectActivityEntryError: ${projectActivityErrorOrResult.message}`, "error")
      }
    }

    if (selectedEmployee !== null && employeeErrorOrResult !== null) {
      if (!(employeeErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, EmployeeEntries: [...pensum.EmployeeEntries, selectedEmployee]},
          "EmployeeEntry",
          employeeErrorOrResult,
          selectedEmployee.ID,
          1
        )

        await new Promise(resolve => setTimeout(() => resolve(), 1000));
        showNotification("SUCCESSFUL_SAVE_employee", "success")
      } else {
        await new Promise(resolve => setTimeout(() => resolve(), 2000));
        showNotification(`CreateEmployeeEntryError: ${employeeErrorOrResult.message}`, "error")
      }
    }

    if (selectedEquipment !== null && equipmentErrorOrResult !== null) {
      if (!(equipmentErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, EquipmentEntries: [...pensum.EquipmentEntries, selectedEquipment]},
          "EquipmentEntry",
          equipmentErrorOrResult,
          selectedEquipment.ID,
          1
        )

        await new Promise(resolve => setTimeout(() => resolve(), 1000));
        showNotification("SUCCESSFUL_SAVE_equipment", "success")
      } else {
        await new Promise(resolve => setTimeout(() => resolve(), 2000));
        showNotification(`CreateEquipmentEntryError: ${equipmentErrorOrResult.message}`, "error")
      }
    }

    if (selectedMixture !== null && mixtureErrorOrResult !== null) {
      if (!(mixtureErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, MixtureEntries: [...pensum.MixtureEntries, selectedMixture]},
          "MixtureEntry",
          mixtureErrorOrResult,
          selectedMixture,
          1
        )

        await new Promise(resolve => setTimeout(() => resolve(), 1000));
        showNotification("SUCCESSFUL_SAVE_mixture", "success")
      } else {
        await new Promise(resolve => setTimeout(() => resolve(), 2000));
        showNotification(`CreateMixtureEntryError: ${mixtureErrorOrResult.message}`, "error")
      }
    }

    if (selectedDriver !== null && driverErrorOrResult !== null) {
      if (!(driverErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, DriverEntries: [...pensum.DriverEntries, selectedDriver]},
          "DriverEntry",
          {...driverErrorOrResult, MixingPlant: { ID: selectedDriverMixingPlant }},
          selectedDriver,
          1
        )
        await new Promise(resolve => setTimeout(() => resolve(), 1000));
        showNotification("SUCCESSFUL_SAVE", "success")
      } else {
        await new Promise(resolve => setTimeout(() => resolve(), 2000));
        showNotification(`CreateDriverEntryError: ${driverErrorOrResult.message}`, "error")
      }
    }

    if (
      selectedProjectActivity === null &&
      selectedEmployee === null &&
      selectedEquipment === null &&
      (selectedMixture === null || selectedMixtureOrderNumber === null || selectedMixtureQuantity === null || selectedMixtureQuantity === "") &&
      (selectedDriver === null || selectedDriverMixingPlant === null || selectedDriverPayload === null || selectedDriverPayload === "")
    ) {
      return
    } else {
      await getPensum(pensum.ID)
      this.handleClick(null, null, null, null, selectedMixingPlant)
    }
  }

  handleDelete = async () => {
    const { deletePensum, task } = this.props
    const { pensum } = task

    const errorOrResult = await deletePensum({ ID: pensum.ID })

    if (errorOrResult !== undefined && !(errorOrResult instanceof Error)) {
      const { handleNewObjectInHistory } = this.props
      
      handleNewObjectInHistory(pensum, null, null, null, null, null, true)

      this.setState({ openDeletePensum: false })
    }
  }

  handleDeleteEntry = async () => {
    const { deleteEntry, getPensum/* , selectedFromDate, selectedToDate */, task } = this.props
    const { deleteEntryObj } = this.state
    const { pensum } = task

    const errorOrResult = await deleteEntry(deleteEntryObj.entryTypeForAction, deleteEntryObj.entryId)

    if (errorOrResult !== undefined && !(errorOrResult instanceof Error)) {
      const { handleNewObjectInHistory } = this.props
      let newPensum

      switch (deleteEntryObj.entryType) {
        case "ProjectActivityEntry":
          newPensum = {...pensum, ProjectActivityEntries: [...pensum.ProjectActivityEntries, deleteEntryObj.entry]}
          break;
        case "EmployeeEntry":
          newPensum = {...pensum, EmployeeEntries: [...pensum.EmployeeEntries, deleteEntryObj.entry]}
          break;
        case "EquipmentEntry":
          newPensum = {...pensum, EquipmentEntries: [...pensum.EquipmentEntries, deleteEntryObj.entry]}
          break;
        case "MixtureEntry":
          newPensum = {...pensum, MixtureEntries: [...pensum.MixtureEntries, deleteEntryObj.entry]}
          break;
        case "DriverEntry":
          newPensum = {...pensum, DriverEntries: [...pensum.DriverEntries, deleteEntryObj.entry]}
          break;
      
        default:
          break;
      }

      handleNewObjectInHistory(
          pensum,
          newPensum,
          deleteEntryObj.entryType,
          deleteEntryObj.entry,
          deleteEntryObj.entityId,
          3
      )
      await getPensum(pensum.ID)
      this.handleClick(null, null, null, false)
      this.setState({ openDeleteEntry: false })
    }
  }

  getDriverByID = (ID) => {
    const { drivers } = this.props;
    return drivers.find(obj => obj.ID === ID)
  }

  getMixingPlantByID = (ID) => {
    const { mixingPlants } = this.props;
    if (mixingPlants) {
      return mixingPlants.find(obj => obj.ID === ID)
    } else return null
  }

  getMixtureOrderByID = (ID) => {
    const { mixtureOrders } = this.props;
    return mixtureOrders.find(obj => obj.ID === ID)
  }

  getFilteredMixtureOrderByON = (OrderNumber) => {
    const { filteredMixtureOrders } = this.state;
    return filteredMixtureOrders.find(obj => obj.OrderNumber === OrderNumber)
  }

  getFilteredMixtureObj = (ID) => {
    const { filteredMixtures } = this.state;
    return filteredMixtures.find(obj => obj.ID === ID)
  }

  handleMixingPlantChange = value => {
    const { mixtureOrders } = this.props
    let filteredMixtureOrders = []

    if (value) {
      const { value: ID } = value
      filteredMixtureOrders = Array.from(mixtureOrders).filter(mix => mix.MixingPlant.ID === ID)
    }

    this.setState({
      selectedMixingPlant: value ? value.value : null,
      filteredMixtureOrders: filteredMixtureOrders,
      selectedMixtureOrderNumber: null,
      selectedMixture: null,
      selectedMixtureQuantity: null,
    })
  }

  handleSelectedDriverChange = value => {
    const { drivers } = this.props
    let defaultDriverPayload = null

    if (value) {
      const { value: ID } = value
      const selectedDriver = drivers.find(d => d.ID === Number(ID))

      if (selectedDriver) {
        if (selectedDriver.DefaultPayload) {
          defaultDriverPayload = selectedDriver.DefaultPayload
        }
      }
    }

    this.setState({
      selectedDriver: value ? value.value : null,
      selectedDriverPayload: defaultDriverPayload
    })
  }

  handleDriverIntervalChange = event => {
    const { selectedDriverEntry } = this.state;
    selectedDriverEntry.Interval = Number(event.target.value);
  }

  handleDriverEntryStartTimes = async () => {
    const { task: { pensum }, editEntry, drivers, mixingPlants } = this.props;
    const { selectedDriverEntry, selectedDriverStartTime, selectedDriverPayload, driverComment, selectedDriver, selectedDriverMixingPlant } = this.state;
    const driverEntries = pensum.DriverEntries;
    let index = driverEntries.findIndex(entry => entry.ID === selectedDriverEntry.ID );

    const startTimeForDriver =
      selectedDriverStartTime === null || selectedDriverStartTime === undefined
        ? "06:30"
        : selectedDriverStartTime

    let interval = selectedDriverEntry.Interval;
    let newStartTime = moment(startTimeForDriver, "hh:mm");
    for (let i = index; i < driverEntries.length; i++) {

      let updatedDriverEntry = {};
      updatedDriverEntry.StartTime = `PT${newStartTime.hours()}H${newStartTime.minutes()}M`;
      if (i === index) {
        updatedDriverEntry.Driver = drivers.find(obj => obj.ID === selectedDriver);
        updatedDriverEntry.Payload = Number(selectedDriverPayload);
        updatedDriverEntry.MixingPlant = mixingPlants.find(obj => obj.ID === selectedDriverMixingPlant);
        updatedDriverEntry.Comment = driverComment;
    }
        newStartTime.add(interval, 'minutes');
        if ((i === index && selectedDriverEntry.isStartTimeChanged) || i !== index){
          await editEntry(driverEntries[i].ID, "DriverEntries", updatedDriverEntry);
        }
    }
    
  }

  handleMixtureOrderChange = value => {
    const { mixtures } = this.props
    let filteredMixtures = []

    if (value) {
      const { value: ON } = value
      filteredMixtures = mixtures ? Array.from(mixtures).filter(mix => mix.Order.OrderNumber === String(ON)) : []
    }
    
    this.setState({
      selectedMixtureOrderNumber: value ? value.value : null,
      filteredMixtures: filteredMixtures,
      selectedMixture: null
    })
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSelectChange = (value, name) => {
    let Value;
    if (value) {
      const { value: ID } = value;
      Value = ID;
    }
    this.setState({
      [name]: Value
    });
  }

  openHeaderPopup = (open) => {
    const { partition, isPensumStartTimeAllowed, task: { pensum } } = this.props
    const pensumStartTime = pensum.StartTime
    let newPensumStartTime = null
    let isValidStartTime = true

    if (isPensumStartTimeAllowed) {
      if (pensumStartTime) {
        newPensumStartTime = getTimeFromMs(getDurationInMs(pensumStartTime))
      } else {
        newPensumStartTime = "00:00"
      }
    } else if (pensumStartTime) {
        newPensumStartTime = getTimeFromMs(getDurationInMs(pensumStartTime))
    } else {
      newPensumStartTime = null
    }
  
    if (isPensumStartTimeAllowed && partition && partition.StartTime && partition.EndTime) {
      const { columnId } = this.props
      const columnDay = moment(columnId.split("/")[1])
      isValidStartTime = checkIsValidStartTime(columnDay, partition.StartTime, partition.EndTime, newPensumStartTime)
    }

    this.setState({
      openHeaderPopup: open,
      pensumStartTime: newPensumStartTime,
      isValidStartTime
    })
  }

  closeHeaderPopup = () => {
    this.setState({ openHeaderPopup: false })
  }

  handleHeaderPopupChange = () => {
    const { task: { pensum }, updatePensum } = this.props
    const { pensumComment, workDuration, totalTons, pensumStartTime } = this.state

    if (Number(totalTons) >= 0) {
      updatePensum({ [pensum.ID]: {
        WorkDuration: `PT${workDuration.split(":")[0]}H${workDuration.split(":")[1]}M`,
        TotalMixtureAmount: Number(totalTons),
        StartTime: pensumStartTime !== null ? moment.duration(pensumStartTime).toISOString() : null,
        Comment: pensumComment
      }})
    }

    this.setState({ openHeaderPopup: false })
  }

  handleDriverStartTimeChange = event => {
    const { value } = event.target
    const { selectedDriverEntry } = this.state;
    this.setState({ selectedDriverStartTime: value });
    if (selectedDriverEntry) {
      selectedDriverEntry.isStartTimeChanged = true;
      this.setState({ selectedDriverEntry })
    }
  }

  handleWorkDurationChange = event => {
    const { value } = event.target
    this.setState({ workDuration: value })
  }

  handlePensumStartTimeChange = event => {
    const { partition } = this.props
    const { value } = event.target
    let isValidStartTime = true

    if (partition && partition.StartTime && partition.EndTime) {
      const { columnId } = this.props
      const columnDay = moment(columnId.split("/")[1])
      isValidStartTime = checkIsValidStartTime(columnDay, partition.StartTime, partition.EndTime, value)
    }

    this.setState({ pensumStartTime: value, isValidStartTime })
  }

  handleMixtureCommentChange = (event) => {
    const { value } = event.target;
    this.setState({
      mixtureComment: value
    });
  }

  handleDriverCommentChange = (event) => {
    const { value } = event.target;
    this.setState({
      driverComment: value
    });
  }

  closeDeleteEntryPopup = () => {
    this.setState({ openDeleteEntry: false, deleteEntryObj: null })
  }

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePensumCopy = () => {
    const { task: { pensum }, handleCopy, showNotification, t } = this.props
    handleCopy(pensum);
    showNotification(t('pensum-copied'), "success")
  }

  handlePensumMove = () => {
    const { task: { pensum }, handleMove, showNotification, t, columnId, index } = this.props
    handleMove(pensum, {columnId: columnId, index: index});
    showNotification(t('pensum-moved'), "success")
  }

  sendMessage = async (e) => {
    e.preventDefault()
    const { sendMessage, task: { pensum } } = this.props
    const { pensumMessage } = this.state
    if (pensumMessage && pensumMessage.length > 0) {
      await sendMessage(pensum.ID, { Message: pensumMessage })
    }
    this.setState({
      openSendMessage: false,
      pensumMessage: undefined
    })
  }

  setPensumState = async (e) => {
    e.preventDefault()
    const { setPensumState, task: { pensum }, getPensum } = this.props
    const { newPensumState, pensumMessage } = this.state
    await setPensumState(pensum.ID, { StateCode: newPensumState }, { Message: pensumMessage })
    this.setState({ openPensumStateChangeConfirm: false, pensumMessage: undefined })
    await getPensum(pensum.ID)
  }

  confirmDriver = async (event, entry) => {
    event.preventDefault()
    const { editEntry, getPensum, task: { pensum } } = this.props
    await editEntry(entry.ID, "DriverEntries", { ...entry, ConfirmedByDriver: event.target.checked })
    await getPensum(pensum.ID)
  }

  handleCostCenterTextColor = (hexcolor) => {
    const rgbcolor = hexToRgb(hexcolor);
    let cccolor;
    if ((rgbcolor.r*0.299 + rgbcolor.g*0.587 + rgbcolor.b*0.114) > 130) {
      cccolor = '#000000'
    } else {
      cccolor = '#ffffff'
    }
    return cccolor;
  }

  stateToColor = (stateCode) => {
    switch (stateCode) {
      case PENSUM_STATE_CODES.New:
        return PENSUM_STATE_COLORS.New;
      case PENSUM_STATE_CODES.ConfirmedByConstructionSupervisor:
        return PENSUM_STATE_COLORS.ConfirmedByConstructionSupervisor;
      case PENSUM_STATE_CODES.ConfirmedByDispatcher:
        return PENSUM_STATE_COLORS.ConfirmedByDispatcher;
      case PENSUM_STATE_CODES.WorkCompleted:
        return PENSUM_STATE_COLORS.ConfirmedByDispatcher;
      case PENSUM_STATE_CODES.Invoiced:
      case PENSUM_STATE_CODES.AutomaticallyInvoiced:
        return PENSUM_STATE_COLORS.ConfirmedByDispatcher;
      case PENSUM_STATE_CODES.Canceled:
        return PENSUM_STATE_COLORS.Canceled;
      default:
        return null;
    }
  }

  stateToAlternativeColor = (stateCode) => {
    switch (stateCode) {
      case PENSUM_STATE_CODES.New:
        return PENSUM_STATE_COLORS.New;
      case PENSUM_STATE_CODES.ConfirmedByConstructionSupervisor:
        return '#a49201';
      case PENSUM_STATE_CODES.ConfirmedByDispatcher:
        return PENSUM_STATE_COLORS.ConfirmedByDispatcher;
      case PENSUM_STATE_CODES.WorkCompleted:
        return PENSUM_STATE_COLORS.ConfirmedByDispatcher;
      case PENSUM_STATE_CODES.Invoiced:
      case PENSUM_STATE_CODES.AutomaticallyInvoiced:
        return PENSUM_STATE_COLORS.ConfirmedByDispatcher;
      case PENSUM_STATE_CODES.Canceled:
        return PENSUM_STATE_COLORS.Canceled;
      default:
        return null;
    }
  }

  render() {
    const {
      task,
      t,
      index,
      projectActivities,
      employee,
      drivers,
      equipment,
      mixingPlants,
      popupIsOpen,
      isPensumStartTimeAllowed,
/*       classes, */
      activeDNDPensum,
      view,
      duplicatedhint,
      copiedPensum,
      movedPensum,
    } = this.props
    const {
      open,
      openDelete,
      openHeaderPopup,
      openForEditEntry,
      anchorEl,
      openDeleteEntry,
      openAssignEntity,
      openAssignMixtureEntity,
      openAssignDriverEntity,
      hoveringAssignEntity,
      hoveringAssignMixtureEntry,
      hoveringAssignDriverEntry,
      filteredMixtureOrders,
      filteredMixtures,
      selectedProjectActivity,
      selectedEmployee,
      selectedEquipment,
      selectedMixture,
      selectedMixtureQuantity,
      selectedMixingPlant,
      selectedMixtureOrderNumber,
      mixtureComment,
      selectedDriver,
      selectedDriverPayload,
      selectedDriverMixingPlant,
      selectedDriverStartTime,
      driverComment,
      totalTons,
      workDuration,
      pensumComment,
      newPensumState,
      pensumStartTime,
      isValidStartTime
    } = this.state
    const { pensum } = task
    const {
      EmployeeEntries,
      EquipmentEntries,
      MixtureEntries,
      DriverEntries,
      ProjectActivityEntries,
    } = pensum

    const saveIsDisabled =
      (selectedMixingPlant !== null || selectedMixture !== null)
        && (selectedMixture !== null && (
          selectedMixtureQuantity === null
          || selectedMixtureQuantity === ""
          || Number(selectedMixtureQuantity) < 0
          || String(selectedMixtureQuantity).charAt(0) === "-"
        ))
      || (
        selectedDriver !== null && selectedDriver !== undefined
          && (
            !selectedDriverMixingPlant || selectedDriverMixingPlant === "empty"
            || (
              selectedDriverPayload === null || selectedDriverPayload === ""
                || Number(selectedDriverPayload) < 0 || String(selectedDriverPayload).charAt(0) === "-"
            )
          ) || selectedDriverStartTime === ""
      )
      || (selectedDriver !== null && selectedDriver !== undefined
          && selectedDriverMixingPlant && selectedDriverMixingPlant !== "empty"
          && (selectedDriverPayload === null || selectedDriverPayload === ""
          || Number(selectedDriverPayload) <= 0 && String(selectedDriverPayload).charAt(0) === "-")
      )

    const headerPopupSaveIsDisabled =
      totalTons === "" || totalTons === null || Number(totalTons) < 0 || String(totalTons).charAt(0) === "-" || workDuration === "" || (isPensumStartTimeAllowed && !isValidStartTime)

    const firstAssignSectionIsEmpty =
      !selectedProjectActivity && !selectedEmployee && !selectedEquipment
    const showFirstAssignSection = !openForEditEntry || (openForEditEntry && (selectedProjectActivity || selectedEmployee || selectedEquipment))
    const showSecondAssignSection = !openForEditEntry || (openForEditEntry && (selectedMixingPlant || selectedMixtureOrderNumber || selectedMixture))
    const showThirdAssignSection = !openForEditEntry || (openForEditEntry && (selectedDriver || selectedDriverStartTime || selectedDriverMixingPlant))
    const selectedDriverObj = this.getDriverByID(selectedDriver);
    const selectedDriverMixingPlantObj = this.getMixingPlantByID(selectedDriverMixingPlant);
    const selectedMixingPlantObj = this.getMixingPlantByID(selectedMixingPlant);
    const selectedFilteredMixtureOrderObj = this.getFilteredMixtureOrderByON(selectedMixtureOrderNumber);
    const selectedMixtureObj = this.getFilteredMixtureObj(selectedMixture);
    let DriverEntriesSum = 0
    let formattedStartTime = selectedDriverStartTime;

    const allowStateChange_CBCS = (pensum.FlagsCode & PENSUM_FLAGS.AllowStateChange_ConfirmedByConstructionSupervisor) !== 0
    const allowStateChange_CBD = (pensum.FlagsCode & PENSUM_FLAGS.AllowStateChange_ConfirmedByDispatcher) !== 0
    const allowStateChange_WC = (pensum.FlagsCode & PENSUM_FLAGS.AllowStateChange_WorkCompleted) !== 0
    const allowStateChange_I = (pensum.FlagsCode & PENSUM_FLAGS.AllowStateChange_Invoiced) !== 0
    const allowStateChangeMessage = (pensum.FlagsCode & PENSUM_FLAGS.AllowStateChangeMessage) !== 0
    const requireStateChangeMessage = (pensum.FlagsCode & PENSUM_FLAGS.RequireStateChangeMessage) !== 0
    const allowCancelation = (pensum.FlagsCode & PENSUM_FLAGS.AllowCancelation) !== 0
    const allowCancelationMessage = (pensum.FlagsCode & PENSUM_FLAGS.AllowCancelationMessage) !== 0
    const requireCancelationMessage = (pensum.FlagsCode & PENSUM_FLAGS.RequireCancelationMessage) !== 0
    const allowSendMessage = (pensum.FlagsCode & PENSUM_FLAGS.AllowSendMessage) !== 0
    const allowDelete = (pensum.FlagsCode & PENSUM_FLAGS.AllowDelete) !== 0
    const allowMove = (pensum.FlagsCode & PENSUM_FLAGS.AllowMove) !== 0
    const allowCommentChange = (pensum.FlagsCode & PENSUM_FLAGS.AllowCommentChange) !== 0
    const allowEdit = (pensum.FlagsCode & PENSUM_FLAGS.AllowEdit) !== 0
    const requirePensumStateChangeMessage = newPensumState === PENSUM_STATE_CODES.Canceled ? requireCancelationMessage : requireStateChangeMessage
    const allowPensumStateChangeMessage = newPensumState === PENSUM_STATE_CODES.Canceled ? allowCancelationMessage : allowStateChangeMessage
    /*const allowEverything = (pensum.FlagsCode & PENSUM_FLAGS.All) == PENSUM_FLAGS.All*/
  
    let headerColor = this.stateToColor(pensum.StateCode) || 'rgb(145,145,145)';

    if (movedPensum && movedPensum.ID === pensum.ID){
      headerColor += "40";
    }

    if (
      selectedDriverStartTime
      && selectedDriverStartTime.includes(":")
      && selectedDriverStartTime.split(":")[0].length === 1
    ) {
      formattedStartTime = `0${selectedDriverStartTime}`
    }
    if (selectedDriverStartTime && !selectedDriverStartTime.includes(":")) {
      if (selectedDriverStartTime.length === 2) {
        formattedStartTime = `00:${selectedDriverStartTime}`
      } else if (selectedDriverStartTime.length === 1) {
        formattedStartTime = `00:0${selectedDriverStartTime}`
      }
    }

    let defaultWorkduration = "00:00"
    if (pensum.WorkDuration) {
      if (!(pensum.WorkDuration.includes("H"))) {
        defaultWorkduration = pensum.WorkDuration.replace("PT","00:").replace("M","").replace("S","")
        if (defaultWorkduration.split(":")[1].length === 0) {
          defaultWorkduration = `${defaultWorkduration}00`
        }
        if (defaultWorkduration.split(":")[1].length === 1) {
          if (defaultWorkduration.split(":")[1] === "0") {
            defaultWorkduration = `${defaultWorkduration}0`
          } else {
            defaultWorkduration = `${defaultWorkduration.split(":")[0]}:0${defaultWorkduration.split(":")[1]}`
          }
        }
        if (defaultWorkduration.split(":")[0].length === 1) {
          defaultWorkduration = `0${defaultWorkduration}`
        }
      } else {
        defaultWorkduration = pensum.WorkDuration.replace("PT","").replace("H",":").replace("M","").replace("S","")
        if (defaultWorkduration.includes(":")) {
          if (defaultWorkduration.split(":")[1].length === 0) {
            defaultWorkduration = `${defaultWorkduration}00`
          }
          if (defaultWorkduration.split(":")[1].length === 1) {
            defaultWorkduration = `${defaultWorkduration}0`
          }
          if (defaultWorkduration.split(":")[0].length === 1) {
            defaultWorkduration = `0${defaultWorkduration}`
          }
        }
      }
    }

    let defaultTotalTons = 0
    if (pensum.TotalMixtureAmount) {
      defaultTotalTons = pensum.TotalMixtureAmount
    }

    if (DriverEntries) {
      DriverEntries.forEach(entry => {
        if (entry.Payload !== null && entry.Payload !== undefined) {
          DriverEntriesSum += Number(entry.Payload)
        }
      })
    }

    EmployeeEntries.sort((a, b) => {
      return a.SortOrder - b.SortOrder
    })

    EquipmentEntries.sort((a, b) => {
      return a.SortOrder - b.SortOrder
    })

    MixtureEntries.sort((a, b) => { 
      return a.SortOrder - b.SortOrder
    })

    ProjectActivityEntries.sort((a, b) => {
      return a.SortOrder - b.SortOrder
    })

    return (
      <Draggable
        draggableId={`pensum-${pensum.ID}`}
        index={index}
        isDragDisabled={open || this.state.openPensumStateChangeConfirm || this.state.openDeletePensum || openHeaderPopup || Boolean(anchorEl) || !allowMove}
      >
        {(provided, snapshot) => (
          <Container
            id={`pensum:${pensum.ID}`}
            className="pensum"
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            isDragDisabled={open || !allowMove}
            {...provided.draggableProps}
            popupIsOpen={popupIsOpen}
          >
                <div
                  onClick={(e) => { e.preventDefault(); this.handleClick(null, null, null, null, null, !allowEdit); }}
                  className="pensum-wrapper"
                >
                  <div
                    className="project-section"
                    style={{
                      backgroundColor: headerColor,
                      cursor:
                        (open || !allowMove) && !allowCommentChange
                        ? 'default'
                        : (open || !allowMove) && allowCommentChange
                          ? 'pointer'
                          : ''
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      this.openHeaderPopup((!allowCommentChange || !allowEdit) ? false : !openHeaderPopup)
                      this.setState({
                        totalTons: (allowCommentChange || allowEdit) && defaultTotalTons,
                        workDuration: (allowCommentChange || allowEdit) && defaultWorkduration,
                        pensumComment: (allowCommentChange || allowEdit) && pensum.Comment
                      });
                    }}
                    {...provided.dragHandleProps}
                    isDragDisabled={open || !allowMove}
                  >
                    <div
                      className="header-project"
                      title={pensum.Project.Name}
                      style={{ height: 17, float: 'left', paddingLeft: 5, textShadow: "0px 1px 3px rgba(0, 0, 0)" }}
                    >
                      {pensum.Project.Name}
                    </div>
                    <div className="pensum-options">
                      <OptionsIcon
                        onClick={(event) => { event.stopPropagation(); this.handleOpen(event); }}
                        style={{
                          height: 20,
                          width: 20,
                          color: "#fff",
                          borderRadius: 10,
                          cursor: 'pointer'
                        }}
                      />
                      <StyledMenu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={(event) => { event.stopPropagation(); this.handleClose() }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <h3
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            paddingLeft: 16,
                            paddingRight: 16,
                            fontFamily: 'Open Sans',
                            letterSpacing: 0
                          }}
                        >
                          {`${t('pensum')} ${t('state')}`}
                        </h3>
                        <div style={{
                          color: `rgb(140, 140, 140)`,
                          fontSize: 14,
                          marginBottom: 6,
                          paddingLeft: 16,
                          paddingRight: 16,
                        }}>
                          {t(
                            `pensumStates.${
                              Object.keys(PENSUM_STATE_CODES)
                                .find(key =>
                                  PENSUM_STATE_CODES[key] === pensum.StateCode
                                )
                            }`
                          )}
                        </div>

                        {allowStateChange_CBCS && (
                          <StyledMenuItem
                            id={PENSUM_STATE_CODES.ConfirmedByConstructionSupervisor}
                            onClick={(e) => {
                              e.stopPropagation()
                              this.setState({ anchorEl: false, openPensumStateChangeConfirm: true, newPensumState: PENSUM_STATE_CODES.ConfirmedByConstructionSupervisor })
                            }}
                            onMouseEnter={() => this.setState({ hoveringConfirmSupervisor: true })}
                            onMouseLeave={() => this.setState({ hoveringConfirmSupervisor: false })}
                          >
                            <ListItemIcon style={{ minWidth: 24 }}>
                              {
                                pensum.StateCode === PENSUM_STATE_CODES.ConfirmedByConstructionSupervisor
                                  ? this.state.hoveringConfirmSupervisor
                                    ? <UnCheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                                    : <CheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                                  : this.state.hoveringConfirmSupervisor
                                    ? <CheckIcon style={{ marginLeft: -2, marginRight: 3, color: this.stateToAlternativeColor(PENSUM_STATE_CODES.ConfirmedByConstructionSupervisor) }} />
                                    : <UnCheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                              }
                            </ListItemIcon>
                            {t('confirm-as-supervisor')}
                          </StyledMenuItem>
                        )}

                        {allowStateChange_CBD && (
                          <StyledMenuItem
                            id={PENSUM_STATE_CODES.ConfirmedByDispatcher}
                            onClick={(e) => {
                              e.stopPropagation()
                              this.setState({ anchorEl: false, openPensumStateChangeConfirm: true, newPensumState: PENSUM_STATE_CODES.ConfirmedByDispatcher })
                            }}
                            onMouseEnter={() => this.setState({ hoveringConfirmDispatcher: true })}
                            onMouseLeave={() => this.setState({ hoveringConfirmDispatcher: false })}
                          >
                            <ListItemIcon style={{ minWidth: 24 }}>
                              {
                                pensum.StateCode === PENSUM_STATE_CODES.ConfirmedByDispatcher
                                  ? this.state.hoveringConfirmDispatcher
                                    ? <UnCheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                                    : <CheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                                  : this.state.hoveringConfirmDispatcher
                                    ? <CheckIcon style={{ marginLeft: -2, marginRight: 3, color: this.stateToAlternativeColor(PENSUM_STATE_CODES.ConfirmedByDispatcher) }} />
                                    : <UnCheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                              }
                            </ListItemIcon>
                            {t('confirm-as-dispatcher')}
                          </StyledMenuItem>
                        )}

                        {allowStateChange_I && (
                          <StyledMenuItem
                            id={PENSUM_STATE_CODES.Invoiced}
                            onClick={(e) => {
                              e.stopPropagation()
                              this.setState({ anchorEl: false, openPensumStateChangeConfirm: true, newPensumState: PENSUM_STATE_CODES.Invoiced })
                            }}
                            onMouseEnter={() => this.setState({ hoveringSetInvoiced: true })}
                            onMouseLeave={() => this.setState({ hoveringSetInvoiced: false })}
                          >
                            <ListItemIcon style={{ minWidth: 24 }}>
                              {
                                pensum.StateCode === PENSUM_STATE_CODES.Invoiced
                                  ? this.state.hoveringSetInvoiced
                                    ? <UnCheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                                    : <CheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                                  : this.state.hoveringSetInvoiced
                                    ? <CheckIcon style={{ marginLeft: -2, marginRight: 3, color: this.stateToAlternativeColor(PENSUM_STATE_CODES.Invoiced) }} />
                                    : <UnCheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                              }
                            </ListItemIcon>
                            {t('set-pensum-invoiced')}
                          </StyledMenuItem>
                        )}

                        {allowStateChange_WC && (
                          <StyledMenuItem
                            id={PENSUM_STATE_CODES.WorkCompleted}
                            onClick={(e) => {
                              e.stopPropagation()
                              this.setState({ anchorEl: false, openPensumStateChangeConfirm: true, newPensumState: PENSUM_STATE_CODES.WorkCompleted })
                            }}
                            onMouseEnter={() => this.setState({ hoveringSetWorkCompleted: true })}
                            onMouseLeave={() => this.setState({ hoveringSetWorkCompleted: false })}
                          >
                            <ListItemIcon style={{ minWidth: 24 }}>
                              {
                                pensum.StateCode === PENSUM_STATE_CODES.WorkCompleted
                                  ? this.state.hoveringSetWorkCompleted
                                    ? <UnCheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                                    : <CheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                                  : this.state.hoveringSetWorkCompleted
                                    ? <CheckIcon style={{ marginLeft: -2, marginRight: 3, color: this.stateToAlternativeColor(PENSUM_STATE_CODES.WorkCompleted) }} />
                                    : <UnCheckIcon style={{ marginLeft: -2, marginRight: 3 }} />
                              }
                            </ListItemIcon>
                            {pensum.StateCode === PENSUM_STATE_CODES.Invoiced ? t('reset-pensum-invoiced') : t('set-pensum-work-completed')}
                          </StyledMenuItem>
                        )}

                        {allowCancelation && (
                          <StyledMenuItem
                            id={PENSUM_STATE_CODES.Canceled}
                            onClick={(e) => {
                              e.stopPropagation()
                              this.setState({ anchorEl: false, openPensumStateChangeConfirm: true, newPensumState: PENSUM_STATE_CODES.Canceled })
                            }}
                            onMouseEnter={() => this.setState({ hoveringCancelPensum: true })}
                            onMouseLeave={() => this.setState({ hoveringCancelPensum: false })}
                          >
                            <ListItemIcon style={{ minWidth: 24 }}>
                              <CancelIcon style={{ marginLeft: -2, marginRight: 3, color: this.state.hoveringCancelPensum && '#c20000' }} />
                            </ListItemIcon>
                            {`${t('cancel-pensum')}`}
                          </StyledMenuItem>
                        )}

                        <Dialog
                          open={this.state.openPensumStateChangeConfirm}
                          onClose={() => this.setState({ openPensumStateChangeConfirm: false })}
                          onClick={(e) => e.stopPropagation()}
                          style={{ fontFamily: 'Open Sans' }}
                        >
                          <DialogTitle id="form-dialog-title" style={{ color: this.stateToAlternativeColor(newPensumState) }}>
                            {t('confirm')}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              {t(pensum.StateCode === PENSUM_STATE_CODES.Invoiced && newPensumState < pensum.StateCode ? 'are-you-sure-pensum-state-revert' : 'are-you-sure-pensum-state', {
                                currentState: t(`pensumStates.${Object.keys(PENSUM_STATE_CODES).find(key => PENSUM_STATE_CODES[key] === pensum.StateCode)}`),
                                newState: t(`pensumStates.${Object.keys(PENSUM_STATE_CODES).find(key => PENSUM_STATE_CODES[key] === newPensumState)}`) })}
                            </DialogContentText>
                            {requirePensumStateChangeMessage && (
                              <div style={{ fontSize: 13 }}>
                                <em style={{ color: 'red', marginRight: 3 }}>*</em>
                                {t('required-field')}
                              </div>
                            )}
                            {allowPensumStateChangeMessage && (
                              <TextareaAutosize
                                style={{
                                  minWidth: 373,
                                  maxWidth: 373,
                                  minHeight: 65,
                                  maxHeight: 65,
                                  marginTop: 12,
                                  padding: 4,
                                  fontWeight: "normal",
                                  fontSize: 14,
                                  fontFamily: 'Open Sans',
                                  borderRadius: 2
                                }}
                                autoFocus={requirePensumStateChangeMessage}
                                aria-label={t('message')}
                                placeholder={`${t('message')}...`}
                                onChange={(e) => this.setState({ pensumMessage: e.target.value })}
                              />
                            )
                          }
                          </DialogContent>
                          <div className="buttonContainer" style={{ margin: 10 }}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={(e) => this.setPensumState(e)}
                              disabled={requirePensumStateChangeMessage && !this.state.pensumMessage}
                              style={{
                                backgroundColor: this.stateToAlternativeColor(newPensumState),
                                marginRight: 5
                              }}
                            >
                              {t(newPensumState === PENSUM_STATE_CODES.Canceled ? 'cancel-pensum' : 'confirm')}
                            </Button>
                            <Button
                              onClick={() => this.setState({ openPensumStateChangeConfirm: false, pensumMessage: undefined, newPensumState: undefined })}
                              variant="contained"
                              color="inherit"
                              size="small"
                            >
                              {t('close')}
                            </Button>
                          </div>
                        </Dialog>

                        <Divider style={{ marginTop: 5, marginBottom: 5 }} />

                        {allowSendMessage && (
                          <StyledMenuItem
                              onClick={(e) => {
                                e.stopPropagation()
                                this.setState({ openSendMessage: true })
                              }}
                          >
                            <ListItemIcon style={{ minWidth: 24 }}>
                              <EmailIcon fontSize="small" />
                            </ListItemIcon>
                            {t('send-message')}
                          </StyledMenuItem>
                        )}
                        <StyledMenuItem onClick={(e) => e.stopPropagation()}>
                          <ListItemIcon style={{ minWidth: 24 }}>
                            <GoIcon fontSize="small" />
                          </ListItemIcon>
                          <Link
                            to={`/project/${pensum.Project.ID}`}
                            style={{
                              textDecoration: 'none',
                              color: 'inherit'
                            }}
                          >
                            {t('go-to-project')}
                          </Link>
                        </StyledMenuItem>
                        {allowDelete && (
                          <StyledMenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              this.setState({ openDeletePensum: true })
                            }}
                          >
                            <ListItemIcon style={{ minWidth: 25 }}>
                              <DeletePensumIcon fontSize="small" />
                            </ListItemIcon>
                            {t('delete')}
                          </StyledMenuItem>
                        )}
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            this.handlePensumCopy();
                            this.handleClose();
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 25 }}>
                            <CopyIcon fontSize="small" />
                          </ListItemIcon>
                          {t('copy')}
                        </StyledMenuItem>
                        <StyledMenuItem
                          disabled={!allowMove}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.handlePensumMove();
                            this.handleClose();
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 25 }}>
                            <FlipToBackIcon />
                          </ListItemIcon>
                          {t('move')}
                        </StyledMenuItem>
                      </StyledMenu>
                    </div>
                    {pensum.IsStateChangePending && (
                        <CircularProgress size="1.2rem" color="secondary" style={{ float: 'right' }} />
                      )
                    }
                    <div className="costCenter">
                      <div
                        className="costCenter-text"
                        style={{
                          //border: `thin solid ${rgbToHex(pensum.Project.Supervisor.ColorRgb)}`,
                          background: `${rgbToHex(pensum.Project.Supervisor.ColorRgb)}`,
                          color: `${this.handleCostCenterTextColor(rgbToHex(pensum.Project.Supervisor.ColorRgb))}`,
                        }}>
                        {task.CostCenter}
                      </div>
                      {pensum.HasInvoice && (
                        <div className="invoice-icon">
                          <InvoiceIcon style={{
                            width: 17,
                            height: 17,
                            borderRadius: 5,
                            border: 'thin solid #fff',
                            paddingLeft: 1,
                            paddingRight: 2,
                            backgroundColor: pensum.Project.FinalInvoiceTimeUtc && 'rgb(255,0,0)'
                          }} />
                        </div>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                  <Droppable droppableId={pensum.ID} type="pensumEntry" isDropDisabled={activeDNDPensum && activeDNDPensum !== pensum.ID}>
                    {(provided, snapshot) => (
                      <div className="details" ref={provided.innerRef} {...provided.droppableProps}>
                        {(isPensumStartTimeAllowed && pensum.StartTime) && (
                          <div className={view.id === 3 ? "detail-section-bordered smaller" : "detail-section smaller"}>
                            <div className="detail">
                              <div className="icon">
                                <ClockIcon style={{ width: 19 }}/>
                              </div>
                              {getTimeFromMs(getDurationInMs(pensum.StartTime))}
                            </div>
                          </div>
                        )}
                        {view.id === 3 &&
                        <div className={ProjectActivityEntries.length > 0
                          || EmployeeEntries.length > 0
                          || EquipmentEntries.length > 0
                          || MixtureEntries.length > 0
                          || DriverEntries.length > 0
                          || pensum.Comment ? "detail-section-bordered smaller" : "detail-section smaller"}
                        >
                          <div className="detail">
                            <div className="icon">
                              <CustomerIcon style={{ width: 20 }}/>
                            </div>
                            {pensum.Project.Customer.Name}
                          </div>
                        </div>}
                        {pensum.Comment && <div className={
                          view.id === 1 && ProjectActivityEntries.length > 0
                          || (view.id !== 1 &&
                            (ProjectActivityEntries.length > 0
                            || EmployeeEntries.length > 0
                            || EquipmentEntries.length > 0
                            || MixtureEntries.length > 0
                            || DriverEntries.length > 0)) ? "detail-section-bordered" : "detail-section"
                        }>
                          <div className="detail">
                            <div className="icon">
                              <CommentIcon style={{ width: 20 }}/>
                            </div>
                            {pensum.Comment/*.slice(0, 39)}{pensum.Comment.length > 39 ? <>...</> : ""*/}
                          </div>
                        </div>}
                        {ProjectActivityEntries.length > 0
                          && <div className={view.id === 3
                          && (
                            EmployeeEntries.length > 0
                            || EquipmentEntries.length > 0
                            || MixtureEntries.length > 0
                            || DriverEntries.length > 0)
                            ? "detail-section-bordered smaller"
                            : "detail-section smaller"}>
                          {ProjectActivityEntries.map((entry, i) => {
                            const displayName = `${entry.ProjectActivity.Name} ${entry.ProjectActivity.Address !== null ? entry.ProjectActivity.Address : ""}`
                            return (
                              <Draggable key={entry.ID} draggableId={`projectActivityEntry-${pensum.ID}-${entry.ID}`} index={i} isDragDisabled={!allowEdit}>
                              {(provided, snapshot) => (
                              <div
                                className={view.id === 3 ? "detail" : "single-detail"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div style={{
                                  paddingTop: snapshot.isDragging ? 0 : 2,
                                  paddingBottom: snapshot.isDragging && 2,
                                  background: snapshot.isDragging && '#fff',
                                  border: snapshot.isDragging && 'thin solid rgba(9,30,66,.3)',
                                  borderBottom: snapshot.isDragging && 'none',
                                  borderRadius: snapshot.isDragging && 4,
                                  boxShadow: snapshot.isDragging && '0 1px 0 rgba(9,30,66,.55)'
                                }}>
                                  <div className="icon">
                                    <ActivityIcon style={{ width: 20, marginLeft: '-1px' }}/>
                                  </div>
                                  <div className={
                                    clsx(
                                      view.id !== 3 && i === 0 ? "subsided-detail" : null,
                                      i !== 0 ? "separate-detail" : null
                                    )}
                                  >
                                    {entry.ProjectActivity.Location && (
                                      <div className="activityLocation" style={{ display: "inline-block", cursor: 'pointer' }} onClick={(e) => {
                                        e.stopPropagation();
                                        window.open(
                                          `https://www.google.com/maps/search/?api=1&query=${entry.ProjectActivity.Location.coordinates[1]},${entry.ProjectActivity.Location.coordinates[0]}`,
                                          "_blank"
                                        )
                                      }}>
                                        {displayName.slice(0, 37)}{displayName.length > 37 && <>...</>}<LocationIcon style={{ fontSize: 14, marginBottom: '-2px' }} />
                                      </div>
                                    )}
                                    {!entry.ProjectActivity.Location && (
                                      <>{displayName.slice(0, 37)}{displayName.length > 37 && <>...</>}</>
                                    )}
                                    <div style={{ display: "inline-block", float: "right", height: 15, marginRight: 5 }}>
                                      {allowEdit && (
                                        <>
                                        <EditIcon
                                          className="entry-button" style={{ width: 17 }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            this.handleClick("activity", entry.ProjectActivity, entry);
                                            this.setState({
                                              deleteEntryObj: {
                                                entryId: entry.ID,
                                                entryType: "ProjectActivityEntry",
                                                entryTypeForAction: "ProjectActivityEntries",
                                                entityId: entry.ProjectActivity.ID,
                                                entry
                                              }
                                            })
                                          }}
                                        />
                                        <DeleteIcon
                                          className="entry-button" style={{ width: 17 }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            this.setState({
                                              openDeleteEntry: true,
                                              deleteEntryObj: {
                                                entryId: entry.ID,
                                                entryType: "ProjectActivityEntry",
                                                entryTypeForAction: "ProjectActivityEntries",
                                                entityId: entry.ProjectActivity.ID,
                                                entry
                                              }
                                            })
                                          }}
                                        />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              )}
                            </Draggable>
                            )
                          })}
                        </div>}
                        {view.id === 3 && EmployeeEntries.map((entry, i) => (
                          <div
                            key={i}
                            className={
                              EquipmentEntries.length > 0
                              || MixtureEntries.length > 0
                              || DriverEntries.length > 0
                              || i !== EmployeeEntries.length - 1
                              ? "detail-section-bordered smaller"
                              : "detail-section smaller"
                            }
                          >
                            <div className="detail">
                              <div className="icon">
                                <EmployeeIcon style={{ width: 20 }}/>
                              </div>
                               <span className={duplicatedhint && duplicatedhint.some(i => i.Day.includes(pensum.Day)) && duplicatedhint[duplicatedhint.map(function(e) { return e.Day; }).indexOf(pensum.Day)].duplicatedEmployeeIDs.includes(entry.Employee.ID) ? "duplicated-hint": null}>
                                {entry.Employee && entry.Employee.LastName} {entry.Employee && entry.Employee.FirstNames} </span> 
                              <div style={{ display: "inline-block", float: "right", height: 15, marginRight: 3 }}>
                                {allowEdit && (
                                  <>
                                    <EditIcon
                                      className="entry-button" style={{ width: 17 }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        this.handleClick("employee", entry.Employee, entry);
                                        this.setState({
                                          deleteEntryObj: {
                                            entryId: entry.ID,
                                            entryType: "EmployeeEntry",
                                            entryTypeForAction: "EmployeeEntries",
                                            entityId: entry.Employee.ID,
                                            entry
                                          }
                                        })
                                      }}
                                    />
                                    <DeleteIcon
                                      className="entry-button" style={{ width: 17 }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        this.setState({
                                          openDeleteEntry: true,
                                          deleteEntryObj: {
                                            entryId: entry.ID,
                                            entryType: "EmployeeEntry",
                                            entryTypeForAction: "EmployeeEntries",
                                            entityId: entry.Employee.ID,
                                            entry
                                          }
                                        })
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        {view.id === 3 && EquipmentEntries.map((entry, i) => (
                          <div className={MixtureEntries.length > 0 || DriverEntries.length > 0 || i !== EquipmentEntries.length - 1 ? "detail-section-bordered" : "detail-section"} key={i}>
                            <div className="detail">
                              <div className="icon">
                                <EquipmentIcon style={{ width: 16 }}/>
                              </div>
                              <span className={duplicatedhint && duplicatedhint.some(i => i.Day.includes(pensum.Day)) && duplicatedhint[duplicatedhint.map(function(e) { return e.Day; }).indexOf(pensum.Day)].duplicatedEquipmentIDs.includes(entry.Equipment.ID) ? "duplicated-hint": null}>
                                {entry.Equipment && entry.Equipment.Name} </span>
                              <div style={{ display: "inline-block", float: "right", height: 15, marginRight: 3 }}>
                                {allowEdit && (
                                  <>
                                    <EditIcon
                                      className="entry-button" style={{ width: 17 }}
                                      onClick={(event) => {
                                        event.stopPropagation(); this.handleClick("equipment", entry.Equipment, entry);
                                        this.setState({
                                          deleteEntryObj: {
                                            entryId: entry.ID,
                                            entryType: "EquipmentEntry",
                                            entryTypeForAction: "EquipmentEntries",
                                            entityId: entry.Equipment.ID,
                                            entry
                                          }
                                        })
                                      }}
                                    />
                                    <DeleteIcon
                                      className="entry-button" style={{ width: 17 }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        this.setState({
                                          openDeleteEntry: true,
                                          deleteEntryObj: {
                                            entryId: entry.ID,
                                            entryType: "EquipmentEntry",
                                            entryTypeForAction: "EquipmentEntries",
                                            entityId: entry.Equipment.ID,
                                            entry
                                          }
                                        })
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        {view.id === 3 && MixtureEntries.length > 0 &&
                          MixtureEntries.map((entry, i) => {
                            const lastIndex = ProjectActivityEntries ? ProjectActivityEntries.length - 1 : 0
                            if (lastIndex !== 0) i++
                            return (
                            <Draggable key={entry.ID} draggableId={`mixtureEntry-${pensum.ID}-${entry.ID}`} index={lastIndex + i} isDragDisabled={!allowEdit}>
                              {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={DriverEntries.length > 0 ? "detail-section-bordered" : "detail-section"}>
                              <div
                                className="detail"
                                style={{
                                  paddingTop: snapshot.isDragging ? 0 : 2,
                                  paddingBottom: snapshot.isDragging && 2,
                                  background: snapshot.isDragging && '#fff',
                                  border: snapshot.isDragging && 'thin solid rgba(9,30,66,.3)',
                                  borderBottom: snapshot.isDragging && 'none',
                                  borderRadius: snapshot.isDragging && 4,
                                  boxShadow: snapshot.isDragging && '0 1px 0 rgba(9,30,66,.55)'
                                }}
                              >
                                <div className="icon">
                                  <MixtureIcon style={{ width: 20 }}/>
                                </div>
                                  <div>
                                    <div style={{ display: 'inline-block', minWidth: 20, height: '100%', marginRight: 3 }}>
                                      {entry.MixtureAmount}
                                    </div>
                                    <div style={{ display: 'inline-block', width: 90, height: '100%', marginRight: 3 }}>
                                      {entry.Mixture && entry.Mixture.Name.slice(0,15)}
                                    </div>
                                    <div style={{ display: 'inline-block', width: 42, height: '100%', marginRight: 4 }}>
                                      {entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.OrderNumber}
                                    </div>
                                    <div style={{ display: 'inline-block', width: 64, marginRight: 1 }}>
                                      {
                                      entry.Mixture
                                      && entry.Mixture.Order
                                      && entry.Mixture.Order.MixingPlant
                                      && entry.Mixture.Order.MixingPlant.Name
                                      && (
                                        <div title={
                                          entry.Mixture.Order.MixingPlant.Name.length > 7
                                            && entry.Mixture.Order.MixingPlant.Name.split("[")[0]}
                                        >
                                          {entry.Mixture.Order.MixingPlant.Name.split("[")[0].slice(0, 7)}
                                          {entry.Mixture.Order.MixingPlant.Name.length > 7 && <>...</>}
                                        </div>
                                      )}
                                    </div>
                                    <div style={{ display: "inline-block", float: "right", height: 15, marginRight: 3 }}>
                                    {allowEdit && (
                                      <>
                                        <EditIcon
                                          className="entry-button" style={{ width: 17 }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            this.handleClick("mixture", entry);
                                            this.setState({
                                              deleteEntryObj: {
                                                entryId: entry.ID,
                                                entryType: "MixtureEntry",
                                                entryTypeForAction: "MixtureEntries",
                                                entityId: entry.Mixture.ID,
                                                entry
                                              }
                                            })
                                          }}
                                        />
                                        <DeleteIcon
                                          className="entry-button" style={{ width: 17 }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            this.setState({
                                              openDeleteEntry: true,
                                              deleteEntryObj: {
                                                entryId: entry.ID,
                                                entryType: "MixtureEntry",
                                                entryTypeForAction: "MixtureEntries",
                                                entityId: entry.Mixture.ID,
                                                entry
                                              }
                                            })
                                          }}
                                        />
                                      </>
                                    )}
                                    </div>
                                  </div>
                                  {/*<br/>*/}
                                  <div style={{ color: "rgb(115,115,115)", marginLeft: 27, marginBottom: 2 }}>{entry.Comment}</div>
                              </div>
                            </div>
                            )}
                          </Draggable>
                        )})}
                        {view.id === 3 &&
                          <div className="detail-section">
                            <div className="detail">
                              <div style={{ marginBottom: 3 }}>
                                <div className="icon">
                                  <DriverIcon style={{ width: 20 }}/>
                                </div>
                                  <div style={{ textDecoration: "underline", marginBottom: 5 }}>
                                    {DriverEntriesSum} / {pensum.TotalMixtureAmount}
                                  </div>
                              </div>
                              {DriverEntries.length > 0 && DriverEntries.sort((a, b) => {
                                let formattedTimeA = a.StartTime !== null ? a.StartTime.replace("PT","").replace("H",":").replace("M","").replace("S", "") : ""
                                if (
                                  a.StartTime && a.StartTime.includes("M")
                                  && formattedTimeA.split(":")[1]
                                  && formattedTimeA.split(":")[1].length === 1
                                )
                                  formattedTimeA = `${formattedTimeA.split(":")[0]}:0${formattedTimeA.split(":")[1]}`
                                if (
                                  a.StartTime
                                  && a.StartTime.includes("H")
                                  && !a.StartTime.includes("M")
                                  && formattedTimeA.split(":")[1] === ""
                                )
                                  formattedTimeA = `${formattedTimeA.split(":")[0]}:00`
                                if (!formattedTimeA.includes(":")) {
                                  if (formattedTimeA.length === 2) {
                                    formattedTimeA = `00:${formattedTimeA}`
                                  } else if (formattedTimeA.length === 1) {
                                    formattedTimeA = `00:0${formattedTimeA}`
                                  }
                                }

                                let formattedTimeB = b.StartTime !== null ? b.StartTime.replace("PT","").replace("H",":").replace("M","").replace("S", "") : ""
                                if (
                                  b.StartTime
                                  && b.StartTime.includes("M")
                                  && formattedTimeB.split(":")[1]
                                  && formattedTimeB.split(":")[1].length === 1
                                )
                                  formattedTimeB = `${formattedTimeB.split(":")[0]}:0${formattedTimeB.split(":")[1]}`
                                if (b.StartTime && b.StartTime.includes("H") && !b.StartTime.includes("M") && formattedTimeB.split(":")[1] === "")
                                  formattedTimeB = `${formattedTimeB.split(":")[0]}:00`
                                if (!formattedTimeB.includes(":")) {
                                  if (formattedTimeB.length === 2) {
                                    formattedTimeB = `00:${formattedTimeB}`
                                  } else if (formattedTimeB.length === 1) {
                                    formattedTimeB = `00:0${formattedTimeB}`
                                  }
                                }

                                if (new Date("2000-01-01 " + formattedTimeA) < new Date("2000-01-01 " + formattedTimeB)) {
                                  return -1;
                                }
                                if (new Date("2000-01-01 " + formattedTimeA) > new Date("2000-01-01 " + formattedTimeB)) {
                                  return 1;
                                }
                                return a.SortOrder - b.SortOrder
                              }).map((entry, i) => {
                                const { MixingPlant, Driver: { Name, IsCommunicationPossible }, Payload, StartTime } = entry
                                const CompanyShortName = entry.Driver.DriverCompany ? entry.Driver.DriverCompany.ShortName : '';
                                let formattedTime = StartTime !== null ? StartTime.replace("PT","").replace("H",":").replace("M","").replace("S", "") : ""
                                if (StartTime && StartTime.includes("M") && formattedTime.split(":")[1] && formattedTime.split(":")[1].length === 1)
                                  formattedTime = `${formattedTime.split(":")[0]}:0${formattedTime.split(":")[1]}`
                                if (StartTime && StartTime.includes("H") && !StartTime.includes("M") && formattedTime.split(":")[1] === "")
                                  formattedTime = `${formattedTime.split(":")[0]}:00`
                                if (!formattedTime.includes(":")) {
                                  if (formattedTime.length === 2) {
                                    formattedTime = `0:${formattedTime}`
                                  } else if (formattedTime.length === 1) {
                                    formattedTime = `0:0${formattedTime}`
                                  }
                                } else {
                                  if (formattedTime.split(":")[0][0] === "0") {
                                    formattedTime = formattedTime.substring(1)
                                  }
                                }

                                const displayName = `${Name} ${CompanyShortName}`
                                let displayNameFormatLengthLimit = 13
                                if (displayName.indexOf(" ") !== -1) {
                                  if (
                                    displayName.length >= displayNameFormatLengthLimit
                                    && displayName.slice(displayName.indexOf(" "), displayName.length-1).indexOf(" ") !== -1
                                  ) {
                                    displayNameFormatLengthLimit = 12
                                  }
                                }

                                const lastActivityIndex = ProjectActivityEntries ? ProjectActivityEntries.length : 0
                                const lastMixtureIndex = MixtureEntries ? MixtureEntries.length : 0
                                i += lastActivityIndex
                                i += lastMixtureIndex

                                return (
                                  <Draggable key={entry.ID} draggableId={`driverEntry-${pensum.ID}-${entry.ID}`} index={i} isDragDisabled={!allowEdit}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className="d-row">
                                          <div className="driverCheckbox" style={{ width: 20, float: 'left', marginRight: 5 }}>
                                            <Checkbox
                                              checked={entry.ConfirmedByDriver}
                                              onChange={(e) => this.confirmDriver(e, entry)}
                                              onClick={(e) => e.stopPropagation()}
                                              inputProps={{ 'aria-label': 'primary checkbox' }}
                                              color="primary"
                                              size="small"
                                              style={{ padding: 0, marginRight: 8 }}
                                              disabled={!allowEdit}
                                            />
                                            {/*<DNDIcon style={{ fontSize: 17, marginBottom: snapshot.isDragging && (i === 0 ? "-8px" : "-6px") }} />*/}
                                          </div>
                                          <div style={{
                                            /* isDragging */
                                            paddingTop: snapshot.isDragging ? 0 : 2,
                                            paddingBottom: snapshot.isDragging && 2,
                                            background: snapshot.isDragging && '#fff',
                                            border: snapshot.isDragging && 'thin solid rgba(9,30,66,.3)',
                                            borderBottom: snapshot.isDragging && 'none',
                                            borderRadius: snapshot.isDragging && 4,
                                            boxShadow: snapshot.isDragging && '0 1px 0 rgba(9,30,66,.55)'
                                          }}>
                                            <div style={{
                                              display: "inline-block",
                                              minWidth: 20,
                                              height: 15,
                                              marginRight: 3,
                                              border: !IsCommunicationPossible && "thin solid #ffa9a9",
                                              background: !IsCommunicationPossible && "#bb9292b3",
                                              borderRadius: !IsCommunicationPossible && 2
                                            }}>
                                              {Payload !== undefined && Payload !== null ? Payload : ''}
                                            </div>
                                            <div
                                              style={{ display: "inline-block", width: 90, marginRight: 3 }}
                                              title={displayName.length > displayNameFormatLengthLimit && displayName}
                                            >
                                              {displayName.slice(0, displayNameFormatLengthLimit)}{displayName.length > displayNameFormatLengthLimit && <>...</>}
                                            </div>
                                            <div style={{ display: "inline-block", width: 30, marginRight: 3 }}>
                                              {formattedTime}
                                            </div>
                                            {MixingPlant && MixingPlant.Name &&
                                              <div
                                                title={MixingPlant.Name.length > 11 && MixingPlant.Name}
                                                style={{ display: 'inline-block' }}
                                              >
                                                {MixingPlant.Name.slice(0, 11)}
                                                {MixingPlant.Name.length > 11 && <>...</>}
                                              </div>
                                            }
                                            <div style={{ display: "inline-block", float: "right", height: 15, marginRight: 3 }}>
                                            {allowEdit && (
                                              <>
                                                <EditIcon
                                                  className="entry-button" style={{ width: 17 }}
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    this.handleClick("driver", entry);
                                                    this.setState({
                                                      deleteEntryObj: {
                                                        entryId: entry.ID,
                                                        entryType: "DriverEntry",
                                                        entryTypeForAction: "DriverEntries",
                                                        entityId: entry.Driver.ID,
                                                        entry
                                                      }
                                                    })
                                                  }}
                                                />
                                                <DeleteIcon
                                                  className="entry-button" style={{ width: 17 }}
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    this.setState({
                                                      openDeleteEntry: true,
                                                      deleteEntryObj: {
                                                        entryId: entry.ID,
                                                        entryType: "DriverEntry",
                                                        entryTypeForAction: "DriverEntries",
                                                        entityId: entry.Driver.ID,
                                                        entry
                                                      }
                                                    })
                                                  }}
                                                />
                                              </>
                                            )}
                                            </div>
                                            <br/>
                                            <div style={{
                                              color: "rgb(75,75,75)",
                                              marginLeft:
                                                String(DriverEntriesSum).length === 3 ? 24 :
                                                String(DriverEntriesSum).length === 2 ? 22 :
                                                String(DriverEntriesSum).length === 1 && Number(DriverEntriesSum) !== 0 ? 18 : 12
                                            }}>{entry.Comment}</div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              })}
                            </div>
                          </div>
                        }
                        {provided.placeholder}
                      </div>
                    )}
                </Droppable>
              </div>
            {open &&
              <Modal
                open={open}
                onClose={this.closePopup}
              >
                <div className={openForEditEntry ? "edit-popup single" : "edit-popup"}>
                  {showFirstAssignSection && <div
                    onMouseEnter={() => this.setState({ hoveringAssignEntity: !openForEditEntry })}
                    onMouseLeave={() => this.setState({ hoveringAssignEntity: false })}
                    className="popup-menu"
                    onClick={() => this.setState({ openAssignEntity: !openForEditEntry ? !this.state.openAssignEntity : true })}
                    style={{
                      backgroundColor: hoveringAssignEntity ? '#182f63' : '#223b67',
                      textShadow: hoveringAssignEntity ? "0px 2px 2px rgb(0, 0, 0)" : null,
                      marginBottom: openAssignEntity ? 15 : 0,
                      paddingTop: 2,
                      paddingBottom: 3,
                      paddingRight: 4,
                      paddingLeft: 14,
                      borderBottom: !openAssignEntity ? "thin solid #ededed" : null,
                      fontFamily: 'Open Sans',
                      color: hoveringAssignEntity ? '#fcfcfc' : 'white',
                      letterSpacing: 0.05,
                      cursor: !openForEditEntry ? 'pointer' : 'default'
                    }}
                  >
                    <div style={{ fontSize: 17.5 }}>
                      {openForEditEntry ? <>{t('edit')}</> : <>{t('assign')}</>}
                      {!openForEditEntry && <div style={{ float: "right", paddingTop: 10 }}>
                        {openAssignEntity && hoveringAssignEntity
                        ? (<ArrowDropDownIcon size="small"/>)
                        : !openAssignEntity && !hoveringAssignEntity
                        ? (<ArrowDropDownIcon size="small"/>)
                        : (<ArrowDropUpIcon size="small" />)}
                      </div>}
                    </div>
                    <div style={{ paddingLeft: 11, fontSize: 12 }}>
                      {!openForEditEntry && `${t('project-activity')}, ${t('employee')}, ${t('equipment')}`}
                      {openForEditEntry && selectedProjectActivity && t('project-activity')}
                      {openForEditEntry && selectedEmployee && t('employee')}
                      {openForEditEntry && selectedEquipment && t('equipment')}
                    </div>
                  </div>}
                  <form onSubmit={!saveIsDisabled && this.handleSave}>
                    {/* Assign ProjectActivity, Employee, Equipment */}
                    {openAssignEntity ? (
                      <Grid container>
                        {!(openForEditEntry && !selectedProjectActivity) && <Grid item xs={12} className="selectGrid">
                          <div className="single-entity">
                            <FormControl
                              className="select-container"
                              variant="outlined"
                            >
                              <ReactSelect
                                value={
                                  selectedProjectActivity
                                  ? {
                                    value: selectedProjectActivity,
                                    label: `${selectedProjectActivity.Name}`}
                                  : null
                                }
                                className="basic-multi-select"
                                placeholder={t('project-activity')}
                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, "selectedProjectActivity")}
                                isDisabled={openForEditEntry && !selectedProjectActivity}
                                isClearable={!openForEditEntry}
                                styles={optionStyles}
                                options={
                                  projectActivities
                                    .filter(obj => obj.Project.ID === pensum.Project.ID)
                                    .map((activity) => {
                                      return { value: activity, label: `${activity.Name}`}
                                    })
                                }
                              />
                            </FormControl>
                          </div>
                        </Grid>}
                        {!(openForEditEntry && !selectedEmployee) && <Grid item xs={12} className="selectGrid">
                          <div className="single-entity">
                            <FormControl
                              className="select-container"
                              variant="outlined"
                            >
                              <ReactSelect
                                value={
                                  selectedEmployee
                                  ? {
                                    value: selectedEmployee,
                                    label: `${selectedEmployee.LastName} ${selectedEmployee.FirstNames || ''}`.trim()}
                                  : null
                                }
                                className="basic-multi-select"
                                placeholder={t('employee')}
                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, "selectedEmployee")}
                                isDisabled={openForEditEntry && !selectedEmployee}
                                isClearable={!openForEditEntry}
                                styles={optionStyles}
                                options={
                                  employee.map((employee) => {
                                    return { value: employee, label: `${employee.LastName} ${employee.FirstNames || ''}`.trim()}
                                  })
                                }
                              />
                            </FormControl>
                          </div>
                        </Grid>}
                        {!(openForEditEntry && !selectedEquipment) &&<Grid item xs={12} className="selectGrid" style={{ marginBottom: 3 }}>
                          <div className="single-entity">
                            <FormControl
                              className="select-container"
                              variant="outlined"
                            >
                              <ReactSelect
                                value={
                                  selectedEquipment
                                  ? {
                                    value: selectedEquipment,
                                    label: `${selectedEquipment.Name}`}
                                  : null
                                }
                                className="basic-multi-select"
                                placeholder={t('equipment')}
                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, "selectedEquipment")}
                                isDisabled={openForEditEntry && !selectedEquipment}
                                isClearable={!openForEditEntry}
                                styles={optionStyles}
                                options={
                                  equipment.map((equipment) => {
                                    return { value: equipment, label: `${equipment.Name}`}
                                  })
                                }
                              />
                            </FormControl>
                          </div>
                        </Grid>}
                      </Grid>
                    ) : null}
                    {showSecondAssignSection && <div
                      onMouseEnter={() => this.setState({ hoveringAssignMixtureEntry: !openForEditEntry })}
                      onMouseLeave={() => this.setState({ hoveringAssignMixtureEntry: false })}
                      className="popup-menu"
                      style={{
                        backgroundColor: hoveringAssignMixtureEntry ? '#182f63' : '#223b67',
                        textShadow: hoveringAssignMixtureEntry ? "0px 2px 2px rgb(0, 0, 0)" : null,
                        marginTop: openAssignEntity ? 15 : 0,
                        marginBottom: openAssignMixtureEntity ? 17 : 0,
                        paddingTop: 2,
                        paddingBottom: 3,
                        paddingLeft: 14,
                        paddingRight: 4,
                        borderBottom: !openAssignMixtureEntity ? "thin solid #ededed" : null,
                        color: 'white',
                        fontFamily: 'Open Sans',
                        fontSize: 17,
                        letterSpacing: 0.05,
                        cursor: !openForEditEntry ? 'pointer' : 'default'
                      }}
                      onClick={() => this.setState({ openAssignMixtureEntity: !openForEditEntry ? !this.state.openAssignMixtureEntity : true })}
                    >
                      <div style={{ fontSize: 17.5 }}>
                        {openForEditEntry ? <>{t('edit')}</> : <>{t('assign')}</>}
                        {!openForEditEntry && <div style={{ float: "right", paddingTop: 10 }}>
                          {openAssignMixtureEntity && hoveringAssignMixtureEntry
                          ? (<ArrowDropDownIcon size="small"/>)
                          : !openAssignMixtureEntity && !hoveringAssignMixtureEntry
                          ? (<ArrowDropDownIcon size="small"/>)
                          : (<ArrowDropUpIcon size="small" />)}
                        </div>}
                      </div>
                      <div style={{ paddingLeft: 11, fontSize: 12 }}>
                        {t('asphalt-mixture')}
                      </div>
                    </div>}
                    {/* Assign Mixture */}
                    {openAssignMixtureEntity ? (
                      <Grid container spacing={2} style={{ maxWidth: 420, margin: 0 }}>
                          <FormControl className="formControl-wrapper">
                            <div style={{ width: '50%', display: 'inline-block' }}>
                              <ReactSelect
                                value={
                                  selectedMixingPlantObj
                                  ? {value: selectedMixingPlantObj.ID, label: `${selectedMixingPlantObj.Name}`}
                                  : null
                                }
                                defaultValue={
                                  pensum.Project.DefaultMixingPlant !== null
                                  && !pensum.Project.DefaultMixingPlant.IsDeleted
                                    ? {
                                      value: this.getMixingPlantByID(pensum.Project.DefaultMixingPlant.ID).ID,
                                      label: this.getMixingPlantByID(pensum.Project.DefaultMixingPlant.ID).Name
                                    }
                                    : selectedMixingPlantObj
                                      ? {value: selectedMixingPlantObj.ID, label: `${selectedMixingPlantObj.Name}`}
                                      : null
                                }
                                className="basic-multi-select"
                                onChange={this.handleMixingPlantChange}
                                placeholder={t('asphalt-mixing-plant')}
                                isDisabled={openForEditEntry && !selectedMixingPlant}
                                isClearable
                                styles={optionStyles}
                                options={
                                  mixingPlants.map((mix) => {
                                    return { value: mix.ID, label: `${mix.Name}`}
                                  })
                                }
                              />
                            </div>
                            <div style={{ width: 'calc(50% - 22px)', marginLeft: 5, display: 'inline-block' }}>
                              <ReactSelect
                                value={
                                  selectedFilteredMixtureOrderObj
                                  ? {
                                    value: selectedFilteredMixtureOrderObj.ID,
                                    label: `${selectedFilteredMixtureOrderObj.OrderNumber} ${selectedFilteredMixtureOrderObj.Description}`}
                                  : null
                                }
                                className="basic-multi-select"
                                placeholder={t('asphalt-mixture-order')}
                                onChange={this.handleMixtureOrderChange}
                                noOptionsMessage={() => (<div><RemoveIcon style={{color: 'rgb(33, 56, 223'}}/><div><b>{t('select-option-no-selection')}</b></div></div>)}
                                isDisabled={!selectedMixingPlant}
                                isClearable
                                styles={optionStyles}
                                options={
                                  filteredMixtureOrders.map((mix) => {
                                    return { value: mix.OrderNumber, label: `${mix.OrderNumber} ${mix.Description}`}
                                  })
                                }
                              />
                            </div>
                          </FormControl>
                          <FormControl className="formControl-wrapper">
                            <div style={{ width: '50%', marginTop: 8, display: 'inline-block' }}>
                              <ReactSelect
                                value={
                                  selectedMixtureObj
                                  ? {value: selectedMixtureObj.ID, label: `${selectedMixtureObj.Name}`}
                                  : null
                                }
                                className="basic-multi-select"
                                placeholder={t('asphalt-mixture')}
                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, "selectedMixture")}
                                isDisabled={(selectedMixingPlant !== null || selectedMixingPlant === null) && selectedMixtureOrderNumber === null}
                                isClearable
                                styles={optionStyles}
                                options={
                                  filteredMixtures.map((mix) => {
                                    return { value: mix.ID, label: `${mix.Name}`}
                                  })
                                }
                              />
                            </div>
                            <div
                              className="input-field"
                              style={{
                                width: 'calc(50% - 22px)', marginLeft: 5,
                                display: 'inline-block', marginTop: 8
                              }}>
                              <NumberInput
                                id="selectedMixtureQuantity"
                                handleChange={this.handleChange}
                                value={selectedMixtureQuantity}
                                placeholder={t('quantity')}
                                disabled={selectedMixture === null || selectedMixtureOrderNumber === null || selectedMixture === ""}
                                error={selectedMixture !== null && (selectedMixtureQuantity === "" || selectedMixtureQuantity === null
                                  || Number(selectedMixtureQuantity) < 0 || String(selectedMixtureQuantity).charAt(0) === "-")}
                                inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">to</InputAdornment>
                                  )
                                }}
                                {...this.props}
                              />
                            </div>
                          </FormControl>
                        <Grid item xs={12} sm={12}>
                          <TextareaAutosize
                            style={{
                              marginLeft: 10,
                              minWidth: 373,
                              maxWidth: 373,
                              maxHeight: 120,
                              marginTop: 4,
                              padding: 4,
                              fontWeight: "normal",
                              fontSize: 14,
                              fontFamily: 'Open Sans',
                              borderRadius: 2
                            }}
                            defaultValue={mixtureComment}
                            id="mixtureComment"
                            aria-label={t('comment')}
                            rowsMin={3}
                            placeholder={`${t('comment')}...`}
                            onChange={this.handleMixtureCommentChange}
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                    {showThirdAssignSection && <div
                      onMouseEnter={() => this.setState({ hoveringAssignDriverEntry: !openForEditEntry })}
                      onMouseLeave={() => this.setState({ hoveringAssignDriverEntry: false })}
                      className="popup-menu"
                      style={{
                        backgroundColor: hoveringAssignEntity ? '#182f63' : '#223b67',
                        textShadow: hoveringAssignDriverEntry ? "0px 2px 2px rgb(0, 0, 0)" : null,
                        marginTop: openAssignMixtureEntity ? 8 : 0,
                        marginBottom: openAssignDriverEntity ? 17 : 0,
                        paddingTop: 2,
                        paddingBottom: 3,
                        paddingLeft: 14,
                        paddingRight: 4,
                        color: 'white',
                        fontFamily: 'Open Sans',
                        fontSize: 17,
                        letterSpacing: 0.05,
                        cursor: !openForEditEntry ? 'pointer' : 'default'
                      }}
                      onClick={() => this.setState({ openAssignDriverEntity: !openForEditEntry ? !this.state.openAssignDriverEntity : true })}
                    >
                      <div style={{ fontSize: 17.5 }}>
                        {openForEditEntry ? <>{t('edit')}</> : <>{t('assign')}</>}
                        {!openForEditEntry && <div style={{ float: "right", paddingTop: 10 }}>
                          {openAssignDriverEntity && hoveringAssignDriverEntry
                            ? (<ArrowDropDownIcon size="small"/>)
                            : !openAssignDriverEntity && !hoveringAssignDriverEntry
                            ? (<ArrowDropDownIcon size="small"/>)
                            : (<ArrowDropUpIcon size="small" />)}
                        </div>}
                      </div>
                      <div style={{ paddingLeft: 11, fontSize: 12 }}>
                        {t('lkw-driver')}
                      </div>
                    </div>}
                    {/* Assign Driver */}
                    {openAssignDriverEntity ? (
                      <Grid container style={{ maxWidth: 420, margin: 0 }}>
                        <FormControl className="formControl-wrapper">
                          <div style={{ width: '50%', display: 'inline-block' }}>
                            <ReactSelect
                              value={
                                selectedDriverObj
                                ? {value: selectedDriverObj.ID, label: `${selectedDriverObj.DriverCompany ? selectedDriverObj.DriverCompany.ShortName : ''} ${selectedDriverObj.Name}`}
                                : null
                              }
                              className="basic-multi-select"
                              onChange={this.handleSelectedDriverChange}
                              placeholder={t('lkw-driver')}
                              isDisabled={openForEditEntry && !selectedDriver}
                              isClearable
                              styles={optionStyles}
                              options={
                                Array.from(drivers)
                                  .filter(driver => !driver.IsDeleted)
                                  .sort((a, b) => {
                                    var result = a.DriverCompany && b.DriverCompany ? a.DriverCompany.ShortName.localeCompare(b.DriverCompany.ShortName, undefined, {sensitivity: 'base'}) : 0;
                                    if (result !== 0)
                                      return result;
                                    result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
                                    if (result !== 0)
                                      return result;
                                    return a.SortOrder - b.SortOrder
                                  })
                                  .map((driver) => {
                                    if (driver.ID === null) {
                                      return { value: null, label: ''}
                                    }
                                    return { value: driver.ID, label: `${driver.DriverCompany ? driver.DriverCompany.ShortName : ''} ${driver.Name}`}
                                  })
                              }
                            />
                          </div>
                          <div className="input-field" style={{ width: 'calc(50% - 22px)', marginLeft: 5, display: 'inline-block' }}>
                            <TextField
                              id="time"
                              type="time"
                              value={formattedStartTime}
                              defaultValue="06:30"
                              onChange={this.handleDriverStartTimeChange}
                              disabled={openForEditEntry && !selectedDriver}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: { textAlign: 'right' },
                                step: 300, // 5 min
                              }}
                            />
                          </div>
                        </FormControl>
                        <FormControl className="formControl-wrapper">
                          <div className="input-field" style={{ width: '50%', marginTop: 8, display: 'inline-block' }}>
                            <ReactSelect
                              value={
                                selectedDriverMixingPlantObj
                                ? {value: selectedDriverMixingPlantObj.ID, label: `${selectedDriverMixingPlantObj.Name}`}
                                : null
                              }
                              className="basic-multi-select"
                              onChange={(selectedOption) => this.handleSelectChange(selectedOption, "selectedDriverMixingPlant")}
                              placeholder={t('asphalt-mixing-plant')}
                              isDisabled={openForEditEntry && !selectedDriver}
                              isClearable
                              styles={optionStyles}
                              options={mixingPlants.map((mix) => {
                                return { value: mix.ID, label: `${mix.Name}`}
                              })}
                            />
                          </div>
                          <div className="input-field" style={{ width: 'calc(50% - 22px)', marginLeft: 5, display: 'inline-block' }}>
                            <NumberInput
                              id="selectedDriverPayload"
                              value={selectedDriverPayload}
                              handleChange={this.handleChange}
                              placeholder={t('default-payload')}
                              disabled={openForEditEntry && !selectedDriver}
                              error={selectedDriver && selectedDriverMixingPlant && (selectedDriverPayload === "" || selectedDriverPayload === null
                                || Number(selectedDriverPayload) < 0 || String(selectedDriverPayload).charAt(0) === "-")}
                              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">to</InputAdornment>
                                )
                              }}
                              {...this.props}
                            />
                          </div>
                        </FormControl>
                        {!showFirstAssignSection && !showSecondAssignSection && (
                        <div className="input-field" style={{ width: '100%', marginLeft: 15, marginRight: 15, display: 'inline-block' }}>
                        <NumberInput
                              id="interval"
                              handleChange={this.handleDriverIntervalChange}
                              placeholder={t('interval of succeeding vehicles')}
                              disabled={openForEditEntry && !selectedDriver}
                              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">min</InputAdornment>
                                )
                              }}
                              style={{textAlign: 'center'}}
                              {...this.props}
                            />
                            </div>
                        )}
                        <Grid item xs={12} sm={12}>
                          <TextareaAutosize
                            style={{
                              marginLeft: 17,
                              minWidth: 373,
                              maxWidth: 373,
                              maxHeight: 76,
                              marginTop: 12,
                              padding: 4,
                              fontWeight: "normal",
                              fontSize: 14,
                              fontFamily: 'Open Sans',
                              borderRadius: 2
                            }}
                            defaultValue={driverComment}
                            id="driverComment"
                            aria-label={t('comment')}
                            rowsMin={3}
                            placeholder={`${t('comment')}...`}
                            onChange={this.handleDriverCommentChange}
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                    <div className="buttonContainer"
                      style={{
                        borderTop: openAssignDriverEntity ? 'thin solid #d4d4d4' : null,
                        marginTop: openAssignDriverEntity ? '20px' : '10px'
                      }}
                    >
                      <Tooltip title={t('save')}>
                        <Button
                          type="submit"
                          variant="text"
                          className="edit-popup-button"
                          color="primary"
                          size="medium"
                          startIcon={
                            <SaveIcon
                              style={{
                                marginLeft: 10,
                                color:
                                  (
                                    ((selectedMixtureQuantity || selectedDriverPayload) && !saveIsDisabled)
                                    || (!saveIsDisabled && !firstAssignSectionIsEmpty)
                                  ) && "#0fbb53"
                              }}
                            />}
                          disabled={saveIsDisabled}
                        />
                      </Tooltip>
                      <Tooltip title={t('close')}>
                        <Button
                          onClick={this.closePopup}
                          variant="text"
                          color="inherit"
                          size="medium"
                          className="edit-popup-button"
                          startIcon={<CloseIcon style={{ marginLeft: 10 }} />}
                        />
                      </Tooltip>
                      {openForEditEntry && <Tooltip title={t('delete')}>
                        <Button
                          onClick={() => this.setState({ openDelete: !this.state.openDelete })}
                          variant="text"
                          color="secondary"
                          size="medium"
                          className="edit-popup-button"
                          startIcon={<DeleteIcon style={{ marginLeft: 10 }} />}
                        />
                      </Tooltip>}
                      {
                        openDelete ? (
                          <div style={{ marginTop: 5 }}>
                            <Button
                              onClick={() => this.setState({ openDelete: !this.state.openDelete })}
                              style={{ marginRight: 2 }}
                              variant="contained"
                              color="primary"
                              size="small"
                            >
                              {t('cancel')}
                            </Button>
                            <Button
                              onClick={this.handleDeleteEntry}
                              variant="contained"
                              color="secondary"
                              size="small"
                            >
                              {t('delete')}
                            </Button>
                          </div>
                        ) : null
                      }
                    </div>
                  </form>
                </div>
              </Modal>
            }
            {this.state.openDeletePensum &&
              <Modal open={this.state.openDeletePensum} onClose={(event) => { event.stopPropagation(); this.setState({ openDeletePensum: false }); this.handleClose() }} className="modal">
                <div className="delete-popup">
                  <div style={{ width: "100%", textAlign: "center", color: "#242424" }}>
                    <h2 style={{ fontFamily: 'Open Sans' }}>{t('deleteText')}</h2>
                  </div>
                  <div className="buttonContainer">
                    <Button
                      onClick={(event) => { event.stopPropagation(); this.setState({ openDeletePensum: false }); this.handleClose() }}
                      style={{ marginRight: 7 }}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={(event) => { event.stopPropagation(); this.handleDelete(); this.handleClose() }}
                      variant="contained"
                      color="secondary"
                      size="small"
                    >
                      {t('delete')}
                    </Button>
                  </div>
                </div>
              </Modal>
            }
            {openDeleteEntry &&
              <Modal open={openDeleteEntry} onClose={this.closeDeleteEntryPopup} className="modal">
                <div className="delete-popup">
                  <div style={{ width: "100%", textAlign: "center", color: "#242424" }}>
                    <h2 style={{ fontFamily: 'Open Sans' }}>{t('deleteText')}</h2>
                  </div>
                  <div className="buttonContainer">
                    <Button
                      onClick={this.closeDeleteEntryPopup}
                      style={{ marginRight: 7 }}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={this.handleDeleteEntry}
                      variant="contained"
                      color="secondary"
                      size="small"
                    >
                      {t('delete')}
                    </Button>
                  </div>
                </div>
              </Modal>
            }
            {openHeaderPopup &&
              <Modal open={openHeaderPopup} onClose={this.closeHeaderPopup} className="modal">
                <div className="pensum-header-popup">
                  <FormControl style={{ marginBottom: 20 }}>
                    <TextField
                      onChange={allowCommentChange && this.handleChange}
                      value={pensumComment}
                      id="pensumComment"
                      name="pensumComment"
                      label={t('comment')}
                      InputLabelProps={{
                        style: {
                          fontSize: 14
                        }
                      }}
                      InputProps={{ style: { color: !allowCommentChange && "rgb(100,100,100)" } }}
                      inputProps={{ maxLength: 255 }}
                      multiline
                    />
                  </FormControl>
                  {isPensumStartTimeAllowed && (
                    <FormControl style={{ marginBottom: 20 }}>
                      <InputLabel className="transformed-label" style={{ color: !isValidStartTime && 'red' }}>
                        {t('time')}
                      </InputLabel>
                      <TextField
                        id="pensumStartTime"
                        type="time"
                        value={pensumStartTime}
                        defaultValue="00:00"
                        onChange={this.handlePensumStartTimeChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          style: {
                            width: 185,
                            color: !isValidStartTime && 'red',
                            textAlign: 'right'
                          },
                          step: 300, // 5 min
                        }}
                      />
                    </FormControl>
                  )}
                  <FormControl>
                    <InputLabel className="transformed-label">
                        {t('workDuration')}
                    </InputLabel>
                    <TextField
                      id="workDuration"
                      type="time"
                      value={workDuration}
                      defaultValue="00:00"
                      onChange={allowEdit && this.handleWorkDurationChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ style: { color: !allowEdit && "rgb(100,100,100)" } }}
                      inputProps={{
                        style: { textAlign: 'right', width: 185 },
                        step: 300, // 5 min
                      }}
                    />
                  </FormControl>
                  <FormControl style={{ marginTop: 20 }}>
                    <InputLabel className="transformed-label">
                        {t('totalTons')}
                    </InputLabel>
                    <NumberInput
                      id="totalTons"
                      handleChange={allowEdit && this.handleChange}
                      value={totalTons}
                      error={totalTons === "" || totalTons === null || Number(totalTons) < 0 || String(totalTons).charAt(0) === "-"}
                      inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
                      InputProps={{
                        style: { color: !allowEdit && "rgb(100,100,100)" },
                        endAdornment: (
                          <InputAdornment position="end">to</InputAdornment>
                        )
                      }}
                      {...this.props}
                    />
                  </FormControl>
                  <div className="buttonContainer" style={{ marginTop: 35 }}>
                    <Button
                      onClick={this.handleHeaderPopupChange}
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={headerPopupSaveIsDisabled}
                    >
                      {t('save')}
                    </Button>
                  </div>
                </div>
              </Modal>
            }
            {this.state.openSendMessage &&
              <Modal
                className="modal"
                open={this.state.openSendMessage}
                onClose={() => this.setState({
                  openSendMessage: false,
                  pensumMessage: undefined
                })}
              >
                <div className="sendMessage-popup">
                  <form onSubmit={this.sendMessage}>
                    <TextareaAutosize
                      style={{
                        marginLeft: 17,
                        minWidth: 373,
                        maxWidth: 373,
                        minHeight: 65,
                        maxHeight: 65,
                        marginTop: 12,
                        padding: 4,
                        fontWeight: "normal",
                        fontSize: 14,
                        fontFamily: 'Open Sans',
                        borderRadius: 2
                      }}
                      autoFocus
                      aria-label={t('message')}
                      placeholder={`${t('message')}...`}
                      onChange={(e) => this.setState({ pensumMessage: e.target.value })}
                    />
                    <div className="buttonContainer" style={{ marginTop: 5 }}>
                      <Button
                        type="submit"
                        style={{ marginRight: 7 }}
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        {t('send')}
                      </Button>
                      <Button
                        onClick={() => this.setState({
                          openSendMessage: false,
                          pensumMessage: undefined
                        })}
                        variant="contained"
                        color="inherit"
                        size="small"
                      >
                        {t('cancel')}
                      </Button>
                    </div>
                  </form>
                </div>
              </Modal>
            }
          </Container>
        )}
      </Draggable>
    )
  }
}

export default withStyles(styles)(Pensum);