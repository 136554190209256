import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {

    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, useSeparators, readOnly, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      style={{ ...props.style, cursor: props.disabled ? 'not-allowed' : 'auto' }}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalSeparator={useSeparators && ","}
      thousandSeparator={useSeparators && "."}
      isNumericString={useSeparators}
      tabIndex={readOnly && "-1"}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function NumberInput(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    [props.id]: null,
  });

  const handleChange = name => event => {
    const { handleChange } = props

    let numberValue = `${event.target.value}`
    if (numberValue.includes(".") && numberValue.split(".")[1].length > 0) {
      numberValue = `${numberValue.split(".")[0]}.${numberValue.split(".")[1].substring(0, 2)}`
    }

    const eventObj = {
      target: {
        name: props.id,
        value: numberValue
      }
    }
    handleChange(eventObj)
    setValues({
      ...values,
      [name]: numberValue,
    });
  };

  return (
    <div className={classes.root}>
      <TextField
        value={props.value}
        label={props.label}
        style={props.style}
        onChange={handleChange(props.id)}
        id={props.id}
        useSeparators={props.useSeparators}
        InputProps={{
          ...props.InputProps, inputComponent: NumberFormatCustom
        }}
        placeholder={props.placeholder}
        inputProps={props.inputProps}
        disabled={props.disabled}
        error={props.error}
      />
    </div>
  );
}
