export const IAS_DEV_SERVICE_URI = "https://iasdev.components.at/odata/";
export const IAS_DEV_URI = "https://iasdev.components.at";

export const INVOICED_TYPES = {
  "INVOICED": 1,
  "NOT_INVOICED": 2,
}

export const CANCELLED_TYPES = {
  "CANCELLED": 1,
  "NOT_CANCELLED": 2,
  "ALL": 3,
}

export const COMMUNICATION_CHANNELS = {
  "None": 0,
  "Email": 1,
  "Sms": 2,
  "EmailAndSms": 1 | 2
}

export const PROJECT_STATE_CODES = {
  "Anticipated": -10,
  "New": 0,
  "PartiallyInvoiced": 10,
  "FinallyInvoiced": 20,
  "InWarrantyPeriod": 30,
  "Archived": 100,
}

export const PENSUM_STATE_CODES = {
  "New": 0,
  "ConfirmedByConstructionSupervisor": 10,
  "ConfirmedByDispatcher": 20,
  "WorkCompleted": 30,
  "Invoiced": 40,
  "AutomaticallyInvoiced": 50,
  "Canceled": 100,
}

export const PENSUM_FLAGS = {
  "None": 0,
  "AllowStateChange_ConfirmedByConstructionSupervisor": 1,
  "AllowStateChange_ConfirmedByDispatcher": 2,
  "AllowStateChange_WorkCompleted": 4,
  "AllowStateChange_Invoiced": 8,
  "AllowStateChangeMessage": 16,
  "RequireStateChangeMessage": 32,
  "AllowCancelation": 64,
  "AllowCancelationMessage": 128,
  "RequireCancelationMessage": 256,
  "AllowSendMessage": 512,
  "AllowDelete": 1024,
  "AllowMove": 2048,
  "AllowCommentChange": 4096,
  "AllowEdit": 8192,
  "All": 16383,
}

export const PENSUM_STATE_COLORS = {
  "New": "#9c0000",
  "ConfirmedByConstructionSupervisor": "#d2c000",
  "ConfirmedByDispatcher": "#017724",
  "Canceled": "#017184",
}

export const ORGANISATION_IDS = {
  "STRABAG_RAIL_GMBH_SUD": 581
}

export const COMPANY_TYPES = {
  "None": 0,
  "EquipmentRental": 1,
  "DriverCompany": 2,
  "EngineeringOffice": 3,
}

export const INVOICE_TYPES = {
  "None": 0,
  "PartialInvoice": 1,
  "FinalInvoice": 2,
  "FictitiousInvoice": 3,
}

export const CHECK_POINT_DEFINITION_TYPES = {
  "None": 0,
  "Text": 1,
  "Flag": 2,
  "Date": 4,
  "Time": 8,
}

export const CHECK_POINT_DEFINITION_VISIBILITY_TYPES = {
  "None": 0,
  "Checklist": 1,
  "DispositionMonitor": 2
}

export const MATERIAL_TYPE = {
  "Default": 0,
  "Recyclable": 1,
  "Loose": 2
}