import { connect } from "react-redux";
import { createSelector } from 'reselect'
import { withNamespaces } from 'react-i18next';

import Planning from "./Planning";
import pensumActions from "../../actions/pensum";
import customerActions from "../../actions/customer";
import crewActions from "../../actions/crew";
import driverActions from "../../actions/driver";
import mixingPlantActions from "../../actions/mixingPlant";
import mixtureActions from "../../actions/mixture";
import mixtureOrderActions from "../../actions/mixtureOrder";
import employeeActions from "../../actions/employee";
import equipmentActions from "../../actions/equipment";
import projectActions from "../../actions/project";
import projectActivitiesActions from "../../actions/projectActivities";
import entryActions from "../../actions/entries";
import notificationActions from "../../actions/notification";
import userActions from "../../actions/user";
import specialdayActions from "../../actions/specialday";
import filterActions from "../../actions/filter";
import companyActions from "../../actions/company";
import waitForMessages from "../../actions/changeMessages/waitForMessages";
import { setDataIsObject } from "../../actions/setDataIsObject";

import { getCrewsSelector } from "../../selectors/crew"
import { getPensumsSelector } from "../../selectors/pensum"
import { getEquipmentSelector } from "../../selectors/equipment"
import { getDriversSelector } from "../../selectors/driver"
import { getEmployeeSelector } from "../../selectors/employee"
import { getProjectsSelector } from "../../selectors/project"
import { getProjectActivitiesSelector } from "../../selectors/projectActivities"
import { getMixtureOrdersSelector } from "../../selectors/mixtureOrder"
import { getMixturesSelector } from "../../selectors/mixture"
import { getMixingPlantsSelector } from "../../selectors/mixingPlant"
import { getSpecialDaysSelector } from "../../selectors/specialDay"
import { getHeaderBackgroundColorSelector } from "../../selectors/settings"
import { getUserCrewNamesSelector } from "../../selectors/user"
import { getUserRolesSelector } from "../../selectors/user"
import { getUserSelector } from "../../selectors/user"
import { getFiltersSelector } from "../../selectors/filter"
import { getProjectActivityEntriesSelector } from "../../selectors/projectActivityEntries"
import { getEmployeeEntriesSelector } from "../../selectors/employeeEntries"
import { getEquipmentEntriesSelector } from "../../selectors/equipmentEntries"
import { getMixtureEntriesSelector } from "../../selectors/mixtureEntries"
import { getDriverEntriesSelector } from "../../selectors/driverEntries"
import { getCompanySelector } from "../../selectors/company"

const getIsFetchingSelector = createSelector(
  pensum => pensum.isFetching,
  crew => crew.isFetching,
  project => project.isFetching,
  projectActivities => projectActivities.isFetching,
  mixture => mixture.isFetching,
  mixtureOrder => mixtureOrder.isFetching,
  mixingPlant => mixingPlant.isFetching,
  employee => employee.isFetching,
  settings => settings.isFetching,
  filter => filter.isFetching,
  entries => entries.isFetching,
  equipment => equipment.isFetching,
  company => company.isFetching,
  (
    pensumIsFetching,
    crewIsFetching,
    projectIsFetching,
    projectActivitiesIsFetching,
    mixtureIsFetching,
    mixtureOrderIsFetching,
    mixingPlantIsFetching,
    employeeIsFetching,
    settingsIsFetching,
    filterIsFetching,
    equipmentIsFetching,
    companyisFetching,
  ) =>
    pensumIsFetching
    || crewIsFetching
    || projectIsFetching
    || projectActivitiesIsFetching
    || mixtureIsFetching
    || mixtureOrderIsFetching
    || mixingPlantIsFetching
    || employeeIsFetching
    || settingsIsFetching
    || filterIsFetching
    || equipmentIsFetching
    || companyisFetching
);

const mapStateToProps = ({
  pensum,
  crew,
  user,
  project,
  projectActivities,
  mixture,
  mixtureOrder,
  mixingPlant,
  driver,
  employee,
  specialday,
  settings,
  projectActivityEntries,
  employeeEntries,
  equipmentEntries,
  mixtureEntries,
  driverEntries,
  filter,
  entries,
  equipment,
  company
}) => {
  return {
    crews: getCrewsSelector(crew),
    pensums: getPensumsSelector(pensum) ? getPensumsSelector(pensum).map(task => ({
      ...task,
      ProjectActivityEntries: getProjectActivityEntriesSelector(projectActivityEntries) ? getProjectActivityEntriesSelector(projectActivityEntries).filter(obj => task.ProjectActivityEntries && task.ProjectActivityEntries.includes(obj.ID)) : [],
      EmployeeEntries: getEmployeeEntriesSelector(employeeEntries) ? getEmployeeEntriesSelector(employeeEntries).filter(obj => task.EmployeeEntries && task.EmployeeEntries.includes(obj.ID)) : [],
      EquipmentEntries: getEquipmentEntriesSelector(equipmentEntries) ? getEquipmentEntriesSelector(equipmentEntries).filter(obj => task.EquipmentEntries && task.EquipmentEntries.includes(obj.ID)) : [],
      MixtureEntries: getMixtureEntriesSelector(mixtureEntries) ? getMixtureEntriesSelector(mixtureEntries).filter(obj => task.MixtureEntries && task.MixtureEntries.includes(obj.ID)) : [],
      DriverEntries: getDriverEntriesSelector(driverEntries) ? getDriverEntriesSelector(driverEntries).filter(obj => task.DriverEntries && task.DriverEntries.includes(obj.ID)) : []
    })) : [],
    equipment: getEquipmentSelector(equipment),
    drivers: getDriversSelector(driver),
    employee: getEmployeeSelector(employee),
    projects: getProjectsSelector(project),
    projectActivities: getProjectActivitiesSelector(projectActivities),
    mixtureOrders: getMixtureOrdersSelector(mixtureOrder),
    mixtures: getMixturesSelector(mixture),
    mixingPlants: getMixingPlantsSelector(mixingPlant),
    specialDays: getSpecialDaysSelector(specialday),
    headerColor: getHeaderBackgroundColorSelector(settings),
    userCrewName: null,//getUserCrewNamesSelector(user),
    userRoles: getUserRolesSelector(user),
    filters: getFiltersSelector(filter),
    user: getUserSelector(user),
    company: getCompanySelector(company),
    isFetching: getIsFetchingSelector(
      pensum,
      crew,
      project,
      projectActivities,
      mixture,
      mixtureOrder,
      mixingPlant,
      employee,
      settings,
      filter,
      company,
      equipment
    )
  }
};

const mapDispatchToProps = {
  ...pensumActions,
  ...customerActions,
  ...crewActions,
  ...driverActions,
  ...mixingPlantActions,
  ...mixtureActions,
  ...mixtureOrderActions,
  ...projectActivitiesActions,
  ...projectActions,
  ...employeeActions,
  ...equipmentActions,
  ...notificationActions,
  ...entryActions,
  ...userActions,
  ...specialdayActions,
  ...filterActions,
  ...companyActions,
  waitForMessages,
  setDataIsObject
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Planning);
export default withNamespaces(['common'])(connected);
