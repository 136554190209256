import { connect } from 'react-redux'; 
import { withNamespaces } from 'react-i18next';

import { getProjectsSelector } from "../../../../selectors/project"
import { getCustomersSelector } from "../../../../selectors/customer"
import { getMixingPlantsSelector } from "../../../../selectors/mixingPlant"
import { getEmployeeSelector } from "../../../../selectors/employee"
import { getCrewsSelector } from "../../../../selectors/crew"
import { getDriversSelector } from "../../../../selectors/driver"
import { getUsersSelector } from "../../../../selectors/user"
import { getCompanySelector } from "../../../../selectors/company"
import { getEquipmentSelector } from "../../../../selectors/equipment"

import FilterContainer from "./FilterContainer";

const mapStateToProps = ({
    crew, customer, mixingPlant, employee, project, driver, user, company, equipment
  }) => ({
    customers: getCustomersSelector(customer),
    mixingPlants: getMixingPlantsSelector(mixingPlant),
    employees: getEmployeeSelector(employee),
    crews: getCrewsSelector(crew),
    projects: getProjectsSelector(project),
    drivers: getDriversSelector(driver),
    user: getUsersSelector(user),
    company: getCompanySelector(company),
    equipment: getEquipmentSelector(equipment)
  });
  
  const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps)(FilterContainer);
export default withNamespaces(['common'])(connected);
