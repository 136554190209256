import React from "react";
import ReactDOM from "react-dom";

import RESOURCES from '../../api/resources';
import { isEqualMasterData, getServiceUrl } from "../../utils";
import { MATERIAL_TYPE } from "../../config/constants";

import MaterialTable, { MTableToolbar } from 'material-table';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { InputAdornment, Button } from "@material-ui/core";
import NumberInput from '../../core/components/Inputs/NumberInput/numberInput';
import Modal from '@material-ui/core/Modal';

import './index2.css';

class MixtureTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      editable: props.editable ? props.editable : '',
      mixtureOrderRow: props.mixtureOrderRow,
      newData: null,
      error: null,
      stateIsUpdated: false,
      confirmMixterOrderImport: false
    };

    this.onImportMixturesChange = this.onImportMixturesChange.bind(this)
  }

  componentDidMount() {
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      var child = node.querySelector('.MuiTableBody-root');
      if (child !== undefined) {
        child.children[0].style.height = "43.5px";
      }
    }

    const { t } = this.props;
    const columns = [
      {
        title: `${t('name')} *`, field: "Name", width: "auto",
        validate: ({ Name }) => Name && Name.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('article-number'), field: "ArticleNumber", filtering: false, width: "180px",
        editComponent: props => (
          <FormControl>
            <TextField
              id="article-number-input"
              value={props.value}
              type="text"
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        )
      },
      {
        title: t('material-type'), field: 'TypeCode', type: 'int', filtering: false,
        render: rowData => (
          t(`materialTypes.${Object.keys(MATERIAL_TYPE).find(key => MATERIAL_TYPE[key] === rowData.TypeCode)}`)
        ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Object.entries(MATERIAL_TYPE).map((types, i) => {
              const name = t(`materialTypes.${types[0]}`)
              const value = types[1]
              return (
                <MenuItem value={value}>
                  <em>{name === "" ? '\xA0' : name}</em>
                </MenuItem>
              )
            })}
          </Select>
        )
      },
      {
        title: `${t('cost-per-tonne')}`, field: "CostPerTonne", width: "100px", align: "right",
        cellStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="costPerTonne"
              type="number"
              value={props.value}
              error={props.value === null || props.value === undefined || props.value === ''}
              handleChange={e => props.onChange(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
    ];

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ isMounted: true, initPage, selectedRow: indexOfNewRow !== -1 ? data[indexOfNewRow] : null, columns: columns })
    }
    else {
      this.setState({ isMounted: true, columns: columns })
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.data !== props.data) {
      if (!this.state.stateIsUpdated)
        this.setState({ data: props.data, selectedRow: null })
    }
  }

  downloadMixtureExcel = (e) => {
    e.preventDefault();
    const link = document.getElementById(`downloadMixtureReport`);
    link.click();
  }

  onImportMixturesChange = async (e) => {
    e.preventDefault();
    var files = Array.from(e.target.files);
    e.target.value = null;
    if (files !== undefined && files !== null && files.length > 0) {
      const { importMixtureOrderMixtures, mixtureOrderRow } = this.props;
      const errorOrResult = await importMixtureOrderMixtures(mixtureOrderRow, files)
      if ((errorOrResult && !(errorOrResult.error instanceof Error))) {
        //TODO: process imported mixtures or reload mixtures?
        this.props.onMixtureOrderRowReload(mixtureOrderRow);
      };
    }
  }

  handleImportFileClick = (e) => {
    e.preventDefault();
    if (this.props.data === null || this.props.data.length == 0) {
      const input = document.getElementById("importMixtures");
      input.click();
    }
    else {
      this.confirmMixterOrderImportPopup();
    }
  }

  confirmMixterOrderImportPopup = () => {
    this.setState({ confirmMixterOrderImport: !this.state.confirmMixterOrderImport })
  }

  render() {
    const { t, mixtureOrderRow, user: { token: userToken } } = this.props;
    const { initPage, isMounted, editable, data, confirmMixterOrderImport } = this.state;

    return (
      isMounted ?
        <div>
          <input style={{ display: "none" }} accept=".xls,.xlsx" id="importMixtures" type="file" onChange={this.onImportMixturesChange} />
          <MaterialTable
            key={data.length}
            title={this.state.title}
            columns={this.state.columns}
            data={data}
            style={{
              paddingLeft: !editable && '2%',
              paddingRight: !editable && '2%',
              fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
              fontSize: `0.875rem`
            }}
            components={{
              Toolbar: props => (
                <div className="rowC">
                  <MTableToolbar {...props} />
                  {
                    mixtureOrderRow != null ? (
                      <div className="rowC">
                        <div style={{ paddingTop: '4px' }}>
                          <span>
                            <a
                              hidden
                              id={`downloadMixtureReport`}
                              target="_blank"
                              href={`${getServiceUrl()}${RESOURCES.MixtureOrders}(${mixtureOrderRow.ID})/${RESOURCES.DownloadMixtureReport}?timeZone=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}&token=${userToken}`}
                              rel="noopener noreferrer"
                            />
                            <Tooltip title={t('export')}>
                              <Button
                                key="export"
                                disabled={data.length <= 0}
                                onClick={this.downloadMixtureExcel}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className="submitButton"
                              >
                                {t('export')}
                              </Button>
                            </Tooltip>
                          </span>
                        </div>
                        <div style={{ width: '16px' }} />
                        <div style={{ paddingTop: '4px' }}>
                          <Tooltip title={t('import')}>
                            <Button
                              key="import"
                              disabled={!this.props.isEditable}
                              onClick={this.handleImportFileClick}
                              fullWidth
                              variant="contained"
                              color="primary"
                              className="submitButton"
                            >
                              {t('import')}
                            </Button>
                          </Tooltip>

                        </div>
                      </div>
                    ) : null
                  }
                </div>
              ),
            }}
            options={{
              pageSize: 10,
              pageSizeOptions: this.state.data.length > 10 ? [5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [10],
              paginationType: "normal",
              initialPage: initPage,
              addRowPosition: "first",
              draggable: false,
              filtering: true,
              searchFieldAlignment: "left",
              toolbarButtonAlignment: "left",
              rowStyle: rowData => ({ backgroundColor: this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id ? '#EEE' : '#FFF' }),
              tableLayout: "fixed"
            }}
            localization={{
              header: {
                actions: t('actions')
              },
              toolbar: {
                searchTooltip: t('search'),
                searchPlaceholder: t('search')
              },
              body: {
                emptyDataSourceMessage: t('no-records-to-display'),
                addTooltip: t('add'),
                deleteTooltip: t('delete'),
                editTooltip: t('edit'),
                editRow: {
                  saveTooltip: t('save'),
                  cancelTooltip: t('cancel'),
                  deleteText: t('deleteText')
                }
              },
              pagination: {
                firstTooltip: t('first-page'),
                previousTooltip: t('previous-page'),
                nextTooltip: t('next-page'),
                lastTooltip: t('last-page')
              }
            }}
            onRowClick={((evt, rowData) => { this.setState({ selectedRow: rowData }); })}
            editable={editable && {
              isEditable: rowData => this.props.isEditable,
              isDeletable: rowData => this.props.isEditable,
              onRowAdd: this.props.isEditable ? newData =>
                new Promise(resolve => {
                  this.setState({
                    newData: newData
                  });
                  setTimeout(async () => {
                    resolve();
                    this.setState({ stateIsUpdated: true });
                    const errorOrResult = await this.props.onMixtureRowAdd({
                      Name: newData.Name,
                      ArticleNumber: newData.ArticleNumber,
                      TypeCode: newData.TypeCode,
                      Order: {
                        ID: this.props.mixtureOrderRow.ID
                      },
                      CostPerTonne: newData.CostPerTonne !== undefined && newData.CostPerTonne != null ? parseFloat(newData.CostPerTonne) : newData.CostPerTonne
                    });
                    this.setState({ stateIsUpdated: false });
                    if (!(errorOrResult instanceof Error)) {
                      const { setNewRow, toggleTable } = this.props
                      const { ID } = errorOrResult
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        newData.ID = ID;
                        data.push(newData);
                        return { ...prevState, data };
                      });
                      setNewRow(ID)
                      toggleTable()
                    }

                  }, 100);
                }) : undefined,
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    resolve();
                    if (oldData && newData && !isEqualMasterData("Mixture", oldData, newData)) {
                      this.setState({ stateIsUpdated: true });
                      const errorOrResult = await this.props.onMixtureRowUpdate({
                        ...newData,
                      }, oldData.tableData);
                      this.setState({ stateIsUpdated: false });
                      if (!(errorOrResult instanceof Error)) {
                        const { setNewRow, toggleTable } = this.props
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }
                  }, 100);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    resolve();
                    const errorOrResult = await this.props.onMixtureRowDelete(oldData);
                    if (!(errorOrResult instanceof Error)) {
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        if (data.indexOf(oldData) >= 0)
                          data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data, selectedRow: (prevState.selectedRow !== undefined && prevState.selectedRow !== null && prevState.selectedRow.ID !== oldData.ID ? prevState.selectedRow : null) };
                      });
                    }
                  }, 100);
                })
            }}
          />
          <Modal open={confirmMixterOrderImport} onClose={this.confirmMixterOrderImportPopup}>
            <div className="confirm-popup">
              <div style={{ width: "100%", textAlign: "center", color: "#242424" }}>
                <h2>{t('confirm-mixture-order-import')}</h2>
              </div>
              <div className="buttonContainer">
                <Button
                  onClick={this.confirmMixterOrderImportPopup}
                  style={{ marginRight: 7 }}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  {t('cancel')}
                </Button>
                <Button
                  onClick={(e) => {
                    this.confirmMixterOrderImportPopup();
                    const input = document.getElementById("importMixtures");
                    input.click();
                  }}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  {t('confirm')}
                </Button>
              </div>
            </div>
          </Modal>
        </div> : null
    );
  }
}

export default MixtureTable;