import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import organisationActions from "../../actions/organisation";
import companyActions from "../../actions/company";
import notificationActions from "../../actions/notification";
import { setDataIsObject } from "../../actions/setDataIsObject";
import Company from "./Company";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ user, company, organisation }) => ({
  user: user,
  company: company.company,
  organisations: organisation.organisations,
  isFetching: isStateFetching([
    company,
    organisation
  ])
});

const mapDispatchToProps = {
  ...companyActions,
  ...organisationActions,
  ...notificationActions,
  setDataIsObject
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Company);
export default withNamespaces(["common"])(connected);
