import {
  GET_PROJECT_ACTIVITY_ENTRIES_REQUEST,
  GET_PROJECT_ACTIVITY_ENTRIES_ERROR,
  GET_PROJECT_ACTIVITY_ENTRIES_SUCCESS,
  CREATE_PROJECT_ACTIVITY_ENTRIES_REQUEST,
  CREATE_PROJECT_ACTIVITY_ENTRIES_ERROR,
  CREATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS,
  UPDATE_PROJECT_ACTIVITY_ENTRIES_REQUEST,
  UPDATE_PROJECT_ACTIVITY_ENTRIES_ERROR,
  UPDATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS,
  DELETE_PROJECT_ACTIVITY_ENTRIES_REQUEST,
  DELETE_PROJECT_ACTIVITY_ENTRIES_ERROR,
  DELETE_PROJECT_ACTIVITY_ENTRIES_SUCCESS,
  CREATE_ENTRY_REQUEST,
  CREATE_ENTRY_ERROR,
  CREATE_ENTRY_SUCCESS,
  UPDATE_ENTRY_OBJ,
  EDIT_ENTRY_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_PROJECT_ACTIVITY_ENTRIES_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_PROJECT_ACTIVITY_ENTRIES_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_PROJECT_ACTIVITY_ENTRIES_SUCCESS;
  reducers.CREATE_ENTITIES_REQUEST = CREATE_PROJECT_ACTIVITY_ENTRIES_REQUEST;
  reducers.CREATE_ENTITIES_ERROR = CREATE_PROJECT_ACTIVITY_ENTRIES_ERROR;
  reducers.CREATE_ENTITIES_SUCCESS = CREATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_PROJECT_ACTIVITY_ENTRIES_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_PROJECT_ACTIVITY_ENTRIES_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_PROJECT_ACTIVITY_ENTRIES_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_PROJECT_ACTIVITY_ENTRIES_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_PROJECT_ACTIVITY_ENTRIES_SUCCESS;
  reducers.entityName = "projectActivityEntries";

  if (action.type === UPDATE_ENTRY_OBJ && action.payload.entryType === reducers.entityName) {
    const activitiesInState = action.payload.fullState.projectActivities.projectActivities
    const newActivity = activitiesInState ? activitiesInState[action.payload.entry.ProjectActivity.ID] : null
    delete action.payload.entry.Pensum
    return {
      ...state,
      [action.payload.entryType]: {
        ...state[action.payload.entryType],
        [action.payload.entry.ID]: {
          ...state[action.payload.entryType][action.payload.entry.ID],
          ...action.payload.entry,
          ProjectActivity: newActivity ? newActivity : state[action.payload.entryType][action.payload.entry.ID].ProjectActivity
        }
      }
    }
  }
  if (action.type === EDIT_ENTRY_SUCCESS && action.entryType === "ProjectActivityEntries") {
    const entryName = action.entryType.charAt(0).toLowerCase() + action.entryType.slice(1)
    return {
        ...state,
        [entryName]: {
          ...state[entryName],
          [action.payload.ID]: {
            ...state[entryName][action.payload.ID],
            ...action.payload
          }
        },
        isFetching: false,
        error: undefined
    }
  }
  if (action.type === CREATE_ENTRY_REQUEST && action.entryType === "ProjectActivityEntries") {
    return {
      ...state,
      isFetching: false,
      error: undefined
    }
  }
  if (action.type === CREATE_ENTRY_SUCCESS && action.entryType === "ProjectActivityEntries") {
    return {
      ...state,
      projectActivityEntries: {
        ...state.projectActivityEntries,
        [action.payload.ID]: {
          ...action.payload
        }
      },
      isFetching: false,
      error: undefined
    }
  }
  if (action.type === CREATE_ENTRY_ERROR && action.entryType === "ProjectActivityEntries") {
    return {
      ...state,
      isFetching: false,
      error: `${action.error}`
    }
  }

  return reducers.entity(reducers)(state, action);
};
