import odata from 'odata-client';
import {
    GET_PENSUMS_REQUEST,
    GET_PENSUMS_ERROR,
    GET_PENSUMS_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';
/*import getToken from '../user/getToken';*/

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const getPensumsRequest = (isSideEffect) => ({
    type: GET_PENSUMS_REQUEST,
    isSideEffect: isSideEffect
});

export const getPensumsError = errorOrResults => ({
    type: GET_PENSUMS_ERROR,
    error: errorOrResults.message,
});

export const getPensumsSuccess = payload => ({
    type: GET_PENSUMS_SUCCESS,
    payload,
});

export default (fromDate, endDate, isSideEffect, isAutoRequest) => async (dispatch, getState) => {
    dispatch(getPensumsRequest(isSideEffect));

    const reqHeaders = {
        Authorization: `Bearer ${getState().user.token}`,
        ...getDefaultHeader()
    }

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.Pensums);
    query.select('ID,Day,WorkDuration,SortOrder,TotalMixtureAmount,HasInvoice,StateCode,Comment,FlagsCode,IsStateChangePending,PartitionIndex,StartTime');
    query.expand(RESOURCES.PensumQuery)
    query.filter("Day", ">=", fromDate)
    query.filter("Day", "<=", endDate)
    const errorOrResult = await query.get({
        headers: isAutoRequest ? {...reqHeaders, "X-IAS-RequestType": "auto"} : {...reqHeaders}
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('get pensums api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const response = res.toJSON()
                const body = JSON.parse(response.body);
                const payload = body.value;
                const pensumsById = {}
                payload.forEach(obj => {
                    const {
                        ProjectActivityEntries,
                        EmployeeEntries,
                        EquipmentEntries,
                        MixtureEntries,
                        DriverEntries,
                    } = obj

                    if (ProjectActivityEntries) {
                        obj.ProjectActivityEntries = ProjectActivityEntries.map(obj => obj.ID)
                    }
                    if (EmployeeEntries) {
                        obj.EmployeeEntries = EmployeeEntries.map(obj => obj.ID)
                    }
                    if (EquipmentEntries) {
                        obj.EquipmentEntries = EquipmentEntries.map(obj => obj.ID)
                    }
                    if (MixtureEntries) {
                        obj.MixtureEntries = MixtureEntries.map(obj => obj.ID)
                    }
                    if (DriverEntries) {
                        obj.DriverEntries = DriverEntries.map(obj => obj.ID)
                    }

                    pensumsById[obj.ID] = obj
                })

                return pensumsById;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(getPensumsError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return;
    }

    dispatch(getPensumsSuccess(errorOrResult));

    return errorOrResult;
};