import {
    IMPORT_MIXTUREORDER_MIXTURES_REQUEST,
    IMPORT_MIXTUREORDER_MIXTURES_ERROR,
    IMPORT_MIXTUREORDER_MIXTURES_SUCCESS
} from '../types';
import { getServiceUrl, getDefaultHeader, sendFileRequest, obj_to_map } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const importMixtureOrderMixturesRequest = () => ({
    type: IMPORT_MIXTUREORDER_MIXTURES_REQUEST,
});

export const importMixtureOrderMixturesError = errorOrResults => ({
    type: IMPORT_MIXTUREORDER_MIXTURES_ERROR,
    error: errorOrResults.message,
});

export const importMixtureOrderMixturesSuccess = payload => ({
    type: IMPORT_MIXTUREORDER_MIXTURES_SUCCESS,
    payload,
});

export default (mixtureOrder, files) => async (dispatch, getState) => {
    dispatch(importMixtureOrderMixturesRequest());

    const url = getServiceUrl() + RESOURCES.MixtureOrders + "(" + mixtureOrder.ID + ")/" + RESOURCES.ImportMixtures;
    const headers = {
        Authorization: `Bearer ${getState().user.token}`,
        ...getDefaultHeader()
    }
    const errorOrResult = await sendFileRequest(url, files, obj_to_map(headers))
        .then(res => {
            const ok = res.status <= 299 && res.status >= 200;

            if (res.status === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return { error: new Error(errorKey) };
            } else if (!ok) {
                console.log('import mixtutres api error', res.toJSON());
                const body = JSON.parse(res.response);
                const { Error: { Message } } = body
                const errorKey = res.status === 401 ? `401: ${Message}` : `${Message}`;
                return { error: new Error(errorKey) };
            }
            if (ok) {
                const body = JSON.parse(res.response);
                const { "@odata.context": omit, ...payload } = body;
                return payload
            }
        }
            , res => {

                // error
                var errorKey = `Error: ${res.status}, ${res.statusText}`;
                console.log('import mixtutres api error', res.responseText);
                // 400 BadRequest - code MissingParameter wenn File nicht gesetzt oder 0 Byte ist
                // 404 wenn MixtureOrder nicht existiert
                // 400 BadRequest - code InvalidFileType wenn ungültiger/unbekannter Dateityp (e.g. kein Excel)
                // 400 BadRequest - code InvalidFileFormat wenn Fileformat nicht passt (mehrere Sheets, falsche Spalten etc.)
                // 400 BadRequest - code EntityNotUnique wenn Mixture Name doppelt vorkommt (DuplicateEntries gebe ich in details aus, durch newline getrennt)
                var error = null;
                if (res.status == 400) {
                    const body = JSON.parse(res.response);
                    const { Error: { Code, Message } } = body
                    if (Code == 'MissingParameter' ||
                        Code == 'InvalidFileType' ||
                        Code == 'InvalidFileFormat' ||
                        Code == 'EntityNotUnique' ||
                        Code == 'Database_DataTooLong'
                    ) {
                        errorKey = `IMPORT_MIXTUREORDER_MIXTURES_ERROR_${Code.toUpperCase()}`
                    }
                    else {
                        errorKey = `Error: ${res.status}, ${Message}`
                    }
                } else if (res.status == 404) {
                    errorKey = `IMPORT_MIXTUREORDER_MIXTURES_ERROR_${'MixtureOrderNotExist'.toUpperCase()}`
                }

                return { error: new Error(errorKey) };
            })
        .catch((res) => {
            const errorKey = `Error: ${res.status}, ${res.statusText}`;
            console.log('import mixtutres api error', res.responseText);
            return { error: new Error(errorKey) };
        })

    if (errorOrResult && errorOrResult.error) {
        dispatch(importMixtureOrderMixturesError(errorOrResult));
        console.log("CODE ERROR: ", errorOrResult.error.message);
        dispatch(showNotification(errorOrResult.error.message, "error"))
        return errorOrResult;
    } else {
        dispatch(showNotification("SUCCESSFUL_IMPORT_MIXTUREORDER_MIXTURES", "success"))
    }

    dispatch(importMixtureOrderMixturesSuccess(errorOrResult));

    return errorOrResult;
};