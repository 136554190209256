import {
  EDIT_ENTRY_REQUEST,
  EDIT_ENTRY_ERROR,
  EDIT_ENTRY_SUCCESS,
  CREATE_DRIVER_ENTRIES_REQUEST,
  CREATE_DRIVER_ENTRIES_ERROR,
  CREATE_DRIVER_ENTRIES_SUCCESS,
  DELETE_ENTRY_REQUEST,
  DELETE_ENTRY_ERROR,
  DELETE_ENTRY_SUCCESS,
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
  
    case EDIT_ENTRY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case EDIT_ENTRY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined
      };

    case EDIT_ENTRY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: `${action.error}`
      };

    case CREATE_DRIVER_ENTRIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case CREATE_DRIVER_ENTRIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined
      };

    case CREATE_DRIVER_ENTRIES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: `${action.error}`
      };

    case DELETE_ENTRY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case DELETE_ENTRY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined
      };

    case DELETE_ENTRY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: `${action.error}`
      };

    default:
      return state;
  }
};
