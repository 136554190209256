import odata from 'odata-client';
import {
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_ERROR,
  GET_EMPLOYEE_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const getEmployeeRequest = () => ({
  type: GET_EMPLOYEE_REQUEST
});

export const getEmployeeError = errorOrResults => ({
  type: GET_EMPLOYEE_ERROR,
  error: errorOrResults.message
});

export const getEmployeeSuccess = payload => ({
  type: GET_EMPLOYEE_SUCCESS,
  payload
});

export default (id, filterQuery) => async (dispatch, getState) => {
  dispatch(getEmployeeRequest());

  const query = odata({ service: getServiceUrl() }).resource(RESOURCES.Employees, id);
  query.select("ID, LastName, FirstNames, CommunicationChannelCode, EmailAddress, PhoneNumber, IsDeleted, IsSupervisor, IsCrewLeader, IsDispatcher, IsAdministration, IsSupplier, IsKeyResourceManager, ColorRgb, SupervisorCommunicationChannelCode,MaximumPlanningDayCount,");
  query.expand("Organisation($select=ID, ShortName, Name, Left, Right), Crew($select=*)");	// note: should be Crew($select=ID, Name) but cannot be used currently due to bug with OData/EFCore
  //query.orderby("LastName, FirstNames, ID")
  if (filterQuery !== undefined && filterQuery !== null) {
    query.filter(filterQuery);
  }

  const errorOrResult = await query.get({
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;

      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        console.log("get employee api error", res.toJSON());
        const body = JSON.parse(res.body);
        const { Error: { Message } } = body
        const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
        return new Error(errorKey);
      } else {
        const body = JSON.parse(res.body);
        const { "@odata.context": omit, ...payload } = body;
        const storeState = getState()
        if (storeState.employee.dataIsObject) {
          const employeeById = {}
          [payload].forEach(obj => {
            employeeById[obj.ID] = obj
          })
          return employeeById;
        }

        return [payload]
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    });

  if (errorOrResult instanceof Error) {
    dispatch(getEmployeeError(errorOrResult, GET_EMPLOYEE_ERROR));
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    console.log("CODE ERROR: ", errorOrResult.message);
    dispatch(showNotification(errorOrResult.message, "error"))
    return errorOrResult;
  }

  dispatch(getEmployeeSuccess(errorOrResult));

  return errorOrResult;
};