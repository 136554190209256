import {
  GET_PROJECT_INVOICES_REQUEST,
  GET_PROJECT_INVOICES_ERROR,
  GET_PROJECT_INVOICES_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/getEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (selectQuery, filterQuery) => (state = initialState, action) => {

  context.init(context);

  context.GET_ENTITY_REQUEST = GET_PROJECT_INVOICES_REQUEST;
  context.GET_ENTITY_ERROR = GET_PROJECT_INVOICES_ERROR;
  context.GET_ENTITY_SUCCESS = GET_PROJECT_INVOICES_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "projectInvoice";
  context.entityLocation = RESOURCES.ProjectInvoices;
  context.selectQuery = "Date,Name,InvoicedAmount,TypeCode,CustomerNumber,ActualAmount,UnbilledAmount,PreChargedAmount,AdjustmentValue,FifthWorkdayReportAmount,Comment";
  context.orderByQuery = "SortOrder";

  if (selectQuery !== undefined && selectQuery !== null)
    context.selectQuery = selectQuery;

  if (filterQuery !== undefined && filterQuery !== null)
    context.filterQuery = filterQuery;

  return context.execute(context)()(state, action);
};