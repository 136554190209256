import React, { Component } from "react";
import CompanyTable from "../Company/CompanyTable";

import Loader from '../../core/components/Loader/LinearProgress'
/* import { getDefaultHeader } from "../../utils"; */

class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      communicationTypes: ["Email", "Phone"],
      onRowAdd: props.createCompany,
      onRowUpdate: props.updateCompany,
      onRowDelete: props.deleteCompany,
      editable: true,
      showTable: true
    };
  }

  async componentDidMount() {
    const { getCompany, getOrganisations, setDataIsObject } = this.props;
    await setDataIsObject(false);
    await getCompany();
    await getOrganisations();
    
    const organisationFilter = document.getElementsByClassName("MuiSelect-selectMenu")[0];
    const organisationSelect = document.getElementsByClassName("MuiSelect-selectMenu")[1];
    const organisationFilterContainer = document.getElementsByClassName("MuiInputBase-formControl")[1];
    const tableHeader = document.getElementsByClassName("MuiToolbar-gutters")[1];
    if (organisationFilter !== undefined) {
      organisationFilter.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationSelect !== undefined) {
      organisationSelect.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationFilterContainer !== undefined) {
      organisationFilterContainer.setAttribute("id", "org-select")
    }
    if (tableHeader !== undefined) {
      tableHeader.style.marginBottom = "35px";
    }
  
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    if (document.getElementsByClassName("MuiSelect-selectMenu")[0] !== undefined) {
      document.getElementsByClassName("MuiSelect-selectMenu")[0].removeEventListener("click", this.expandPopup);
    }
  }

  expandPopup = async () => {
    await new Promise(resolve => setTimeout(() => resolve(), 100));
    const { organisations } = this.props;
    const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
    /*const waitUntilPopupIsMounted = async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 100));
      if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }
    };*/

    if (organisationPopup) {
      const orgsList = organisationPopup.childNodes[0].childNodes;
      if (orgsList) {
        const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
        let i = 0;
        orgsList.forEach(orgElement => {
          const orgObj = organisations.find(item => item.ShortName === orgElement.getAttribute("data-value"));
          const orgText = document.getElementsByClassName("MuiTypography-body1")[i];

          if (orgObj) {
            orgText.setAttribute("data-value", orgObj.ShortName);
            if (orgObj.Level === topLevel) {
              orgText.style.marginLeft = "0px";
            } else if (orgObj.Level > topLevel) {
              const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 5;
              orgText.style.paddingLeft = `${margin}px`;
            }
          }

          i++;
        })
      }
      organisationPopup.setAttribute("style", organisationPopup.getAttribute("style").replace(" width: 250px;", ""));
    } /*else if (!organisationPopup) {
      await waitUntilPopupIsMounted();
    }*/
  }

  sortData = (data) => {
    if (data) {
      return data.sort((a, b) => {
        const result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'})
        if (result !== 0)
          return result
        return a.ID - b.ID
      })
    }
    return null
  }

  setNewRow = (id) => this.setState({ newRow: id })

  toggleTable = (open) => {
    this.setState({ showTable: open })
    this.setState({ showTable: true })
  }

  render() {
    const { company, organisations, t, isFetching } = this.props;
    const { isMounted, showTable, ...rest } = this.state;
    const sortedCompany = company && company.length > 0
    ? this.sortData(company) : [];

    return (
      <div
        style={{
          width: isFetching && !isMounted ? "100%" : "93%",
          margin: "0 auto",
          marginTop: isFetching && !isMounted ? 0 : 30,
          justifyContent: "center"
        }}
      >
        {
          isMounted ? (
            showTable ?
              <CompanyTable
                title={t('company')}
                data={sortedCompany}
                organisations={organisations}
                toggleTable={this.toggleTable}
                setNewRow={this.setNewRow}
                t={t}
                {...rest}
                {...this.props}
              /> : null
          ) : <Loader />
        }

      </div>
    );
  }
}

export default Company;
