export default {
  LoggedInUsers: "LoggedInUsers",
  Logout: "Logout",
  Equipment: "Equipment",
  Customers: "Customers",
  Customer: "Customer",
  DownloadMixtureReport: "DownloadMixtureReport",
  Employees: "Employees",
  Employee: "Employee",
  Crews: "Crews",
  Crew: "Crew",
  SpecialDays: "SpecialDays",
  Drivers: "Drivers",
  MixingPlants: "MixingPlants",
  MixtureOrders: "MixtureOrders",
  Mixture: "Mixture",
  Mixtures: "Mixtures",
  MixtureEntries: "MixtureEntries",
  Organisation: "Organisation",
  Organisations: "Organisations",
  OrganisationData: "OrganisationData",
  CheckpointDefinitions: "CheckpointDefinitions",
  Pensums: "Pensums",
  Pensum: "Pensum",
  ProjectExpanded: "Project($expand=Supervisor,Customer)",
  Supervisor: "Supervisor",
  ApplicationSettings: "ApplicationSettings",
  Projects: "Projects",
  Project: "Project",
  ProjectActivities: "ProjectActivities",
  ProjectInvoices: "ProjectInvoices",
  CreateProjectList: "CreateProjectList",
  Invoices: "Invoices",
  ProjectActivityEntries: "ProjectActivityEntries",
  EmployeeEntries: "EmployeeEntries",
  EquipmentEntries: "EquipmentEntries",
  DriverEntries: "DriverEntries",
  ChangeMessages: "ChangeMessages",
  ChangePassword: "ChangePassword",
  ResetUserPassword: "ResetUserPassword",
  WaitForMessages: "WaitForMessages",
  DefaultMixingPlant: "DefaultMixingPlant",
  DefaultMixtureOrder: "DefaultMixtureOrder",
  PensumQuery: `Crew($select=ID,Name),Project($select=ID,Name,FinalInvoiceTimeUtc;$expand=Supervisor($select=ID,LastName,FirstNames,ColorRgb),Customer($select=ID,Name),DefaultMixingPlant,DefaultMixtureOrder),ProjectActivityEntries($select=ID,SortOrder;$expand=ProjectActivity($select=ID,Name,Address,Location);$orderby=SortOrder),EmployeeEntries($select=ID,SortOrder;$expand=Employee($select=ID,LastName,FirstNames)),EquipmentEntries($select=ID,SortOrder;$expand=Equipment($select=ID,Name)),MixtureEntries($select=ID,SortOrder,MixtureAmount,Comment;$expand=Mixture($select=ID,Name,;$expand=Order($expand=MixingPlant));$orderby=SortOrder),DriverEntries($select=ID,SortOrder,Payload,StartTime,ConfirmedByDriver,Comment;$expand=Driver($select=ID,Name,DefaultPayload,;$expand=DriverCompany($select=*)),MixingPlant($select=*))`,
  ImportOfferDocument: "ImportOfferDocument",
  ImportTenderDocument: "ImportTenderDocument",
  ImportDocuments: "ImportDocuments",
  ImportImages: "ImportImages",
  ImportMixtures: "ImportMixtures",
  OfferDocument: "OfferDocument",
  TenderDocument: "TenderDocument",
  Documents: "Documents",
  Images: "Images",
  Ref: "$ref",
  Attachments: "Attachments",
  DownloadFile: "DownloadFile",
  DownloadThumbnail: "DownloadThumbnail",
  CreateMixingPlantReport: "CreateMixingPlantReport",
  CommunicationChannel: "CommunicationChannel",
  SendMessage: "SendMessage",
  SetState: "SetState",
  Companies: "Companies",
  ProjectCorrespondenceEntries: "ProjectCorrespondenceEntries",
  CreateFifthWorkdayReport: "CreateFifthWorkdayReport"
};

export const ContractType = {
  None: 0,
  Public: 1,
  Private: 2,
  Subcontractor: 3,
  InternalSubcontractor: 4
};