import _ from 'lodash';
import moment from 'moment';
import { IAS_DEV_SERVICE_URI, IAS_DEV_URI } from './config/constants';
import { store } from './App';
import packageJSON from '../package.json';

export const isDevelopmentSystem = () => window.location.hostname === "localhost" && (window.location.port == 3000 || window.location.port == 3001);

export const isDevelopment = () => {
  return (window.location.hostname === "localhost" && (window.location.port == 3000 || window.location.port == 3001)) ||
    window.location.hostname === 'iasdev.components.at';
}

export const getServiceUrl = () => {
  if (isDevelopmentSystem()) {
    return IAS_DEV_SERVICE_URI;
  } else {
    return `${window.location.origin}/odata/`;
  }
}

export const getWebUrl = (url) => {
  if (url && (url.toLowerCase().startsWith('https://') || url.toLowerCase().startsWith('http://'))) {
    return url;
  } else if (isDevelopmentSystem()) {
    return IAS_DEV_URI + url;
  } else {
    return window.location.origin + url;
  }
}

export const getPlanning2Url = (webToken) => `${window.location.origin}/planning2/?WebToken=${webToken}&Version=${packageJSON.at_co_procon.version}`;
export const getPlanning2LocalUrl = (webToken) => `http://localhost:3000/planning2/?WebToken=${webToken}&Version=${packageJSON.at_co_procon.version}`;
export const getMonitorUrl = (webToken) => `${window.location.origin}/planning2/?WebToken=${webToken}&Version=${packageJSON.at_co_procon.version}#/monitor`;

export const mixingPlantGroupNamesSeparator = ';';

export const getDefaultHeader = () => {
  const state = store.getState();

  return {
    "Accept-Language": state.user.userLanguage,
    "X-IAS-ClientApp": `${packageJSON.name} ${packageJSON.at_co_procon.version}`
  }
}

export const rgbToHex = (rgb) => {
  if (rgb === null || rgb === "null" || rgb === "")
    rgb = 0x00FFFFFF;

  var hex = (0x00FFFFFF & Number(rgb)).toString(16).padStart(6, '0');
  return "#" + hex;
}

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const strToInt = (value) => {
  return value !== null && value !== undefined && value != '' ? parseInt(value, 10) : null;
}

export const getNotificationMessageLocale = (message) => {
  if (message === undefined || message === null || (message && message === "")) {
    return {
      content: 'something-went-wrong'
    }
  }

  switch (message.trim()) {
    case "LoginOrganisationNotFound":
      return {
        content: 'login-organisation-not-found'
      }

    case "LoginBaseOrganisationNotFound":
      return {
        content: 'login-base-organisation-not-found'
      }

    case "UNAUTHORIZED_ERROR":
      return {
        content: 'invalid-credentials'
      }

    case "LOGOUT_REQUEST_FAILED":
      return {
        content: 'logout-request-failed'
      }

    case "The Name field is required.":
      return {
        content: 'name-field-required'
      }

    case "The OrderNumber field is required.":
      return {
        content: 'order-number-field-required'
      }

    case "Invalid date: DateTo may not be before DateFrom.":
      return {
        content: 'dateto-before-datefrom'
      }

    case ":The input was not valid.":
      return {
        content: 'input-was-invalid'
      }

    case ":\nThe input was not valid.":
      return {
        content: 'input-was-invalid'
      }

    case "The input was not valid.":
      return {
        content: 'input-was-invalid'
      }

    case "Database_ForeignKeyConstraintViolation":
    case "ForeignKeyConstraintViolation":
    case "Database server error occurred: ForeignKeyConstraintViolation.":
      return {
        content: 'foreign-key-constraint-violation'
      }

    case "NO_MORE_SYNC":
      return {
        content: 'no-more-sync'
      }

    case "SUCCESSFUL_SAVE":
      return {
        content: 'successful-save'
      }

    case "SUCCESSFUL_RESET_PASSWORD":
      return {
        content: 'successful-reset-password'
      }

    case "SUCCESSFUL_SAVE_PASSWORD":
      return {
        content: 'successful-save-password'
      }

    case "SUCCESSFUL_DELETE":
      return {
        content: 'successful-delete'
      }

    case "SUCCESSFUL_SAVE_employee":
      return {
        content: 'successful-save-employee'
      }

    case "SUCCESSFUL_SAVE_equipment":
      return {
        content: 'successful-save-equipment'
      }

    case "SUCCESSFUL_SAVE_projectactivity":
      return {
        content: 'successful-save-projectactivity'
      }

    case "SUCCESSFUL_SAVE_mixture":
      return {
        content: 'successful-save-mixture'
      }

    case "SUCCESSFUL_IMPORT_OFFER_DOCUMENT":
      return {
        content: 'successful-import-offer-document'
      }

    case "SUCCESSFUL_EXPORT_OFFER_DOCUMENT":
      return {
        content: 'successful-export-offer-document'
      }

    case "SUCCESSFUL_DELETE_OFFER_DOCUMENT":
      return {
        content: 'successful-delete-offer-document'
      }

    case "SUCCESSFUL_IMPORT_TENDER_DOCUMENT":
      return {
        content: 'successful-import-tender-document'
      }

    case "SUCCESSFUL_EXPORT_TENDER_DOCUMENT":
      return {
        content: 'successful-export-tender-document'
      }

    case "SUCCESSFUL_DELETE_TENDER_DOCUMENT":
      return {
        content: 'successful-delete-tender-document'
      }

    case "SUCCESSFUL_IMPORT_DOCUMENT":
      return {
        content: 'successful-import-document'
      }

    case "SUCCESSFUL_IMPORT_IMAGE":
      return {
        content: 'successful-import-image'
      }

    case "SUCCESSFUL_DELETE_DOCUMENT":
      return {
        content: 'successful-delete-document'
      }

    case "SUCCESSFUL_DELETE_IMAGE":
      return {
        content: 'successful-delete-image'
      }

    case "UNSUCCESSFUL_UPDATE_PENSUM":
      return {
        content: 'unsuccessful-update-pensum'
      }

    case "ProjectHasProjectActivities":
      return {
        content: 'project-has-project-activities'
      }

    case "InvalidFileType":
      return {
        content: 'invalid-file-type'
      }

    case "GENERAL_SERVER_ERROR":
      return {
        content: 'general-server-error'
      }

    case "FORBIDDEN":
      return {
        content: 'access-denied'
      }

    case "InvalidPlusCode":
      return {
        content: 'invalid-plus-code'
      }

    case "InvalidEmailAddress":
      return {
        content: 'invalid-email-address'
      }

    case "InvalidPhoneNumber":
      return {
        content: 'invalid-phone-number'
      }

    case "MessageCorrelationFailed":
      return {
        content: 'MessageCorrelationFailed'
      }

    case "MessageCorrelationFailedTryAgain":
      return {
        content: 'MessageCorrelationFailedTryAgain'
      }

    case "InvalidPlusCode_ContextNotFound":
      return {
        content: 'invalid-plus-code-context-not-found'
      }

    case "message-sent":
      return {
        content: 'message-sent'
      }

    case "successful-set-pensum-state":
      return {
        content: 'successful-set-pensum-state'
      }

    case "CrewLeaderRequiresCrew":
      return {
        content: 'crew-leader-requires-crew'
      }

    case "SUCCESSFUL_CREATE_EMPLOYEE_CREW":
      return {
        content: 'successful-create-employee-crew'
      }

    case "SUCCESSFUL_DELETE_EMPLOYEE_CREW":
      return {
        content: 'successful-delete-employee-crew'
      }

    case "SUCCESSFUL_CREATE_EMPLOYEE_MIXINGPLANT":
      return {
        content: 'successful-create-employee-mixing-plant'
      }

    case "SUCCESSFUL_DELETE_EMPLOYEE_MIXINGPLANT":
      return {
        content: 'successful-delete-employee-mixing-plant'
      }

    case "IMPORT_MIXTUREORDER_MIXTURES_ERROR_MISSINGPARAMETER":
      return {
        content: 'import-mixtureorder-mixtures-error-missing-parameter'
      }

    case "IMPORT_MIXTUREORDER_MIXTURES_ERROR_INVALIDFILETYPE":
      return {
        content: 'import-mixtureorder-mixtures-error-invalid-file-type'
      }

    case "IMPORT_MIXTUREORDER_MIXTURES_ERROR_INVALIDFILEFORMAT":
      return {
        content: 'import-mixtureorder-mixtures-error-invalid-file-format'
      }

    case "IMPORT_MIXTUREORDER_MIXTURES_ERROR_ENTITYNOTUNIQUE":
      return {
        content: 'import-mixtureorder-mixtures-error-entity-not-unique'
      }

    case "IMPORT_MIXTUREORDER_MIXTURES_ERROR_MIXTUREORDERNOTEXIST":
      return {
        content: 'import-mixtureorder-mixtures-error-mixture-order-not-exist'
      }

    case "IMPORT_MIXTUREORDER_MIXTURES_ERROR_DATABASE_DATATOOLONG":
      return {
        content: 'import-mixtureorder-mixtures-error-database-data-too-long'
      }

    case "SUCCESSFUL_IMPORT_MIXTUREORDER_MIXTURES":
      return {
        content: 'import-mixtureorder-mixtures-success'
      }

    case "SUCCESSFUL_CREATE_SPECIALDAY_CREW":
      return {
        content: 'successful-create-special-day-crew'
      }

    case "SUCCESSFUL_DELETE_SPECIALDAY_CREW":
      return {
        content: 'successful-delete-special-day-crew'
      }

    default:
      return {
        content: message.trim(),
        notFoundLocale: true
      }
  }
}

export const insertAtIndex = (index, key, value, map) => {
  const arr = Array.from(map);
  arr.splice(index, 0, [key, value]);
  return new Map(arr);
}

export const isStateFetching = stateArray => stateArray
  .some(({ isFetching }) => isFetching === true);


export const sendFileRequest = (url, files, headers) => {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    const fd = new FormData();

    xhr.open("POST", url, true);

    files.forEach(file => fd.append('File', file));

    xhr.onload = resolve;
    xhr.onerror = reject;

    // Setup our listener to process compeleted requests
    xhr.onreadystatechange = function () {
      // Only run if the request is complete
      if (xhr.readyState !== 4) return;
      // Process the response
      if (xhr.status >= 200 && xhr.status < 300) {
        // If successful
        resolve(xhr);
      } else {
        // If failed
        reject(xhr);
      }
    };

    if (headers !== undefined && headers !== null) {
      headers.forEach((v, k) => {
        xhr.setRequestHeader(k, v)
      });
    }

    // Initiate a multipart/form-data upload
    xhr.send(fd);
  });
};

export const downloadFileRequest = (url, headers) => {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.responseType = 'blob';
    xhr.open("GET", url, true);
    xhr.onload = resolve;
    xhr.onerror = reject;

    // Setup our listener to process compeleted requests
    xhr.onreadystatechange = function () {
      // Only run if the request is complete
      if (xhr.readyState !== 4) return;
      // Process the response
      if (xhr.status >= 200 && xhr.status < 300) {
        // If successful
        resolve(xhr);
      } else {
        // If failed
        reject(xhr);
      }
    };

    if (headers !== undefined && headers !== null) {
      headers.forEach((v, k) => {
        xhr.setRequestHeader(k, v)
      });
    }

    xhr.send(fd);
  });
};

export const obj_to_map = (obj => {
  const mp = new Map();
  Object.keys(obj).forEach(k => { mp.set(k, obj[k]) });
  return mp;
});

export const sort = (unordered) => {
  const ordered = {};
  Object.keys(unordered).sort().forEach(function (key) {
    ordered[key] = unordered[key];
  });
  return ordered;
};

export const isEqualMasterData = (keyGroup, objectA, objectB) => {
  const employeeKeyGroup = ["Organisation", "Crew", "Crews", "MixingPlants", "ColorRgb", "LastName", "FirstNames", "CommunicationChannelCode", "SupervisorCommunicationChannelCode", "EmailAddress", "PhoneNumber", "IsCrewLeader", "IsSupervisor", "IsDispatcher", "IsAdministration", "IsSupplier", "IsKeyResourceManager", "IsDeleted", "MaximumPlanningDayCount"]
  const crewKeyGroup = ["Organisation", "DefaultSortOrder", "Name", "IsDeleted", "EquipmentCostPerHour"]
  const specialDayKeyGroup = ["Organisation", "DateFrom", "DateTo", "Name", "IsPublicHoliday", "PartitionIndex", "Crews"]
  const customerKeyGroup = ["Name", "Organisation", "ContractTypeCode", "IsDeleted"]
  const equipmentKeyGroup = ["Organisation", "Name", "IsDeleted", "IsKeyResource", "IsIncludedInDailySummary", "RentalCompany", "CostPerHour"]
  const driverKeyGroup = ["Organisation", "DefaultCrew", "DefaultStartTime", "CommunicationChannelCode", "EmailAddress", "PhoneNumber", "DriverCompany", "Name", "DefaultPayload", "IsDeleted", "VehicleRegistrationNumber", "CostPerHour"]
  const mixingPlantKeyGroup = ["Name", "Organisation", "CommunicationChannelCode", "EmailAddress", "PhoneNumber", "LocationPlusCode", "IsDeleted", "MinimumAmountOfOutput", "MaximumAmountOfOutput", "GroupNames"]
  const mixtureOrderKeyGroup = ["OrderNumber", "Description", "IsDeleted"]
  const mixtureKeyGroup = ["Name", "ArticleNumber", "TypeCode", "CostPerTonne"]
  const companyKeyGroup = ["Organisation", "ShortName", "Name", "CommunicationChannelCode", "EmailAddress", "PhoneNumber", "TypeCode"]
  const organisationDataKeyGroup = ["Organisation", "EmployeeCostPerHour", "VehicleCostPerHour", "VehicleCostPerHour_0to13Tonnes", "VehicleCostPerHour_13to15Tonnes", "VehicleCostPerHour_15to18Tonnes", "VehicleCostPerHour_18to28Tonne", "AgkPercentage"]
  const checkpointDefKeyGroup = ["Organisation", "Name", "TypeCode", "SortOrder", "IsDeleted", "VisibilityCode", "AllowEditStateCodes", "RequiredStateCodes"]

  let useKeyGroup = []
  let isEqual = true

  switch (keyGroup) {
    case "Employee":
      useKeyGroup = employeeKeyGroup
      break;
    case "Crew":
      useKeyGroup = crewKeyGroup
      break;
    case "SpecialDay":
      useKeyGroup = specialDayKeyGroup
      break;
    case "Customer":
      useKeyGroup = customerKeyGroup
      break;
    case "Equipment":
      useKeyGroup = equipmentKeyGroup
      break;
    case "Driver":
      useKeyGroup = driverKeyGroup
      break;
    case "MixingPlant":
      useKeyGroup = mixingPlantKeyGroup
      break;
    case "MixtureOrder":
      useKeyGroup = mixtureOrderKeyGroup
      break;
    case "Mixture":
      useKeyGroup = mixtureKeyGroup
      break;
    case "Company":
      useKeyGroup = companyKeyGroup;
      break;
    case "OrganisationData":
      useKeyGroup = organisationDataKeyGroup;
      break;
    case "CheckpointDefinition":
      useKeyGroup = checkpointDefKeyGroup;
      break;
    default:
      break;
  }

  if (useKeyGroup.length > 0) {
    for (let i = 0; i < useKeyGroup.length; i++) {
      const key = useKeyGroup[i]
      if (isEqual) {
        const valueAisObject = typeof objectA[key] === "object"
        const valueBisObject = typeof objectB[key] === "object"
        if (valueAisObject || valueBisObject) {
          if (valueAisObject && valueBisObject) {
            isEqual = _.isEqual(objectA[key], objectB[key])
          } else {
            isEqual = false
          }
        } else {
          isEqual = objectA[key] === objectB[key]
        }
      } else break
    }
  }
  return isEqual
}

export const isValidEmail = (email) => {
  const emailRegExp = new RegExp(/.+@.+/, 'g');
  return emailRegExp.test(email);
}

export const getArrayOfObjectValues = (object) => {
  if (object) {
    return Object.values(object)
  } else {
    return object
  }
}

export const getDurationInMs = (duration) => {
  return moment.duration(duration).asMilliseconds()
}

export const getTimeFromMs = (s) => {
  // Pad to 2 or 3 digits, default is 2
  const pad = (n, z = 2) => ('00' + n).slice(-z);
  return pad(s / 3.6e6 | 0) + ':' + pad((s % 3.6e6) / 6e4 | 0)/* + ':' + pad((s%6e4)/1000|0) + '.' + pad(s%1000, 3)*/;
}

export const checkIsValidStartTime = (date, partitionStartTime, partitionEndTime, startTime) => {
  const partitionStartTimeInMs = moment.duration(partitionStartTime).asMilliseconds()
  const partitionEndTimeInMs = moment.duration(partitionEndTime).asMilliseconds()
  const newStartTimeInMs = moment.duration(startTime).asMilliseconds()
  let partitionStartTimeDate = date.clone().startOf('day')
  let partitionEndTimeDate = date.clone().startOf('day')
  let newStartTime = date.clone().startOf('day')

  partitionStartTimeDate.add(partitionStartTimeInMs, 'milliseconds')
  if (partitionStartTimeInMs < partitionEndTimeInMs) {
    /* day shift */
    partitionEndTimeDate.add(partitionEndTimeInMs, 'milliseconds')
    newStartTime.add(newStartTimeInMs, 'milliseconds')
  } else {
    /* night shift */
    partitionEndTimeDate.add(1, 'days')
    partitionEndTimeDate.add(partitionEndTimeInMs, 'milliseconds')
    if (newStartTimeInMs <= partitionStartTimeInMs && newStartTimeInMs <= partitionEndTimeInMs) {
      /* after midnight */
      newStartTime.add(1, 'days')
    }
    newStartTime.add(newStartTimeInMs, 'milliseconds')
  }

  const afterStartTime = partitionStartTimeDate.diff(newStartTime) <= 0
  const beforeEndTime = partitionEndTimeDate.diff(newStartTime) >= 0

  const isValid = afterStartTime && beforeEndTime

  return isValid
}

export const decodeMixingPlantClusters = (groupNames, separator = mixingPlantGroupNamesSeparator) => {
  var result = groupNames !== undefined && groupNames !== null ? groupNames.split(separator) : [];
  if (result.length > 0) {
    var grouped = _.groupBy(result, name => name);
    var res = Object.entries(grouped).map((e) => ({ ID: e[0], Name: e[0] }));
    return res;
  }
  return [];
}

export const encodeMixingPlantClusters = (clusters, separator = mixingPlantGroupNamesSeparator) => {
  return clusters !== undefined && clusters !== null ? Object.keys(clusters).map((key) => clusters[key]).join(",") : null;
}

export const getMixingPlantClusters = (mixingPlants) => {
  if (mixingPlants && mixingPlants.length > 0) {
    var flat = mixingPlants.filter((mp) => mp.GroupNames !== null && mp.GroupNames !== '').map((mp) => decodeMixingPlantClusters(mp.GroupNames)).flat();
    var grouped = _.groupBy(flat, cluster => cluster.Name);
    return Object.values(grouped).map((e) => e[0]);
  }
  return [];
}