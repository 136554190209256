import React from "react";
import _ from "lodash";

import { isEqualMasterData } from "../../utils";

import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
/* import InputLabel from '@material-ui/core/InputLabel'; */
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import { convertRecord2CheckpointDefinition } from "../OrganisationData/utils"

import './index.css';

class CheckpointDefinitionDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      organisation: props.organisation ? props.organisation : '',
      data: props.data ? props.data : '',
      checkpointDefinitions: props.checkpointDefinitions ? props.checkpointDefinitions : '',
      editable: props.editable ? props.editable : '',
      newData: null,
      error: null,
      showTable: true
    };
  }

  calcCurrentSortOrder(data) {
    if (data === undefined || data === null || data.length === 0)
      return 0;

    const step = 10;
    var maxSortOrder = 0;
    data.forEach(d => { maxSortOrder = (d.sortOrder < maxSortOrder ? maxSortOrder : d.sortOrder); });
    return maxSortOrder + step;
  }

  handleOnChange(props, e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      props.onChange(e.target.value);
    }
  }

  componentDidMount() {

    const {
      data
    } = this.state;
    const { t } = this.props;
    const columns = [
      {
        title: `${t('name')} *`, field: "name",
        validate: ({ name }) => name !== undefined && name !== null,
        editComponent: props => (
          <FormControl>
            <TextField
              id="checkpoint-definition-name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },

      {
        title: t(`checkpointTypes.Text`), field: "TypeCodeText", type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.TypeCodeText} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t(`checkpointTypes.Flag`), field: "TypeCodeFlag", type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.TypeCodeFlag} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t(`checkpointTypes.Date`), field: "TypeCodeDate", type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.TypeCodeDate} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t(`checkpointTypes.Time`), field: "TypeCodeTime", type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.TypeCodeTime} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t("is-required"), field: "isRequired", type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.isRequired} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t("checkpointVisibilities.DispositionMonitor"), field: "dispositionMonitor", type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.dispositionMonitor} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: `${t('sequence')} *`, field: "sortOrder", filtering: false, initialEditValue: this.calcCurrentSortOrder(data),
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined}
              inputProps={{ maxLength: 255 }}
              onChange={e => this.handleOnChange(props, e)}
            />
          </FormControl>
        ),
      },

      {
        title: t("is-deleted"), field: "isDeleted", type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.isDeleted} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
    ];

    this.setState({ isMounted: true, columns: columns })
  }

  render() {
    const {
      initPage,
      isMounted,
      pingedRowIndex,
      editable,
      data
    } = this.state;
    const { t } = this.props;

    return (
      isMounted ? (
        <MaterialTable
          title={this.state.title}
          columns={this.state.columns}
          data={data}
          style={{
            paddingLeft: !editable && '2%',
            paddingRight: !editable && '2%',
            fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
            fontSize: `0.875rem`
          }}
          options={{
            pageSize: data.length > 1 ? 10 : 1,
            pageSizeOptions: data.length > 1 ? [5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [1],
            paginationType: "normal",
            initialPage: initPage,
            addRowPosition: "first",
            draggable: false,
            filtering: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "left",
            rowStyle: rowData => ({
              backgroundColor: (pingedRowIndex === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
          }}
          onChangePage={() => this.setState({ pingedRowIndex: undefined })}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            body: {
              emptyDataSourceMessage: t('no-records-to-display'),
              editTooltip: t('edit'),
              editRow: {
                saveTooltip: t('save'),
                cancelTooltip: t('cancel'),
              }
            },
            pagination: {
              firstTooltip: t('first-page'),
              previousTooltip: t('previous-page'),
              nextTooltip: t('next-page'),
              lastTooltip: t('last-page')
            },
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(async () => {
                  newData.organisation = { ID: this.state.organisation.ID }
                  const errorOrResult = await this.props.onRowAdd(
                    convertRecord2CheckpointDefinition(newData)
                  );
                  if (!(errorOrResult instanceof Error)) {
                    const { ID } = errorOrResult
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      newData.ID = ID;
                      data.push(newData);
                      return { ...prevState, data };
                    });
                    this.forceUpdate();
                    this.setState({});
                  }
                  resolve();
                }, 100);

              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  if (oldData && newData && !isEqualMasterData("CheckpointDefinition",
                    convertRecord2CheckpointDefinition(oldData),
                    convertRecord2CheckpointDefinition(newData))) {
                    const errorOrResult = await this.props.onRowUpdate(
                      convertRecord2CheckpointDefinition(newData)
                      , oldData.tableData);
                    if (!(errorOrResult instanceof Error)) {
                      const { setNewRow, toggleTable } = this.props
                      newData.ID = errorOrResult.ID
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                    }
                  }
                }, 100);
              }),
          }}
        />
      ) : <></>
    );
  }
}

export default CheckpointDefinitionDataTable;