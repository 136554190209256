import React from 'react';
import _ from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';
import moment from 'moment';

import PensumsContainer from './components/PensumsContainer/';
import CrewContainer from './components/CrewContainer/';
import FilterContainer from './components/FilterContainer/';
import DateContainer from './components/DateContainer/';
import Loader from '../../core/components/Loader/LinearProgress'

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';

import MixtureIcon from '@material-ui/icons/LineStyle';
import DriverIcon from '@material-ui/icons/LocalShipping';
import TimelineIcon from '@material-ui/icons/Timeline';
import SunIcon from '@material-ui/icons/WbSunny';
import MoonIcon from '@material-ui/icons/NightsStay';
import CheckAllPensumsIcon from '@material-ui/icons/PlaylistAddCheck';

import './index.css'
import { CANCELLED_TYPES, INVOICED_TYPES, PENSUM_STATE_CODES, PENSUM_FLAGS } from '../../config/constants';
/* import { getPensum } from '../../actions/pensum'; */
import { getDurationInMs, getTimeFromMs, checkIsValidStartTime } from '../../utils';
import Pensum from './components/Pensum/Pensum';

const Container = styled.div`
  display: flex;
  padding-right: 3px;
`

const FirstColumnContainer = styled.div`
  width: 105px;
  background-color: #223b67;
  border-radius: 0px 3px 3px 0px;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 3px;
  padding-top: 3px;
  padding-bottom: 6px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  color: rgb(250,250,250);
  text-align: center;
`

export default class Planning extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      filters: {
        fromDate,
        toDate,
        customer,
        supervisor,
        crew,
        mixingPlant,
        company,
        equipment,
        selectedProjects,
        selectedCostCenters,
        selectedDrivers,
        selectedInvoiced,
        selectedCancelled,
        selectedCompanies,
        selectedTypeofEquipments,
        view
      }
    } = props
    const dates = []
    const fDate = moment(fromDate)
    const eDate = moment(toDate)
    const now = moment()
    const startOfWeek = fromDate ? fDate : now
    const endOfWeek = toDate ? eDate : startOfWeek.clone().add(13, "days")

    const startOfWeekForCalculation = startOfWeek.clone()
    dates.push(`${startOfWeekForCalculation.format("YYYY-MM-DD")}`)
    while (startOfWeekForCalculation.dayOfYear() < endOfWeek.dayOfYear()) {
      startOfWeekForCalculation.add(1, 'days')
      dates.push(`${startOfWeekForCalculation.format("YYYY-MM-DD")}`)
    }

    this.state = {
      selectedFromDate: startOfWeek.format("YYYY-MM-DD"),
      selectedToDate: endOfWeek.format("YYYY-MM-DD"),
      customer: customer || [],
      supervisor: supervisor || [],
      //2new
      company: company || [],
      equipment: equipment || [],
      selectedCrew: crew || [],
      mixingPlant: mixingPlant || [],
      //2new
      selectedCompanies: selectedCompanies || [],
      selectedTypeofEquipments: selectedTypeofEquipments || [],
      selectedProjects: selectedProjects || [],
      selectedCostCenters: selectedCostCenters || [],
      selectedDrivers: selectedDrivers || [],
      selectedInvoiced: selectedInvoiced,
      selectedCancelled: selectedCancelled || CANCELLED_TYPES.NOT_CANCELLED,
      history: {
        currentStep: -1,
        chain: []
      },
      view: view || null,
      dates: dates || [],
      crews: [],
      saveFilterDisabled: true,
      dayPartitions: [{}],
      pensumsToConfirm: [],
      pensumDayToConfirm: null,
      isValidStartTime: true,
      isMounted: false,
      tableFlipp: localStorage.getItem("table"),
      hint: 0
    }
  }
   componentDidUpdate(prevState) {
    const tableFlipp = localStorage.getItem("table")
/*     console.log("tableFlipp didupdate",tableFlipp)
    console.log("prevState didupdate",prevState) */
    if(tableFlipp){
      this.setState({
        showSwappedTable: JSON.parse(tableFlipp),
        isLoading: false
     })
  } 
}

  componentWillUpdate(nextProps, nextState){
    localStorage.getItem("tableFlipp", JSON.stringify(nextState.showSwappedTable))
/*     console.log(localStorage.getItem("tableFlipp will update log", JSON.stringify(nextState.showSwappedTable))) */
  } 


  async componentDidMount() {
    const {
      userRoles,
/*       savedUserFilters, */
      getPensums,
      getCrews,
      getDriver,
      getMixingPlant,
      getProjectActivities,
      getMixture,
      getMixtureOrder,
      getProjects,
      getEmployee,
      getCustomer,
      getEquipment,
      getSpecialDay,
      getProjectActivityEntries,
      getEmployeeEntries,
      getEquipmentEntries,
      getMixtureEntries,
      getDriverEntries,
      setChangeMessageToken,
      setDataIsObject,
      getCompany,
    } = this.props;
    const { selectedFromDate, selectedToDate } = this.state

    await setDataIsObject(true);
    Promise.all([
      await getCrews(),
      await getPensums(new Date(selectedFromDate), new Date(selectedToDate)),
      await getCustomer("IsDeleted eq false"),
      await getEmployee(undefined, "IsDeleted eq false"),
      await getMixingPlant(null, null, "IsDeleted eq false"),
      await getProjects(),
      await getProjectActivities(),
      await getDriver("IsDeleted eq false"),
      await getEquipment("IsDeleted eq false"),
      await getMixtureOrder(null, null, "IsDeleted eq false"),
      await getMixture(),
      await getSpecialDay(),
      await getCompany("IsDeleted eq false")]);
    Promise.all([
      await getProjectActivityEntries(null, this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEmployeeEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEquipmentEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getMixtureEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getDriverEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID))]);
    
    const {
      filters: {
        fromDate,
        toDate,
        customer,
        supervisor,
        crew,
        mixingPlant,
        selectedProjects,
        selectedCostCenters,
        //2new
        selectedCompanies,
        selectedTypeofEquipments,
        selectedDrivers,
        selectedInvoiced,
        selectedCancelled,
        view
      },
      projects,
/*       pensums, */
user
} = this.props


    let userView
    if (!view) {
      if (userRoles.some(role => role === "Supervisor")) {
        userView = { id: 1 }
      } else if (userRoles.some(role => role === "CrewLeader")) {
        userView = { id: 2 }
      } else if (userRoles.some(role => role === "Dispatcher")) {
        userView = { id: 3 }
      } else if (userRoles.some(role => role === "User")) {
        userView = { id: 1 }
      } else {
        userView = { id: 1 }
      }
    } else {
      userView = { id: view }
    }

    const dates = []
    const fDate = fromDate ? moment(fromDate) : moment(selectedFromDate)
    const eDate = toDate ? moment(toDate) : moment(selectedToDate)
    dates.push(`${fDate.format("YYYY-MM-DD")}`)
    if ((fDate.year() + 1) === eDate.year()) {
      const lastDayOfYear = moment(`${moment().year()}-12-31`).dayOfYear()
      let fromDateDayOfYear = fDate.dayOfYear()
      let endDateDayOfYear = lastDayOfYear + eDate.dayOfYear()
      while (fromDateDayOfYear < endDateDayOfYear) {
        fromDateDayOfYear++
        fDate.add(1, 'days')
        dates.push(`${fDate.format("YYYY-MM-DD")}`)
      }
    } else {
      while (fDate.dayOfYear() < eDate.dayOfYear()) {
        fDate.add(1, 'days')
        dates.push(`${fDate.format("YYYY-MM-DD")}`)
      }
    }

    const costCenters = []
    if (projects) {
      projects.forEach(obj => {
        if (!costCenters.some(cost => cost.ID === obj.CostCenter))
          costCenters.push({
            ID: obj.CostCenter,
            Name: obj.CostCenter
          })
      })
    }

  const tableFlipp = localStorage.getItem("table")
    
    this.setState((prevState) => ({
      selectedFromDate: fromDate ? fromDate : selectedFromDate,
      selectedToDate: toDate ? toDate : selectedToDate,
      dates,
      costCenters,
      customer,
/*       company: selectedCompanies,
      equipment: selectedTypeofEquipments, */
      supervisor,
      selectedCrew: crew,
      mixingPlant,
      selectedProjects,
      selectedCompanies,
      selectedTypeofEquipments,
      selectedCostCenters,
      selectedDrivers,
      selectedInvoiced,
      selectedCancelled: selectedCancelled || prevState.selectedCancelled,
      view: userView,
      showSwappedTable: tableFlipp ? true : false /* user.organisation.IsTimeAndCrewSwapped ? user.organisation.IsTimeAndCrewSwapped : user.baseOrganisation.IsTimeAndCrewSwapped ? user.baseOrganisation.IsTimeAndCrewSwapped : false */,
      isPensumStartTimeAllowed: user.baseOrganisation.IsPensumStartTimeAllowed,
      dayPartitions: user.baseOrganisation.DayPartitions && user.baseOrganisation.DayPartitions.length > 0 ? user.baseOrganisation.DayPartitions : [{}],
      isMounted: true
    }))


    setChangeMessageToken()
    this.waitForMessages(null)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

 /*  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps.pensums, this.props.pensums) || !_.isEqual(nextState, this.state)) {
      return true
    }
    return false
  } */

  componentWillReceiveProps(nextProps) {
    const { userCrewName, userRoles } = this.props
    let view = null

    if (userRoles.some(role => role === "Supervisor")) {
      view = { id: 1 }
    } else if (userRoles.some(role => role === "CrewLeader")) {
      view = { id: 2 }
    } else if (userRoles.some(role => role === "Dispatcher")) {
      view = { id: 3 }
    } else if (userRoles.some(role => role === "User")) {
      view = { id: 1 }
    } else {
      view = { id: 1 }
    }

    if (JSON.stringify(nextProps.crews) !== JSON.stringify(this.state.crews)) {
      this.setState({ crews: nextProps.crews });
    }

    const crews = this.state.crews.length > 0 ? this.state.crews : nextProps.crews !== undefined && Array.from(nextProps.crews).length > 0 ? Array.from(nextProps.crews) : []
    let filteredCrews = []

    if (view.id === 2 && userCrewName !== null && crews !== undefined && crews !== null && crews.length > 0) {
      filteredCrews = crews.filter(crew => crew.Name.toLowerCase() === userCrewName.toLowerCase())
    } else {
      filteredCrews = crews
    }

    this.setState({ crews: filteredCrews });
  }

  /*calculatePensumsInColumns = (dates, filteredCrews, pensums) => {
    dates.forEach(day => {
      filteredCrews.forEach(crew => {
        const pensumsInColumn = []
        const taskIdsInColumn = []
        const columnId = `${crew.ID}/${day}`

        pensums.forEach(pensum => {
          if (
            moment(day).format("YYYY-MM-DD") === moment(pensum.Day).format("YYYY-MM-DD")
            && pensum.Crew.ID === crew.ID
          ) {
            pensumsInColumn.push(pensum)
          }
        })

        const sortedPensums = pensumsInColumn.sort((a, b) => { return a.SortOrder - b.SortOrder })

        sortedPensums.forEach(pensum => taskIdsInColumn.push(`pensum-${pensum.ID}`))

        const { columns } = this.state
        columns[columnId] = {
          id: columnId,
          taskIds: taskIdsInColumn,
          pensums: sortedPensums
        }

        this.setState({ columns })
      })
    })
  }*/

  waitForMessages = async (lastChangeMessageID, fromDate, toDate, filteredCrews) => {
    /*
      "Note: In case of invalid (e.g. too old) LastChangeMessageID
      then status code 400 with error code 'InvalidLastChangeMessageID' is returned.
      In such case synchronisation cannot be guaranteed so the client needs to completely reload the current view."
      
      solution -> needToReload
      no need to reload current view, only need to update pensums to the latest, and call waitForMessages
    */
    const { waitForMessages, getPensums, updatePensumObj, createPensumObj, showNotification, pensums } = this.props
    const { crews, selectedFromDate, selectedToDate } = this.state
    const isSideEffect = true
/*     const isAutoRequest = true */
    let crewIDs = []

    if (filteredCrews) {
      crewIDs = filteredCrews
    } else if (crews) {
      crews.forEach(crew => {
        crewIDs.push(crew.ID)
      })
    }

    const errorOrMessageObject = await waitForMessages(
      {
        "Filter": {
          "DayFrom": fromDate ? moment(fromDate).format("YYYY-MM-DD") : moment(selectedFromDate).format("YYYY-MM-DD"),
          "DayTo": toDate ? moment(toDate).format("YYYY-MM-DD") : moment(selectedToDate).format("YYYY-MM-DD"),
          "CrewIDs": crewIDs
        },
        "LastChangeMessageID": lastChangeMessageID
      },
      pensums
    )

    if (errorOrMessageObject === undefined) {
      return
    }

    const { error, needToReload, cancel } = errorOrMessageObject

    if (cancel) {
      return
    }

    if (!error) {
      const { latestChangeMessageID, objectsToChange } = errorOrMessageObject
      const entitiesToUpdate = []
      const entitiesToCreate = []

      if (objectsToChange !== undefined && objectsToChange !== null && objectsToChange.length > 0) {
        for (let i = 0; i < objectsToChange.length; i++) {
          const { getPensum } = this.props
          const { updateEntry } = this.props
          const entity = objectsToChange[i]
          const entityType = entity.ChangedEntityTypeName
          const { ChangeTypeCode } = entity

          switch (entityType) {
            case "Pensum":
              const existingPensum = this.props.pensums.find(pensum => pensum.ID === entity.ChangedEntityID)
              const pensum = existingPensum || {}
              if (Number(ChangeTypeCode) === 3) {
                const { deletePensumObj } = this.props
                deletePensumObj({ ID: entity.ChangedEntityID })
              }
              if (Number(ChangeTypeCode) === 2) {
                let newEntity = {
                  ...pensum, ...entity.Pensum
                }
                if (pensum.Project && pensum.Project.ID) {
                  newEntity = {
                    ...newEntity,
                    Project: pensum.Project
                  }
                } else if (newEntity.Project && newEntity.Project.ID) {
                  const { projects } = this.props
                  newEntity = {
                    ...newEntity,
                    Project: projects.find(obj => obj.ID === newEntity.Project.ID) 
                  }
                }
                if (entity.ProjectActivityEntries === null || (entity.ProjectActivityEntries && entity.ProjectActivityEntries.length <= 0)) {
                  delete newEntity.ProjectActivityEntries
                }
                if (entity.EmployeeEntries === null || (entity.EmployeeEntries && entity.EmployeeEntries.length <= 0)) {
                  delete newEntity.EmployeeEntries 
                }
                if (entity.EquipmentEntries === null || (entity.EquipmentEntries && entity.EquipmentEntries.length <= 0)) {
                  delete newEntity.EquipmentEntries 
                }
                if (entity.MixtureEntries === null || (entity.MixtureEntries && entity.MixtureEntries.length <= 0)) {
                  delete newEntity.MixtureEntries 
                }
                if (entity.DriverEntries === null || (entity.DriverEntries && entity.DriverEntries.length <= 0)) {
                  delete newEntity.DriverEntries 
                }
                entitiesToUpdate.push(newEntity)
              }
              if (Number(ChangeTypeCode) === 1 && !pensum.ID) {
                const newPensum = await getPensum(entity.Pensum.ID)
                if (newPensum && newPensum.ID) {
                  const { getDriverEntry } = this.props
                  if (newPensum.DriverEntries) {
                    newPensum.DriverEntries.forEach(ID => {
                      getDriverEntry(ID)
                    })
                  }
                }
              }
              break;
            
            case "ProjectActivityEntry":
              if (entity.ProjectActivityEntry) {
                const oldProjectActivityEntryPensum = this.props.pensums.find(pensum => pensum.ID === entity.ProjectActivityEntry.Pensum.ID)
                if (oldProjectActivityEntryPensum) {
                  if (ChangeTypeCode === 3) {
                    getPensum(oldProjectActivityEntryPensum.ID)
                  } else if(ChangeTypeCode === 2) {
                    updateEntry("projectActivityEntries", entity.ProjectActivityEntry)
                  } else {
                    getPensum(oldProjectActivityEntryPensum.ID)
                    updateEntry("projectActivityEntries", entity.ProjectActivityEntry)
                  }
                }
              }
              break;

            case "EmployeeEntry":
              if (entity.EmployeeEntry) {
                const oldEmployeeEntryPensum = this.props.pensums.find(pensum => pensum.ID === entity.EmployeeEntry.Pensum.ID)
                if (oldEmployeeEntryPensum) {
                  if (ChangeTypeCode === 3) {
                    getPensum(oldEmployeeEntryPensum.ID)
                  } else if(ChangeTypeCode === 2) {
                    updateEntry("employeeEntries", entity.EmployeeEntry)
                  } else {
                    getPensum(oldEmployeeEntryPensum.ID)
                    updateEntry("employeeEntries", entity.EmployeeEntry)
                  }
                }
              }
              break;

            case "EquipmentEntry":
              if (entity.EquipmentEntry) {
                const oldEquipmentEntryPensum = this.props.pensums.find(pensum => pensum.ID === entity.EquipmentEntry.Pensum.ID)
                if (oldEquipmentEntryPensum) {
                  if (ChangeTypeCode === 3) {
                    getPensum(oldEquipmentEntryPensum.ID)
                  } else if(ChangeTypeCode === 2) {
                    updateEntry("equipmentEntries", entity.EquipmentEntry)
                  } else {
                    getPensum(oldEquipmentEntryPensum.ID)
                    updateEntry("equipmentEntries", entity.EquipmentEntry)
                  }
                }
              }
              break;

            case "MixtureEntry":
              if (entity.MixtureEntry) {
                const oldMixtureEntryPensum = this.props.pensums.find(pensum => pensum.ID === entity.MixtureEntry.Pensum.ID)
                if (oldMixtureEntryPensum) {
                  if (ChangeTypeCode === 3) {
                    getPensum(oldMixtureEntryPensum.ID)
                  } else if(ChangeTypeCode === 2) {
                    updateEntry("mixtureEntries", entity.MixtureEntry)
                  } else {
                    getPensum(oldMixtureEntryPensum.ID)
                    updateEntry("mixtureEntries", entity.MixtureEntry)
                  }
                }
              }
              break;

            case "DriverEntry":
              if (entity.DriverEntry) {
                const oldDriverEntryPensum = this.props.pensums.find(pensum => pensum.ID === entity.DriverEntry.Pensum.ID)
                if (oldDriverEntryPensum) {
                  if (ChangeTypeCode === 3) {
                    getPensum(oldDriverEntryPensum.ID)
                  } else if(ChangeTypeCode === 2) {
                    if (entity.OriginalProperties && entity.OriginalProperties.PensumID && entity.OriginalProperties.PensumID !== oldDriverEntryPensum.ID) {
                      getPensum(entity.OriginalProperties.PensumID)
                    }
                    getPensum(oldDriverEntryPensum.ID)
                    updateEntry("driverEntries", entity.DriverEntry)
                  } else {
                    getPensum(oldDriverEntryPensum.ID)
                    updateEntry("driverEntries", entity.DriverEntry)
                  }
                }
              }
              break;

            default:
              break;
          }
        }
      }

      if (entitiesToUpdate.length > 0) {
        entitiesToUpdate.forEach(entity => {
          if (entity.ProjectActivityEntries) {
            entity.ProjectActivityEntries = entity.ProjectActivityEntries.map(obj => obj.ID)
          }
          if (entity.EmployeeEntries) {
            entity.EmployeeEntries = entity.EmployeeEntries.map(obj => obj.ID)
          }
          if (entity.EquipmentEntries) {
            entity.EquipmentEntries = entity.EquipmentEntries.map(obj => obj.ID)
          }
          if (entity.MixtureEntries) {
            entity.MixtureEntries = entity.MixtureEntries.map(obj => obj.ID)
          }
          if (entity.DriverEntries) {
            entity.DriverEntries = entity.DriverEntries.map(obj => obj.ID)
          }
          updatePensumObj({
            ...entity
          })
        })
      }
      if (entitiesToCreate.length > 0) {
        entitiesToCreate.forEach(entity => {
          createPensumObj({
            ...entity,
            ProjectActivityEntries: entity.ProjectActivityEntries.map(obj => obj.ID),
            EmployeeEntries: entity.EmployeeEntries.map(obj => obj.ID),
            EquipmentEntries: entity.EquipmentEntries.map(obj => obj.ID),
            MixtureEntries: entity.MixtureEntries.map(obj => obj.ID),
            DriverEntries: entity.DriverEntries.map(obj => obj.ID),
          })
        })
      }

      this.waitForMessages(latestChangeMessageID)
    } else if (needToReload) {
      const {
        getProjectActivityEntries,
        getEmployeeEntries,
        getEquipmentEntries,
        getMixtureEntries,
        getDriverEntries,
      } = this.props
      await getPensums(new Date(selectedFromDate), new Date(selectedToDate), isSideEffect)
    Promise.all([
      await getProjectActivityEntries(null, this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEmployeeEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEquipmentEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getMixtureEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getDriverEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID))]);
      this.waitForMessages(null)
    } else {
      showNotification("NO_MORE_SYNC", "error")
    }
  }

  dateIsInvalid(date) {
    const isInvalidDate = `${date}`.toLowerCase() === "invalid date"

    if (isInvalidDate) {
      return false
    } else {
      return true
    }
  }

  handleFromDateChange = async (date) => {
    const { selectedFromDate, selectedToDate } = this.state
    const {
      getPensums,
      getProjectActivityEntries,
      getEmployeeEntries,
      getEquipmentEntries,
      getMixtureEntries,
      getDriverEntries,
      saveFilter
    } = this.props
    const dates = []

    const fromDate = moment(date)
    const endDate = moment(selectedToDate)

    if (
      this.dateIsInvalid(fromDate)
      && moment(selectedFromDate).format("YYYY-MM-DD") !== fromDate.format("YYYY-MM-DD")
    ) {
      dates.push(`${fromDate.format("YYYY-MM-DD")}`)
      endDate.add(fromDate.diff(moment(selectedFromDate), "days", true), "days")

      if ((fromDate.year() + 1) === endDate.year()) {
        const lastDayOfYear = moment(`${moment().year()}-12-31`).dayOfYear()
        let fromDateDayOfYear = fromDate.dayOfYear()
        let endDateDayOfYear = lastDayOfYear + endDate.dayOfYear()
        while (fromDateDayOfYear < endDateDayOfYear) {
          fromDateDayOfYear++
          fromDate.add(1, 'days')
          dates.push(`${fromDate.format("YYYY-MM-DD")}`)
        }
      } else {
        while (fromDate.dayOfYear() < endDate.dayOfYear()) {
          fromDate.add(1, 'days')
          dates.push(`${fromDate.format("YYYY-MM-DD")}`)
        }
      }

      this.setState({
        dates,
        selectedFromDate: date,
        selectedToDate: endDate.diff(fromDate, "days") < 0 ? date : endDate.format("YYYY-MM-DD"),
        saveFilterDisabled: false
      })
      await saveFilter({ id: "fromDate", value: moment(date).format("YYYY-MM-DD") });
      await saveFilter({ id: "toDate", value: endDate.format("YYYY-MM-DD") });
      await getPensums(new Date(date), new Date(endDate.format("YYYY-MM-DD")))
    Promise.all([
      await getProjectActivityEntries(null, this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEmployeeEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEquipmentEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getMixtureEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getDriverEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID))]);
      this.waitForMessages(null, date, endDate.format("YYYY-MM-DD"))
    }
  }

  handleToDateChange = async (date) => {
    const { selectedFromDate, selectedToDate } = this.state
    const {
      getPensums,
      getProjectActivityEntries,
      getEmployeeEntries,
      getEquipmentEntries,
      getMixtureEntries,
      getDriverEntries,
      saveFilter
    } = this.props
    const dates = []

    const fromDate = moment(selectedFromDate)
    const endDate = moment(date)

    if (
      this.dateIsInvalid(endDate)
      && moment(selectedToDate).format("YYYY-MM-DD") !== endDate.format("YYYY-MM-DD")
    ) {
      dates.push(`${fromDate.format("YYYY-MM-DD")}`)
      if ((fromDate.year() + 1) === endDate.year()) {
        const lastDayOfYear = moment(`${moment().year()}-12-31`).dayOfYear()
        let fromDateDayOfYear = fromDate.dayOfYear()
        let endDateDayOfYear = lastDayOfYear + endDate.dayOfYear()
        while (fromDateDayOfYear < endDateDayOfYear) {
          fromDateDayOfYear++
          fromDate.add(1, 'days')
          dates.push(`${fromDate.format("YYYY-MM-DD")}`)
        }
      } else {
        while (fromDate.dayOfYear() < endDate.dayOfYear()) {
          fromDate.add(1, 'days')
          dates.push(`${fromDate.format("YYYY-MM-DD")}`)
        }
      }

      this.setState({ dates, selectedToDate: endDate.diff(fromDate) < 0 ? fromDate : date, saveFilterDisabled: false })
      await saveFilter({ id: "toDate", value: endDate.diff(fromDate) < 0 ? fromDate.format("YYYY-MM-DD") : endDate.format("YYYY-MM-DD") })
      await getPensums(new Date(selectedFromDate), endDate.diff(fromDate) < 0 ? new Date(selectedFromDate) : new Date(date))
         Promise.all([
      await getProjectActivityEntries(null, this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEmployeeEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEquipmentEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getMixtureEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getDriverEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID))]);
      this.waitForMessages(null, selectedFromDate, endDate.diff(fromDate) < 0 ? fromDate : date)
    }
  }
  handleCustomerChange = async selectedCustomer => {
    const { saveFilter } = this.props
    let customers = selectedCustomer || []
    if (customers && customers.length > 0) {
      customers = customers.map(obj => obj.value)
    }
    this.setState({ customer: customers, saveFilterDisabled: false })
    await saveFilter({ id: "customer", value: customers });
  }
  //=========================================================================
  handleCompanyChange = async selectedCompanies => {
    const { saveFilter } = this.props
    let companies = selectedCompanies || []
    if (companies && companies.length > 0) {
      companies = companies.map(obj => obj.value)
    }
    this.setState({ selectedCompanies: companies, saveFilterDisabled: false })
    await saveFilter({ id: "selectedCompanies", value: companies });
  }
  handleTypeofEquipmentChange = async selectedTypeofEquipments => {
    const { saveFilter } = this.props
    let equipments = selectedTypeofEquipments || []
    if (equipments && equipments.length > 0) {
      equipments = equipments.map(obj => obj.value)
    }
    this.setState({ selectedTypeofEquipments: equipments, saveFilterDisabled: false })//jovolt ha true xDDD
    await saveFilter({ id: "selectedTypeofEquipments", value: equipments });
  }

  
  //=========================================================================

  handleSupervisorChange = async selectedSupervisor => {
    const { saveFilter } = this.props
    let supervisors = selectedSupervisor || []
    if (supervisors && supervisors.length > 0) {
      supervisors = supervisors.map(obj => obj.value)
    }
    this.setState({ supervisor: supervisors, saveFilterDisabled: false })
    await saveFilter({ id: "supervisor", value: supervisors });
  }

  handleCrewChange = async crew => {
    const { saveFilter } = this.props
    let crewSelection = crew || []
    if (crewSelection && crewSelection.length > 0) {
      crewSelection = crewSelection.map(obj => obj.value)
    }
    this.setState({ selectedCrew: crewSelection, saveFilterDisabled: false })
    this.waitForMessages(null, null, null, crewSelection.length === 0 ? null : crewSelection)
    await saveFilter({ id: "crew", value: crewSelection });
  }

  handleMixingPlantChange = async selectedMixingPlants => {
    const { saveFilter } = this.props
    let mixingPlants = selectedMixingPlants || []
    if (mixingPlants && mixingPlants.length > 0) {
      mixingPlants = mixingPlants.map(obj => obj.value)
    }
    this.setState({ mixingPlant: mixingPlants, saveFilterDisabled: false })
    await saveFilter({ id: "mixingPlant", value: mixingPlants });
  }

  handleProjectChange = async selectedProjects => {
    const { saveFilter } = this.props
    let projects = selectedProjects || []
    if (projects && projects.length > 0) {
      projects = projects.map(obj => obj.value)
    }
    this.setState({ selectedProjects: projects, saveFilterDisabled: false })
    await saveFilter({ id: "selectedProjects", value: projects });
  }

  handleCostCenterChange = async selectedCostCenters => {
    const { saveFilter } = this.props
    let costCenters = selectedCostCenters || []
    if (costCenters && costCenters.length > 0) {
      costCenters = costCenters.map(obj => obj.value)
    }
    this.setState({ selectedCostCenters: costCenters, saveFilterDisabled: false })
    await saveFilter({ id: "selectedCostCenters", value: costCenters });
  }

  handleDriverChange = async selectedDrivers => {
    const { saveFilter } = this.props
    let drivers = selectedDrivers || []
    if (drivers && drivers.length > 0) {
      drivers = drivers.map(obj => obj.value)
    }
    this.setState({ selectedDrivers: drivers, saveFilterDisabled: false })
    await saveFilter({ id: "selectedDrivers", value: drivers });
  }

  handleInvoicedChange = async selectedInvoiced => {
    const { saveFilter } = this.props
    let selectedInvoiceTypeID
    if (selectedInvoiced) {
      selectedInvoiceTypeID = selectedInvoiced.value
    }
    this.setState({ selectedInvoiced: selectedInvoiceTypeID || null, saveFilterDisabled: false })
    await saveFilter({ id: "selectedInvoiced", value: selectedInvoiceTypeID || null });
  }

  handleCancelledChange = async selectedCancelled => {
    const { saveFilter } = this.props
    let selectedCancelledTypeID
    if (selectedCancelled) {
      selectedCancelledTypeID = selectedCancelled.value
    }
    this.setState({ selectedCancelled: selectedCancelledTypeID || null, selectedCancelledTypeID: false })
    await saveFilter({ id: "selectedCancelled", value: selectedCancelledTypeID || null });
  }

  handleViewChange = async selectedView => {
    const { dates, crews, selectedFromDate, selectedToDate } = this.state
    const {
      getPensums,
      getProjectActivityEntries,
      getEmployeeEntries,
      getEquipmentEntries,
      getMixtureEntries,
      getDriverEntries,
      saveFilter,
      userCrewName
    } = this.props
    const { value } = selectedView
    let filteredCrews = []

    if (Number(value) === 2 && userCrewName !== null) {
      filteredCrews = crews.filter(crew => crew.Name.toLowerCase() === userCrewName.toLowerCase())
    } else {
      filteredCrews = this.props.crews
    }

    this.setState({
      view: {
        id: Number(value)
      },
      crews: filteredCrews,
      saveFilterDisabled: false
    })

    await saveFilter({ id: "view", value: Number(value) });
    await getPensums(new Date(selectedFromDate), new Date(selectedToDate));
    Promise.all([
      await getProjectActivityEntries(null, this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEmployeeEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getEquipmentEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getMixtureEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID)),
      await getDriverEntries(this.props.pensums && this.props.pensums.map(obj => obj.ID))]);
    this.waitForMessages(
      null,
      new Date(selectedFromDate), 
      new Date(selectedToDate),
      filteredCrews.map(obj => obj.ID)
    )
  }

  handleSaveFilter = () => {
    this.setState({ saveFilterDisabled: true })
  }

  handleCopy = (pensum) => {
    this.setState({ copiedPensum: pensum })
  }

  handleMove = (pensum, source) => {
    this.setState({ movedPensum: pensum, pensumSource: source })
  }

  handleHistoryChange = async (step, direction, newChain) => {
    const { pensums, updatePensum, showNotification } = this.props
    const { history, history: { chain, currentStep } } = this.state
    const {
      pensumId,
      objectBefore,
      objectAfter,
      changedEntryType,
      changedEntry,
      changeTypeOfEntry,
/*       entityID, */
      isDeletedPensum,
      wasDND
    } = chain[step]
    const isBackward = direction && direction === "back"
    const objToUpdate = direction ? isBackward ? objectBefore : objectAfter : null
    let canDoChange = false

    if (objectAfter && newChain === null) {
      const pensumInPresent = !isDeletedPensum && pensums.find(pensum => pensum.ID === pensumId)

      /*
        need more information about SortOrder changing.
        question: if only the SortOrder changes, and the SortOrder which we want to update to is reserved, what should be the new SortOrder?
      */

      if (!isDeletedPensum &&
        (isBackward
          && pensumInPresent.Crew.ID === objectAfter.Crew.ID
          && pensumInPresent.Day === objectAfter.Day
          && pensumInPresent.SortOrder === objectAfter.SortOrder)
        || (direction === "forward"
          && pensumInPresent.Crew.ID === objectBefore.Crew.ID
          && pensumInPresent.Day === objectBefore.Day
          && pensumInPresent.SortOrder === objectBefore.SortOrder)
      ) {
        canDoChange = true
      } else {
        /*
          handle problem when moving same object and remove all same entries in the chain.
          or there is no need to remove all, because if the user goes back/forward again,
          he removes it one by one
        */
        if (!isDeletedPensum) {
          const indexOfHistoryPensum = chain.indexOf(chain[step])
          chain.splice(indexOfHistoryPensum, 1)

          this.setState({ history: { ...history, chain, currentStep: isBackward ? currentStep - 1 : currentStep } })
          showNotification(`PENSUM_HAS_BEEN_CHANGED`, "error")
        }
      }
    }

    if (isDeletedPensum) {
      /*
        (!) missing SortOrder check (!)
            check and if necessary calculate new SortOrder
      */

      if (isBackward) {
        const { createPensum, updatePensumObj } = this.props

        const errorOrResult = await createPensum(
          {
            CreationTimeUtc: moment().utc().format(),
            Day: objectBefore.Day,
            Project: { ID: objectBefore.Project.ID },
            Crew: { ID: objectBefore.Crew.ID },
            SortOrder: objectBefore.SortOrder,
            WorkDuration: objectBefore.WorkDuration,
            TotalMixtureAmount: objectBefore.TotalMixtureAmount,
            Comment: objectBefore.Comment,
            StateCode: objectBefore.StateCode,
            StartTime: objectBefore.StartTime,
            PartitionIndex: objectBefore.PartitionIndex
          },
          false
        )

        if (errorOrResult && errorOrResult.ID) {
          const waitUntilStateIsSet = async () => {
            await new Promise(resolve => setTimeout(() => resolve(), 100));
            const pensumCopy = this.props.pensums.find(obj => obj.ID === errorOrResult.ID)
            if (pensumCopy.IsStateChangePending) {
              await waitUntilStateIsSet();
            } else {
              const { createEntry } = this.props
              const {
                MixtureEntries,
                DriverEntries,
                ProjectActivityEntries,
                EmployeeEntries,
                EquipmentEntries
              } = objectBefore
    
              if (MixtureEntries.length > 0) {
                MixtureEntries.forEach(async mix => {
                  const resultMixtureEntry = await createEntry("MixtureEntries", {
                    Mixture: mix.Mixture,
                    Pensum: { ID: errorOrResult.ID },
                    MixtureAmount: mix.MixtureAmount,
                    Comment: mix.Comment,
                    SortOrder: mix.SortOrder
                  })
                  if (resultMixtureEntry && !(resultMixtureEntry instanceof Error)) {
                    const { pensums: pensumsInStore } = this.props
                    const newPensum = pensumsInStore.find(obj => obj.ID === errorOrResult.ID)
                    updatePensumObj({
                      ...newPensum,
                      ProjectActivityEntries: [...Object.values(newPensum.ProjectActivityEntries).map(obj => obj.ID)],
                      EmployeeEntries: [...Object.values(newPensum.EmployeeEntries).map(obj => obj.ID)],
                      EquipmentEntries: [...Object.values(newPensum.EquipmentEntries).map(obj => obj.ID)],
                      MixtureEntries: [...Object.values(newPensum.MixtureEntries).map(obj => obj.ID), resultMixtureEntry.ID],
                      DriverEntries: [...Object.values(newPensum.DriverEntries).map(obj => obj.ID)],
                    })
                  }
                })
              }
              if (DriverEntries.length > 0) {
                DriverEntries.forEach(async mix => {
                  const resultDriverEntry = await createEntry("DriverEntries", {
                    Driver: mix.Driver,
                    Pensum: { ID: errorOrResult.ID },
                    MixingPlant: mix.MixingPlant && mix.MixingPlant.ID ? mix.MixingPlant : null,
                    Payload: mix.Payload !== null && mix.Payload !== undefined ? Number(mix.Payload) : null,
                    StartTime: mix.StartTime,
                    Comment: mix.Comment,
                    SortOrder: mix.SortOrder
                  })
                  if (resultDriverEntry && !(resultDriverEntry instanceof Error)) {
                    const { pensums: pensumsInStore } = this.props
                    const newPensum = pensumsInStore.find(obj => obj.ID === errorOrResult.ID)
                    updatePensumObj({
                      ...newPensum,
                      ProjectActivityEntries: [...Object.values(newPensum.ProjectActivityEntries).map(obj => obj.ID)],
                      EmployeeEntries: [...Object.values(newPensum.EmployeeEntries).map(obj => obj.ID)],
                      EquipmentEntries: [...Object.values(newPensum.EquipmentEntries).map(obj => obj.ID)],
                      MixtureEntries: [...Object.values(newPensum.MixtureEntries).map(obj => obj.ID)],
                      DriverEntries: [...Object.values(newPensum.DriverEntries).map(obj => obj.ID), resultDriverEntry.ID]
                    })
                  }
                })
              }
              if (ProjectActivityEntries.length > 0) {
                ProjectActivityEntries.forEach(async mix => {
                  const resultProjectActivityEntry = await createEntry("ProjectActivityEntries", {
                    ProjectActivity: mix.ProjectActivity,
                    Pensum: { ID: errorOrResult.ID },
                    SortOrder: mix.SortOrder
                  })
                  if (resultProjectActivityEntry && !(resultProjectActivityEntry instanceof Error)) {
                    const { pensums: pensumsInStore } = this.props
                    const newPensum = pensumsInStore.find(obj => obj.ID === errorOrResult.ID)
                    updatePensumObj({
                      ...newPensum,
                      ProjectActivityEntries: [...Object.values(newPensum.ProjectActivityEntries).map(obj => obj.ID), resultProjectActivityEntry.ID],
                      EmployeeEntries: [...Object.values(newPensum.EmployeeEntries).map(obj => obj.ID)],
                      EquipmentEntries: [...Object.values(newPensum.EquipmentEntries).map(obj => obj.ID)],
                      MixtureEntries: [...Object.values(newPensum.MixtureEntries).map(obj => obj.ID)],
                      DriverEntries: [...Object.values(newPensum.DriverEntries).map(obj => obj.ID)],
                    })
                  }
                })
              }
              if (EmployeeEntries.length > 0) {
                EmployeeEntries.forEach(async mix => {
                  const resultEmployeeEntry = await createEntry("EmployeeEntries", {
                    Employee: mix.Employee,
                    Pensum: { ID: errorOrResult.ID },
                    SortOrder: mix.SortOrder
                  })
                  if (resultEmployeeEntry && !(resultEmployeeEntry instanceof Error)) {
                    const { pensums: pensumsInStore } = this.props
                    const newPensum = pensumsInStore.find(obj => obj.ID === errorOrResult.ID)
                    updatePensumObj({
                      ...newPensum,
                      ProjectActivityEntries: [...Object.values(newPensum.ProjectActivityEntries).map(obj => obj.ID)],
                      EmployeeEntries: [...Object.values(newPensum.EmployeeEntries).map(obj => obj.ID), resultEmployeeEntry.ID],
                      EquipmentEntries: [...Object.values(newPensum.EquipmentEntries).map(obj => obj.ID)],
                      MixtureEntries: [...Object.values(newPensum.MixtureEntries).map(obj => obj.ID)],
                      DriverEntries: [...Object.values(newPensum.DriverEntries).map(obj => obj.ID)],
                    })
                  }
                })
              }
              if (EquipmentEntries.length > 0) {
                EquipmentEntries.forEach(async mix => {
                  const resultEquipmentEntry = await createEntry("EquipmentEntries", {
                    Equipment: mix.Equipment,
                    Pensum: { ID: errorOrResult.ID },
                    SortOrder: mix.SortOrder
                  })
                  if (resultEquipmentEntry && !(resultEquipmentEntry instanceof Error)) {
                    const { pensums: pensumsInStore } = this.props
                    const newPensum = pensumsInStore.find(obj => obj.ID === errorOrResult.ID)
                    updatePensumObj({
                      ...newPensum,
                      ProjectActivityEntries: [...Object.values(newPensum.ProjectActivityEntries).map(obj => obj.ID)],
                      EmployeeEntries: [...Object.values(newPensum.EmployeeEntries).map(obj => obj.ID)],
                      EquipmentEntries: [...Object.values(newPensum.EquipmentEntries).map(obj => obj.ID), resultEquipmentEntry.ID],
                      MixtureEntries: [...Object.values(newPensum.MixtureEntries).map(obj => obj.ID)],
                      DriverEntries: [...Object.values(newPensum.DriverEntries).map(obj => obj.ID)],
                    })
                  }
                })
              }
            }
    
            this.setState((prevState) => {
              return {
                history: {
                  ...prevState.history,
                  currentStep: step - 1,
                  chain: prevState.history.chain.map(obj => (
                    obj === chain[step]
                      ? Object.assign(obj, { pensumId: errorOrResult instanceof Error ? obj.pensumId : errorOrResult.ID })
                      : obj
                  ))
                }
              }
            })
          }
          await waitUntilStateIsSet()
        }
      } else {
        const { deletePensum } = this.props
        const errorOrResult = await deletePensum({ ID: pensumId })

        if (!(errorOrResult instanceof Error)) {
          this.setState((prevState) => {
            return {
              history: {
                ...prevState.history,
                currentStep: step
              }
            }
          })
        }
      }
    }

    if (objToUpdate && canDoChange) {
      const cleanObjToUpdate = Object.assign({}, objToUpdate)

      /*
        delete unchanged properties to prevent Duplicate entry for Primary keys
      */
      delete cleanObjToUpdate.ID
      delete cleanObjToUpdate.EmployeeEntries
      delete cleanObjToUpdate.EquipmentEntries
      delete cleanObjToUpdate.MixtureEntries
      delete cleanObjToUpdate.DriverEntries
      delete cleanObjToUpdate.ProjectActivityEntries
      delete cleanObjToUpdate.TotalMixtureAmount
      delete cleanObjToUpdate.WorkDuration
      delete cleanObjToUpdate.Project

      if (
        objectBefore.Crew.ID === objectAfter.Crew.ID
        && objectBefore.Day === objectAfter.Day
        && objectBefore.SortOrder === objectAfter.SortOrder
      ) {
        const {
/*           createProjectActivityEntry, */
          updateProjectActivityEntry,
          deleteProjectActivityEntry,
/*           createEmployeeEntry, */
          updateEmployeeEntry,
          deleteEmployeeEntry,
          createEquipmentEntry,
          updateEquipmentEntry,
          deleteEquipmentEntry,
          deleteEntry,
          getPensum
        } = this.props

        if (changedEntry && changeTypeOfEntry && changedEntryType === "ProjectActivityEntry") {
          const { createEntry } = this.props
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = await deleteProjectActivityEntry(changedEntry.ID)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                }
                getPensum(objToUpdate.ID)
              } else {
                const errorOrResult = await createEntry("ProjectActivityEntries", {
                  ProjectActivity: changedEntry.ProjectActivity,
                  Pensum: { ID: objToUpdate.ID },
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                      ))
                    }
                  }));
                }
                getPensum(objToUpdate.ID)
              }
              break;

            /* Modify */
            case 2:
              updateProjectActivityEntry(changedEntry)
              getPensum(objToUpdate.ID)
              break;

            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createEntry("ProjectActivityEntries", {
                    ProjectActivity: changedEntry.ProjectActivity,
                    Pensum: { ID: objToUpdate.ID },
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                            ? Object.assign(obj, {
                              changedEntry: {
                                ...obj.changedEntry,
                                ID: errorOrResult.ID
                              }
                            })
                            : obj
                        ))
                      }
                    }));
                  }
                  getPensum(objToUpdate.ID)
                } else {
                  deleteProjectActivityEntry(changedEntry.ID)
                  getPensum(objToUpdate.ID)
                }
              }
              break;

            default:
              break;
          }
        }
        if (changedEntry && changeTypeOfEntry && changedEntryType === "EmployeeEntry") {
          const { createEntry } = this.props
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = await deleteEmployeeEntry(changedEntry.ID)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                }
                getPensum(objToUpdate.ID)
              } else {
                const errorOrResult = await createEntry("EmployeeEntries", {
                  Employee: changedEntry.Employee,
                  Pensum: { ID: objToUpdate.ID },
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                      ))
                    }
                  }));
                }
                getPensum(objToUpdate.ID)
              }
              break;

            /* Modify */
            case 2:
              updateEmployeeEntry(changedEntry)
              getPensum(objToUpdate.ID)
              break;

            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createEntry("EmployeeEntries", {
                    Employee: changedEntry.Employee,
                    Pensum: { ID: objToUpdate.ID },
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                            ? Object.assign(obj, {
                              changedEntry: {
                                ...obj.changedEntry,
                                ID: errorOrResult.ID
                              }
                            })
                            : obj
                        ))
                      }
                    }));
                  }
                  getPensum(objToUpdate.ID)
                } else {
                  deleteEmployeeEntry(changedEntry.ID)
                  getPensum(objToUpdate.ID)
                }
              }
              break;

            default:
              break;
          }
        }
        if (changedEntry && changeTypeOfEntry && changedEntryType === "EquipmentEntry") {
          const { createEntry } = this.props
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = await deleteEquipmentEntry(changedEntry.ID)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                }
                getPensum(objToUpdate.ID)
              } else {
                const errorOrResult = await createEntry("EquipmentEntries", {
                  Equipment: changedEntry.Equipment,
                  Pensum: { ID: objToUpdate.ID },
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                      ))
                    }
                  }));
                }
                getPensum(objToUpdate.ID)
              }
              break;

            /* Modify */
            case 2:
              updateEquipmentEntry(changedEntry)
              getPensum(objToUpdate.ID)
              break;

            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createEquipmentEntry({
                    Equipment: changedEntry.Equipment,
                    Pensum: { ID: objToUpdate.ID },
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                            ? Object.assign(obj, {
                              changedEntry: {
                                ...obj.changedEntry,
                                ID: errorOrResult.ID
                              }
                            })
                            : obj
                        ))
                      }
                    }));
                  }
                  getPensum(objToUpdate.ID)
                } else {
                  deleteEquipmentEntry(changedEntry.ID)
                  getPensum(objToUpdate.ID)
                }
              }
              break;

            default:
              break;
          }
        }
        if (changedEntry && changeTypeOfEntry && changedEntryType === "MixtureEntry") {
          const { createEntry } = this.props
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = await deleteEntry("MixtureEntries", changedEntry.ID)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                  this.setState((prevState) => { return { history: { ...prevState.history, currentStep: step - 1 } } })
                }
                getPensum(objToUpdate.ID)
              } else {
                const errorOrResult = await createEntry("MixtureEntries", {
                  Mixture: changedEntry.Mixture,
                  Pensum: { ID: pensumId },
                  MixtureAmount: changedEntry.MixtureAmount,
                  Comment: changedEntry.Comment,
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                  this.setState((prevState) => { return { history: { ...prevState.history, currentStep: step - 1 } } })
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                      ))
                    }
                  }));
                }
                getPensum(objToUpdate.ID)
              }
              break;

            /* Modify */
            case 2:
              updateEquipmentEntry(changedEntry)
              getPensum(objToUpdate.ID)
              break;

            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createEntry("MixtureEntries", {
                    Mixture: changedEntry.Mixture,
                    Pensum: { ID: pensumId },
                    MixtureAmount: changedEntry.MixtureAmount,
                    Comment: changedEntry.Comment,
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                    this.setState((prevState) => {
                      return {
                        history: {
                          ...prevState.history,
                          currentStep: step - 1,
                        }
                      }
                    })
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                            ? Object.assign(obj, {
                              changedEntry: {
                                ...obj.changedEntry,
                                ID: errorOrResult.ID
                              }
                            })
                            : obj
                        ))
                      }
                    }));
                  }
                  getPensum(objToUpdate.ID)
                } else {
                  await deleteEntry("MixtureEntries", changedEntry.ID)
                  getPensum(objToUpdate.ID)
                }
              }
              break;

            default:
              break;
          }
        }
        if (changedEntry && changeTypeOfEntry && changedEntryType === "DriverEntry") {
          const { createEntry } = this.props
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = deleteEntry("DriverEntries", changedEntry.ID, wasDND)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                  this.setState((prevState) => { return { history: { ...prevState.history, currentStep: step - 1 } } })
                }
                getPensum(objToUpdate.ID)
              } else {
                const errorOrResult = await createEntry("DriverEntries", {
                  Driver: changedEntry.Driver,
                  Pensum: { ID: pensumId },
                  MixingPlant: changedEntry.MixingPlant && changedEntry.MixingPlant.ID ? changedEntry.MixingPlant : null,
                  Payload: changedEntry.Payload,
                  StartTime: changedEntry.StartTime,
                  Comment: changedEntry.Comment,
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                  this.setState((prevState) => { return { history: { ...prevState.history, currentStep: step - 1 } } })
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                      ))
                    }
                  }));
                }
                getPensum(objToUpdate.ID)
              }
              break;

            /* Modify */
            case 2:
              updateEquipmentEntry(changedEntry)
              break;

            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createEntry("DriverEntries", {
                    Driver: changedEntry.Driver,
                    Pensum: { ID: pensumId },
                    MixingPlant: changedEntry.MixingPlant && changedEntry.MixingPlant.ID ? changedEntry.MixingPlant : null,
                    Payload: changedEntry.Payload,
                    StartTime: changedEntry.StartTime,
                    Comment: changedEntry.Comment,
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                    this.setState((prevState) => {
                      return {
                        history: {
                          ...prevState.history,
                          currentStep: step - 1,
                        }
                      }
                    })
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                            ? Object.assign(obj, {
                              changedEntry: {
                                ...obj.changedEntry,
                                ID: errorOrResult.ID
                              }
                            })
                            : obj
                        ))
                      }
                    }));
                  }
                  getPensum(objToUpdate.ID)
                } else {
                  await deleteEntry("DriverEntries", changedEntry.ID)
                  getPensum(objToUpdate.ID)
                }
              }
              break;

            default:
              break;
          }
        }
      } else {
        updatePensum({ [objToUpdate.ID]: cleanObjToUpdate })
      }

      this.setState((prevState) => {
        return {
          history: {
            ...prevState.history,
            currentStep: direction ? isBackward ? step - 1 : step : null,
            chain: newChain !== null ? newChain : chain
          }
        }
      })
      if (wasDND) {
        this.handleHistoryChange(step - 1, "back", null)
      }
    }
  }

  handleNewObjectInHistory = (pensumBefore, pensumAfter, changedEntryType, changedEntry, entityID, changeTypeOfEntry, isDeletedPensum, wasDND) => {
    const { history, history: { chain, currentStep } } = this.state

    const newChain = chain.slice(0, currentStep + 1)
    

    newChain.push({
      pensumId: pensumBefore.ID,
      objectBefore: pensumBefore,
      objectAfter: pensumAfter,
      changedEntryType: changedEntryType,
      changedEntry: changedEntry,
      changeTypeOfEntry: changeTypeOfEntry,
      entityID: entityID,
      isDeletedPensum: isDeletedPensum,
      wasDND: wasDND
    })

    /*
      handle problem when:
      delete entry, step back (revert 1),
      delete entry => bad currentstep calculation
    */

    if(changedEntryType === "EmployeeEntry" || changedEntryType === "EquipmentEntry") {
        this.setState((prevstate) => ({
          hint: prevstate.hint+1
        }))
    }
      
    this.setState({
      history: {
        ...history,
        chain: newChain,
        currentStep: newChain.length === 1 ? 0 : currentStep + 1
      }
    })
  }

  updatePensumWithTime = (e) => {
    const {
      pensumsToUpdateFromDND,
      newChainInHistory,
      pensumIDFromDND,
      pensumStartTime,
      isNewPartitionFromDND,
      destinationPartitionIndex
    } = this.state
    const { updatePensum, showNotification } = this.props

    let errorOrResult
    if (Object.keys(pensumsToUpdateFromDND).length !== 0 && pensumsToUpdateFromDND.constructor === Object) {
      Object.keys(pensumsToUpdateFromDND).forEach(async key => {
        let pensum = pensumsToUpdateFromDND[key]
        if (Number(key) === pensumIDFromDND) {
          pensum = {
            ...pensum,
            StartTime: moment.duration(pensumStartTime).toISOString()
          }
        }
        if (isNewPartitionFromDND) {
          pensum = {
            ...pensum,
            PartitionIndex: Number(destinationPartitionIndex)
          }
        }
        errorOrResult = await updatePensum({ [key]: pensum })
      })
    }

    if (errorOrResult instanceof Error) {
      showNotification("UNSUCCESSFUL_UPDATE_PENSUM", "error")
    } else {
      this.setState(prevState => {
        const { history, history: { chain, currentStep } } = prevState

        const newChainInHistoryObject = {
          ...newChainInHistory,
          objectAfter: {
            ...newChainInHistory.objectAfter,
            StartTime: moment.duration(pensumStartTime).toISOString()
          }
        }

        chain.slice(0, currentStep + 1)
        chain.push(newChainInHistoryObject)

        return ({
          history: {
            ...history,
            currentStep: chain.length === 1 ? 0 : currentStep + 1
          }
        })
      })
    }

    this.closeConfirmStartTime()
  }

  closeConfirmStartTime = () => {
    this.setState({
      timeRangeStart: null,
      timeRangeEnd: null,
      destinationPartition: null,
      cellDay: null,
      pensumIDFromDND: null,
      pensumsToUpdateFromDND: null,
      newChainInHistory: null,
      openConfirmStartTime: false,
      isValidStartTime: true
    })
  }

  handlePensumStartTimeChange = event => {
    const { destinationPartition } = this.state
    const { value } = event.target
    let isValidStartTime = true

    if (destinationPartition && destinationPartition.StartTime && destinationPartition.EndTime) {
      const { cellDay } = this.state
      isValidStartTime = checkIsValidStartTime(moment(cellDay), destinationPartition.StartTime, destinationPartition.EndTime, value)
    }

    this.setState({ pensumStartTime: value, isValidStartTime })
  }

  onDragStart = (start) => {
    const { draggableId } = start
    const type = draggableId.split("-")[0]
    const pensumId = draggableId.split("-")[1]
    const disableOtherPensums = type && type === "projectActivityEntry" || type === "mixtureEntry"
    if (disableOtherPensums) {
      this.setState({ activeDNDPensum: Number(pensumId) })
    }
  }

  onDragEnd = async result => {
    const { type, destination, source, draggableId } = result
    const { pensums } = this.props
    const { isPensumStartTimeAllowed, dayPartitions } = this.state
    const destinationPartitionIndex = type !== "pensumEntry" && destination && Number(destination.droppableId.split("/")[2])
    const destinationPartition = dayPartitions[destinationPartitionIndex]
    const cellDay = type !== "pensumEntry" && destination && destination.droppableId.split("/")[1]

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    if (type === "pensum") {
      const { updatePensum, showNotification } = this.props
      const movingPensum = pensums.find(pensum => pensum.ID === parseInt(draggableId.split("-")[1]))
      const movingPensumStartCopy = Object.assign({}, movingPensum)
      const start = source.droppableId
      const finish = destination.droppableId
      let objectsToUpdate = {}
      let timeRangeStart
      let timeRangeEnd
  
      if (start === finish) {
        const newTaskIds = pensums.sort((a, b) => { return a.SortOrder - b.SortOrder })
          .filter(pensum => (
            start.split("/")[1] === pensum.Day
            && pensum.Crew.ID === Number(start.split("/")[0])
            && pensum.PartitionIndex === destinationPartitionIndex
          ))
          .map(pensum => `pensum-${pensum.ID}`)
        const startTaskIds = [...newTaskIds]
        const draggedPensum = _.cloneDeep(movingPensum)
  
        newTaskIds.splice((source.index === 0 ? 0 : source.index), 1)
        newTaskIds.splice((destination.index === 0 ? 0 : destination.index), 0, draggableId)
  
        if (newTaskIds.length > 0) {
          let queryData = {}
          let newSortOrder
          let recalculated = false
  
          if (newTaskIds.length === 1) {
            newSortOrder = 0
            if (isPensumStartTimeAllowed) {
              if (destinationPartition.StartTime) {
                timeRangeStart = getTimeFromMs(getDurationInMs(destinationPartition.StartTime))
                timeRangeEnd = getTimeFromMs(getDurationInMs(destinationPartition.EndTime))
              }
            }
          } else {
            const indexOfNewTaskId = newTaskIds.indexOf(`pensum-${draggableId.split("-")[1]}`)
            const indexOfPreviousPensum = indexOfNewTaskId - 1
            const indexOfNextPensum = indexOfNewTaskId + 1
            let firstOldPensum
            let lastOldPensum
            let previousPensum
            let nextPensum
            let previousPensumSortOrder
            let nextPensumSortOrder
            pensums.forEach(pensum => {
            if (
              indexOfNewTaskId === 0
              && pensum.ID === parseInt(startTaskIds[0].split("-")[1])
            ) {
              firstOldPensum = pensum
            } else if (
              indexOfNewTaskId === newTaskIds.length - 1
              && pensum.ID === parseInt(startTaskIds[startTaskIds.length - 1].split("-")[1])
              ) {
                lastOldPensum = pensum
              } else {
                if (newTaskIds[indexOfPreviousPensum] && pensum.ID === parseInt(newTaskIds[indexOfPreviousPensum].split("-")[1])) {
                  previousPensum = pensum
                  previousPensumSortOrder = pensum.SortOrder
                }
                if (newTaskIds[indexOfNextPensum] && pensum.ID === parseInt(newTaskIds[indexOfNextPensum].split("-")[1])) {
                  nextPensum = pensum
                  nextPensumSortOrder = pensum.SortOrder
                }
              }
            })
  
            if (indexOfNewTaskId === 0) {
              newSortOrder = firstOldPensum.SortOrder - 16
              if (isPensumStartTimeAllowed) {
                timeRangeStart = destinationPartition.StartTime ? getTimeFromMs(getDurationInMs(destinationPartition.StartTime)) : null
                timeRangeEnd = firstOldPensum.StartTime ? getTimeFromMs(getDurationInMs(firstOldPensum.StartTime)) : null
              }
            } else if (indexOfNewTaskId === newTaskIds.length - 1) {
              newSortOrder = lastOldPensum.SortOrder + 16
              if (isPensumStartTimeAllowed) {
                timeRangeStart = lastOldPensum.StartTime ? getTimeFromMs(getDurationInMs(lastOldPensum.StartTime)) : null
                timeRangeEnd = destinationPartition.EndTime ? getTimeFromMs(getDurationInMs(destinationPartition.EndTime)) : null
              }
            } else {
              newSortOrder = previousPensumSortOrder + (nextPensumSortOrder - previousPensumSortOrder) / 2

              if (isPensumStartTimeAllowed) {
                timeRangeStart = previousPensum.StartTime ? getTimeFromMs(getDurationInMs(previousPensum.StartTime)) : null
                timeRangeEnd = nextPensum.StartTime ? getTimeFromMs(getDurationInMs(nextPensum.StartTime)) : null
              }
  
              if (newSortOrder <= previousPensumSortOrder || newSortOrder >= nextPensumSortOrder) {
                newSortOrder = -16
  
                newTaskIds.forEach(ID => {
               /*    const id = Number(ID.split("-")[1]) */
                  const ModificationTimeUtc = moment().utc().format()
  
                  newSortOrder += 16
  
                  queryData = { SortOrder: newSortOrder, ModificationTimeUtc }
  
                  if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
                    objectsToUpdate[Number(ID.split("-")[1])] = {
                      ...objectsToUpdate[Number(ID.split("-")[1])], ...queryData
                    }
                  }
                })
  
                recalculated = true
              }
            }
  
            if (!recalculated) {
              draggedPensum.SortOrder = newSortOrder
              queryData = { ...queryData, SortOrder: newSortOrder, ModificationTimeUtc: moment().utc().format() }
  
              if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
                objectsToUpdate[draggableId.split("-")[1]] = {
                  ...objectsToUpdate[draggableId.split("-")[1]], ...queryData
                }
              }
            }
          }
        }

        if (!isPensumStartTimeAllowed) {
          let errorOrResult
          if (Object.keys(objectsToUpdate).length !== 0 && objectsToUpdate.constructor === Object) {
            Object.keys(objectsToUpdate).forEach(async key => {
              errorOrResult = await updatePensum({ [key]: objectsToUpdate[key] })
            })
          }
      
          if (errorOrResult instanceof Error) {
            showNotification("UNSUCCESSFUL_UPDATE_PENSUM", "error")
          } else {
            const { history, history: { chain, currentStep } } = this.state
            const movingPensumFinishCopy = Object.assign({}, draggedPensum)
    
            chain.slice(0, currentStep + 1)
    
            chain.push({
              pensumId: movingPensumStartCopy.ID,
              objectBefore: movingPensumStartCopy,
              objectAfter: movingPensumFinishCopy
            })
    
            this.setState({
              history: {
                ...history,
                currentStep: chain.length === 1 ? 0 : currentStep + 1
              }
            })
          }
        } else {
          const movingPensumFinishCopy = Object.assign({}, draggedPensum)

          this.setState({
            timeRangeStart,
            timeRangeEnd,
            destinationPartition,
            cellDay,
            pensumIDFromDND: draggedPensum.ID,
            pensumsToUpdateFromDND: objectsToUpdate,
            newChainInHistory: {
              pensumId: movingPensumStartCopy.ID,
              objectBefore: movingPensumStartCopy,
              objectAfter: movingPensumFinishCopy
            },
            pensumStartTime: "00:00",
            isValidStartTime:
              destinationPartition.StartTime
                ? checkIsValidStartTime(moment(cellDay), destinationPartition.StartTime, destinationPartition.EndTime, "00:00")
                : true,
            openConfirmStartTime: true
          })
        }
  
        return
      }

      // Moving from one cell to another
      const finishTaskIds = pensums.sort((a, b) => { return a.SortOrder - b.SortOrder })
        .filter(pensum => (
          finish.split("/")[1] === pensum.Day
          && pensum.Crew.ID === Number(finish.split("/")[0])
          && pensum.PartitionIndex === destinationPartitionIndex
        ))
        .map(pensum => `pensum-${pensum.ID}`)
      const finishOriginalTaskIds = [...finishTaskIds]
      finishTaskIds.splice(destination.index, 0, draggableId)
  
      let crewToAssign
  
      this.state.crews.forEach(crew => {
        if (crew.ID === parseInt(destination.droppableId.split("/")[0])) {
          crewToAssign = crew
        }
      })

      let queryData = {}
      const draggedPensum = _.cloneDeep(movingPensum)
      const columnDay = destination.droppableId.split("/")[1]
      const modificationTime = moment().utc().format()
      if (crewToAssign !== undefined && crewToAssign.ID !== draggedPensum.Crew.ID) {
        draggedPensum.Crew = crewToAssign
        queryData = { ...queryData, Crew: { ID: crewToAssign.ID }, ModificationTimeUtc: modificationTime }
      }
      if (columnDay !== undefined && columnDay !== draggedPensum.Day) {
        draggedPensum.Day = columnDay
        queryData = { ...queryData, Day: columnDay, ModificationTimeUtc: modificationTime }
      }
      draggedPensum.ModificationTimeUtc = modificationTime
  
      if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
        objectsToUpdate[draggedPensum.ID] = {
          ...objectsToUpdate[draggedPensum.ID], ...queryData
        }
      }
  
      if (finishTaskIds.length > 0) {
        let queryData = {}
        let newSortOrder
        let recalculated = false

        if (draggedPensum.PartitionIndex !== destinationPartitionIndex) {
          queryData = { PartitionIndex: destinationPartitionIndex }
        }
  
        if (finishTaskIds.length === 1) {
          newSortOrder = 0
          if (isPensumStartTimeAllowed) {
            if (destinationPartition.StartTime) {
              timeRangeStart = getTimeFromMs(getDurationInMs(destinationPartition.StartTime))
              timeRangeEnd = getTimeFromMs(getDurationInMs(destinationPartition.EndTime))
            }
          }
        } else {
          const indexOfNewTaskId = finishTaskIds.indexOf(`pensum-${draggableId.split("-")[1]}`)
          const indexOfPreviousPensum = indexOfNewTaskId - 1
          const indexOfNextPensum = indexOfNewTaskId
          let firstOldPensum
          let lastOldPensum
          let previousPensum
          let nextPensum
          let previousPensumSortOrder
          let nextPensumSortOrder
          pensums.forEach(pensum => {
            if (
              indexOfNewTaskId === 0
              && pensum.ID === parseInt(finishOriginalTaskIds[0].split("-")[1])
            ) {
              firstOldPensum = pensum
            } else if (
              indexOfNewTaskId === finishTaskIds.length - 1
              && pensum.ID === parseInt(finishOriginalTaskIds[finishOriginalTaskIds.length - 1].split("-")[1])
            ) {
              lastOldPensum = pensum
            } else {
              if (finishOriginalTaskIds[indexOfPreviousPensum] && pensum.ID === parseInt(finishOriginalTaskIds[indexOfPreviousPensum].split("-")[1])) {
                previousPensum = pensum
                previousPensumSortOrder = pensum.SortOrder
              }
              if (finishOriginalTaskIds[indexOfNextPensum] && pensum.ID === parseInt(finishOriginalTaskIds[indexOfNextPensum].split("-")[1])) {
                nextPensum = pensum
                nextPensumSortOrder = pensum.SortOrder
              }
            }
          })

          if (indexOfNewTaskId === 0) {
            newSortOrder = firstOldPensum.SortOrder - 16
            if (isPensumStartTimeAllowed) {
              timeRangeStart = destinationPartition.StartTime ? getTimeFromMs(getDurationInMs(destinationPartition.StartTime)) : null
              timeRangeEnd = firstOldPensum.StartTime ? getTimeFromMs(getDurationInMs(firstOldPensum.StartTime)) : null
            }
          } else if (indexOfNewTaskId === finishTaskIds.length - 1) {
            newSortOrder = lastOldPensum.SortOrder + 16
            if (isPensumStartTimeAllowed) {
              timeRangeStart = lastOldPensum.StartTime ? getTimeFromMs(getDurationInMs(lastOldPensum.StartTime)) : null
              timeRangeEnd = destinationPartition.EndTime ? getTimeFromMs(getDurationInMs(destinationPartition.EndTime)) : null
            }
          } else {
            newSortOrder = previousPensumSortOrder + (nextPensumSortOrder - previousPensumSortOrder) / 2

            if (isPensumStartTimeAllowed) {
              timeRangeStart = previousPensum.StartTime ? getTimeFromMs(getDurationInMs(previousPensum.StartTime)) : null
              timeRangeEnd = nextPensum.StartTime ? getTimeFromMs(getDurationInMs(nextPensum.StartTime)) : null
            }

            if (newSortOrder <= previousPensumSortOrder || newSortOrder >= nextPensumSortOrder) {
              newSortOrder = -16
  
              finishTaskIds.forEach(ID => {
                const id = Number(ID.split("-")[1])
                const ModificationTimeUtc = moment().utc().format()
  
                newSortOrder += 16
  
                queryData = { SortOrder: newSortOrder, ModificationTimeUtc }
  
                if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
                  objectsToUpdate[id] = {
                    ...objectsToUpdate[id], ...queryData
                  }
                }
              })
  
              recalculated = true
            }
          }
        }
  
        if (!recalculated) {
          movingPensum.SortOrder = newSortOrder
          draggedPensum.SortOrder = newSortOrder
  
          queryData = { ...queryData, SortOrder: newSortOrder, ModificationTimeUtc: moment().utc().format() }
  
          if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
            objectsToUpdate[draggableId.split("-")[1]] = {
              ...objectsToUpdate[draggableId.split("-")[1]], ...queryData
            }
          }
        }
      }
  
      if (!isPensumStartTimeAllowed) {
        /*
          let errorOrResult
          if (Object.keys(objectsToUpdate).length !== 0 && objectsToUpdate.constructor === Object) {
            errorOrResult = await updatePensum({ [Object.keys(objectsToUpdate)[0]]: objectsToUpdate[Object.keys(objectsToUpdate)[0]] })
          }
          
          TODO:
            CHECK WHY: DRAGGED PENSUM DISAPPEARS AFTER DROP AND APPEAR AFTER REQUEST SUCCESS
        */
        let errorOrResult
        if (Object.keys(objectsToUpdate).length !== 0 && objectsToUpdate.constructor === Object) {
          Object.keys(objectsToUpdate).forEach(async key => {
            errorOrResult = await updatePensum({ [key]: objectsToUpdate[key] })
          })
        }
  
        if (errorOrResult instanceof Error) {
          showNotification("UNSUCCESSFUL_UPDATE_PENSUM", "error")
        } else {
          const { history, history: { chain, currentStep } } = this.state
          const movingPensumFinishCopy = Object.assign({}, draggedPensum)
    
          const newChain = Array.from(chain.slice(0, currentStep + 1))  
          newChain.push({
            pensumId: movingPensumStartCopy.ID,
            objectBefore: movingPensumStartCopy,
            objectAfter: movingPensumFinishCopy
          })
  
          this.setState({
            history: {
              ...history,
              currentStep: newChain.length === 1 ? 0 : currentStep + 1,
              chain: newChain
            }
          })
        }
      } else {
        const movingPensumFinishCopy = Object.assign({}, draggedPensum)

        this.setState({
          timeRangeStart,
          timeRangeEnd,
          destinationPartition,
          destinationPartitionIndex,
          cellDay,
          pensumIDFromDND: draggedPensum.ID,
          pensumsToUpdateFromDND: objectsToUpdate,
          isNewPartitionFromDND: true,
          newChainInHistory: {
            pensumId: movingPensumStartCopy.ID,
            objectBefore: movingPensumStartCopy,
            objectAfter: {
              ...movingPensumFinishCopy,
              PartitionIndex: Number(destinationPartitionIndex)
            }
          },
          pensumStartTime: "00:00",
          isValidStartTime:
              destinationPartition.StartTime
                ? checkIsValidStartTime(moment(cellDay), destinationPartition.StartTime, destinationPartition.EndTime, "00:00")
                : true,
          openConfirmStartTime: true
        })
      }
    }

    if (type === "mixtureEntry") {
/*       const { editEntry } = this.props */
      const sourcePensumID = source.droppableId
      const destinationPensumID = destination.droppableId

      if (sourcePensumID !== destinationPensumID) return

/*       const mixtureEntryID = draggableId */
/*       const sourcePensum = pensums.find(obj => obj.ID === sourcePensumID) */
/*       const mixtureEntries = sourcePensum.MixtureEntries */
  /*     const mixtureEntry = mixtureEntries.find(obj => obj.ID === mixtureEntryID) */

      /*mixtureEntries

      const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
      );

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };

      await editEntry(mixtureEntryID, "MixtureEntries", {
        Mixture: { ...mixtureEntry.Mixture },
        MixtureAmount: mixtureEntry.MixtureAmount,
        Comment: mixtureEntry.Comment,
        SortOrder: newSortOrder,
        Pensum: { ID: destinationPensumID },
      })*/
    }

    if (type === "pensumEntry") {
      const { updatePensumObj, editEntry } = this.props
      const sourcePensumID = source.droppableId
      const destinationPensumID = destination.droppableId
      const sourcePensum = pensums.find(obj => obj.ID === sourcePensumID)
      const destinationPensum = pensums.find(obj => obj.ID === destinationPensumID)
      const entryType = draggableId.split("-")[0]
      const entryID = Number(draggableId.split("-")[2])
      const sourceIndex = source.index
      const destinationIndex = destination.index
      
      if (entryType === "mixtureEntry" || entryType === "projectActivityEntry") {
        const resourceType = entryType === "mixtureEntry" ? "MixtureEntries" : "ProjectActivityEntries"
        const entries = [
          ...sourcePensum.ProjectActivityEntries,
          ...sourcePensum.MixtureEntries.sort((a, b) => {
            if (a.SortOrder - b.SortOrder !== 0) {
              return a.SortOrder - b.SortOrder
            } else {
              return a.ID - b.ID
            }
          }),
          ...sourcePensum.DriverEntries.sort((a, b) => {
            if (a.SortOrder - b.SortOrder !== 0) {
              return a.SortOrder - b.SortOrder
            } else {
              return a.ID - b.ID
            }
          })
        ]
        const sourceEntries = sourcePensum[resourceType]
        const firstSourceEntry = sourceEntries[0]
        const firstSourceEntryIndex = entries.map(e => e.ID).indexOf(firstSourceEntry.ID)
        const lastSourceEntry = sourceEntries[sourceEntries.length - 1]
        const lastSourceEntryIndex = entries.map(e => e.ID).indexOf(lastSourceEntry.ID)

        const movedEntry = entries[sourceIndex]
        const newEntries = [...sourcePensum[resourceType]]
        newEntries.splice(sourceIndex, 1)
        newEntries.splice(destinationIndex, 0, movedEntry)

        let newSortOrder

        if (destinationIndex <= firstSourceEntryIndex) {
          newSortOrder = firstSourceEntry.SortOrder - 16
          await editEntry(movedEntry.ID, resourceType, {
            ...movedEntry,
            SortOrder: newSortOrder,
          })
        } else if (destinationIndex >= lastSourceEntryIndex) {
          newSortOrder = lastSourceEntry.SortOrder + 16
          await editEntry(movedEntry.ID, resourceType, {
            ...movedEntry,
            SortOrder: newSortOrder,
          })
        } else {
          const previousEntrySO = newEntries[destinationIndex - 1].SortOrder
          const nextEntrySO = newEntries[destinationIndex + 1].SortOrder
          newSortOrder = previousEntrySO + (nextEntrySO - previousEntrySO) / 2

          if (newSortOrder <= previousEntrySO || newSortOrder >= nextEntrySO) {
            newSortOrder = -16
            newEntries.forEach(async entry => {
              newSortOrder += 16

              await editEntry(entry.ID, resourceType, {
                ...entry,
                SortOrder: newSortOrder,
              })
            })
          } else {
            await editEntry(movedEntry.ID, resourceType, {
              ...movedEntry,
              SortOrder: newSortOrder,
            })
          }
        }
      }

      if (entryType === "driverEntry") {
        if (sourcePensumID === destinationPensumID) return
  
        const driverEntry = sourcePensum.DriverEntries.find(obj => obj.ID === entryID)
  
        await editEntry(entryID, "DriverEntries", {
          Driver: { ID: driverEntry.Driver.ID, Name: driverEntry.Driver.Name, DriverCompany: driverEntry.Driver.DriverCompany ? { ID: driverEntry.Driver.DriverCompany.ID, Name: driverEntry.Driver.Name } : null },
          MixingPlant: driverEntry.MixingPlant ? { ID: driverEntry.MixingPlant.ID, Name: driverEntry.MixingPlant.Name } : null,
          Pensum: { ID: destinationPensumID },
          Payload: driverEntry.Payload,
          StartTime: driverEntry.StartTime,
          Comment: driverEntry.Comment
        })

        updatePensumObj({
          ...sourcePensum,
          ProjectActivityEntries: Object.values(sourcePensum.ProjectActivityEntries).map(obj => obj.ID),
          EmployeeEntries: Object.values(sourcePensum.EmployeeEntries).map(obj => obj.ID),
          EquipmentEntries: Object.values(sourcePensum.EquipmentEntries).map(obj => obj.ID),
          MixtureEntries: Object.values(sourcePensum.MixtureEntries).map(obj => obj.ID),
          DriverEntries: Object.values(sourcePensum.DriverEntries).map(obj => obj.ID).filter(ID => ID !== entryID)
        })
        updatePensumObj({
          ...destinationPensum,
          ProjectActivityEntries: Object.values(destinationPensum.ProjectActivityEntries).map(obj => obj.ID),
          EmployeeEntries: Object.values(destinationPensum.EmployeeEntries).map(obj => obj.ID),
          EquipmentEntries: Object.values(destinationPensum.EquipmentEntries).map(obj => obj.ID),
          MixtureEntries: Object.values(destinationPensum.MixtureEntries).map(obj => obj.ID),
          DriverEntries: [...Object.values(destinationPensum.DriverEntries).map(obj => obj.ID), entryID]
        })
      }
    }

    this.setState({ activeDNDPensum: undefined })
  }

  setPensumState = async (e) => {
    e.preventDefault()
    const { setPensumState, getPensum } = this.props
    const { pensumMessage, pensumsToConfirm: pensums } = this.state
    pensums.forEach(async (pensum) => {
      let message
      if (pensum.allowMessage && pensumMessage) {
        message = pensumMessage
      }
      await setPensumState(pensum.ID, { StateCode: PENSUM_STATE_CODES.ConfirmedByDispatcher }, { Message: message })
      this.setState({ openConfirmAllPensums: false, pensumMessage: undefined })
      await getPensum(pensum.ID)
    })
  }

  updatePlanState = (newState) => {
    this.setState(prevState => ({
      ...prevState,
      ...newState
    }))
  }

  sortBySortOrder = (a, b) => {
    if (a.pensum.SortOrder - b.pensum.SortOrder !== 0) {
      return a.pensum.SortOrder - b.pensum.SortOrder
    } else {
      return a.pensum.ID - b.pensum.ID
    }
  }

  hint = (pensums) => {
    if (pensums && pensums.length > 0) {
      //splitting pensums by day
      const dailyPensums = [{"Day": pensums[0].Day, "pensums": [pensums[0]]}];
      for(let j = 1; j < pensums.length; j++){
          let count = 0;
          for(let i = 0; i < dailyPensums.length; i++){
            if(pensums[j].Day === dailyPensums[i].Day){
              dailyPensums[i].pensums.push(pensums[j]);
              count++;
            }
          }
          if(!count){
            dailyPensums.push({Day: pensums[j].Day, pensums: [pensums[j]]});
          }
      }
      const dailyEmployee = [];
      const dailyEquipment = [];
      dailyPensums.map(dailyPensum => {
        let employeesofpensum = [];
        let equipmentsofpensum = [];
        for(let i = 0; i < dailyPensum.pensums.length; i++) {
          for(let emp = 0; emp < dailyPensum.pensums[i].EmployeeEntries.length; emp++){
            employeesofpensum.push(dailyPensum.pensums[i].EmployeeEntries[emp].Employee.ID);
          }
          for(let equ = 0; equ < dailyPensum.pensums[i].EquipmentEntries.length; equ++){
            equipmentsofpensum.push(dailyPensum.pensums[i].EquipmentEntries[equ].Equipment.ID)
          }
        }
        dailyEmployee.push({"Day": dailyPensum.Day, "employees": employeesofpensum})
        dailyEquipment.push({"Day": dailyPensum.Day, "equipments": equipmentsofpensum})
      })
      dailyEmployee.map(e => e.employees.sort());
      dailyEquipment.map(e => e.equipments.sort()); 

      let duplicatedhint = [];
      for(let i = 0; i < dailyPensums.length; i++){
        duplicatedhint[i] = {"Day": dailyEmployee[i].Day, "duplicatedEmployeeIDs": dailyEmployee[i].employees.filter((a, i, aa) => aa.indexOf(a) === i && aa.lastIndexOf(a) !== i), "duplicatedEquipmentIDs": dailyEquipment[i].equipments.filter((a, i, aa) => aa.indexOf(a) === i && aa.lastIndexOf(a) !== i)};
      }
      return duplicatedhint;
    }
  }

  maxMixtureAmountOfCrewPerDay = (pensums) => {
    if (pensums && pensums.length > 0) {
      //splitting pensums by day and crew
      const dailyPensums = [{"Day": pensums[0].Day, "Crew": pensums[0].Crew.ID, "pensums": [pensums[0]]}];
      for(let j = 1; j < pensums.length; j++){
          let count = 0;
          for(let i = 0; i < dailyPensums.length; i++){
            if(pensums[j].Day === dailyPensums[i].Day && pensums[j].Crew.ID === dailyPensums[i].Crew){
              dailyPensums[i].pensums.push(pensums[j]);
              count++;
            }
          }
          if(!count){
            dailyPensums.push({"Day": pensums[j].Day, "Crew": pensums[j].Crew.ID, "pensums": [pensums[j]]});
          }
      }
      const maxMixtureAmountOfCrewPerDay = [];
      dailyPensums.map(p => {
        let mixtures = [];
        p.pensums.map(pen =>{
          mixtures.push(pen.TotalMixtureAmount);
        });
        let maxmixture = Math.max.apply(Math, mixtures);
        maxMixtureAmountOfCrewPerDay.push({"Day": p.Day, "Crew": p.Crew, "MaxMixture": maxmixture});
      })
      
      return maxMixtureAmountOfCrewPerDay;
    }
    
  }
companyFilter = (pensum) => {
/*   console.log("pensum", pensum) */
    let isCompanyIncluded = false;
    const { selectedCompanies } = this.state;
    selectedCompanies && pensum.DriverEntries && pensum.DriverEntries.map((de) => {
      let ID;
      if(de.Driver && de.Driver.DriverCompany){
        ID = de.Driver.DriverCompany.ID
      }
      if(selectedCompanies.includes(ID)){
        isCompanyIncluded = true;
/*         console.log("ID",ID)
        console.log("selectedCompanies",selectedCompanies) */
      }
    })
    return isCompanyIncluded
  }

  render() {
    const {
      isMounted,
      crews,
      dates,
      customer,
      supervisor,
      selectedCrew,
      mixingPlant,
      selectedProjects,
      selectedCostCenters,
      selectedDrivers,
      selectedCompanies,
      selectedTypeofEquipments,
      company,
      selectedInvoiced,
      selectedCancelled,
      history,
      showSwappedTable,
      isPensumStartTimeAllowed,
      dayPartitions,
      openConfirmStartTime,
      isValidStartTime,
      pensumStartTime,
      timeRangeStart,
      timeRangeEnd,
      destinationPartition,
      view,
    } = this.state;
   /*  console.log("showSwappedTable",this.state.showSwappedTable) */
    const { projects, specialDays, t, userCrewName, pensums } = this.props
    const mixturesByMixingPlant = {}
    const shifts = []
    let driverPayloadSumArray = []
    let mixtureAmountSumArray = []
    let totalMixtureAmountSumArray = []
    let showSumDetails = false
    let filteredCrews = crews && crews.length > 0 ? crews : ["", "", "", "", "", ""]
    let filteredPensums = pensums
    let duplicatedhint;
    let maxMixtureAmountOfCrewPerDay
    
    if (isMounted && filteredPensums) {
    /*{ console.log("pensums: ", filteredPensums);}
    { console.log("selectedCompanies",selectedCompanies)} */ 
      duplicatedhint = this.hint(filteredPensums);
      maxMixtureAmountOfCrewPerDay = this.maxMixtureAmountOfCrewPerDay(filteredPensums);
      filteredPensums = filteredPensums.filter(pensum => {
        let CostCenter
        //console.log("pensum.DriverEntries.Driver.DriverCompany.ID",selectedCompanies && pensum.DriverEntries && pensum.DriverEntries.Driver && pensum.DriverEntries.Driver.DriverCompany.ID)
        projects.forEach(project => {
          if (pensum.Project && project.ID === pensum.Project.ID) CostCenter = project.CostCenter
        })
        
        return (
          ((customer && Array.from(customer).includes(pensum.Project.Customer.ID)) || ((customer !== undefined && customer !== undefined && customer.length === 0)) || customer === undefined || customer === null)
          && ((supervisor && Array.from(supervisor).includes(pensum.Project.Supervisor.ID)) || (supervisor !== undefined && supervisor !== undefined && supervisor.length === 0) || supervisor === undefined || supervisor === null)
          && ((selectedCrew && Array.from(selectedCrew).includes(pensum.Crew.ID)) || (selectedCrew !== undefined && selectedCrew !== undefined && selectedCrew.length === 0) || selectedCrew === undefined || selectedCrew === null)
          && ((selectedProjects && Array.from(selectedProjects).includes(pensum.Project.ID)) || (selectedProjects !== undefined && selectedProjects !== undefined && selectedProjects.length === 0) || selectedProjects === undefined || selectedProjects === null)
          && ((selectedCostCenters && CostCenter && Array.from(selectedCostCenters).includes(CostCenter)) || (selectedCostCenters !== undefined && selectedCostCenters !== undefined && selectedCostCenters.length === 0) || selectedCostCenters === undefined || selectedCostCenters === null)
          && ((selectedInvoiced && (
            selectedInvoiced === INVOICED_TYPES.INVOICED && pensum.HasInvoice
            || (selectedInvoiced === INVOICED_TYPES.NOT_INVOICED && !pensum.HasInvoice))
          ) || selectedInvoiced === undefined || selectedInvoiced === null)
          && (selectedCancelled && (
            selectedCancelled === CANCELLED_TYPES.ALL
            || (selectedCancelled === CANCELLED_TYPES.CANCELLED && pensum.StateCode === PENSUM_STATE_CODES.Canceled)
            || (selectedCancelled === CANCELLED_TYPES.NOT_CANCELLED && pensum.StateCode !== PENSUM_STATE_CODES.Canceled)
          ))
          && ((mixingPlant && pensum.MixtureEntries.some(({ Mixture: { Order: { MixingPlant: { ID } } } }) => Array.from(mixingPlant).includes(ID))) || (mixingPlant !== null && mixingPlant !== undefined && mixingPlant.length === 0) || mixingPlant === undefined || mixingPlant === null)
          && ((selectedDrivers && pensum.DriverEntries.some(({ Driver: { ID } }) => Array.from(selectedDrivers).includes(ID))) || (selectedDrivers !== null && selectedDrivers !== undefined && selectedDrivers.length === 0) || selectedDrivers === undefined || selectedDrivers === null)
        )
          && ((selectedTypeofEquipments && pensum.EquipmentEntries.some(({ Equipment: { ID } }) => Array.from(selectedTypeofEquipments).includes(ID))) || (selectedTypeofEquipments !== null && selectedTypeofEquipments !== undefined && selectedTypeofEquipments.length === 0) || selectedTypeofEquipments === undefined || selectedTypeofEquipments === null)
          //&& ((selectedCompanies && pensum.DriverEntries && pensum.DriverEntries.map((de) => {de.Driver && de.Driver.DriverCompany && de.Driver.find(({DriverCompany: {ID}}) => Array.from(selectedCompanies.includes(ID)))})) || (selectedCompanies !== null && selectedCompanies !== undefined  && selectedCompanies.length === 0) || selectedCompanies === undefined || selectedCompanies === null)
          //&& ((selectedCompanies && pensum.DriverEntries && pensum.DriverEntries.Driver && pensum.DriverEntries.Driver.DriverCompany && pensum.DriverEntries.Driver.some(({DriverCompany: {ID} } ) => Array.from(selectedCompanies).includes(ID))) || (selectedCompanies !== null && selectedCompanies !== undefined && selectedCompanies.length === 0) || selectedCompanies === undefined || selectedCompanies === null)
          //&& ((selectedCompanies && pensum.DriverEntries.some(({DriverCompany: {ID} } ) => Array.from(selectedCompanies).includes(ID))) || (selectedCompanies !== null && selectedCompanies !== undefined && selectedCompanies.length === 0) || selectedCompanies === undefined || selectedCompanies === null)
         /*  && ((selectedCompanies && pensum.DriverEntries && pensum.DriverEntries.forEach((de) => {
            let ID;
            if(de.Driver && de.Driver.DriverCompany){
              ID = de.Driver.DriverCompany.ID
            }
            Array.from(selectedCompanies.includes(ID));})) || (selectedCompanies !== null && selectedCompanies !== undefined  && selectedCompanies.length === 0) || selectedCompanies === undefined || selectedCompanies === null) */
            && ((selectedCompanies && this.companyFilter(pensum)) || (selectedCompanies !== null && selectedCompanies !== undefined  && selectedCompanies.length === 0) || selectedCompanies === undefined || selectedCompanies === null)
          })
        if (selectedCrew) {
          filteredCrews = crews.filter(crew => Array.from(selectedCrew).includes(crew.ID) || selectedCrew.length === 0)
      }
      if (view.id === 2 && userCrewName !== null) {
        filteredCrews = crews.filter(crew => crew.Name.toLowerCase() === userCrewName.toLowerCase())
      }
      
      dates.forEach(day => {
        const currDay = day
        dayPartitions.forEach((partition, pIndex) => {
          const pensumsToConfirm = []
          let mixAmountSum = 0
          let driverPayloadSum = 0
          let mixtureAmountSum = 0
          let showConfirmAllPensums = false
          let requirePensumStateChangeMessage
          let allowPensumStateChangeMessage
          let addedCrews = []
          
          filteredPensums.forEach(pensum => {
            if (currDay === pensum.Day && pIndex === pensum.PartitionIndex) {
              if (pensum.MixtureEntries) {
                pensum.MixtureEntries.forEach(mix => {
                  const { Mixture: { Order: { MixingPlant: { Name } } } } = mix
                  if (mix.MixtureAmount) {
                    if (mixturesByMixingPlant[currDay] && mixturesByMixingPlant[currDay][pIndex]) {
                      if (mixturesByMixingPlant[currDay][pIndex][Name]) {
                        mixturesByMixingPlant[currDay][pIndex][Name] += mix.MixtureAmount
                        if (mix.MixtureAmount > 0) {
                          showSumDetails = true                        
                        }
                      } else {
                        mixturesByMixingPlant[currDay][pIndex][Name] = mix.MixtureAmount
                        if (mix.MixtureAmount > 0) {
                          showSumDetails = true                        
                        }
                      }
                    } else {
                      mixturesByMixingPlant[currDay] = {
                        ...mixturesByMixingPlant[currDay],
                        [pIndex]: {
                          [Name]: null
                        }
                      }
                      mixturesByMixingPlant[currDay][pIndex][Name] = mix.MixtureAmount
                      if (mix.MixtureAmount > 0) {
                        showSumDetails = true                        
                      }
                    }
                  }
                  mixAmountSum += mix.MixtureAmount
                })
              }
              if (pensum.DriverEntries) {
                pensum.DriverEntries.forEach(driver => {
                  driverPayloadSum += driver.Payload
                })
              }
              maxMixtureAmountOfCrewPerDay.map(max => {
                if(max.Day === currDay && max.Crew === pensum.Crew.ID && !addedCrews.includes(pensum.Crew.ID)){
                  mixtureAmountSum += max.MaxMixture;
                  addedCrews.push(max.Crew);
                }
              })
              
              //mixtureAmountSum += pensum.TotalMixtureAmount

              const stateChangeRequireMessage = (pensum.FlagsCode & PENSUM_FLAGS.RequireStateChangeMessage) !== 0
              const stateChangeAllowMessage = (pensum.FlagsCode & PENSUM_FLAGS.AllowStateChangeMessage) !== 0
              
              if ((pensum.FlagsCode & PENSUM_FLAGS.AllowStateChange_ConfirmedByDispatcher) !== 0) {
                showConfirmAllPensums = true
                pensumsToConfirm.push({
                  ID: pensum.ID,
                  allowMessage: stateChangeRequireMessage || stateChangeAllowMessage,
                })
              }
              
              if (stateChangeRequireMessage) {
                requirePensumStateChangeMessage = true
              }
              
              if (stateChangeAllowMessage) {
                allowPensumStateChangeMessage = true
              }
            }
            
          })
          if (driverPayloadSum > 0 || mixtureAmountSum > 0 || mixAmountSum > 0) {
            showSumDetails = true
          }
          driverPayloadSumArray.push(driverPayloadSum)
          totalMixtureAmountSumArray.push(mixtureAmountSum)
          mixtureAmountSumArray.push(mixAmountSum)
          
          shifts.push({
            partition: {
              ...partition,
              index: pIndex,
            },
            day,
            showConfirmAllPensums,
            requirePensumStateChangeMessage,
            allowPensumStateChangeMessage,
            pensumsToConfirm,
          })
        })
      })
    }
    return (
      <>
        {
          isMounted ? (
            <div>
              <Modal open={openConfirmStartTime} onClose={this.closeConfirmStartTime} className="modal">
                <div className="confirm-time-popup">
                  {(destinationPartition && destinationPartition.StartTime) && (
                    <div>
                      {t('valid-duration-range')}<span className="time-range">{getTimeFromMs(getDurationInMs(destinationPartition.StartTime))} - {getTimeFromMs(getDurationInMs(destinationPartition.EndTime))}</span>
                    </div>
                  )}
                  {!(timeRangeStart === "00:00" && timeRangeEnd === "24:00") && (
                    <div className="time-range-container">
                      {t('in-time-range')}<span className="time-range">{timeRangeStart} - {timeRangeEnd}</span>
                    </div>
                  )}
                  <FormControl style={{ marginLeft: 81 }}>
                    <InputLabel className="transformed-time-range-label" style={{ color: !isValidStartTime && 'red' }}>
                      {t('time')}
                    </InputLabel>
                    <TextField
                      id="pensumStartTime"
                      type="time"
                      value={pensumStartTime}
                      defaultValue="00:00"
                      onChange={this.handlePensumStartTimeChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          width: 217,
                          color: !isValidStartTime && 'red',
                          textAlign: 'right'
                        },
                        step: 300, // 5 min
                      }}
                    />
                  </FormControl>
                  <div className="confirm-time-buttonContainer">
                    <Button
                      type="submit"
                      style={{ marginRight: 7 }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={(e) => this.updatePensumWithTime(e)}
                      disabled={!isValidStartTime}
                    >
                      {t('confirm')}
                    </Button>
                    <Button
                      onClick={this.closeConfirmStartTime}
                      variant="contained"
                      color="inherit"
                      size="small"
                    >
                      {t('close')}
                    </Button>
                  </div>
                </div>
              </Modal>
              <Backdrop style={{ zIndex: 9 }} open={this.props.isFetching}>
                <CircularProgress color="secondary" />
              </Backdrop>
              <FilterContainer
                handleFromDateChange={this.handleFromDateChange}
                handleToDateChange={this.handleToDateChange}
                handleCustomerChange={this.handleCustomerChange}
                //2new
                handleCompanyChange={this.handleCompanyChange}
                handleTypeofEquipmentChange={this.handleTypeofEquipmentChange}
                handleSupervisorChange={this.handleSupervisorChange}
                handleCrewChange={this.handleCrewChange}
                handleMixingPlantChange={this.handleMixingPlantChange}
                handleProjectChange={this.handleProjectChange}
                handleCostCenterChange={this.handleCostCenterChange}
                handleDriverChange={this.handleDriverChange}
                handleInvoicedChange={this.handleInvoicedChange}
                handleViewChange={this.handleViewChange}
                handleCancelledChange={this.handleCancelledChange}
                handleSaveFilter={this.handleSaveFilter}
                userCrewName={userCrewName}
                filteredPensums={filteredPensums}
                filterMixingPlantIDs={mixingPlant}
                {...this.state}
              />
               {/*  //table switch
              <button onClick={() => this.setState({ showSwappedTable: !showSwappedTable })}>Switch table</button> */}
              <div style={{ margin: filteredCrews.length === 1 ? "0 auto" : null, width: view.id === 2 && filteredCrews.length === 1 ? 420 : null }}>
                {!showSwappedTable ? (
                  <>
                    <CrewContainer
                      crews={filteredCrews.sort((a, b) => {
                        if (a.DefaultSortOrder - b.DefaultSortOrder !== 0) {
                          return a.DefaultSortOrder - b.DefaultSortOrder
                        } else {
                          return a.ID - b.ID
                        }
                      })}
                      handleHistoryChange={this.handleHistoryChange}
                      history={history}
                    />
                    {/* <div>alma{console.log("render alma")}</div> */}
                    <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                      {shifts.map((row, x) => {
                        const {
                          day,
                          partition,
                          pensumsToConfirm,
                          showConfirmAllPensums,
                          requirePensumStateChangeMessage,
                          allowPensumStateChangeMessage,
                        } = row
                        const partitionIndex = row.partition.index
                        const momentDay = moment(day)
                        return (
                        <Container key={x}>
                          <FirstColumnContainer isFirst={x === 0} className="firstColumnContainer">
                            <div className="dateColumn" day={momentDay.day()}>
                              <div style={{
                                width: 72,
                                margin: "0 auto",
                                fontSize: 15,
                                padding: 4,
                                paddingTop: 2,
                                marginBottom: 2
                              }}>
                                <b style={{ fontSize: 12, fontWeight: 700 }}>{t('week')}</b> {momentDay.isoWeek()}
                              </div>
                              <div
                                className="day-container"
                                style={{
                                  width: '80%',
                                  margin: '0 auto',
                                  backgroundColor: momentDay.day() === 6 || momentDay.day() === 0 ? "rgb(70, 70, 70)" : "#fff",
                                  fontFamily: "Open Sans",
                                  webkitBoxShadow: '0 3px 2px -1px rgba(0, 0, 0, 0.85)',
                                  mozBoxShadow: '0 3px 2px -1px rgba(0, 0, 0, 0.85)',
                                  boxShadow: '0 3px 2px -1px rgba(0, 0, 0, 0.85)',
                                  color: momentDay.day() === 6 || momentDay.day() === 0 ? "#fdfdfd" : "#223b67",
                                  paddingBottom: 3,
                                  marginBottom: 5,
                                  borderRadius: 26,
                                  fontSize: 22
                                }}
                              >
                                {t(`day-${momentDay.day()}`)}
                              </div>
                              <div style={{ fontSize: 13 }}>
                                &nbsp;{momentDay.format("DD.MM.YYYY")}&nbsp;
                              </div>
                              <div style={{ marginTop: 5 }}>
                                {partition.IconIndex === 1 && (
                                  <MoonIcon style={{ color: "#b5b5b5", marginBottom: -5 }} />
                                )}
                                {partition.IconIndex === 0 && (
                                  <SunIcon style={{ color: "#ffe000", marginBottom: -6, marginRight: 4 }} />
                                )}
                                {partition.Name}
                              </div>
                            </div>
                            {driverPayloadSumArray[x] > 0 || totalMixtureAmountSumArray[x] > 0 || mixtureAmountSumArray[x] > 0 ?
                              <div
                                style={{
                                  width: 95,
                                  marginLeft: 4,
                                  textAlign: 'center',
                                  borderRadius: 2,
                                  paddingTop: 4,
                                  paddingBottom: 1
                                }}
                              >
                                {driverPayloadSumArray[x] > 0 ? (
                                  <div style={{
                                    marginBottom: 3,
                                    marginLeft: 4,
                                    paddingLeft: 10,
                                    color: "#f7f7f7",
                                    textAlign: "center"
                                  }}>
                                    <div style={{
                                      float: "left",
                                      width: 23,
                                      height: 20,
                                      borderBottom: "thin solid rgb(245,245,245)",
                                      display: "inline-block",
                                      textAlign: "center"
                                    }}>
                                      <DriverIcon style={{ color: "rgb(245,245,245)" }} />
                                    </div>
                                    <div id="driverPayloadSum" style={{
                                      display: "inline-block",
                                      height: 15,
                                      minWidth: 30,
                                      paddingTop: 2,
                                      paddingBottom: 2,
                                      fontFamily: "Oswald, sans-serif",
                                      letterSpacing: 0.5,
                                      fontSize: 13,
                                      color: "#fca623",
                                      textShadow: '0px 1px 1px rgba(0,0,0,0.8)',
                                      marginTop: 3
                                    }}>
                                      <div style={{ marginTop: -3, paddingLeft: 3, paddingRight: 3 }}>
                                        {driverPayloadSumArray[x]} to
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {totalMixtureAmountSumArray[x] > 0 ? (
                                  <div style={{
                                    marginBottom: 4,
                                    marginLeft: 4,
                                    paddingLeft: 10,
                                    color: "#f7f7f7",
                                    textAlign: "center"
                                  }}>
                                    <div style={{
                                      float: "left",
                                      width: 24,
                                      height: 20,
                                      display: "inline-block",
                                      textAlign: "center"
                                    }}>
                                      <DriverIcon style={{ color: "rgb(245,245,245)", position: 'absolute', left: 18 }} />
                                      <TimelineIcon style={{ position: 'absolute', left: 18, fontSize: 14, marginLeft: 2, marginTop: 4, color: '#223b67' }} />
                                    </div>
                                    <div id="totalMixtureAmountSum" style={{
                                      display: "inline-block",
                                      height: 15,
                                      minWidth: 30,
                                      marginTop: 3,
                                      paddingTop: 2,
                                      paddingBottom: 2,
                                      fontFamily: "Oswald, sans-serif",
                                      letterSpacing: 0.5,
                                      color: "#fca623",
                                      textShadow: '0px 1px 1px rgba(0,0,0,0.8)',
                                      fontSize: 13,
                                    }}>
                                      <div style={{ marginTop: -3, paddingLeft: 3, paddingRight: 3 }}>
                                        {totalMixtureAmountSumArray[x]} to
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {mixtureAmountSumArray[x] > 0 ? (
                                  <div style={{
                                    marginBottom: 2,
                                    marginLeft: 4,
                                    paddingLeft: 10,
                                    color: "#f7f7f7",
                                    textAlign: "center"
                                  }}>
                                    <div style={{
                                      float: "left",
                                      width: 24,
                                      height: 20,
                                      display: "inline-block",
                                      textAlign: "center"
                                    }}>
                                      <MixtureIcon style={{ color: "rgb(245,245,245)" }} />
                                    </div>
                                    <div id="mixtureAmountSum" style={{
                                      display: "inline-block",
                                      height: 15,
                                      minWidth: 30,
                                      marginTop: 3,
                                      paddingTop: 2,
                                      paddingBottom: 2,
                                      fontFamily: "Oswald, sans-serif",
                                      letterSpacing: 0.5,
                                      color: "#fca623",
                                      textShadow: '0px 1px 1px rgba(0,0,0,0.8)',
                                      fontSize: 13,
                                    }}>
                                      <div style={{ marginTop: -3, paddingLeft: 3, paddingRight: 3 }}>
                                        {mixtureAmountSumArray[x]} to
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              : null}
                            {(mixturesByMixingPlant[day] && mixturesByMixingPlant[day][partitionIndex] && view.id === 3) ? (
                              <div style={{
                                width: 94,
                                marginTop: 5,
                                marginLeft: 5,
                              }}>
                                {Object.keys(mixturesByMixingPlant[day][partitionIndex]).map((key, i) => (
                                  <div key={`${day}/${partitionIndex}/${key}`}
                                    style={{
                                      display: "inline-block",
                                      width: '100%',
                                      minHeight: 13,
                                      minWidth: 30,
                                      marginTop: 3,
                                      paddingTop: 4,
                                      paddingBottom: 2,
                                      background: 'rgba(0, 0, 0, 0.2)',
                                      fontFamily: "Open Sans",
                                      fontSize: 12,
                                      textAlign: 'center',
                                      borderRadius: 2
                                    }}
                                  >
                                    <b style={{ textShadow: '0px 1px 1px rgba(0,0,0,0.8)' }}>
                                      {key}
                                    </b>
                                    <div style={{ width: '100%' }}>
                                      <div style={{
                                        display: 'inline-block', minWidth: '30%',
                                        color: "#fca623", textShadow: '0px 1px 1px rgba(0,0,0,0.8)'
                                      }}>
                                        <b>{mixturesByMixingPlant[day][partitionIndex][key]}&nbsp;to</b>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : null}
                            {showConfirmAllPensums && (
                              <Tooltip
                                title={t('confirmAllPensumsByDispatcher')}
                                style={{ width: 55, height: 30, marginTop: 8, marginBottom: 4 }}
                              >
                                <Fab
                                  variant="extended"
                                  onClick={() => this.setState({
                                    openConfirmAllPensums: true,
                                    requirePensumStateChangeMessage,
                                    allowPensumStateChangeMessage,
                                    pensumsToConfirm,
                                    pensumDayToConfirm: `${t(`day-${momentDay.day()}`)} ${momentDay.format("DD.MM.YYYY")}`,
                                  })}
                                >
                                  <CheckAllPensumsIcon style={{ color: "#017724" }} />
                                </Fab>
                              </Tooltip>
                            )}
                          </FirstColumnContainer>
                          {filteredCrews.sort((a, b) => {
                            if (a.DefaultSortOrder - b.DefaultSortOrder !== 0) {
                              return a.DefaultSortOrder - b.DefaultSortOrder
                            } else {
                              return a.ID - b.ID
                            }
                          }).map((crew, i) => {
                            const columnId = `${crew.ID}/${day}`
                            const pensumsInColumn = [];
                            let workdurationInMinutes = 0
                            filteredPensums.forEach(pensum => {
                              let CostCenter
                              projects.forEach(project => {
                                if (pensum.Project && project.ID === pensum.Project.ID) CostCenter = project.CostCenter
                              })
                              if (
                                day === pensum.Day
                                && pensum.Crew.ID === crew.ID
                                && pensum.PartitionIndex === partitionIndex
                              ) {
                                const { WorkDuration } = pensum
                                if (WorkDuration) {
                                  if (!(WorkDuration.includes("H"))) {
                                    workdurationInMinutes += Number(WorkDuration.replace("PT", "").replace("M", "").replace("S", ""))
                                  } else {
                                    const workDuration = WorkDuration.replace("PT", "").replace("H", ":").replace("M", "").replace("S", "")
                                    workdurationInMinutes += Number(workDuration.split(":")[0] * 60)
                                    workdurationInMinutes += Number(workDuration.split(":")[1])
                                  }
                                }
                                pensumsInColumn.push({ pensum: pensum, CostCenter: CostCenter });
                              }
                            })

                            const today = moment(day)

                            const specialDaysInColumn = []
                            let publicHolidayName
                            specialDays.forEach(sDay => {
                              const dateFrom = moment(sDay.DateFrom)
                              const dateTo = moment(sDay.DateTo)
                              let specialDayObj = {}
                              if (today.diff(dateFrom) >= 0 && today.diff(dateTo) <= 0) {
                                specialDayObj.name = sDay.Name
                                if (sDay.IsPublicHoliday) {
                                  specialDayObj.isPublicHoliday = true
                                } else {
                                  specialDayObj.isPublicHoliday = false
                                }
                                specialDaysInColumn.push(specialDayObj)
                              }
                            })

                            let workloadIndication = "grey"
                            if (
                              today.day() === 1
                              || today.day() === 2
                              || today.day() === 3
                              || today.day() === 4
                            ) {
                              /* 0-7 hours */
                              if (workdurationInMinutes >= 0 && workdurationInMinutes < 420) {
                                workloadIndication = "red"
                              }
                              /* 7-9 hours */
                              else if (workdurationInMinutes >= 420 && workdurationInMinutes < 540) {
                                workloadIndication = "yellow"
                              }
                              /* >= 9 hours */
                              else if (workdurationInMinutes >= 540) {
                                workloadIndication = "green"
                              }
                            }
                            if (today.day() === 5) {
                              // special case for Fridays
                              /* 0-5 hours */
                              if (workdurationInMinutes >= 0 && workdurationInMinutes < 300) {
                                workloadIndication = "red"
                              }
                              /* >= 5 hours */
                              else if (workdurationInMinutes >= 300) {
                                workloadIndication = "green"
                              }
                            }

                            const { partition, partition: { index } } = row
                            const sortedPensums = pensumsInColumn.sort((a, b) => {
                              if (isPensumStartTimeAllowed) {
                                const aPensumStartTime = getDurationInMs(a.pensum.StartTime)
                                const bPensumStartTime = getDurationInMs(b.pensum.StartTime)
                                const partitionStartTimeInMs = partition.StartTime && getDurationInMs(partition.StartTime)
                                const partitionEndTimeInMs = partition.EndTime && getDurationInMs(partition.EndTime)
                                if (
                                  partitionStartTimeInMs && partitionEndTimeInMs
                                  && aPensumStartTime > bPensumStartTime
                                  && partitionStartTimeInMs > partitionEndTimeInMs
                                ) {
                                  /* night shift allows StartTime to be greater than 24h */
                                  return true
                                } else if (aPensumStartTime - bPensumStartTime !== 0) {
                                  return aPensumStartTime - bPensumStartTime
                                } else {
                                  return this.sortBySortOrder(a, b)
                                }
                              } else {
                                return this.sortBySortOrder(a, b)
                              }
                            })

                            return (
                              <PensumsContainer
                                key={`${columnId}/${index}`}
                                columnId={`${columnId}/${index}`}
                                partition={partition}
                                tasks={sortedPensums}
                                day={moment(day).day()}
                                handleCopy={this.handleCopy}
                                handleMove={this.handleMove}
                                handleNewObjectInHistory={this.handleNewObjectInHistory}
                                specialDaysInColumn={specialDaysInColumn}
                                publicHolidayName={publicHolidayName}
                                workloadIndication={workloadIndication}
                                crewsEmpty={crews === undefined || crews === null || (crews && crews.length === 0)}
                                duplicatedhint={duplicatedhint}
                                copiedPensum={this.state.copiedPensum}
                                movedPensum={this.state.movedPensum}
                                pensumSource={this.state.pensumSource}
                                onDragEnd={this.onDragEnd}
                                {...this.state}
                                {...this.props}
                              />
                            )
                          })}
                        </Container>
                      )})}
                    </DragDropContext>
                  </>
                ) : (
                  //swapped table for Strabag-Rail
                  <>
                    <DateContainer
                      dates={dates}
                      driverPayloadSumArray={driverPayloadSumArray}
                      totalMixtureAmountSumArray={totalMixtureAmountSumArray}
                      mixtureAmountSumArray={mixtureAmountSumArray}
                      mixturesByMixingPlant={mixturesByMixingPlant}
                      showSumDetails={showSumDetails}
                      handleHistoryChange={this.handleHistoryChange}
                      updatePlanState={this.updatePlanState}
                      dayPartitions={dayPartitions}
                      shifts={shifts}
                      history={history}
                      view={view}
                      t={t}
                    /> {/* <div>korte{console.log("render korte")}</div> */}
                    <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                      {filteredCrews.sort((a, b) => {
                        if (a.DefaultSortOrder - b.DefaultSortOrder !== 0) {
                          return a.DefaultSortOrder - b.DefaultSortOrder
                        } else {
                          return a.ID - b.ID
                        }
                      }).map((crew, x) => (
                        <Container key={x}>
                          <FirstColumnContainer style={{ width: 125 }}>
                            <div className="swapped-crew-container">
                              <div
                                style={{
                                  width: '100%',
                                  padding: '1%',
                                  /* position: 'absolute', */
                                  top: '50%',
                                  msTransform: 'translateY(-50%)',
                                  transform: 'translateY(-50%)',
                                  textAlign: 'center'
                                }}
                              >
                                {crew.Name}
                              </div>
                            </div>
                          </FirstColumnContainer>
                          {shifts.map((row, x) => {
                            const {
                              day,
                              partition,
                            } = row
                            const partitionIndex = row.partition.index
                            const columnId = `${crew.ID}/${day}/${partitionIndex}`
                            const pensumsInColumn = [];
                            let workdurationInMinutes = 0

                            filteredPensums.forEach(pensum => {
                              let CostCenter
                              projects.forEach(project => {
                                if (pensum.Project && project.ID === pensum.Project.ID) CostCenter = project.CostCenter
                              })

                              if (
                                day === pensum.Day
                                && pensum.Crew.ID === crew.ID
                                && pensum.PartitionIndex === partitionIndex
                              ) {
                                const { WorkDuration } = pensum
                                if (WorkDuration) {
                                  if (!(WorkDuration.includes("H"))) {
                                    workdurationInMinutes += Number(WorkDuration.replace("PT", "").replace("M", "").replace("S", ""))
                                  } else {
                                    const workDuration = WorkDuration.replace("PT", "").replace("H", ":").replace("M", "").replace("S", "")
                                    workdurationInMinutes += Number(workDuration.split(":")[0] * 60)
                                    workdurationInMinutes += Number(workDuration.split(":")[1])
                                  }
                                }

                                pensumsInColumn.push({ pensum: pensum, CostCenter: CostCenter });
                              }
                            })

                            const today = moment(day)

                            const specialDaysInColumn = []
                            let publicHolidayName
                            specialDays.forEach(sDay => {
                              const dateFrom = moment(sDay.DateFrom)
                              const dateTo = moment(sDay.DateTo)
                              let specialDayObj = {}
                              if (today.diff(dateFrom) >= 0 && today.diff(dateTo) <= 0) {
                                specialDayObj.name = sDay.Name
                                if (sDay.IsPublicHoliday) {
                                  specialDayObj.isPublicHoliday = true
                                } else {
                                  specialDayObj.isPublicHoliday = false
                                }
                                specialDaysInColumn.push(specialDayObj)
                              }
                            })

                            let workloadIndication = "grey"
                            if (
                              today.day() === 1
                              || today.day() === 2
                              || today.day() === 3
                              || today.day() === 4
                            ) {
                              /* 0-7 hours */
                              if (workdurationInMinutes >= 0 && workdurationInMinutes < 420) {
                                workloadIndication = "red"
                              }
                              /* 7-9 hours */
                              else if (workdurationInMinutes >= 420 && workdurationInMinutes < 540) {
                                workloadIndication = "yellow"
                              }
                              /* >= 9 hours */
                              else if (workdurationInMinutes >= 540) {
                                workloadIndication = "green"
                              }
                            }
                            if (today.day() === 5) {
                              // special case for Fridays
                              /* 0-5 hours */
                              if (workdurationInMinutes >= 0 && workdurationInMinutes < 300) {
                                workloadIndication = "red"
                              }
                              /* >= 5 hours */
                              else if (workdurationInMinutes >= 300) {
                                workloadIndication = "green"
                              }
                            }

                            const sortedPensums = pensumsInColumn.sort((a, b) => {
                              if (isPensumStartTimeAllowed) {
                                const partitionStartTimeInMs = partition.StartTime && getDurationInMs(partition.StartTime)
                                const partitionEndTimeInMs = partition.EndTime && getDurationInMs(partition.EndTime)
                                let aPensumStartTime = getDurationInMs(a.pensum.StartTime)
                                let bPensumStartTime = getDurationInMs(b.pensum.StartTime)
                                if (
                                  partitionStartTimeInMs && partitionEndTimeInMs
                                  && partitionStartTimeInMs > partitionEndTimeInMs
                                ) {
                                  /* night shift allows StartTime to be greater than 24h, for that additional 24h is added for the comparisation */
                                  if (aPensumStartTime < partitionStartTimeInMs) {
                                    aPensumStartTime += 86400000
                                  }
                                  if (bPensumStartTime < partitionStartTimeInMs) {
                                    bPensumStartTime += 86400000
                                  }
                                  if (aPensumStartTime > bPensumStartTime) {
                                    return 0
                                  } else if (aPensumStartTime === bPensumStartTime) {
                                    return this.sortBySortOrder(a, b)
                                  } else {
                                    return -1
                                  }
                                } else if (aPensumStartTime - bPensumStartTime !== 0) {
                                  return aPensumStartTime - bPensumStartTime
                                } else {
                                  return this.sortBySortOrder(a, b)
                                }
                              } else {
                                return this.sortBySortOrder(a, b)
                              }
                            })

                            return (
                              <PensumsContainer
                                key={`${columnId}`}
                                columnId={`${columnId}`}
                                partition={partition}
                                tasks={sortedPensums}
                                day={moment(day).day()}
                                handleCopy={this.handleCopy}
                                handleMove={this.handleMove}
                                handleNewObjectInHistory={this.handleNewObjectInHistory}
                                specialDaysInColumn={specialDaysInColumn}
                                publicHolidayName={publicHolidayName}
                                workloadIndication={workloadIndication}
                                crewsEmpty={crews === undefined || crews === null || (crews && crews.length === 0)}
                                copiedPensum={this.state.copiedPensum}
                                movedPensum={this.state.movedPensum}
                                selectedFromDate={this.state.selectedFromDate}
                                selectedToDate={this.state.selectedToDate}
                                activeDNDPensum={this.state.activeDNDPensum}
                                isPensumStartTimeAllowed={isPensumStartTimeAllowed}
                                view={view}
                                onDragEnd={this.onDragEnd}
                                pensumSource={this.state.pensumSource}
                                {...this.props}
                              />
                            )
                          })}
                        </Container>
                      ))}
                    </DragDropContext>
                  </>
                )}
              </div>
              
              <Dialog
                open={this.state.openConfirmAllPensums}
                onClose={() => this.setState({ openConfirmAllPensums: false })}
                onClick={(e) => e.stopPropagation()}
                style={{ fontFamily: 'Open Sans' }}
              >
                <DialogTitle id="form-dialog-title" style={{ color: '#017724' }}>
                  {t('confirm')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('are-you-sure-confirm-all-pensums', {
                      pensumNumber: this.state.pensumsToConfirm.length,
                      day: this.state.pensumDayToConfirm,
                    })}
                  </DialogContentText>
                  {this.state.requirePensumStateChangeMessage && (
                    <div style={{ fontSize: 13 }}>
                      <em style={{ color: 'red', marginRight: 3 }}>*</em>
                      {t('required-field')}
                    </div>
                  )}
                  {this.state.allowPensumStateChangeMessage && (
                    <TextareaAutosize
                      style={{
                        minWidth: 373,
                        maxWidth: 373,
                        minHeight: 65,
                        maxHeight: 65,
                        marginTop: 12,
                        padding: 4,
                        fontWeight: "normal",
                        fontSize: 14,
                        fontFamily: 'Open Sans',
                        borderRadius: 2
                      }}
                      autoFocus={this.state.requirePensumStateChangeMessage}
                      aria-label={t('message')}
                      placeholder={`${t('message')}...`}
                      onChange={(e) => this.setState({ pensumMessage: e.target.value })}
                    />
                  )
                }
                </DialogContent>
                <div className="buttonContainer" style={{ margin: 10 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={(e) => this.setPensumState(e)}
                    disabled={this.state.requirePensumStateChangeMessage && !this.state.pensumMessage}
                    style={{
                      backgroundColor: '#017724',
                      marginRight: 5
                    }}
                  >
                    {t('confirm')}
                  </Button>
                  <Button
                    onClick={() => this.setState({ openConfirmAllPensums: false, pensumMessage: undefined })}
                    variant="contained"
                    color="inherit"
                    size="small"
                  >
                    {t('close')}
                  </Button>
                </div>
              </Dialog>
            </div>
          ) : <div><Loader /></div>
        }
      </>
    )
  }
}
