import odata from 'odata-client';
import {
    SET_PENSUM_STATE_REQUEST,
    SET_PENSUM_STATE_ERROR,
    SET_PENSUM_STATE_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const setPensumStateRequest = () => ({
    type: SET_PENSUM_STATE_REQUEST,
});

export const setPensumStateError = errorOrResults => ({
    type: SET_PENSUM_STATE_ERROR,
    error: errorOrResults.message,
});

export const setPensumStateSuccess = payload => ({
    type: SET_PENSUM_STATE_SUCCESS,
    payload,
});

export default (pensumID, stateCode, message) => async (dispatch, getState) => {
  dispatch(setPensumStateRequest());

  const body = { ...stateCode, ...message }
  const query = odata({service: getServiceUrl()}).resource(RESOURCES.Pensums, pensumID).resource(RESOURCES.SetState);
  const errorOrResult = await query.post(body, {
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;
      if (res.statusCode === 500) {
        const body = JSON.parse(res.body);
        const { Error: { Code, Message } } = body
        let errorKey = "GENERAL_SERVER_ERROR"
        if (Code === "MessageCorrelationFailedTryAgain" || Code === "MessageCorrelationFailed") {
          errorKey = Message
        }
        return new Error(errorKey);
      } else if (!ok) {
        console.log('set pensum state api error', res.toJSON());
        const body = JSON.parse(res.body);
        const { Error: { Message } } = body
        const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
        return new Error(errorKey);
      } else {
        return true;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    })

  if (errorOrResult instanceof Error) {
    dispatch(setPensumStateError(errorOrResult));
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    console.log("CODE ERROR: ", errorOrResult.message);
    dispatch(showNotification(errorOrResult.message, "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("successful-set-pensum-state", "success"))
  }

  dispatch(setPensumStateSuccess(errorOrResult));

  return errorOrResult;
};