import {
  IMPORT_PROJECT_TENDER_DOCUMENT_REQUEST,
  IMPORT_PROJECT_TENDER_DOCUMENT_ERROR,
  IMPORT_PROJECT_TENDER_DOCUMENT_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader, sendFileRequest, obj_to_map } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const importProjectTenderDocumentRequest = () => ({
  type: IMPORT_PROJECT_TENDER_DOCUMENT_REQUEST
});

export const importProjectTenderDocumentError = errorOrResult => ({
  type: IMPORT_PROJECT_TENDER_DOCUMENT_ERROR,
  error: errorOrResult.message
});

export const importProjectTenderDocumentSuccess = payload => ({
  type: IMPORT_PROJECT_TENDER_DOCUMENT_SUCCESS,
  payload
});

export default (project, file) => async (dispatch, getState) => {

  dispatch(importProjectTenderDocumentRequest());
  const url = getServiceUrl() + RESOURCES.Projects + "(" + project.ID + ")/" + RESOURCES.ImportTenderDocument;

  const headers = {
    Authorization: `Bearer ${getState().user.token}`,
    ...getDefaultHeader()
  };

  const errorOrResult = await sendFileRequest(url, [file], obj_to_map(headers))
    .then(res => {
      const ok = res.status <= 299 && res.status >= 200;
      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        const error = JSON.parse(res.responseText).Error
        const errorKey = res.statusCode === 401 ? `401: ${error.Message}` : `${error.Message}`;
        console.log('import project offer document api error', res.toJSON());
        return new Error(errorKey);
      } else {
        const body = JSON.parse(res.response);
        const { "@odata.context": omit, ...payload } = body;
        return payload;
      }
    }, res => {
      // error
      var errorKey = null
      if (res.responseText && res.responseText !== null) {
        if (res.status === 400 || res.status === 401) {
          const response = JSON.parse(res.responseText);
          if (response !== null && response.Error !== undefined) {
            const error = response.Error;
            if (error !== null && error.Code !== undefined) {
              if (res.status === 401)
                errorKey = res.status === 401 ? `401: ${error.Message}` : `${error.Message}`;
              else {
                if (error.Code === "InvalidFileType" || error.Code === "ProjectHasProjectActivities")
                  errorKey = error.Code
                else
                  errorKey = `${error.Code}: ${error.Message}`;
              }
            }
          }
        }
      }
      if (errorKey === null)
        errorKey = `Error: ${res.status}, ${res.statusText}`;

      console.log('import project tender document api error', res.responseText);
      return new Error(errorKey);
    }
    )
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    });

  if (errorOrResult instanceof Error) {
    dispatch(importProjectTenderDocumentError(errorOrResult, IMPORT_PROJECT_TENDER_DOCUMENT_ERROR));
    console.log("CODE ERROR: ", errorOrResult.message);
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    if (errorOrResult.message.includes(","))
      dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
    else
      dispatch(showNotification(errorOrResult.message, "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_IMPORT_TENDER_DOCUMENT", "success"))
  }

  dispatch(importProjectTenderDocumentSuccess(errorOrResult));

  return errorOrResult;
};