import {
  GET_ORGANISATION_DATA_REQUEST,
  GET_ORGANISATION_DATA_ERROR,
  GET_ORGANISATION_DATA_SUCCESS,
  UPDATE_ORGANISATION_DATA_REQUEST,
  UPDATE_ORGANISATION_DATA_ERROR,
  UPDATE_ORGANISATION_DATA_SUCCESS,
  SET_DATA_IS_OBJECT
} from "../../actions/types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_ORGANISATION_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case GET_ORGANISATION_DATA_SUCCESS:
      return {
        ...state,
        organisationData: action.payload,
        isFetching: false,
        error: undefined
      };

    case GET_ORGANISATION_DATA_ERROR:
      return {
        organisationData: {},
        isFetching: false,
        error: `${action.error}`
      };

    case SET_DATA_IS_OBJECT:
      return {
        ...state,
        dataIsObject: action.isObject
      };

    case UPDATE_ORGANISATION_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case UPDATE_ORGANISATION_DATA_SUCCESS:
      let newState = {
        ...state,
        organisationData: state.organisationData.map(item => {
          if (item.ID === action.payload.ID) {
            return action.payload;
          }
          return item;
        }),
        isFetching: false,
        error: undefined
      }
      return { ...newState };

    case UPDATE_ORGANISATION_DATA_ERROR:
      return {
        ...state,
        error: `${action.error}`,
        isFetching: false,
      };

    default:
      return state;
  }
};
