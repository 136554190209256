import {
  GET_PROJECT_INVOICES_REQUEST,
  GET_PROJECT_INVOICES_ERROR,
  GET_PROJECT_INVOICES_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITIES_REQUEST = GET_PROJECT_INVOICES_REQUEST;
  reducers.GET_ENTITIES_ERROR = GET_PROJECT_INVOICES_ERROR;
  reducers.GET_ENTITIES_SUCCESS = GET_PROJECT_INVOICES_SUCCESS;
  reducers.entitiesName = "projectInvoices";

  return reducers.entity(reducers)(state, action);
};
