import React from "react";
import { isMobile } from "react-device-detect";

/* import AppBar from "@material-ui/core/AppBar"; */
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import packageJSON from '../../../../package.json';

import Tooltip from '@material-ui/core/Tooltip';
import PasswordIcon from '@material-ui/icons/LockOpen';
import ProfileIcon from '@material-ui/icons/AccountBox';

import { getWebUrl } from '../../../utils';

const fallbackLogo = require("../../../images/fallbacklogo.png");
const PCDLogo = require("../../../images/PCD_Logo.png");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: "15px",
    marginLeft: "-24px",
    paddingRight: "15px",
    paddingLeft: "12px",
    paddingTop: "17px",
    height: isMobile ? 50 : 67,
    backgroundColor: "white",
    borderBottom: "1px solid #b6b6b6"
  },
  title: {
    flexGrow: 1,
    fontSize: "17px",
    fontFamily: "Open Sans",
    marginLeft: "20px",
    paddingBottom: "1px",
    paddingLeft: "5px",
  },
  logoutButton: {
    marginRight: 20,
    color: "white",
    backgroundColor: "#52a7d1"
  }
}));

export default function Header(props) {
  const [infoIsOpen, openInfo] = React.useState(false);
  const classes = useStyles();
  const { t, headerColor, logoUrl } = props;

  const handleLogout = async () => {
    const { logout } = props;
    await logout();

    props.history.push("/");
  }

  const headerStyle = {
    width: document.body.scrollWidth,
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 11,
    backgroundColor: headerColor,
    color: "#fff"
  }
  const infoStyle = {
    width: document.body.scrollWidth,
    height: 90,
    position: "sticky",
    top: 55,
    left: 0,
    zIndex: 11,
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: headerColor,
    color: "#fff",
    fontSize: 12,
    borderTop: headerColor !== "#223b67" ? 'thin solid #fff' : 'thin solid rgb(155,155,155)'
  }

  return (
    <>
      <div style={headerStyle} className="header-container">
        <Toolbar className="header-toolbar">
          <div className={classes.menuButton}>
            <img src={logoUrl ? `${getWebUrl(logoUrl)}` : fallbackLogo} style={{ maxWidth: isMobile ? 120 : 166, maxHeight: 67 }} alt="" />
          </div>
          {!isMobile &&
            <>
              <div style={{ fontFamily: 'Open Sans', fontSize: 15, marginLeft: 25 }}>
                v{packageJSON.version}
              </div>
              <Typography variant="h4" className={classes.title}>
                &nbsp;&nbsp;{props.username} &nbsp;&nbsp;&nbsp; {props.organisationName} &nbsp;&nbsp;&nbsp; {t('environment')}: {props.environmentDisplayName}
              </Typography>
            </>
          }
          {isMobile &&
            <ProfileIcon
              style={{ fontSize: 26, color: 'rgb(250,250,250)' }}
              onClick={isMobile ? () => openInfo(!infoIsOpen) : undefined }
            />
          }
          <Tooltip title={t('changePasswordTitle')}>
            <Button
              href="/changepassword"
              size="small"
              color="primary"
              className="edit-popup-button"
              style={{ marginRight: !isMobile && 10, marginTop: !isMobile && 1 }}
            >
              <PasswordIcon style={{ fontSize: 24, color: 'rgb(235, 235, 235)' }} />
            </Button>
          </Tooltip>
          <Button
            variant="outlined"
            size="small"
            className={classes.logoutButton}
            onClick={handleLogout}
          >
            {t('logout')}
          </Button>
          <img
            alt="logo"
            src={PCDLogo}
            style={{ maxWidth: "166px !important", maxHeight: "85px !important" }}
          />
        </Toolbar>
      </div>
      {isMobile && infoIsOpen && (
        <div style={infoStyle}>
        <Typography variant="h4" className={classes.title}>
          {props.username}<br/>{props.organisationName}<br/>{t('environment')}:&nbsp;{props.environmentDisplayName}
          <div style={{ minWidth: 55, marginLeft: 10, paddingRight: 5, float: 'right', color: 'rgb(215,215,215)' }}>v{packageJSON.version}</div>
        </Typography>
        </div>
      )}
    </>
  );
}
