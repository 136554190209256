import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
/* import crewActions from "../../actions/crew"; */
import organisationActions from "../../actions/organisation";
import { setDataIsObject } from "../../actions/setDataIsObject";
import Settings from "./Settings";
import userActions from "../../actions/user";
import { getUserSelector } from "../../selectors/user"

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ crew, organisation, user, /* userActions */  }) => ({
  settings: crew.crew,
  organisations: organisation.organisations,
  user: getUserSelector(user),
  isFetching: isStateFetching([
    organisation,
    user
  ])
});

const mapDispatchToProps = {
/*   ...crewActions, */
...userActions,
  ...organisationActions,
  setDataIsObject,
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Settings);
export default withNamespaces(["common"])(connected);
