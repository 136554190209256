import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';

import UndoIcon from '@material-ui/icons/NavigateBefore';
import RedoIcon from '@material-ui/icons/NavigateNext';
import MixtureIcon from '@material-ui/icons/LineStyle';
import DriverIcon from '@material-ui/icons/LocalShipping';
import TimelineIcon from '@material-ui/icons/Timeline';
import SunIcon from '@material-ui/icons/WbSunny';
import MoonIcon from '@material-ui/icons/NightsStay';
import CheckAllPensumsIcon from '@material-ui/icons/PlaylistAddCheck';

import './index.css';

const Container = styled.div`
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 137px;
  z-index: 3;
`

const Day = styled.div`
  position: relative;
  width: ${props => !props.isSinglePartition && !props.lastPartition ? '343px' : '340px'};
  margin-right: ${props => props.lastPartition && '3px'};
  margin-bottom: 3px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.isWeekend ? "rgb(77, 77, 77)" : "#223b67"};
  color: #fff;
  border-top: thin solid #fdfdfd;
  border-radius: ${props => props.isSinglePartition ? '3px' : props.firstPartition ? '3px 0px 0px 3px' : props.lastPartition ? '0px 3px 3px 0px' : ''};
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  background-color: #223b67;
`

const FirstColumn = styled.div`
  width: 125px;
  margin-bottom: 2px;
  margin-left: 3px;
  margin-right: 2px;
  padding-top: 7px;
  padding-bottom: 8px;
  background-color: #223b67;
  text-shadow: 1px 1px 2px rgba(0,0,0);
  color: white;
  border-top: thin solid #fdfdfd;
  border-bottom: thin solid #fdfdfd;
  border-right: thin solid #fdfdfd;
  border-radius: 0px 3px 3px 0px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  z-index: 2;
`

export default class DateContainer extends Component {
/*     constructor(props) {
        super(props)
    }
 */
    render() {
        const {
            dates,
            driverPayloadSumArray,
            totalMixtureAmountSumArray,
            mixtureAmountSumArray,
            mixturesByMixingPlant,
            showSumDetails,
            history,
            handleHistoryChange,
            view,
            dayPartitions,
            updatePlanState,
            shifts,
            t
        } = this.props
        const currentStep = history !== undefined ? history.currentStep : undefined
        const chain = history !== undefined ? history.chain : undefined
        const disabled = chain === undefined || chain === null || chain.length < 1
        let columnIndex = -1

        return (
            <Container>
                <FirstColumn className="history-container">
                    <div className="undo-button-container" onMouseEnter={this.onMouseEnterBack} onMouseLeave={this.onMouseLeaveBack}>
                        <UndoIcon
                            className="undo-buttons"
                            onClick={() => !disabled && currentStep !== -1 ? handleHistoryChange(currentStep, "back", null) : null}
                            style={{
                                marginTop: 6,
                                cursor: !disabled && currentStep !== -1 ? "pointer" : "default"
                            }}
                        />
                    </div>
                    <div
                        className="history-center"
                        style={{
                            marginTop: 6,
                            fontSize: 17,
                            fontFamily: "Open Sans",
                            textAlign: "center"
                        }}
                    >
                        {currentStep + 1} / {chain.length}
                    </div>
                    <div className="undo-button-container" onMouseEnter={this.onMouseEnterForward} onMouseLeave={this.onMouseLeaveForward}>
                        <RedoIcon
                            className="undo-buttons"
                            onClick={() => !disabled && currentStep !== chain.length - 1 ? handleHistoryChange(currentStep + 1, "forward", null) : null}
                            style={{
                                marginTop: 6,
                                cursor: !disabled && currentStep !== chain.length - 1 ? "pointer" : "default"
                            }}
                        />
                    </div>
                </FirstColumn>
                {dates.map((day, x) => (
                    dayPartitions && dayPartitions.map((partition, partitionIndex) => {
                        const isCenterPartition =
                            dayPartitions.length % 2 === 0 && (Math.round(dayPartitions.length / 2) === partitionIndex)
                            || (dayPartitions.length % 2 !== 0 && ((Math.round(dayPartitions.length / 2) - 1) === partitionIndex))
                            || dayPartitions.length === 1
                        const shift = shifts.find(shift => shift.day === day && shift.partition.index === partitionIndex)
                        const {
                            showConfirmAllPensums,
                            requirePensumStateChangeMessage,
                            allowPensumStateChangeMessage,
                            pensumsToConfirm,
                        } = shift

                        columnIndex++

                        return (
                            <Day
                                key={`${columnIndex}`}
                                isSinglePartition={dayPartitions && dayPartitions.length <= 1}
                                firstPartition={partitionIndex === 0}
                                lastPartition={dayPartitions && partitionIndex === dayPartitions.length - 1}
                                isWeekend={moment(day).day() === 6 || moment(day).day() === 0}
                            >
                                {isCenterPartition && (
                                    <div style={{ marginLeft: dayPartitions.length > 1 && dayPartitions.length % 2 === 0 && -165, maxWidth: 343 }}>
                                        <div style={{
                                            margin: "0 auto",
                                            fontSize: 14,
                                            padding: 4,
                                            paddingTop: 6,
                                            marginTop: 2,
                                            marginBottom: 2,
                                            textAlign: 'center'
                                        }}>
                                            <div style={{ display: 'inline-block', width: 55 }}>
                                                <b style={{ fontSize: 13, fontWeight: 700 }}>
                                                    {t('week')}
                                                </b>
                                                &nbsp;{moment(day).isoWeek()}
                                            </div>
                                            <div
                                                style={{
                                                    display: 'inline-block',
                                                    width: dayPartitions.length > 1 ? '35%' : '40%',
                                                    marginLeft: 10,
                                                    marginRight: 8,
                                                    paddingBottom: 1,
                                                    fontFamily: "Open Sans",
                                                    background: '#fff',
                                                    webkitBoxShadow: '0 3px 2px -1px rgba(0, 0, 0, 0.85)',
                                                    mozBoxShadow: '0 3px 2px -1px rgba(0, 0, 0, 0.85)',
                                                    boxShadow: '0 3px 2px -1px rgba(0, 0, 0, 0.85)',
                                                    backgroundColor: moment(day).day() === 6 || moment(day).day() === 0 ? "rgb(77, 77, 77)" : "white",
                                                    color: moment(day).day() === 6 || moment(day).day() === 0 ? "white" : "#223b67",
                                                    borderRadius: 26,
                                                    fontSize: 16,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {t(`dayOfWeek-${moment(day).day()}`)}
                                            </div>
                                            <div style={{ display: 'inline-block', width: 75, fontSize: 14, fontWeight: 700 }}>
                                                {moment(day).format("DD.MM.YYYY")}
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                )}
                                {(partition.Name || partition.StartTime) && (
                                    <div style={{
                                        height: 30,
                                        width: '100%',
                                        marginTop: dayPartitions && dayPartitions.length > 1 && !isCenterPartition && 37,
                                        textAlign: 'center',
                                        marginBottom: showSumDetails && 2,
                                        borderBottom: showSumDetails && 'thin solid rgb(255,255,255,.6)'
                                    }}>
                                        {partition.IconIndex === 1 && (
                                            <MoonIcon style={{ color: "#b5b5b5", marginBottom: -5 }} />
                                        )}
                                        {partition.IconIndex === 0 && (
                                            <SunIcon style={{ color: "#ffe000", marginBottom: -6, marginRight: 4 }} />
                                        )}
                                        <span style={{
                                            marginRight: 5,
                                            fontSize: 16,
                                            paddingRight: 1 
                                        }}>
                                            {partition.Name}
                                        </span>
                                    </div>
                                )}
                                {showConfirmAllPensums && (
                                    <Tooltip
                                        title={t('confirmAllPensumsByDispatcher')}
                                        style={{ width: 55, height: 30, margin: "0 auto", marginTop: 4, marginBottom: 8 }}
                                    >
                                        <Fab
                                            variant="extended"
                                            onClick={() => updatePlanState({
                                                openConfirmAllPensums: true,
                                                requirePensumStateChangeMessage,
                                                allowPensumStateChangeMessage,
                                                pensumsToConfirm,
                                                pensumDayToConfirm: `${t(`day-${moment(day).day()}`)} ${moment().format("DD.MM.YYYY")}`,
                                            })}
                                        >
                                        <CheckAllPensumsIcon style={{ color: "#017724" }} />
                                        </Fab>
                                    </Tooltip>
                                )}
                                {driverPayloadSumArray[columnIndex] > 0 || totalMixtureAmountSumArray[columnIndex] > 0 || mixtureAmountSumArray[columnIndex] > 0 ?
                                    <div style={{ textAlign: 'center', marginBottom: mixturesByMixingPlant[day] && view.id === 3 && 3 }}>
                                        {driverPayloadSumArray[columnIndex] > 0 ? (
                                            <div style={{ display: "inline-block", minWidth: 75, color: "#F7F7F7" }}>
                                                <div style={{
                                                    float: "left",
                                                    marginTop: -4,
                                                    width: 23,
                                                    height: 20,
                                                    borderBottom: "thin solid rgb(245,245,245)",
                                                }}>
                                                    <DriverIcon style={{ color: "rgb(245,245,245)" }} />
                                                </div>
                                                <div id="driverPayloadSum" style={{
                                                    fontFamily: "Oswald, sans-serif",
                                                    letterSpacing: 0.6,
                                                    fontSize: 13,
                                                    color: "#FCA623",
                                                    textShadow: '0px 1px 1px rgba(0,0,0,0.8)'
                                                }}>
                                                    <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                                                        {driverPayloadSumArray[columnIndex]} to
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {totalMixtureAmountSumArray[columnIndex] > 0 ? (
                                            <div style={{ display: "inline-block", minWidth: 75, color: "#F7F7F7", position: 'relative' }}>
                                                <div style={{
                                                    float: "left",
                                                    marginTop: -3,
                                                    width: 24,
                                                    height: 20,
                                                }}>
                                                    <DriverIcon style={{ color: "rgb(245,245,245)", position: 'absolute', left: 0 }} />
                                                    <TimelineIcon style={{ position: 'absolute', left: 0, fontSize: 14, marginLeft: 2, marginTop: 4, color: '#223B67' }} />
                                                </div>
                                                <div id="totalMixtureAmountSum" style={{
                                                    fontFamily: "Oswald, sans-serif",
                                                    letterSpacing: 0.6,
                                                    color: "#FCA623",
                                                    textShadow: '0px 1px 1px rgba(0,0,0,0.8)',
                                                    fontSize: 13,
                                                }}>
                                                    <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                                                        {totalMixtureAmountSumArray[columnIndex]} to
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {mixtureAmountSumArray[columnIndex] > 0 ? (
                                            <div style={{ display: "inline-block", minWidth: 75, color: "#F7F7F7" }}>
                                                <div style={{
                                                    float: "left",
                                                    marginTop: -3,
                                                    width: 24,
                                                    height: 20,
                                                }}>
                                                    <MixtureIcon style={{ color: "rgb(245,245,245)" }} />
                                                </div>
                                                <div id="mixtureAmountSum" style={{
                                                    fontFamily: "Oswald, sans-serif",
                                                    letterSpacing: 0.6,
                                                    color: "#FCA623",
                                                    textShadow: '0px 1px 1px rgba(0,0,0,0.8)',
                                                    fontSize: 13,
                                                }}>
                                                    <div style={{ paddingLeft: 3, paddingRight: 3 }}>
                                                        {mixtureAmountSumArray[columnIndex]} to
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    : null}
                                {(mixturesByMixingPlant[day] && mixturesByMixingPlant[day][partitionIndex] && view.id === 3) ? (
                                    <div style={{ margin: '0 auto', paddingLeft: 10, paddingRight: 10 }}>
                                        {Object.keys(mixturesByMixingPlant[day][partitionIndex]).map((key, i) => (
                                            <div key={`${day}/${partitionIndex}/${key}`} style={{
                                                display: "inline-block",
                                                width: 100,
                                                marginRight: i !== Object.keys(mixturesByMixingPlant[day][partitionIndex]).length - 1 && 3,
                                                padding: 2,
                                                background: 'rgba(0, 0, 0, 0.2)',
                                                fontFamily: "Open Sans",
                                                fontSize: 11,
                                                textAlign: 'center',
                                                borderRadius: 2
                                            }}>
                                                <b style={{ textShadow: '0px 1px 1px rgba(0,0,0,0.8)' }}>
                                                    {key}
                                                </b>
                                                <div style={{ width: '100%' }}>
                                                    <div style={{
                                                        display: 'inline-block', minWidth: '30%',
                                                        color: "#FF9A00", textShadow: '0px 1px 1px rgba(0,0,0,0.8)'
                                                    }}>
                                                        <b>{mixturesByMixingPlant[day][partitionIndex][key]}&nbsp;to</b>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                                {dayPartitions.length > 1 && partitionIndex !== dayPartitions.length - 1 && (
                                    <div className="date-partition-divider" style={{ borderRight: 'thin solid rgb(255,255,255,.6)' }} />
                                )}
                            </Day>
                        )
                    })
                ))}
            </Container>
        )
    }
}
