import { combineReducers } from "redux";
import user from "./user";
import equipment from "./equipment";
import equipmentEntries from "./equipmentEntries";
import customer from "./customer";
import employee from "./employee";
import employeeEntries from "./employeeEntries";
import crew from "./crew";
import specialday from "./specialday";
import driver from "./driver";
import driverEntries from "./driverEntries";
import mixingPlant from "./mixingPlant";
import mixtureOrder from "./mixtureOrder";
import mixtureEntries from "./mixtureEntries";
import mixture from "./mixture";
import pensum from "./pensum";
import organisation from "./organisation";
import organisationData from "./organisationData";
import settings from "./settings";
import notification from "./notification";
import filter from "./filter";
import project from "./project";
import projectActivity from "./projectActivities";
import projectActivities from "./projectActivities";
import projectActivityEntries from "./projectActivityEntries";
import projectInvoices from "./projectInvoices";
import entries from "./entries";
import company from "./company";
import projectCorrespondenceEntries from "./projectCorrespondenceEntries";
import checkpointDefinitions from "./checkpointDefinitions";

const appReducer =
  combineReducers({
    user,
    equipment,
    equipmentEntries,
    customer,
    employee,
    employeeEntries,
    crew,
    specialday,
    driver,
    driverEntries,
    mixingPlant,
    mixtureOrder,
    mixture,
    mixtureEntries,
    organisation,
    organisationData,
    pensum,
    settings,
    notification,
    filter,
    project,
    projectActivity,
    projectActivities,
    projectActivityEntries,
    projectInvoices,
    entries,
    company,
    projectCorrespondenceEntries,
    checkpointDefinitions
  });

export default (state, action) => {
  if (action.type === "USER_LOGOUT_SUCCESS" || action.type === "TOKEN_EXPIRED") {
    const settings = state.settings.settings
    localStorage.removeItem('persist:root')
    sessionStorage.removeItem('user')
    state = { settings: settings }
  }

  if (action.type === "USER_LOGIN_SUCCESS") {
    state = {
      ...state,
      filter: {}
    }
  }

  return appReducer(state, action);
};