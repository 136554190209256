import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import organisationActions from "../../actions/organisation";
import organisationDataActions from "../../actions/organisation";
import checkpointDefinitionActions from "../../actions/organisation";
import notificationActions from "../../actions/notification";
import { setDataIsObject } from "../../actions/setDataIsObject";
import OrganisationData from "./OrganisationData";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ user, organisation, organisationData, checkpointDefinitions }) => ({
  user: user,
  organisationData: organisationData.organisationData,
  organisations: organisation.organisations,
  checkpointDefinitions: checkpointDefinitions.checkpointDefinitions,
  isFetching: isStateFetching([
    organisation,
    organisationData
  ])
});

const mapDispatchToProps = {
  ...organisationActions,
  ...organisationDataActions,
  ...checkpointDefinitionActions,
  ...notificationActions,
  setDataIsObject
};

const connected = connect(mapStateToProps, mapDispatchToProps)(OrganisationData);
export default withNamespaces(["common"])(connected);
