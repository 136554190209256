import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import employeeActions from "../../actions/employee";
import organisationActions from "../../actions/organisation";
import crewActions from "../../actions/crew";
import mixingPlantAction from "../../actions/mixingPlant";
import notificationActions from "../../actions/notification";
import settingsActions from "../../actions/settings";
import { setDataIsObject } from "../../actions/setDataIsObject";
import Employee from "./Employee";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ user, employee, organisation, crew, mixingPlant, settings }) => ({
  user: user,
  employee: employee.employee,
  employees: employee.employees,
  organisations: organisation.organisations,
  crews: crew.crew,
  mixingPlants: mixingPlant.mixingPlant,
  settings: settings.settings,
  isFetching: isStateFetching([
    employee,
    organisation,
    crew,
    mixingPlant,
    settings
  ])
});

const mapDispatchToProps = {
  ...employeeActions,
  ...organisationActions,
  ...crewActions,
  ...mixingPlantAction,
  ...settingsActions,
  ...notificationActions,
  setDataIsObject
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Employee);
export default withNamespaces(["common"])(connected);
