import odata from 'odata-client';
import {
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_ERROR,
    SEND_MESSAGE_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const sendMessageRequest = () => ({
    type: SEND_MESSAGE_REQUEST,
});

export const sendMessageError = errorOrResults => ({
    type: SEND_MESSAGE_ERROR,
    error: errorOrResults.message,
});

export const sendMessageSuccess = payload => ({
    type: SEND_MESSAGE_SUCCESS,
    payload,
});

export default (pensumID, message) => async (dispatch, getState) => {
  dispatch(sendMessageRequest());

  const query = odata({service: getServiceUrl()}).resource(RESOURCES.Pensums, pensumID).resource(RESOURCES.SendMessage);
  const errorOrResult = await query.post(message, {
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;
      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        console.log('send pensum message api error', res.toJSON());
        if (res.body !== "") {
          const body = JSON.parse(res.body);
          const { Error: { Message } } = body
          const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
          return new Error(errorKey);
        }
        return new Error()
      } else {
        return true;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    })

  if (errorOrResult instanceof Error) {
    dispatch(sendMessageError(errorOrResult));
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    console.log("CODE ERROR: ", errorOrResult.message);
    dispatch(showNotification(errorOrResult.message, "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("message-sent", "success"))
  }

  dispatch(sendMessageSuccess(errorOrResult));

  return errorOrResult;
};