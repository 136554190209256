export default {
  "ROOT": "/",
  "LOGIN": "/login",
  "CHANGEPASSWORD": "/changePassword",
  "RESETPASSWORD": "/resetPassword",

  "PLANNING": "/planning",
  "PLANNING2": "/planning2",
  "PROJECT": "/project",
  "MASTERDATA": "/masterdata",
  "MONITOR": "/planning2/#/monitor",
  "HELP": "/help",

  "EMPLOYEE": "/employee",
  "CREW": "/crew",
  "SPECIAL_DAYS": "/specialdays",
  "CUSTOMER": "/customer",
  "EQUIPMENT": "/equipment",
  "DRIVER": "/driver",
  "MIXINGPLANT": "/mixingplant",
  "COMPANY": "/company",
  "ORGANISATIONDATA": "/organisationData",
  "SETTINGS": "/settings",

  "HIGHCHART": "/highchart",
}
