import React from "react";
import _ from 'lodash';
import moment from "moment";

/* 
import { makeStyles } from '@material-ui/core/styles';
 */
import ReactSelect from 'react-select';

import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CrewIcon from '@material-ui/icons/Group';
import FormControl from "@material-ui/core/FormControl";

import { enGB, enUS, de } from 'date-fns/locale'

import { decodeMixingPlantClusters, getMixingPlantClusters } from '../../utils';
import routes from "../../config/routes";
import Mode from "../Projects/ModeEnum";

import './index.css';


const styles = theme => ({
  formControl: {
    '& .MuiInput-formControl': {
      width: '100% !important',
    },
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
    width: "50%",
  },
  label: {
    fontSize: "1em",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    display: "flex",
    alignItems: "center",
    width: "300px"
  },
  label2: {
    fontSize: "1em",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    display: "flex",
    alignItems: "center",
  },
  thumbnail: {
    width: "150px",
    height: "170px",
    margin: "5px",
    display: "inline-block",
  },
  thumbnailImage: {
    width: "auto",
    height: "auto",
    maxWidth: "120px",
    maxHeight: "120px",
    border: "1px solid #ddd", /* Gray border */
    boxShadow: "3px 5px 5px #888888",
    cursor: "pointer",
  },
  thumbnailLabel: {
    height: "30px",
    width: "140px",
    fontSize: "15px",
    marginBlockStart: "0px",
    marginBlockEnd: "0px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  attachmentDeleteButton: {
    width: 17,
    marginLeft: 4,
  },
});

// const input = React.forwardRef((props, ref) => (
//   <input ref={ref} className="input">
//     {props.children}
//   </input>
// ));

class EmployeeDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      editable: props.editable ? props.editable : '',
      mode: props.mode,
      selectedFilters: [],
      error: null,
      dataRow: props.dataRow ? props.dataRow : null,
      crews: props.crews,
      mixingPlants: props.mixingPlants,
      mixingPlantsClusters: getMixingPlantClusters(props.mixingPlants),
      organisations: props.organisations,
      selectedCrews: props.dataRow && props.dataRow.Crews !== undefined ? props.dataRow.Crews : null,
      selectedMixingPlants: props.dataRow && props.dataRow.MixingPlants !== undefined ? props.dataRow.MixingPlants : null,
      selectedOrganisations: props.dataRow && props.dataRow.Organisations !== undefined ? props.dataRow.Organisations : null,
    };
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCrewChange = this.handleCrewChange.bind(this);
    this.handleMixinPlantChange = this.handleMixinPlantChange.bind(this);
    this.handleMixinPlantClusterChange = this.handleMixinPlantClusterChange.bind(this);
    this.handleOrganisationChange = this.handleOrganisationChange.bind(this);
  }

  handleBackClick(e, redirected) {
    if (redirected) {
      window.location.href = routes.PLANNING2;
    } else {
      const {
        dataRow
      } = this.state;
      e.preventDefault();
      window.history.replaceState({}, document.title, routes.EMPLOYEE)
      this.props.onChangeMode(Mode.Uninit, dataRow, redirected);
    }
  }

  async handleSaveClick(e) {

    const { dataRow, selectedCrews, selectedMixingPlants, selectedOrganisations } = this.state;
    e.preventDefault();

    var errorOrResult = null;
    if (this.state.mode === Mode.Update) {
      var errors = false;
      // process crews
      var existing = dataRow.Crews != null ? dataRow.Crews.map((c) => c.ID) : [];
      var selected = selectedCrews != null ? selectedCrews.map((c) => c.ID) : [];
      var _new = selectedCrews.filter((c) => existing.length == 0 || !existing.includes(c.ID));
      var old = dataRow.Crews.filter((c) => !selected.includes(c.ID));
      for (let i = 0; i < old.length; i++) {
        errorOrResult = await this.props.deleteEmployeeCrewHandler(dataRow, old[i]);
        errors = errors || (errorOrResult instanceof Error)
      };
      for (let i = 0; i < _new.length; i++) {
        errorOrResult = await this.props.createEmployeeCrewHandler(dataRow, _new[i]);
        errors = errors || (errorOrResult instanceof Error)
      };

      // process mixing plants
      if (!errors) {
        var existing = dataRow.MixingPlants != null ? dataRow.MixingPlants.map((c) => c.ID) : [];
        var selected = selectedMixingPlants != null ? selectedMixingPlants.map((c) => c.ID) : [];
        var _new = selectedMixingPlants.filter((c) => existing.length == 0 || !existing.includes(c.ID));
        var old = dataRow.MixingPlants.filter((c) => !selected.includes(c.ID));
        for (let i = 0; i < old.length; i++) {
          errorOrResult = await this.props.deleteEmployeeMixingPlantHandler(dataRow, old[i]);
          errors = errors || (errorOrResult instanceof Error)
        };
        for (let i = 0; i < _new.length; i++) {
          errorOrResult = await this.props.createEmployeeMixingPlantHandler(dataRow, _new[i]);
          errors = errors || (errorOrResult instanceof Error)
        };
      }

      if (!errors) {
        window.history.replaceState({}, document.title, routes.EMPLOYEE)
        this.props.onChangeMode(Mode.Uninit, dataRow, false);
      }
      /*
            // process organisations
            var existing = dataRow.Organisations != null ? dataRow.Organisations.map((c) => c.ID) : [];
            var selected = selectedOrganisations != null ? selectedOrganisations.map((c) => c.ID) : [];
            var _new = selectedOrganisations.filter((c) => existing.length == 0 || !existing.includes(c.ID));
            var old = dataRow.Organisations.filter((c) => !selected.includes(c.ID));
            for (let i = 0; i < old.length; i++) {
              errorOrResult = await this.props.deleteEmployeeOrganisationHandler(dataRow, old[i]);
              errors = errors || (errorOrResult instanceof Error)
            };
            for (let i = 0; i < _new.length; i++) {
              errorOrResult = await this.props.createEmployeeOrganisationHandler(dataRow, _new[i]);
              errors = errors || (errorOrResult instanceof Error)
            };
      */
    }
  }

  isValid = () => {
    return true;
  }

  handleChange = (prop, isSelect) => evt => {
    if (isSelect) {
      this.setState(state => {
        return { ...state, [prop]: evt ? evt.value : null };
      });
    } else {
      const value = evt.target.value === '' ? null : evt.target.value;
      this.setState(state => {
        return { ...state, [prop]: prop === "stateCode" ? Number(value) : value };
      });
    }
  }

  fillLookup(entries, lookup) {
    if (entries.length > 0) {
      entries.forEach(entry => {
        lookup[entry.ID] = entry;
      })
    }
  }

  selectLocale(language) {

    let languages = new Map();
    languages.set("de-DE", de);
    languages.set("en-GB", enGB);
    languages.set("en-US", enUS);

    let res = languages.get(language);
    return res != null ? res : languages["de-DE"];
  }

  dateRemoveTimePart(date) {
    return moment(date).startOf('day');
  }

  handleCrewChange(crew) {
    const { crews } = this.state;
    let crewSelection = crew || []
    if (crewSelection && crewSelection.length > 0) {
      crewSelection = crewSelection.map(obj => obj.value)
    }
    const res = crews.filter(c => (crewSelection.includes(c.ID)));
    this.setState({ selectedCrews: res });
  }

  handleMixinPlantChange(mixinPlant) {
    const { mixingPlants } = this.state;
    let mixingPlantSelection = mixinPlant || []
    if (mixingPlantSelection && mixingPlantSelection.length > 0) {
      mixingPlantSelection = mixingPlantSelection.map(obj => obj.value)
    }
    const res = mixingPlants.filter(c => (mixingPlantSelection.includes(c.ID)));
    this.setState({ selectedMixingPlants: res });
  }

  handleMixinPlantClusterChange(mixinPlantCluster) {
    const { mixingPlants, selectedMixingPlants, mixingPlantsClusters, selectedMixingPlantsClusters } = this.state;
    let mixingPlantClusterSelection = mixinPlantCluster || []
    if (mixingPlantClusterSelection && mixingPlantClusterSelection.length > 0) {
      mixingPlantClusterSelection = mixingPlantClusterSelection.map(obj => obj.value)
    }
    const res = mixingPlantsClusters.filter(c => (mixingPlantClusterSelection.includes(c.ID)));
    if (res.length > 0) {

      var added = res.filter(n => selectedMixingPlantsClusters === undefined || !selectedMixingPlantsClusters.some((c) => c.ID == n.ID));

      var allMixingPlants =
        mixingPlants.filter(mp => decodeMixingPlantClusters(mp.GroupNames).some((cl) => res.some((cluster) => cluster.ID == cl.ID)));

      var addedMixingPlants =
        mixingPlants.filter(mp => decodeMixingPlantClusters(mp.GroupNames).some((cl) => added.some((cluster) => cluster.ID == cl.ID)));

      var selected = selectedMixingPlants.filter((s) => allMixingPlants.some((mp) => mp.ID == s.ID))
      addedMixingPlants.forEach(a => { if (!selected.some(s => s.ID === a.ID)) selected.push(a); })

      this.setState({ selectedMixingPlantsClusters: res, selectedMixingPlants: selected });
    }
    else {
      this.setState({ selectedMixingPlantsClusters: res });
    }
  }

  handleOrganisationChange(organisation) {
    const { organisations } = this.state;
    let organisationSelection = organisation || []
    if (organisationSelection && organisationSelection.length > 0) {
      organisationSelection = organisationSelection.map(obj => obj.value)
    }
    const res = organisations.filter(c => (organisationSelection.includes(c.ID)));
    this.setState({ selectedOrganisations: res });
  }

  render() {
    const {
      dataRow, selectedCrews, crews, selectedMixingPlants, mixingPlants, selectedMixingPlantsClusters, mixingPlantsClusters, selectedOrganisations, organisations
    } = this.state;
    const { t, lng } = this.props;
    var locale = this.selectLocale(lng);

    const { classes, ...rest } = this.props;

    return (
      <div style={{ padding: "5px", margin: "5px" }}>

        <div className="rowC" style={{ padding: "5px", margin: "5px" }}>
          {`${t('employee')}: ${(dataRow && ((dataRow.FirstNames && dataRow.FirstNames.trim() !== "" ? dataRow.FirstNames.trim() : "") + ' ' + (dataRow.LastName && dataRow.LastName.trim() !== "" ? dataRow.LastName.trim() : "")))}`}
        </div>

        <div className="rowC" style={{ padding: "5px", margin: "5px" }}>
          <Button onClick={this.handleSaveClick}
            style={{ padding: "5px", width: "120px", margin: "5px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submitButton"
            disabled={!this.isValid()}
          >
            {t('save')}
          </Button>
          <Button onClick={this.handleBackClick}
            style={{ padding: "5px", width: "120px", margin: "5px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submitButton"
          >
            {this.props.redirected ? t('back-to-projects') : t('back')}
          </Button>
          {this.props.redirected &&
            <Button onClick={() => this.handleBackClick(null, true)}
              style={{ padding: "5px", width: "120px", margin: "5px" }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submitButton"
            >
              {t('back-to-planning')}
            </Button>
          }
        </div>


        <div>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            < div >
              <div className="filter">
                <div className="filter-label">
                  <CrewIcon style={{ marginBottom: -5, marginRight: 2 }} />
                  {t('view-visible-crews')}
                </div>
                <ReactSelect
                  value={selectedCrews != null ? selectedCrews.map(obj => ({ value: obj.ID, label: obj.Name })) : []}
                  className="basic-multi-select"
                  placeholder={t('crew')}
                  onChange={this.handleCrewChange}
                  isClearable
                  isMulti
                  closeMenuOnSelect={true}
                  options={
                    crews
                      .sort((a, b) => {
                        if (a.DefaultSortOrder - b.DefaultSortOrder !== 0) {
                          return a.DefaultSortOrder - b.DefaultSortOrder
                        } else {
                          return a.ID - b.ID
                        }
                      })
                      .map(crew => {
                        return { value: crew.ID, label: `${crew.Name}` }
                      })
                  }
                />
              </div>
            </div>
          </FormControl>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            < div >
              <div className="filter">
                <div className="filter-label">
                  <CrewIcon style={{ marginBottom: -5, marginRight: 2 }} />
                  {t('view-visible-mixing-plants')}
                </div>
                <ReactSelect
                  value={selectedMixingPlants != null ? selectedMixingPlants.map(obj => ({ value: obj.ID, label: obj.Name })) : []}
                  className="basic-multi-select"
                  placeholder={t('asphalt-mixing-plant')}
                  onChange={this.handleMixinPlantChange}
                  isClearable
                  isMulti
                  closeMenuOnSelect={true}
                  options={
                    mixingPlants
                      .sort((a, b) => {
                        if (a.DefaultSortOrder - b.DefaultSortOrder !== 0) {
                          return a.DefaultSortOrder - b.DefaultSortOrder
                        } else {
                          return a.ID - b.ID
                        }
                      })
                      .map(mp => {
                        return { value: mp.ID, label: `${mp.Name}` }
                      })
                  }
                />
              </div>
            </div>
          </FormControl>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            < div >
              <div className="filter">
                <div className="filter-label">
                  <CrewIcon style={{ marginBottom: -5, marginRight: 2 }} />
                  {t('asphalt-mixing-plant-cluster')}
                </div>
                <ReactSelect
                  value={selectedMixingPlantsClusters != null ? selectedMixingPlantsClusters.map(obj => ({ value: obj.ID, label: obj.Name })) : []}
                  className="basic-multi-select"
                  placeholder={t('asphalt-mixing-plant-cluster')}
                  onChange={this.handleMixinPlantClusterChange}
                  isClearable
                  isMulti
                  closeMenuOnSelect={true}
                  options={
                    mixingPlantsClusters
                      .sort((a, b) => {
                        const result = a.Name.localeCompare(b.Name, undefined, { sensitivity: 'base' })
                        if (result !== 0)
                          return result
                        return a.ID - b.ID
                      })
                      .map(mp => {
                        return { value: mp.ID, label: `${mp.Name}` }
                      })
                  }
                />
              </div>
            </div>
          </FormControl>
          {/*  
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            < div >
              <div className="filter">
                <div className="filter-label">
                  <CrewIcon style={{ marginBottom: -5, marginRight: 2 }} />
                  {t('view-visible-organisations')}
                </div>
                <ReactSelect
                  value={selectedOrganisations != null ? selectedOrganisations.map(obj => ({ value: obj.ID, label: obj.Name })) : []}
                  className="basic-multi-select"
                  placeholder={t('organisation')}
                  onChange={this.handleOrganisationChange}
                  isClearable
                  isMulti
                  closeMenuOnSelect={true}
                  options={
                    organisations
                      .sort((a, b) => {
                        if (a.DefaultSortOrder - b.DefaultSortOrder !== 0) {
                          return a.DefaultSortOrder - b.DefaultSortOrder
                        } else {
                          return a.ID - b.ID
                        }
                      })
                      .map(mp => {
                        return { value: mp.ID, label: `${mp.Name}` }
                      })
                  }
                />
              </div>
            </div>
          </FormControl>
                */  }
        </div>


      </div>
    );
  }
}

EmployeeDetailsForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmployeeDetailsForm);