import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import specialDayActions from "../../actions/specialday";
import organisationActions from "../../actions/organisation";
import crewActions from "../../actions/crew";
import notificationActions from "../../actions/notification";
import { setDataIsObject } from "../../actions/setDataIsObject";
import SpecialDay from "./SpecialDay";

import { isStateFetching, getArrayOfObjectValues } from "../../utils"

const mapStateToProps = ({ user, specialday, organisation, crew }) => ({
  user: user,
  specialday: getArrayOfObjectValues(specialday.specialday),
  organisations: getArrayOfObjectValues(organisation.organisations),
  crews: getArrayOfObjectValues(crew.crew),
  isFetching: isStateFetching([
    specialday,
    organisation,
    crew
  ])
});

const mapDispatchToProps = {
  ...specialDayActions,
  ...organisationActions,
  ...crewActions,
  ...notificationActions,
  setDataIsObject
};

const connected = connect(mapStateToProps, mapDispatchToProps)(SpecialDay);
export default withNamespaces(["common"])(connected);
