import React from "react";
import MaterialTable from 'material-table';
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import './index.css';

class ProjectCorrespondenceTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.correspondencies ? props.correspondencies : [],
      editable: props.editable ? props.editable : '',
      projectRow: props.projectRow ? props.projectRow : null,
      newData: null,
      error: null
    };
  }

  today = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }

  formatDateDay = dateTime => moment(dateTime).format('DD.MM.YYYY')

  render() {
    const { t, user } = this.props;
    let today = this.today();
    const columns = [
      {
        title: `${t("date")} *`, field: "Date", filtering: false, defaultSort: "asc", render: rowData => (<p>{this.formatDateDay(rowData.Date)}</p>),
        editComponent: props => (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              format="dd.MM.yyyy"
              value={props.value}
              onChange={e => props.onChange(e)}
              okLabel={t('date-picker-label-ok')}
              cancelLabel={t('date-picker-label-cancel')}
              invalidDateMessage={t('date-picker-label-invalid-date-format')}
            />
          </MuiPickersUtilsProvider>
        ),
      },
      {
        title: `${t('text')} *`, field: "Name", filtering: false,
        validate: ({ Name }) => Name && Name.length > 0 && Name.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              disabled={user.userNumber === props.rowData.CreatorUserNumber ? false : props.rowData.CreatorUserNumber === undefined ? false : true}
              id="name-input"
              value={props.value}
              type="text"
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
            />
          </FormControl>
        )
      },
      {
        title: t('creator'), field: "CreatorDisplayName", filtering: false,
        render: rowData => (rowData.CreatorDisplayName && <p>{rowData.CreatorDisplayName}</p>),
        editComponent: props => (
          <FormControl>
            <InputLabel className="trans-label-name" id="creator"></InputLabel>
            <TextField
              disabled
              id="creator-input"
              value={props.rowData.CreatorDisplayName ? props.rowData.CreatorDisplayName : null}
              type="text"
            />
          </FormControl>
        ),
      },
    ];

    return (
      <MaterialTable
        title={this.state.title}
        columns={columns}
        data={this.state.data}
        style={{
          fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
          fontSize: `0.875rem`
        }}
        options={{
          pageSize: 12,
          paginationType: "normal",
          addRowPosition: "first",
          draggable: false,
          filtering: true,
          searchFieldAlignment: "left",
          toolbarButtonAlignment: "left",
        }}
        localization={{
          header: {
            actions: t('actions')
          },
          toolbar: {
            searchTooltip: t('search'),
            searchPlaceholder: t('search')
          },
          body: {
            emptyDataSourceMessage: t('no-records-to-display'),
            addTooltip: t('add'),
            deleteTooltip: t('delete'),
            editTooltip: t('edit'),
            editRow: {
              saveTooltip: t('save'),
              cancelTooltip: t('cancel'),
              deleteText: t('deleteText')
            }
          },
          pagination: {
            firstTooltip: t('first-page'),
            previousTooltip: t('previous-page'),
            nextTooltip: t('next-page'),
            lastTooltip: t('last-page')
          }
        }}
        editable={this.props.editable && {
          isEditable: rowData => rowData.CreatorUserNumber === this.props.user.userNumber,
          isDeletable: rowData => rowData.CreatorUserNumber === this.props.user.userNumber,
          onRowAdd: newData =>
            new Promise(resolve => {
              this.setState({
                newData: newData
              });
              setTimeout(async () => {
                const errorOrResult = await this.props.onProjectCorrespondenceRowAdd({
                  Date: newData.Date ? moment(newData.Date).format("YYYY-MM-DD") : moment(this.today()).format("YYYY-MM-DD"),
                  Name: newData.Name ? newData.Name : '',
                  CreatorUserNumber: this.props.user.userNumber,
                  Project: {
                    ID: this.props.projectRow && this.props.projectRow.ID
                  }
                });
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    newData.ID = errorOrResult.ID;
                    newData.CreatorDisplayName = errorOrResult.CreatorDisplayName;
                    newData.CreatorUserNumber = errorOrResult.CreatorUserNumber;
                    newData.Date = errorOrResult.Date;
                    data.push(newData);
                    return { ...prevState, data };
                  });
                }
                resolve();
              }, 100);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(async () => {
                if (oldData) {
                  const errorOrResult = await this.props.onProjectCorrespondenceRowUpdate({
                    ID: newData.ID,
                    Date: newData.Date ? moment(newData.Date).format("YYYY-MM-DD") : moment(this.today()).format("YYYY-MM-DD"),
                    Name: newData.Name ? newData.Name : '',
                    CreatorUserNumber: this.props.user.userNumber,
                    Project: {
                      ID: this.props.projectRow && this.props.projectRow.ID
                    }
                  }, oldData.tableData);
                  if (!(errorOrResult instanceof Error)) {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                  resolve();
                }
              }, 100);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(async () => {
                const errorOrResult = await this.props.onProjectCorrespondenceRowDelete(oldData);
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    if (data.indexOf(oldData) >= 0)
                      data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                }
                resolve();
              }, 100);
            })
        }}
      />
    );
  }
}

export default ProjectCorrespondenceTable;