import {
  GET_DRIVER_ENTRIES_REQUEST,
  GET_DRIVER_ENTRIES_ERROR,
  GET_DRIVER_ENTRIES_SUCCESS,
  CREATE_DRIVER_ENTRIES_REQUEST,
  CREATE_DRIVER_ENTRIES_ERROR,
  CREATE_DRIVER_ENTRIES_SUCCESS,
  GET_DRIVER_ENTRY_SUCCESS,
  CREATE_ENTRY_REQUEST,
  CREATE_ENTRY_ERROR,
  CREATE_ENTRY_SUCCESS,
  UPDATE_ENTRY_OBJ,
  EDIT_ENTRY_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_DRIVER_ENTRIES_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_DRIVER_ENTRIES_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_DRIVER_ENTRIES_SUCCESS;
  reducers.CREATE_ENTITIES_REQUEST = CREATE_DRIVER_ENTRIES_REQUEST;
  reducers.CREATE_ENTITIES_ERROR = CREATE_DRIVER_ENTRIES_ERROR;
  reducers.CREATE_ENTITIES_SUCCESS = CREATE_DRIVER_ENTRIES_SUCCESS;
  reducers.entityName = "driverEntries";

  if (action.type === UPDATE_ENTRY_OBJ && action.payload.entryType === reducers.entityName) {
    const driversInState = action.payload.fullState.driver.driver
    const mixingPlantsInState = action.payload.fullState.mixingPlant.mixingPlant
    const newDriver = driversInState && action.payload.entry.Driver ? driversInState[action.payload.entry.Driver.ID] : null
    let newMixingPlant = mixingPlantsInState && action.payload.entry.MixingPlant ? mixingPlantsInState[action.payload.entry.MixingPlant.ID] : null
    if (!newMixingPlant) {
      newMixingPlant = action.payload.entry.MixingPlant
    }
    return {
      ...state,
      [action.payload.entryType]: {
        ...state[action.payload.entryType],
        [action.payload.entry.ID]: {
          ...state[action.payload.entryType][action.payload.entry.ID],
          ...action.payload.entry,
          Driver: newDriver ? newDriver : state[action.payload.entryType][action.payload.entry.ID].Driver ? state[action.payload.entryType][action.payload.entry.ID].Driver : action.payload.entry.Driver,
          MixingPlant: newMixingPlant
        }
      }
    }
  }
  if (action.type === EDIT_ENTRY_SUCCESS && action.entryType === "DriverEntries") {
    const entryName = action.entryType.charAt(0).toLowerCase() + action.entryType.slice(1)
    return {
        ...state,
        [entryName]: {
          ...state[entryName],
          [action.payload.ID]: {
            ...state[entryName][action.payload.ID],
            ...action.payload
          }
        },
        isFetching: false,
        error: undefined
    }
  }
  if (action.type === GET_DRIVER_ENTRY_SUCCESS) {
    return {
      ...state,
      driverEntries: {
        ...state.driverEntries,
        [action.payload.ID]: {
          ...action.payload
        }
      },
      isFetching: false,
      error: undefined
    }
  }
  if (action.type === CREATE_ENTRY_REQUEST && action.entryType === "DriverEntries") {
    return {
      ...state,
      isFetching: true,
      error: undefined
    }
  }
  if (action.type === CREATE_ENTRY_SUCCESS && action.entryType === "DriverEntries") {
    return {
      ...state,
      driverEntries: {
        ...state.driverEntries,
        [action.payload.ID]: {
          ...action.payload
        }
      },
      isFetching: false,
      error: undefined
    }
  }
  if (action.type === CREATE_ENTRY_ERROR && action.entryType === "DriverEntries") {
    return {
      ...state,
      isFetching: false,
      error: `${action.error}`
    }
  }

  return reducers.entity(reducers)(state, action);
};
