import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AppContainer from "../AppContainer";

import routes from "../../../config/routes";

import NotFound from "../../../pages/NotFound";
import Login from "../../../pages/Login";
import Welcome from "../../../pages/Welcome";
import ChangePassword from "../../../pages/ChangePassword";
import ResetPassword from "../../../pages/ResetPassword";
import Planning from "../../../pages/Planning";
import Help from "../../../pages/Help";
import Equipment from "../../../pages/Equipment";
import Customer from "../../../pages/Customer";
import Employee from "../../../pages/Employee";
import Crew from "../../../pages/Crew";
import Projects from "../../../pages/Projects";
import SpecialDay from "../../../pages/SpecialDay";
import Driver from "../../../pages/Driver";
import Company from "../../../pages/Company";
import MixingPlant from "../../../pages/MixingPlant";
import OrganisationData from "../../../pages/OrganisationData";
import Settings from "../../../pages/Settings";
import { HighChart } from "../../../core/components/HighChart/HighChart";

const AppRouter = ({ isLoggedIn, isSetNewPassword, isPasswordExpired }) => (
  <Router>
    <AppContainer>
      <Switch>
        <Route
          exact
          path={routes.ROOT}
          render={() => (!isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : isLoggedIn ? <Welcome /> : <Login />)}
        />
        <Route
          exact
          path={routes.CHANGEPASSWORD}
          render={() => (isLoggedIn || (!isLoggedIn && (isSetNewPassword || isPasswordExpired)) ? <ChangePassword /> : <Login />)}
        />
        <Route
          exact
          path={routes.RESETPASSWORD}
          render={() => ((!isLoggedIn) ? <ResetPassword /> : <Login />)}
        />
        <Route exact path={routes.LOGIN} render={() => <Login />} />
        <Route
          path={routes.PLANNING}
          render={() =>
            isLoggedIn ? <Planning /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.PROJECT}
          render={() =>
            isLoggedIn ? <Projects /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.HELP}
          render={() =>
            isLoggedIn ? <Help /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.EQUIPMENT}
          render={() =>
            isLoggedIn ? <Equipment /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.CUSTOMER}
          render={() =>
            isLoggedIn ? <Customer /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.EMPLOYEE}
          render={() =>
            isLoggedIn ? <Employee /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.CREW}
          render={() =>
            isLoggedIn ? <Crew /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.SPECIAL_DAYS}
          render={() =>
            isLoggedIn ? <SpecialDay /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.DRIVER}
          render={() =>
            isLoggedIn ? <Driver /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.MIXINGPLANT}
          render={() =>
            isLoggedIn ? <MixingPlant /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.SETTINGS}
          render={() =>
            isLoggedIn ? <Settings /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        {/*<Route
          path={routes.HIGHCHART}
          render={() =>
            isLoggedIn ? <HighChart /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />*/}
        <Route
          path={routes.COMPANY}
          render={() =>
            isLoggedIn ? <Company /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.ORGANISATIONDATA}
          render={() =>
            isLoggedIn ? <OrganisationData /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path={routes.PLANNING2}
          component={props => {
            window.location.replace(props.location.pathname);
            return null;
          }}
        />
        <Route
          path={['/http:', '/https:']}
          component={props => {
            window.location.replace(props.location.pathname.substr(1)); // substr(1) removes the preceding '/'
            return null;
          }}
        />
        <Route path={routes.ROOT} component={NotFound} />
      </Switch>
    </AppContainer>
  </Router>
);

export default AppRouter;
