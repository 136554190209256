import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import packageJSON from '../../../package.json';
import { isDevelopment } from '../../utils';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";

import "./index.css";

const logo = '/content/media/StrabagLogo.png';

const initialState = {
  organisation: "",
  userNumber: "",
  password: "",
  environment: "",
  submitted: false,
  showSnack: false,
  fixedDBInstance: null,
  fixedOrg: null,
  fixedUser: null,
  DatabaseInstanceOptions: [],
  isMounted: false,
  error: undefined
}

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  async componentDidMount() {
    const { history, getAppSettings } = this.props;
    const { location: { search } } = history;
    const loginParams = sessionStorage.getItem("loginParams")

    await getAppSettings();

    if (search.length > 0 || loginParams) {
      let paramsToUse, directParams

      if (loginParams === null || loginParams === undefined) {
        paramsToUse = search
        directParams = true
        this.setState({ directParams })
        sessionStorage.setItem("loginParams", search)
      } else if (loginParams && search.length === 0) {
        paramsToUse = loginParams
        directParams = true
      } else {
        paramsToUse = search
        directParams = true
        this.setState({ directParams })
        sessionStorage.setItem("loginParams", search)
      }
      this.applyQueryParams(paramsToUse, true, directParams);
    } else {
      this.applyQueryParams(null, false);
    }

    const { settings } = this.props
    if (settings) {
      const { DatabaseInstanceOptions } = settings;
      this.setState({ DatabaseInstanceOptions, isMounted: true });
    }
  }

  applyQueryParams = (rawParams, setParams, directParams) => {
    const { settings } = this.props;

    if (setParams) {
      const params = new URLSearchParams(rawParams);
      let DBs = [];
      if (settings) {
        const { DatabaseInstanceOptions } = settings;
        DatabaseInstanceOptions.forEach(database => {
          DBs.push(database.Name.replace(" ", "").toLowerCase());
        })
      }
      params.forEach((value, key) => {
        if (key.toLowerCase() === "dbinstance") {
          key = "environment";
          // check for valid DBInstance name
          if (directParams && value.length > 0 && DBs.length > 0 && DBs.includes(value.replace(" ", "").toLowerCase())) {
            // show fixed DBInstance entry only
            const { DatabaseInstanceOptions } = settings;
            const { setEnvironmentLogo } = this.props
            const logo = DatabaseInstanceOptions.find(db => db.Name.toLowerCase() === value.toLowerCase()).CompanyLogoUrl

            setEnvironmentLogo(logo)
            this.setState({ fixedDBInstance: value, environment: value, [key]: `${value}` });
          }
        } else if (key.toLowerCase() === "user") {
          key = "userNumber";
          this.setState({ fixedUser: true, [key]: `${value}` });
        } else if (key.toLowerCase() === "organisation") {
          key = key.toLowerCase();
          this.setState({ fixedOrg: true, [key]: `${value}` });
        }
      });

      // show URL in browser without query string parameters
      //if (window.history.replaceState)
      //  window.history.replaceState(null, null, '/');

      this.setState({ canSetAutoFocus: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error instanceof Error && nextProps.error !== this.state.error) {
      this.setState({ error: nextProps.error, showSnack: true });
    }
  }

  handleChange = event => {
    const { DatabaseInstanceOptions } = this.state
    const { id, value } = event.target;

    if (id === "environment") {
      const { setEnvironmentLogo } = this.props
      const logo = DatabaseInstanceOptions.find(db => db.Name === value).CompanyLogoUrl
      setEnvironmentLogo(logo)
    }

    sessionStorage.removeItem("submittedLogin")
    this.setState({
      [id]: value,
      showSnack: false
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const organisation = event.target.organisation.value;
    const userNumber = event.target.userNumber.value;
    const password = event.target.password.value;
    const environment = event.target.environment.value;
    const environmentDisplayName = event.target.environment.options[event.target.environment.selectedIndex].text;
    const { onSubmit } = this.props;

    sessionStorage.setItem("submittedLogin", true)

    const errorOrResult = await onSubmit({ organisation, userNumber, password, environment, environmentDisplayName });

    this.setState({
      showSnack: false,
      organisation: errorOrResult instanceof Error ? this.state.organisation : "",
      userNumber: errorOrResult instanceof Error ? this.state.userNumber : "",
      password: errorOrResult instanceof Error ? this.state.password : ""
    });
  };

  render() {
    const {
      organisation,
      userNumber,
      password,
      environment,
      /*       directParams, */
      fixedDBInstance,
      fixedUser,
      fixedOrg,
      DatabaseInstanceOptions,
      isMounted,
      canSetAutoFocus
    } = this.state;
    const { t, isFetching, settings } = this.props;
    const submitted = sessionStorage.getItem("submittedLogin")

    return (
      <Container component="div" maxWidth="xs" style={{ marginTop: 140, marginBottom: 40, paddingBottom: 40 }}>
        {
          isMounted ? (
            <>
              <CssBaseline />
              <div className="logo-container-big">
                <img className="logo-big" alt="logo" src={logo} />
              </div>
              <form onSubmit={this.handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  value={organisation}
                  onChange={this.handleChange}
                  required
                  fullWidth
                  id="organisation"
                  label={t('organisation')}
                  name="organisation"
                  autoFocus={(canSetAutoFocus && !fixedOrg) || (!fixedDBInstance && !fixedOrg) || (fixedDBInstance && !fixedOrg)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  onChange={this.handleChange}
                  value={userNumber}
                  required
                  fullWidth
                  id="userNumber"
                  label={t('username')}
                  name="userNumber"
                  autoFocus={(fixedOrg && !fixedUser)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  onChange={this.handleChange}
                  value={password}
                  required
                  fullWidth
                  name="password"
                  label={t('password')}
                  type="password"
                  id="password"
                  autoFocus={(fixedDBInstance !== null && fixedUser && fixedOrg) || (!fixedDBInstance && fixedUser && fixedOrg)}
                />
                <FormControl
                  variant="outlined"
                  className="selectWrapper"
                  margin="normal"
                  fullWidth
                  required
                >
                  <InputLabel
                    id="environment"
                    htmlFor="environment"
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                  >
                    {t('environment')}
                  </InputLabel>
                  <Select
                    labelId="environment"
                    id="environment"
                    onChange={this.handleChange}
                    inputProps={{
                      name: "environment",
                      id: "environment"
                    }}
                    native
                    labelWidth={102}
                  >
                    {
                      DatabaseInstanceOptions.map((database, i) => {
                        const value = `${database.Name}`;
                        const displayName = database.DisplayName ? database.DisplayName : database.Name;
                        if (database.Visible || (fixedDBInstance && database.Name.toLowerCase() === fixedDBInstance.toLowerCase())) {
                          return (
                            <option selected={environment && value.replace(" ", "").toLowerCase() === environment.replace(" ", "").toLowerCase()} key={i} value={value}>
                              {displayName}
                            </option>
                          )
                        } else {
                          return null;
                        }
                      })}
                  </Select>
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="submitButton"
                  disabled={organisation === "" || userNumber === "" || password === "" || submitted}
                >
                  {t('login')}
                </Button>
              </form>
              {isDevelopment() ? (
                <div className="resetPasswordLink">
                  <Tooltip title={t('reset-password-link')}>
                    <div>
                      <a
                        id={`resetpassword`}
                        target="_blank"
                        href="/resetpassword"
                        rel="noopener noreferrer"
                      >
                        {t('reset-password-link')}
                      </a>
                    </div>
                  </Tooltip>
                </div>) : null
              }
              <div className="versions">
                {settings && <>Server: {settings.ApplicationVersion}</>} &nbsp; Client: {packageJSON.at_co_procon.version}
              </div>
              {isFetching && <div className="login-loader"><CircularProgress color="secondary" /></div>}
            </>
          ) : null
        }
      </Container>
    );
  }
}

export default withRouter(LoginForm);
