import odata from 'odata-client';
import resources from '../../api/resources';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import { showNotification } from '../notification';

var getEntity = {

  tokenExpired: context => () => ({
    type: context.TOKEN_EXPIRED
  }),
  getEntityRequest: context => () => ({
    type: context.GET_ENTITY_REQUEST
  }),
  getEntityError: context => errorOrResult => ({
    type: context.GET_ENTITY_ERROR,
    error: errorOrResult.message
  }),
  getEntitySuccess: context => payload => ({
    type: context.GET_ENTITY_SUCCESS,
    payload
  }),

  init: context => {
    context.GET_ENTITY_REQUEST = null;
    context.GET_ENTITY_ERROR = null;
    context.GET_ENTITY_SUCCESS = null;
    context.TOKEN_EXPIRED = null;
    context.entityName = null;
    context.entityLocation = null;
    context.selectQuery = null;
    context.expandQuery = null;
    context.filterQuery = null;
    context.orderByQuery = null;
  },

  execute: context => () => async (dispatch, getState) => {

    dispatch(context.getEntityRequest(context)());

    const query = odata({ service: getServiceUrl() }).resource(context.entityLocation);

    if (context.orderByQuery !== undefined && context.orderByQuery !== null)
      query.orderby(context.orderByQuery)

    if (context.selectQuery !== undefined && context.selectQuery !== null)
      query.select(context.selectQuery);

    if (context.expandQuery !== undefined && context.expandQuery !== null)
      query.expand(context.expandQuery);

    if (context.filterQuery !== undefined && context.filterQuery !== null)
      query.filter(context.filterQuery);

    if (context.customQuery !== undefined && context.customQuery !== null)
      query.custom(context.customQuery);

    const errorOrResult = await query.get({
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        ...getDefaultHeader()
      }
    })
      .then(res => {
        const ok = res.statusCode <= 299 && res.statusCode >= 200;

        if (res.statusCode === 500) {
          const errorKey = "GENERAL_SERVER_ERROR";
          return new Error(errorKey);
        } else if (!ok) {
          console.log(`get ${context.entityName} api error`, res.toJSON());
          const error = JSON.parse(res.body).Error
          const errorKey = res.statusCode === 401 ? `401: ${error.Message}` : `${error.Message}`;
          return new Error(errorKey);
        } else {
          const body = JSON.parse(res.body);
          const payload = body.value;
          const storeState = getState()

          if (
            (context.entityLocation === resources.SpecialDays && storeState.specialday.dataIsObject)
            || (context.entityLocation === resources.Drivers && storeState.driver.dataIsObject)
            || (context.entityLocation === resources.MixtureOrders && storeState.mixtureOrder.dataIsObject)
            || (context.entityLocation === resources.Mixtures && storeState.mixture.dataIsObject)
            || (context.entityLocation === resources.ProjectActivities && storeState.projectActivities.dataIsObject)
          ) {
            const payloadObject = {}
            payload.forEach(obj => {
              payloadObject[obj.ID] = obj
            })
            return payloadObject
          }

          if (context.entityLocation === resources.MixingPlants && storeState.mixingPlant.dataIsObject) {
            const mixingPlantsById = {}
            payload.forEach(mixingPlant => {
              mixingPlantsById[mixingPlant.ID] = mixingPlant
            })

            return mixingPlantsById
          }

          return payload;
        }
      }, res => {
        // error
        const errorKey = `Error: ${res.status}, ${res.statusText}`;
        console.log('export project tender document api error', res.responseText);
        return new Error(errorKey);
      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch(showNotification("Request probably timed out.", "error"))
      });

    if (errorOrResult instanceof Error) {
      dispatch(context.getEntityError(context)(errorOrResult, context.GET_ENTITY_ERROR));
      if (errorOrResult.message.split(":")[0] === "401") {
        dispatch(context.tokenExpired(context)());
        dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
      }
      console.log("CODE ERROR: ", errorOrResult.message);
      dispatch(showNotification(errorOrResult.message, "error"))
      return;
    }

    dispatch(context.getEntitySuccess(context)(errorOrResult));

    return errorOrResult;
  }
};

export default getEntity;