import { createSelector } from 'reselect'

export const getUsersSelector = createSelector(
  user => user,
  (users) => users
);

export const getUserCrewNamesSelector = createSelector(
  user => user.crewNames,
  (crewNames) => crewNames
);

export const getUserRolesSelector = createSelector(
  user => user.roles,
  (roles) => roles
);

export const getUserSelector = createSelector(
  user => user,
  (user) => user
);