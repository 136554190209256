import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import Backend from 'i18next-http-backend';
import packageJSON from '../../package.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + packageJSON.at_co_procon.version
    },
    ns: ['common'],
    fallbackLng: 'en',
    defaultNS: ['common'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
