import React, { Component } from 'react'
import Container from "@material-ui/core/Container"
import Box from '@material-ui/core/Box';

import './index.css'

export default class Welcome extends Component {
/*     constructor(props) {
        super(props)
    }
 */
    render() {
        const { username, t } = this.props

        return (
            <Container
                component="div"
                className="welcome-box"
                style={{ fontFamily: "Open Sans" }}>
                <Box mt={5}>
                    <h1>{t('welcome')}&nbsp;{username}!</h1>
                </Box>
            </Container>
        )
    }
}