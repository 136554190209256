import odata from 'odata-client';
import {
    DELETE_PENSUM_REQUEST,
    DELETE_PENSUM_ERROR,
    DELETE_PENSUM_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const deletePensumRequest = () => ({
    type: DELETE_PENSUM_REQUEST,
});

export const deletePensumError = errorOrResults => ({
    type: DELETE_PENSUM_ERROR,
    error: errorOrResults.message,
});

export const deletePensumSuccess = payload => ({
    type: DELETE_PENSUM_SUCCESS,
    payload,
});

export default (pensum) => async (dispatch, getState) => {
    dispatch(deletePensumRequest());

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.Pensums, pensum.ID);
    const errorOrResult = await query.delete({
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            "X-IAS-SessionID": `${getState().user.changeMessageToken}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;
            if (res.statusCode === 500) {
                const body = JSON.parse(res.body);
                const { Error: { Code, Message } } = body
                let errorKey = "GENERAL_SERVER_ERROR"
                if (Code === "MessageCorrelationFailedTryAgain" || Code === "MessageCorrelationFailed") {
                    errorKey = Message
                }
                return new Error(errorKey);
            } else if (!ok) {
                console.log('delete pensum api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                return pensum;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(deletePensumError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return errorOrResult;
    } else {
        dispatch(showNotification("SUCCESSFUL_DELETE", "success"))
    }

    dispatch(deletePensumSuccess(errorOrResult));

    return errorOrResult;
};