import React, { Component } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { isMobile } from "react-device-detect";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";

import MenuItem from "../../components/MenuItem";

import routes from "../../../config/routes";

import { getPlanning2Url, getPlanning2LocalUrl, getMonitorUrl } from "../../../utils"

import "./index.css";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuValue: "",
      secondaryMenuValue: "",
      showSecondaryMenu: false
    };

    this.updateNavMenu = this.updateNavMenu.bind(this);
  }

  // FIXME how to access ApplicationSettings from here?
  //isDevelopment() { return this.state.settings.ApplicationState == 'Development'; }
  isDevelopment() { return window.location.hostname === 'iasdev.components.at'; }
  isProduction() { return window.location.hostname === 'ias.components.at'; }

  showPlanning1() { return !this.props.user.IsParentLogin && this.props.user.roles.includes('ReadPensum') && this.isDevelopment() && ['1', '46', '2099', '5076', '9732', '10062'].includes(this.props.user.userNumber); }
  showPlanning2() { return (this.props.user.roles.includes('ReadPensum') || this.props.user.roles.includes('ReadKeyResource')); }
  showPlanning2Local() { return !this.props.user.IsParentLogin && this.props.user.roles.includes('ReadPensum') && this.isDevelopment() && ['1', '46', '5076', '9732', '10062'].includes(this.props.user.userNumber); }
  showProjects() { return !this.props.user.IsParentLogin && this.props.user.roles.includes('ShowMasterDataUI') && this.props.user.roles.includes('ReadMasterData'); }
  showMasterData() { return this.props.user.roles.includes('ShowMasterDataUI') && this.props.user.roles.includes('ReadMasterData'); }
  showMonitor() { return this.props.user.roles.includes('ReadMonitor'); }

  showCrews() { return !this.props.user.IsParentLogin; }

  updateNavMenu = () => {
    const { pathname } = this.props;
    let menuValueDefault = "";
    let secondaryMenuValueDefault = "";

    switch (pathname) {
      default:
        menuValueDefault = pathname;
        break;

      case routes.EMPLOYEE:
      case routes.ORGANISATIONDATA:
      case routes.CREW:
      case routes.SPECIAL_DAYS:
      case routes.CUSTOMER:
      case routes.EQUIPMENT:
      case routes.DRIVER:
      case routes.MIXINGPLANT:
      case routes.COMPANY:
      case routes.SETTINGS:
        menuValueDefault = routes.MASTERDATA;
        secondaryMenuValueDefault = pathname;
        break;
    }
    this.setState({
      menuValue: menuValueDefault,
      secondaryMenuValue: secondaryMenuValueDefault
    });
  }

  componentDidMount() {
    const { user, showNotification, t } = this.props

    this.updateNavMenu();
    if (Boolean(user.isSetNewPassword)) {
      if (Boolean(user.lastPasswordChange)) {
        showNotification(t('passwordPolicyMessageSetNewPassword'), "error")
      } else {
        showNotification(t('passwordPolicyMessageSetFirstPassword').replace("&lt;br/&gt;", "\n"), "error")
      }
    } else if (Boolean(user.isPasswordExpired)) {
      showNotification(t('passwordPolicyMessagePasswordExpired'), "error")
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.updateNavMenu();
    }
  }

  toggleMenu = (index) => {
    this.setState({
      menuValue: index,
      showSecondaryMenu: false,
      secondaryMenuValue: ""
    });
  }

  render() {
    const { menuValue, showSecondaryMenu, secondaryMenuValue } = this.state;
    const { t } = this.props;
    const menuItemsColor = '#111e36'
    const navStyle = {
      width: document.body.scrollWidth,
      position: 'sticky',
      top: isMobile ? 67 : 84,
      left: 0,
      zIndex: 11,
      webkitBoxShadow: '0px 3px 5px 0px rgba(0,0,0,0.25)',
      mozBoxShadow: '0px 3px 5px 0px rgba(0,0,0,0.25)',
      boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.25)'
    }

    return (
      <div className="nav-container" style={navStyle}>
        <div className="menu-root">
          <Paper className="menu-container" elevation={1}>
            <Tabs
              value={menuValue}
              className="menu-list"
              indicatorColor="secondary"
              centered
              style={{ /*backgroundColor: 'rgb(200,200,200)'*/ }}
            >
              {this.showPlanning2() &&
                <MenuItem
                  label={t('planning')}
                  component={Link}
                  className="menu-item"
                  value={routes.PLANNING2}
                  to={{ pathname: getPlanning2Url(this.props.user.token) }}
                  style={{ color: menuItemsColor }}
                  onClick={() => {
                    this.setState({
                      menuValue: this.value,
                      showSecondaryMenu: false,
                      secondaryMenuValue: ""
                    });
                  }}
                />}
              {this.showPlanning2Local() &&
                <MenuItem
                  label={t('planning') + " Local"}
                  component={Link}
                  className="menu-item"
                  to={{ pathname: getPlanning2LocalUrl(this.props.user.token) }}
                  style={{ color: menuItemsColor }}
                  onClick={() => {
                    this.setState({
                      menuValue: this.value,
                      showSecondaryMenu: false,
                      secondaryMenuValue: ""
                    });
                  }}
                />}
              {this.showPlanning1() &&
                <MenuItem
                  label={t('planning') + " Old"}
                  component={Link}
                  className="menu-item"
                  value={routes.PLANNING}
                  to={routes.PLANNING}
                  style={{ color: menuItemsColor }}
                  onClick={() => {
                    this.setState({
                      menuValue: this.value,
                      showSecondaryMenu: false,
                      secondaryMenuValue: ""
                    });
                  }}
                />}
              {this.showProjects() &&
                <MenuItem
                  label={t('projects')}
                  component={Link}
                  className="menu-item"
                  value={routes.PROJECT}
                  to={routes.PROJECT}
                  style={{ color: menuItemsColor }}
                  onClick={() => {
                    this.setState({
                      menuValue: this.value,
                      showSecondaryMenu: false,
                      secondaryMenuValue: ""
                    });
                  }}
                />}
              {this.showMasterData() &&
                <MenuItem
                  className="menu-item"
                  label={t('masterdata')}
                  value={routes.MASTERDATA}
                  style={{ color: menuItemsColor }}
                  onClick={() => {
                    this.setState({
                      showSecondaryMenu: !this.state.showSecondaryMenu
                    });
                  }}
                />}
              {this.showMonitor() &&
                <MenuItem
                  label={t('monitor')}
                  component={Link}
                  className="menu-item"
                  value={routes.MONITOR}
                  to={{ pathname: getMonitorUrl(this.props.user.token) }}
                  style={{ color: menuItemsColor }}
                  onClick={() => {
                    this.setState({
                      menuValue: this.value,
                      showSecondaryMenu: false,
                      secondaryMenuValue: ""
                    });
                  }}
                />}
              {/*<MenuItem
                label={t('help')}
                component={Link}
                className="menu-item"
                value={routes.HELP}
                to={routes.HELP}
                disabled={true}
                style={{ color: menuItemsColor }}
                onClick={() => {
                  this.setState({
                    menuValue: this.value,
                    showSecondaryMenu: false,
                    secondaryMenuValue: ""
                  });
                }}
              />*/}
            </Tabs>
          </Paper>
        </div>
        {showSecondaryMenu && (
          <div className="submenu-root">
            <Paper className="menu-container" elevation={1}>
              <Tabs
                value={secondaryMenuValue}
                className={clsx("submenu-list", { "submenu-list-mobile": isMobile })}
                indicatorColor="primary"
                centered
              >
                <MenuItem
                  label={t('employee')}
                  component={Link}
                  className="submenu-item"
                  value={routes.EMPLOYEE}
                  to={routes.EMPLOYEE}
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: this.value
                    });
                  }}
                />
                {this.showCrews() &&
                  <MenuItem
                    label={t('construction-crew')}
                    component={Link}
                    className="submenu-item"
                    value={routes.CREW}
                    to={routes.CREW}
                    style={{ color: "#f5003d" }}
                    subColor={menuItemsColor}
                    onClick={() => {
                      this.setState({
                        secondaryMenuValue: this.value
                      });
                    }}
                  />}
                <MenuItem
                  label={t('special-days')}
                  component={Link}
                  className="submenu-item"
                  value={routes.SPECIAL_DAYS}
                  to={routes.SPECIAL_DAYS}
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: this.value
                    });
                  }}
                />
                <MenuItem
                  label={t('customer')}
                  component={Link}
                  className="submenu-item"
                  value={routes.CUSTOMER}
                  to={routes.CUSTOMER}
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: this.value
                    });
                  }}
                />
                <MenuItem
                  label={t('equipment')}
                  component={Link}
                  className="submenu-item"
                  value={routes.EQUIPMENT}
                  to={routes.EQUIPMENT}
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: this.value
                    })
                  }}
                />
                <MenuItem
                  label={t('lkw-driver')}
                  component={Link}
                  className="submenu-item"
                  value={routes.DRIVER}
                  to={routes.DRIVER}
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: this.value
                    });
                  }}
                />
                <MenuItem
                  label={t('asphalt-mixing-plant')}
                  component={Link}
                  className="submenu-item"
                  value={routes.MIXINGPLANT}
                  to={routes.MIXINGPLANT}
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: this.value
                    });
                  }}
                />
                <MenuItem
                  label={t('company')}
                  component={Link}
                  className="submenu-item"
                  value={routes.COMPANY}
                  to={routes.COMPANY}
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: this.value
                    });
                  }}
                />
                <MenuItem
                  label={t('organisation-data')}
                  component={Link}
                  className="submenu-item"
                  value={routes.ORGANISATIONDATA}
                  to={routes.ORGANISATIONDATA}
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: this.value
                    });
                  }}
                />
                {this.showPlanning1() && <MenuItem
                  label={t('settings')}
                  component={Link}
                  className="submenu-item"
                  value={routes.SETTINGS}
                  to={routes.SETTINGS}
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: this.value
                    });
                  }}
                />}
              </Tabs>
            </Paper>
          </div>
        )}
      </div>
    );
  }
}

export default Navigation;
