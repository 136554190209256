import React from "react";
/* import _ from 'lodash'; */

import { isEqualMasterData, isValidEmail, strToInt, decodeMixingPlantClusters } from "../../utils";
import { COMMUNICATION_CHANNELS } from "../../config/constants";

import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
/* import InputLabel from '@material-ui/core/InputLabel'; */
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemText from "@material-ui/core/ListItemText";
import NumberInput from '../../core/components/Inputs/NumberInput/numberInput';

import './index.css';
import { expandPopupOrganisation, filterOrganisations, filterEntities, isEditable, getOrganisation } from "../Common/common";
import { mixingPlantGroupNamesSeparator } from "../../utils";
import { Typography } from "@material-ui/core";

class MixingPlantTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      organisations: props.organisations ? props.organisations : '',
      editable: props.editable ? props.editable : '',
      selectedFilters: [],
      newData: null,
      error: null,
      newClusters: []
    };

    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.componentDidMount_();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mixingPlantClusters !== this.props.mixingPlantClusters) {
      this.componentDidMount_();
    }
  }

  componentDidMount_() {

    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }

    var lookupMixingPlantClusters = new Map();
    var filteredLookupMixingPlantClusters = new Map();

    const { organisations, selectedFilters } = this.state;
    const { t } = this.props;
    const lookupOrgs = {};
    const filteredLookupOrgs = {};
    const columns = [
      {
        title: `${t('organisation')} *`, field: "Organisation.Name", lookup: lookupOrgs, defaultFilter: this.state.selectedFilters,
        initialEditValue: selectedFilters.length > 0 ? selectedFilters : null,
        render: rowData => (
          rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
        ),
        validate: ({ Organisation }) => Organisation !== undefined && Organisation !== null,
        editComponent: props => (
          <FormControl>
            <Select
              labelId="org-label"
              id="organisation"
              className="organisationSelect"
              value={props.value}
              autoFocus
              onChange={e => props.onChange(e.target.value)}
              error={props.value === null || props.value === undefined}
              onClick={async () => {
                await new Promise(resolve => setTimeout(() => resolve(), 100));
                const { organisations } = this.state;
                var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
                var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
                expandPopupOrganisation(filteredOrgs, "MuiListItem-button");
              }}
            >
              {Object.keys(props.columnDef.lookup).map(key => (
                (selectedFilters.length === 0 || selectedFilters.includes(key)) && filteredLookupOrgs[key] !== undefined ?
                  <MenuItem
                    value={key}
                  >
                    <em>{props.columnDef.lookup[key]}</em>
                  </MenuItem>
                  : null
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        title: `${t('name')} *`, field: "Name",
        validate: ({ Name }) => Name && Name.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('communication-channel-code'), field: 'CommunicationChannelCode', filtering: false,
        render: rowData => (
          t(`communicationChannels.${Object.keys(COMMUNICATION_CHANNELS).find(key => COMMUNICATION_CHANNELS[key] === rowData.CommunicationChannelCode)}`)
        ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Object.entries(COMMUNICATION_CHANNELS).map((channel, i) => {
              const name = t(`communicationChannels.${channel[0]}`)
              const value = channel[1]
              return (
                <MenuItem value={value}>
                  <em>{name}</em>
                </MenuItem>
              )
            })}
          </Select>
        )
      },
      {
        title: t('email-address'), field: 'EmailAddress', filtering: false,
        validate: ({ EmailAddress }) => EmailAddress === null || EmailAddress === undefined || (EmailAddress && isValidEmail(EmailAddress)),
        editComponent: props => (
          <FormControl className="input-short">
            <TextField
              id="email-input"
              value={props.value}
              type="text"
              error={props.value && !isValidEmail(props.value)}
              InputProps={{
                style: {
                  width: 130
                }
              }}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        )
      },
      {
        title: t('phone-number'), field: 'PhoneNumber', filtering: false,
        editComponent: props => (
          <FormControl className="input-short">
            <TextField
              id="phone-input"
              value={props.value}
              type="text"
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        )
      },
      { title: t('location-pluscode'), field: "LocationPlusCode", filtering: false },
      {
        title: t("is-deleted"), field: "IsDeleted", type: 'boolean', tableData: { "filterValue": "unchecked" }, width: '50px',
        render: rowData => (
          <Checkbox checked={rowData.IsDeleted} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: `${t('minimum-amount-of-output')}`, field: "MinimumAmountOfOutput", align: "right",
        cellStyle: {
          textAlign: "right"
        },
        validate: rowData => !(rowData !== undefined && strToInt(rowData.MinimumAmountOfOutput) !== null && strToInt(rowData.MaximumAmountOfOutput) !== null && strToInt(rowData.MinimumAmountOfOutput) > strToInt(rowData.MaximumAmountOfOutput)),
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="minimumAmountOfOutput"
              type="number"
              value={props.value}
              error={strToInt(props.value) !== null && strToInt(props.rowData.MaximumAmountOfOutput) !== null && strToInt(props.value) > strToInt(props.rowData.MaximumAmountOfOutput)}
              handleChange={e => props.onChange(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">to</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('maximum-amount-of-output')}`, field: "MaximumAmountOfOutput", align: "right",
        cellStyle: {
          textAlign: "right"
        },
        validate: rowData => !(rowData !== undefined && strToInt(rowData.MinimumAmountOfOutput) !== null && strToInt(rowData.MaximumAmountOfOutput) !== null && strToInt(rowData.MinimumAmountOfOutput) > strToInt(rowData.MaximumAmountOfOutput)),
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="maximumAmountOfOutput"
              type="number"
              value={props.value}
              error={strToInt(props.value) !== null && strToInt(props.rowData.MinimumAmountOfOutput) !== null && strToInt(props.value) < strToInt(props.rowData.MinimumAmountOfOutput)}
              handleChange={e => props.onChange(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">to</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('asphalt-mixing-plant-cluster'), field: 'GroupNames', filtering: true, lookup: filteredLookupMixingPlantClusters,
        customFilterAndSearch: (term, rowData) =>
          //search
          (
            (typeof term) === "string" &&
            (
              term === '' ||
              (rowData.GroupNames != null && rowData.GroupNames.includes(term))
            )
          ) ||
          //filter
          (
            (typeof term) !== "string" &&
            (
              term.length === 0 ||
              (rowData.GroupNames == null && term.includes(null)) ||
              (rowData.GroupNames != null && term.filter((t) => t !== null).some((t) => decodeMixingPlantClusters(rowData.GroupNames).some((g) => g.Name === t)))
            )
          ),
        render: rowData => (
          rowData.GroupNames != null ? <>{rowData.GroupNames}</> : <>{' '}</>
        ),
        filterComponent: props => (
          <Select
            multiple
            value={props.columnDef.tableData.filterValue || []}
            renderValue={(selecteds) => Object.keys(selecteds).map((key) => selecteds[key]).join(", ")}
            onChange={(event) => {
              props.columnDef.tableData.filterValue = event.target.value;
              if (props.columnDef.filterOnItemSelect === true)
                props.onFilterChanged(
                  props.columnDef.tableData.id,
                  event.target.value
                );
              else
                props.onFilterChanged(
                  props.columnDef.tableData.id,
                  event.target.value
                );
            }}
            input={
              <Input id={"select-multiple-checkbox" + props.columnDef.tableData.id} />
            }
          >
            {
              Array.from(props.columnDef.lookup, ([key, value]) => (
                filteredLookupMixingPlantClusters.get(key) !== undefined ?
                  <MenuItem key={key} value={key}>
                    <Checkbox checked={props.columnDef.tableData.filterValue !== undefined ? props.columnDef.tableData.filterValue.indexOf(key) > -1 : false} />
                    <ListItemText primary={value} />
                  </MenuItem> : null
              ))}
          </Select>
        ),
        editComponent: props => (
          <div>
            <Select
              multiple
              value={props.value != null ? this.isString(props.value) ? props.value.split(mixingPlantGroupNamesSeparator) : props.value : []}
              renderValue={(selected) => selected != null ? Object.values(selected).map((v) => v).join(",") : null}
              onChange={e => this.handleOnChange(props, e)}
              closeMenuOnSelect
            >
              {Array.from(props.columnDef.lookup, ([key, value]) => (
                filteredLookupMixingPlantClusters.get(key) !== undefined && key !== null ?
                  <MenuItem key={key} value={value}>
                    <ListItemText primary={value} />
                  </MenuItem>
                  : null
              ))}
            </Select>
          </div>
        )
      },
    ];

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
      var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
      var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
      filteredOrgs.forEach(org => {
        filteredLookupOrgs[org.Name] = org.Name;
      })
    }

    const { mixingPlantClusters } = this.props;
    const { newClusters } = this.state;

    var clusters = [...mixingPlantClusters, ...newClusters]
      .sort((a, b) => {
        const result = a.Name.localeCompare(b.Name, undefined, { sensitivity: 'base' })
        return result;
      });

    //lookupMixingPlantClusters.set(null, '\xA0');
    if (clusters && clusters.length > 0) {
      clusters.forEach(c => {
        lookupMixingPlantClusters.set(c.Name, c.Name);
      })
      // currently no actual fitlering
      var filteredMixingPlantCluster = clusters;// filterEntities(clusters, this.props.user.organisation, this.props.user.IsParentLogin);
      filteredLookupMixingPlantClusters.set(null, '\xA0');
      filteredMixingPlantCluster.forEach(c => {
        filteredLookupMixingPlantClusters.set(c.Name, c.Name);
      })
    }

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ initPage, selectedRow: indexOfNewRow !== -1 ? data[indexOfNewRow] : null, isMounted: true, columns: columns })
    }
    else {
      this.setState({ isMounted: true, columns: columns })
    }

  }

  isString = thing => Object.prototype.toString.call(thing) === '[object String]'

  handleOnChange(props, e) {
    if (e.target.value != null && e.target.value.length > 0) {
      var value = e.target.value
      props.onChange(value.length > 0 ? value.join(mixingPlantGroupNamesSeparator) : null);
    }
    else {
      props.onChange(null);
    }
  }

  handleMixingPlantClusterChange = event => {
    const { value } = event.target
    const { t, mixingPlantClusters } = this.props;
    const { newClusters } = this.state;
    let isValidMixingPlantClusterName = true

    var clusterName = value;
    var mixingPlantClusterNameErrorMessage = null;

    isValidMixingPlantClusterName = clusterName !== null && clusterName !== '';

    if (mixingPlantClusters.some((c) => c.Name === clusterName) || newClusters.some((c) => c.Name === clusterName)) {
      isValidMixingPlantClusterName = false;
      mixingPlantClusterNameErrorMessage = t('asphalt-mixing-plant-cluster-validation-message-cluster-already-exists');
    }
    if (clusterName.includes(mixingPlantGroupNamesSeparator)) {
      isValidMixingPlantClusterName = false;
      mixingPlantClusterNameErrorMessage = t('asphalt-mixing-plant-cluster-validation-message-name-contains-invalid-character', { invalid: mixingPlantGroupNamesSeparator });
    }

    this.setState({ isValidMixintPlantClusterName: isValidMixingPlantClusterName, clusterName, mixingPlantClusterNameErrorMessage })
  }

  createMixingPlantCluster = (e) => {

    const { newClusters, clusterName } = this.state;
    if (!newClusters.some((c) => c.Name === clusterName)) {
      newClusters.push({ ID: clusterName, Name: clusterName });
      this.componentDidMount_();
    }

    this.setState({
      newClusters: newClusters,
      openAddCluster: false,
      clusterName: null
    })
  }

  closeAddClustter = () => {
    this.setState({
      openAddCluster: false,
      clusterName: null
    })
  }

  render() {
    const { organisations, initPage, isMounted, editable, openAddCluster, isValidMixintPlantClusterName, mixingPlantClusterNameErrorMessage, clusterName } = this.state;
    const { t } = this.props;

    return (
      isMounted ? (
        <div>
          <IconButton
            key="add"
            aria-label="add"
            color="inherit"
            style={{ borderRadius: 5 }}
            onClick={(e) => this.setState({ openAddCluster: true })}
          >
            <AddIcon />
            <Typography variant="caption">{t('create-asphalt-mixing-plant-cluster')}</Typography>
          </IconButton>
          <Modal open={openAddCluster} onClose={this.closeAddClustter} className="modal">
            <div className="create-popup">
              <div className="popup-title"> {t('create-asphalt-mixing-plant-cluster')}</div>
              <div style={{ textAlign: "left", padding: "20px" }}>
                <FormControl>
                  <InputLabel className="transformed-mixing-plant-cluster-label" style={{ color: !isValidMixintPlantClusterName && 'red' }}>
                    {t('asphalt-mixing-plant-cluster')}
                  </InputLabel>
                  <TextField
                    id="addMixingPlantCluster"
                    value={clusterName}
                    onChange={this.handleMixingPlantClusterChange}
                    inputProps={{
                      style: {
                        color: !isValidMixintPlantClusterName && 'red',
                      },
                    }}
                    helperText={!isValidMixintPlantClusterName && mixingPlantClusterNameErrorMessage !== null ? mixingPlantClusterNameErrorMessage : ''}
                    FormHelperTextProps={{
                      style: {
                        color: !isValidMixintPlantClusterName && 'red',
                      },
                    }}
                  />
                </FormControl>
              </div>
              <div style={{ width: "100%", textAlign: "center", padding: "10px" }}>
                <div className="button-container">
                  <Button
                    type="submit"
                    style={{ marginRight: 7 }}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={(e) => this.createMixingPlantCluster(e)}
                    disabled={!isValidMixintPlantClusterName}
                  >
                    {t('save')}
                  </Button>
                  <Button
                    onClick={this.closeAddClustter}
                    variant="contained"
                    color="inherit"
                    size="small"
                  >
                    {t('close')}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          <MaterialTable
            tableRef={this.tableRef}
            title={this.state.title}
            columns={this.state.columns}
            data={this.state.data}
            onRowClick={((evt, rowData) => {
              this.setState({ selectedRow: rowData });
              this.props.onMixingPlantRowChanged(rowData);
            })}
            style={{
              paddingLeft: !editable && '2%',
              paddingRight: !editable && '2%',
              fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
              fontSize: `0.875rem`
            }}
            options={{
              pageSize: 5,
              pageSizeOptions: this.state.data.length > 5 ? [3, 4, 5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [5],
              paginationType: "normal",
              initialPage: initPage,
              addRowPosition: "first",
              draggable: false,
              filtering: true,
              searchFieldAlignment: "left",
              toolbarButtonAlignment: "left",
              rowStyle: rowData => ({
                backgroundColor: this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id ? '#EEE' : '#FFF'
              })
            }}
            localization={{
              header: {
                actions: t('actions')
              },
              toolbar: {
                searchTooltip: t('search'),
                searchPlaceholder: t('search')
              },
              body: {
                emptyDataSourceMessage: t('no-records-to-display'),
                addTooltip: t('add'),
                deleteTooltip: t('delete'),
                editTooltip: t('edit'),
                editRow: {
                  saveTooltip: t('save'),
                  cancelTooltip: t('cancel'),
                  deleteText: t('deleteText')
                }
              },
              pagination: {
                firstTooltip: t('first-page'),
                previousTooltip: t('previous-page'),
                nextTooltip: t('next-page'),
                lastTooltip: t('last-page')
              }
            }}
            editable={editable && {
              isEditable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
              isDeletable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
              onRowAdd: newData =>
                new Promise(resolve => {
                  this.setState({
                    newData: newData
                  });
                  setTimeout(async () => {
                    resolve();
                    const { organisations } = this.state;
                    if (organisations) {
                      const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                      if (newData.EmailAddress && !isValidEmail(newData.EmailAddress)) {
                        const { t, showNotification } = this.props
                        showNotification(t('email-address-invalid'), "error")
                        return
                      }
                      const errorOrResult = await this.props.onMixingPlantRowAdd({
                        Name: newData.Name,
                        EmailAddress: newData.EmailAddress,
                        LocationPlusCode: newData.LocationPlusCode,
                        CommunicationChannelCode: newData.EmailAddress !== null && newData.EmailAddress !== undefined && newData.EmailAddress.length > 0 ? COMMUNICATION_CHANNELS.Email : COMMUNICATION_CHANNELS.None,
                        MinimumAmountOfOutput: newData.MinimumAmountOfOutput,
                        MaximumAmountOfOutput: newData.MaximumAmountOfOutput,
                        GroupNames: newData.GroupNames !== undefined && newData.GroupNames !== null && newData.GroupNames.length === 0 ? null : newData.GroupNames,
                        Organisation: {
                          ID: org ? org.ID : null,
                          Name: org ? org.Name : null,
                          ShortName: org ? org.ShortName : null
                        },
                      });
                      if (!(errorOrResult instanceof Error)) {
                        const { setNewRow, toggleTable } = this.props
                        const { ID } = errorOrResult
                        if (org) {
                          newData.Organisation = org;
                        }
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          newData.ID = ID;
                          data.push(newData);
                          return { ...prevState, data };
                        });
                        setNewRow(ID)
                        toggleTable()
                        await this.props.onMixingPlantRowChanged(newData);
                        await this.props.onMixingPlantRowModified(null, newData);
                      }
                    }
                  }, 100);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    resolve();
                    if (oldData && newData && !isEqualMasterData("MixingPlant", oldData, newData)) {
                      const { organisations } = this.state;
                      if (organisations) {
                        const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                        if (newData.EmailAddress && !isValidEmail(newData.EmailAddress)) {
                          const { t, showNotification } = this.props
                          showNotification(t('email-address-invalid'), "error")
                          return
                        }
                        const errorOrResult = await this.props.onMixingPlantRowUpdate({
                          ID: newData.ID,
                          Name: newData.Name,
                          EmailAddress: newData.EmailAddress,
                          CommunicationChannelCode: newData.EmailAddress !== null && newData.EmailAddress !== undefined && newData.EmailAddress.length > 0 ? COMMUNICATION_CHANNELS.Email : COMMUNICATION_CHANNELS.None,
                          LocationPlusCode: newData.LocationPlusCode,
                          MinimumAmountOfOutput: newData.MinimumAmountOfOutput,
                          MaximumAmountOfOutput: newData.MaximumAmountOfOutput,
                          GroupNames: newData.GroupNames !== null && newData.GroupNames.length === 0 ? null : newData.GroupNames,
                          Organisation: {
                            ID: org ? org.ID : null,
                            Name: org ? org.Name : null,
                            ShortName: org ? org.ShortName : null
                          },
                        }, oldData.tableData);
                        if (!(errorOrResult instanceof Error)) {
                          if (org) {
                            newData.Organisation = org;
                          }
                          this.setState(prevState => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                          await this.props.onMixingPlantRowModified(oldData, newData);
                        }
                      }
                    }
                  }, 100);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    resolve();
                    var selected = this.state.selectedRow;
                    const errorOrResult = await this.props.onMixingPlantRowDelete(oldData);
                    if (!(errorOrResult instanceof Error)) {
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data, selectedRow: (prevState.selectedRow !== undefined && prevState.selectedRow !== null && prevState.selectedRow.ID !== oldData.ID ? prevState.selectedRow : null) };
                      });
                      if (selected !== undefined && selected != null && oldData.ID === selected.ID) {
                        await this.props.onMixingPlantRowChanged(null);
                      }
                      await this.props.onMixingPlantRowModified(oldData, null);
                    }
                  }, 100);
                })
            }}
          />
        </div>
      ) : <></>
    );
  }
}

export default MixingPlantTable;