import React, { Component } from 'react';
import styled from 'styled-components';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import UndoIcon from '@material-ui/icons/NavigateBefore';
import RedoIcon from '@material-ui/icons/NavigateNext';

import './index.css';

const Container = styled.div`
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 137px;
  z-index: 3;
`

const Crew = styled.div`
  width: 340px;
  height: 30px;
  margin-right: 3px;
  margin-bottom: 3px;
  padding-top: 8px;
  padding-bottom: 11px;
  display: flex;
  text-align: center;
  color: white;
  border-top: thin solid #FDFDFD;
  border-radius: 3px;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0,0,0);
  flex-direction: column;
  background-color: #223B67;
`

const FirstColumn = styled.div`
  height: 25px;
  width: 105px;
  margin-bottom: 2px;
  margin-left: 3px;
  margin-right: 2px;
  padding-top: 7px;
  padding-bottom: 17px;
  background-color: #223B67;
  text-shadow: 1px 1px 2px rgba(0,0,0);
  color: white;
  border-top: thin solid #FDFDFD;
  border-bottom: thin solid #FDFDFD;
  border-right: thin solid #FDFDFD;
  border-radius: 0px 3px 3px 0px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
`

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 2,
      /*style: trigger ? {backgroundColor: "rgba(255,255,255,0.99)"} : {}*/
    });
}

export default class CrewContainer extends Component {
  constructor(props) {
      super(props);
      this.state = {
          crews: props.crews,
          isHoveringBack: false,
          isHoveringRevert: false,
          isHoveringForward: false,
      }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.crews && nextProps.crews !== this.state.crews) {
      this.setState({ crews: nextProps.crews });
    }
  }

  onMouseEnterBack = () => {
    this.setState({ isHoveringBack: true });
  }

  onMouseLeaveBack = () => {
    this.setState({ isHoveringBack: false });
  }

  onMouseEnterForward = () => {
    this.setState({ isHoveringForward: true });
  }

  onMouseLeaveForward = () => {
    this.setState({ isHoveringForward: false });
  }

  render() {
    const { crews, /* isHoveringBack, isHoveringForward  */} = this.state;
    const {
      history,
      handleHistoryChange
    } = this.props;
    const currentStep = history !== undefined ? history.currentStep : undefined
    const chain = history !== undefined ? history.chain : undefined
    const disabled = chain === undefined || chain === null || chain.length < 1

    return (
      <>
        {crews !== undefined ? (
          <ElevationScroll>
            <Container>
              <FirstColumn className="history-container">
                <div className="undo-button-container" onMouseEnter={this.onMouseEnterBack} onMouseLeave={this.onMouseLeaveBack}>
                  <UndoIcon
                    className="undo-buttons"
                    onClick={() => !disabled && currentStep !== -1 ? handleHistoryChange(currentStep, "back", null) : null}
                    style={{
                      marginTop: 6,
                      /*color: !disabled && currentStep !== -1 ? isHoveringBack ? "#146D99" : "#0B415B" : "#fff",*/
                      cursor: !disabled && currentStep !== -1 ? "pointer" : "default"
                    }}
                  />
                </div>
                <div
                  className="history-center"
                  style={{
                    marginTop: 6,
                    fontSize: 17,
                    fontFamily: "Open Sans",
                    textAlign: "center"
                  }}
                >
                  {currentStep + 1} / {chain.length}
                  {/*<RevertAllIcon
                    className="undo-buttons"
                    onClick={() => !disabled && !historyRevertIsDisabled ? handleHistoryChange(chain.length-1, "back", []) : null}
                    style={{
                      marginTop: -2,
                      color: !disabled && !historyRevertIsDisabled ? isHoveringRevert ? "#FF0A0A" : "#0B415B" : "#A3A3A3",
                      cursor: !disabled && !historyRevertIsDisabled ? "pointer" : "default"
                    }}
                  />*/}
                </div>
                <div className="undo-button-container" onMouseEnter={this.onMouseEnterForward} onMouseLeave={this.onMouseLeaveForward}>
                  <RedoIcon
                    className="undo-buttons"
                    onClick={() => !disabled && currentStep !== chain.length - 1 ? handleHistoryChange(currentStep + 1, "forward", null) : null}
                    style={{
                      marginTop: 6,
                      /*color: !disabled && currentStep !== chain.length - 1 ? isHoveringForward ? "#146D99" : "#0B415B" : "#fff",*/
                      cursor: !disabled && currentStep !== chain.length - 1 ? "pointer" : "default"
                    }}
                  />
                </div>
              </FirstColumn>
              {this.state.crews.map(crew => (
                <Crew key={crew.ID} {...this.props} className="crew-container">{crew.Name}</Crew>
              ))}
            </Container>
          </ElevationScroll>
        ) : null}
      </>
    )
  }
}