import odata from 'odata-client';
import {
  GET_EMPLOYEE_CREW_REQUEST,
  GET_EMPLOYEE_CREW_ERROR,
  GET_EMPLOYEE_CREW_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const GetEmployeeCrewRequest = () => ({
  type: GET_EMPLOYEE_CREW_REQUEST
});

export const GetEmployeeCrewError = errorOrResult => ({
  type: GET_EMPLOYEE_CREW_ERROR,
  error: errorOrResult.message
});

export const GetEmployeeCrewSuccess = payload => ({
  type: GET_EMPLOYEE_CREW_SUCCESS,
  payload
});

export default (employee) => async (dispatch, getState) => {

  dispatch(GetEmployeeCrewRequest());

  const query = odata({ service: getServiceUrl() }).resource(RESOURCES.Employees, employee.ID).resource(RESOURCES.Crews);
  const errorOrResult = await query.get({
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    },
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;
      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        console.log('Get employee crew api error', res.toJSON());
        const body = JSON.parse(res.body);
        const { Error: { Message } } = body
        const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
        return new Error(errorKey);
      } else {
        const body = JSON.parse(res.body);
        const { "@odata.context": omit, ...payload } = body.value;
        return payload;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    })

  if (errorOrResult instanceof Error) {
    dispatch(GetEmployeeCrewError(errorOrResult, GET_EMPLOYEE_CREW_ERROR));
    console.log("CODE ERROR: ", errorOrResult.message);
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
    return errorOrResult;
  }

  dispatch(GetEmployeeCrewSuccess(errorOrResult));

  return errorOrResult;
};