import {
  GET_CREWS_SUCCESS,/*  GET_MIXINGPLANT_SUCCESS, */ GET_PROJECTS_SUCCESS, GET_PROJECT_ACTIVITIES_SUCCESS, UPDATE_PROJECT_SUCCESS, GET_PROJECT_SUCCESS, GET_EMPLOYEE_SUCCESS, UPDATE_EMPLOYEE_SUCCESS
} from "../../actions/types";

var reducers = {
  entityName: null,
  initialState: {},
  init: context => {
    context.GET_ENTITY_REQUEST = null;
    context.GET_ENTITIES_REQUEST = null;
    context.GET_ENTITY_SUCCESS = null;
    context.GET_ENTITIES_SUCCESS = null;
    context.GET_ENTITY_ERROR = null;
    context.GET_ENTITIES_ERROR = null;
    context.CREATE_ENTITY_REQUEST = null;
    context.CREATE_ENTITY_SUCCESS = null;
    context.CREATE_ENTITY_ERROR = null;
    context.CREATE_ENTITIES_REQUEST = null;
    context.CREATE_ENTITIES_SUCCESS = null;
    context.CREATE_ENTITIES_ERROR = null;
    context.UPDATE_ENTITY_REQUEST = null;
    context.UPDATE_ENTITY_SUCCESS = null;
    context.UPDATE_ENTITY_ERROR = null;
    context.DELETE_ENTITY_REQUEST = null;
    context.DELETE_ENTITY_SUCCESS = null;
    context.DELETE_ENTITY_ERROR = null;

    context.entityName = null;
    context.entitiesName = null;
  },
  entity: context => (state, action) => {
    switch (action.type) {
      case context.GET_ENTITY_REQUEST:
        return {
          ...state,
          [context.entityName]: {},
          isFetching: true,
          error: undefined
        };
      case context.GET_ENTITIES_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.GET_ENTITY_SUCCESS:
        let entity = action.payload;
        if (action.type === GET_EMPLOYEE_SUCCESS && entity) {
          Object.values(entity).map(e => {
            if (e.FirstNames === null) {
              e.FirstNames = "";
            }
          })
        }
        let newStateObj = {
          ...state,
          [context.entityName]: entity,
          isFetching: false,
          error: undefined
        }
        if (action.type === GET_PROJECT_SUCCESS && action.payload.length > 0) {
          const existingIndexOfProject = state.projects.findIndex(obj => obj.ID === action.payload[0].ID)
          //console.log("existingIndexOfProject:", existingIndexOfProject)
          if (existingIndexOfProject === -1) {
            newStateObj = {
              ...newStateObj,
              projects: [...state.projects, action.payload[0]]
            }
          } else {
            newStateObj = {
              ...newStateObj,
              projects: state.projects.map(item => {
                if (item.ID === action.payload[0].ID) {
                  return action.payload[0];
                }
                return item;
              })
            }
          }
        }
        return { ...newStateObj };

      case context.GET_ENTITIES_SUCCESS:
        let entities;
        if (
          action.type === GET_CREWS_SUCCESS
          || action.type === GET_PROJECTS_SUCCESS
          || action.type === GET_PROJECT_ACTIVITIES_SUCCESS
        ) {
          entities = action.payload
        } else {
          entities = Array.from(action.payload)
        }
        //console.log(entities);
        return {
          ...state,
          [context.entitiesName]: action.payload,
          isFetching: false,
          error: undefined
        };

      case context.GET_ENTITY_ERROR:
        return {
          [context.entityName]: {},
          error: `${action.error}`,
          isFetching: false,
        };

      case context.GET_ENTITIES_ERROR:
        return {
          [context.entitiesName]: {},
          error: `${action.error}`,
          isFetching: false,
        };

      case context.CREATE_ENTITY_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.CREATE_ENTITY_SUCCESS:
        return {
          ...state,
          [context.entityName]: [...state[context.entityName], action.payload],
          isFetching: false,
          error: undefined
        };

      case context.CREATE_ENTITY_ERROR:
        return {
          [context.entityName]: state[context.entityName],
          error: `${action.error}`,
          isFetching: false,
        };

      case context.CREATE_ENTITIES_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.CREATE_ENTITIES_SUCCESS:
        return {
          ...state,
          [context.entityName]: {
            ...state[context.entityName],
            [action.payload.ID]: action.payload
          },
          isFetching: false,
          error: undefined
        };

      case context.UPDATE_ENTITY_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.UPDATE_ENTITY_SUCCESS:
        let newState = {
          ...state,
          [context.entityName]: state[context.entityName].map(item => {
            if (item.ID === action.payload.ID) {
              return action.payload;
            }
            return item;
          }),
          isFetching: false,
          error: undefined
        }

        if (action.type === UPDATE_PROJECT_SUCCESS) {
          newState = {
            ...newState,
            projects: state.projects.map(item => {
              if (item.ID === action.payload.ID) {
                return {
                  ...item,
                  ...action.payload,
                };
              }
              return item;
            })
          }
        }
        else if (action.type === UPDATE_EMPLOYEE_SUCCESS) {
          newState = {
            ...newState,
            employees: state.employees.map(item => {
              if (item.ID === action.payload.ID) {
                return {
                  ...item,
                  ...action.payload,
                };
              }
              return item;
            })
          }
        }

        return { ...newState };

      case context.UPDATE_ENTITY_ERROR:
        return {
          ...state,
          error: `${action.error}`,
          isFetching: false,
        };

      case context.DELETE_ENTITY_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.DELETE_ENTITY_SUCCESS:
        let { [context.entityName]: { [action.payload.ID]: omit, ...newEntities } } = state
        return {
          ...state,
          [context.entityName]: state.dataIsObject ? newEntities : state[context.entityName].filter(item => item.ID !== action.payload.ID),
          isFetching: false,
          error: undefined
        };

      case context.DELETE_ENTITY_ERROR:
        return {
          ...state,
          error: `${action.error}`,
          isFetching: false,
        };

      case context.SET_DATA_IS_OBJECT:
        return {
          ...state,
          dataIsObject: action.isObject
        };

      default:
        return state;
    }
  }
};

export default reducers;
