import {
  GET_EQUIPMENT_ENTRIES_REQUEST,
  GET_EQUIPMENT_ENTRIES_ERROR,
  GET_EQUIPMENT_ENTRIES_SUCCESS,
  CREATE_EQUIPMENT_ENTRIES_REQUEST,
  CREATE_EQUIPMENT_ENTRIES_ERROR,
  CREATE_EQUIPMENT_ENTRIES_SUCCESS,
  UPDATE_EQUIPMENT_ENTRIES_REQUEST,
  UPDATE_EQUIPMENT_ENTRIES_ERROR,
  UPDATE_EQUIPMENT_ENTRIES_SUCCESS,
  DELETE_EQUIPMENT_ENTRIES_REQUEST,
  DELETE_EQUIPMENT_ENTRIES_ERROR,
  DELETE_EQUIPMENT_ENTRIES_SUCCESS,
  CREATE_ENTRY_REQUEST,
  CREATE_ENTRY_ERROR,
  CREATE_ENTRY_SUCCESS,
  UPDATE_ENTRY_OBJ,
  EDIT_ENTRY_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_EQUIPMENT_ENTRIES_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_EQUIPMENT_ENTRIES_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_EQUIPMENT_ENTRIES_SUCCESS;
  reducers.CREATE_ENTITIES_REQUEST = CREATE_EQUIPMENT_ENTRIES_REQUEST;
  reducers.CREATE_ENTITIES_ERROR = CREATE_EQUIPMENT_ENTRIES_ERROR;
  reducers.CREATE_ENTITIES_SUCCESS = CREATE_EQUIPMENT_ENTRIES_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_EQUIPMENT_ENTRIES_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_EQUIPMENT_ENTRIES_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_EQUIPMENT_ENTRIES_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_EQUIPMENT_ENTRIES_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_EQUIPMENT_ENTRIES_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_EQUIPMENT_ENTRIES_SUCCESS;
  reducers.entityName = "equipmentEntries";

  if (action.type === UPDATE_ENTRY_OBJ && action.payload.entryType === reducers.entityName) {
    const equipmentInState = action.payload.fullState.equipment.equipment
    const newEquipment = equipmentInState ? equipmentInState[action.payload.entry.Equipment.ID] : null
    delete action.payload.entry.Pensum
    return {
      ...state,
      [action.payload.entryType]: {
        ...state[action.payload.entryType],
        [action.payload.entry.ID]: {
          ...state[action.payload.entryType][action.payload.entry.ID],
          ...action.payload.entry,
          Equipment: newEquipment ? newEquipment : state[action.payload.entryType][action.payload.entry.ID].Equipment
        }
      }
    }
  }
  if (action.type === EDIT_ENTRY_SUCCESS && action.entryType === "EquipmentEntries") {
    const entryName = action.entryType.charAt(0).toLowerCase() + action.entryType.slice(1)
    return {
        ...state,
        [entryName]: {
          ...state[entryName],
          [action.payload.ID]: {
            ...state[entryName][action.payload.ID],
            ...action.payload
          }
        },
        isFetching: false,
        error: undefined
    }
  }
  if (action.type === CREATE_ENTRY_REQUEST && action.entryType === "EquipmentEntries") {
    return {
      ...state,
      isFetching: false,
      error: undefined
    }
  }
  if (action.type === CREATE_ENTRY_SUCCESS && action.entryType === "EquipmentEntries") {
    return {
      ...state,
      equipmentEntries: {
        ...state.equipmentEntries,
        [action.payload.ID]: {
          ...action.payload
        }
      },
      isFetching: false,
      error: undefined
    }
  }
  if (action.type === CREATE_ENTRY_ERROR && action.entryType === "EquipmentEntries") {
    return {
      ...state,
      isFetching: false,
      error: `${action.error}`
    }
  }

  return reducers.entity(reducers)(state, action);
};
