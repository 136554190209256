import React from "react";
import { isEqualMasterData } from "../../utils";

import { ContractType } from '../../api/resources';

import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
/* import InputLabel from '@material-ui/core/InputLabel'; */
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import './index.css';
import { expandPopupOrganisation, filterOrganisations, isEditable, getOrganisation } from "../Common/common";

class CustomerTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      organisations: props.organisations ? props.organisations : '',
      editable: props.editable ? props.editable : '',
      selectedFilters: [],
      contractType: null,
      newData: null,
      error: null,
    };
  }

  componentDidMount() {
    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }

    const { organisations, selectedFilters } = this.state;
    const { t } = this.props;

    const lookupOrgs = {};
    const filteredLookupOrgs = {};
    // prepare contract type values for select component
    const lookupContractTypes = {};

    const columns = [
      {
        title: `${t('organisation')} *`, field: "Organisation.Name", lookup: lookupOrgs, defaultFilter: this.state.selectedFilters,
        initialEditValue: selectedFilters.length > 0 ? selectedFilters : null,
        render: rowData => (
          rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
        ),
        validate: ({ Organisation }) => Organisation !== undefined && Organisation !== null,
        editComponent: props => (
          <FormControl>
            <Select
              labelId="org-label"
              id="organisation"
              className="organisationSelect"
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
              autoFocus
              error={props.value === null || props.value === undefined}
              onClick={async () => {
                await new Promise(resolve => setTimeout(() => resolve(), 100));
                const { organisations } = this.state;
                var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
                var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
                expandPopupOrganisation(filteredOrgs, "MuiListItem-button");
              }}
            >
              {Object.keys(props.columnDef.lookup).map(key => (
                (selectedFilters.length === 0 || selectedFilters.includes(key)) && filteredLookupOrgs[key] !== undefined ?
                  <MenuItem
                    value={key}
                  >
                    <em>{props.columnDef.lookup[key]}</em>
                  </MenuItem>
                  : null
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        title: `${t('name')} *`, field: "Name",
        validate: ({ Name }) => Name && Name.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('contract-type')} *`,
        field: 'ContractTypeCode',
        filtering: true,
        lookup: lookupContractTypes,
        validate: ({ ContractTypeCode }) => ContractTypeCode !== null && ContractTypeCode !== undefined,
        customFilterAndSearch: (term, rowData) =>
          term.length === 0 ||
          (rowData.ContractTypeCode == null && term.includes('')) ||
          (rowData.ContractTypeCode != null && term.includes(`${rowData.ContractTypeCode}`)),
        editComponent: props => (
          <FormControl>
            <Select
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
              error={props.value === null || props.value === undefined}
            >
              {Object.keys(props.columnDef.lookup).map(key => (
                <MenuItem value={key}>
                  <em>{props.columnDef.lookup[key]}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        title: t('is-deleted'), field: "IsDeleted", type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsDeleted} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      }
    ];

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
      var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
      var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
      filteredOrgs.forEach(org => {
        filteredLookupOrgs[org.Name] = org.Name;
      })
    }

    for (var key in ContractType) {
      var v = t(`contract-type-${key}`.toLowerCase());
      if (v === '')
        v = '\xA0';
      lookupContractTypes[ContractType[key]] = v;
    }

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ initPage, pingedRowIndex: indexOfNewRow, isMounted: true, columns: columns })
    }
    else {
      this.setState({ isMounted: true, columns: columns })
    }
  }

  render() {
    const { organisations, initPage, isMounted, pingedRowIndex, editable } = this.state;
    const { t } = this.props;

    return (
      isMounted ? (
        <MaterialTable
          title={this.state.title}
          columns={this.state.columns}
          data={this.state.data}
          style={{
            paddingLeft: !editable && '2%',
            paddingRight: !editable && '2%',
            fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
            fontSize: `0.875rem`
          }}
          options={{
            pageSize: this.state.data.length > 5 ? 10 : 5,
            pageSizeOptions: this.state.data.length > 5 ? [5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [5],
            paginationType: "normal",
            initialPage: initPage,
            addRowPosition: "first",
            draggable: false,
            filtering: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "left",
            rowStyle: rowData => ({
              backgroundColor: (pingedRowIndex === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
          }}
          onChangePage={() => this.setState({ pingedRowIndex: undefined })}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            body: {
              emptyDataSourceMessage: t('no-records-to-display'),
              addTooltip: t('add'),
              deleteTooltip: t('delete'),
              editTooltip: t('edit'),
              editRow: {
                saveTooltip: t('save'),
                cancelTooltip: t('cancel'),
                deleteText: t('deleteText')
              }
            },
            pagination: {
              firstTooltip: t('first-page'),
              previousTooltip: t('previous-page'),
              nextTooltip: t('next-page'),
              lastTooltip: t('last-page')
            }
          }}
          editable={
            editable && {
              isEditable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
              isDeletable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
              onRowAdd: newData =>
                new Promise(resolve => {
                  this.setState({
                    newData: newData
                  });
                  setTimeout(async () => {
                    resolve();
                    const { organisations } = this.state;
                    if (organisations) {
                      const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                      const errorOrResult = await this.props.onRowAdd({
                        Name: newData.Name,
                        Organisation: {
                          ID: org ? org.ID : null,
                          Name: org ? org.Name : null,
                          ShortName: org ? org.ShortName : null
                        },
                        ContractTypeCode: parseInt(newData.ContractTypeCode),
                        IsDeleted: newData.IsDeleted
                      });
                      if (!(errorOrResult instanceof Error)) {
                        const { setNewRow, toggleTable } = this.props
                        const { ID } = errorOrResult
                        if (org) {
                          newData.Organisation = org;
                        }
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          newData.ID = ID;
                          data.push(newData);
                          return { ...prevState, data };
                        });
                        setNewRow(ID)
                        toggleTable()
                      }
                    }
                  }, 100);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    resolve();
                    if (oldData && newData && !isEqualMasterData("Customer", oldData, newData)) {
                      const { organisations } = this.state;
                      if (organisations) {
                        const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                        const errorOrResult = await this.props.onRowUpdate({
                          ID: newData.ID,
                          Name: newData.Name,
                          IsDeleted: newData.IsDeleted,
                          Organisation: {
                            ID: org ? org.ID : null,
                            Name: org ? org.Name : null,
                            ShortName: org ? org.ShortName : null
                          },
                          ContractTypeCode: parseInt(newData.ContractTypeCode),
                        }, oldData.tableData);
                        if (!(errorOrResult instanceof Error)) {
                          const { setNewRow, toggleTable } = this.props
                          if (org) {
                            newData.Organisation = org;
                          }
                          this.setState(prevState => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                        }
                      }
                    }
                  }, 100);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    resolve();
                    const errorOrResult = await this.props.onRowDelete(oldData);
                    if (!(errorOrResult instanceof Error)) {
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data, pingedRowIndex: undefined };
                      });
                    }
                  }, 100);
                })
            }
          }
        />
      ) : <></>
    );
  }
}

export default CustomerTable;