import React from "react";
import moment from "moment";
import { isEqualMasterData } from "../../utils";

import MaterialTable from 'material-table';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';


import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
/* import InputLabel from '@material-ui/core/InputLabel'; */
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import './index.css';
import { expandPopupOrganisation, filterOrganisations, isEditable, getOrganisation, filterEntities } from "../Common/common";

// Require locales
import { enGB, enUS, de } from 'date-fns/locale'

class SpecialDayTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      organisations: props.organisations ? props.organisations : '',
      crews: props.crews ? props.crews : '',
      editable: props.editable ? props.editable : '',
      selectedFilters: [],
      newData: null,
      error: null,
    };
  }

  selectLocale(language) {

    let languages = new Map();
    languages.set("de-DE", de);
    languages.set("en-GB", enGB);
    languages.set("en-US", enUS);

    let res = languages.get(language);
    return res != null ? res : languages["de-DE"];
  }

  validateDate(rowData, date, type) {
    const { showNotification } = this.props
    const isInvalidDate = `${date}`.toLowerCase() === "invalid date"
    let fromDate
    let toDate

    if (!isInvalidDate) {
      if (type === "fromDate") {
        const { DateTo } = rowData
        if (DateTo) {
          fromDate = moment(date)
          toDate = moment(DateTo)

          if (toDate.diff(fromDate, "days") < 0) {
            showNotification("DateFrom cannot be greater than DateTo", "error")
          } else {
            if (toDate.diff(fromDate, 'days', true) > 183) {
              showNotification("The duration cannot be greater than 6 months", "error")
            }
          }
        }
      }

      if (type === "toDate") {
        const { DateFrom } = rowData
        if (DateFrom) {
          fromDate = moment(DateFrom)
          toDate = moment(date)

          if (toDate.diff(fromDate, "days") < 0) {
            showNotification("DateTo cannot be less than DateFrom", "error")
          } else {
            if (toDate.diff(fromDate, 'days', true) > 183) {
              showNotification("The duration cannot be greater than 6 months", "error")
            }
          }
        }
      }
    }

    return true
  }

  componentDidMount() {
    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }

    const { organisations, crews, selectedFilters } = this.state;
    const { t, lng, user } = this.props;
    const lookupOrgs = {};
    const lookupCrews = {};
    const lookupDayPartitions = {};
    const filteredLookupOrgs = {};
    const filteredLookupCrews = {};

    var dayPartitions = user.baseOrganisation != null && user.baseOrganisation.DayPartitions && user.baseOrganisation.DayPartitions.length > 0 ?
      user.baseOrganisation.DayPartitions : [];

    const locale = this.selectLocale(lng);
    const formatDate = dateTime => moment(dateTime).format('DD.MM.YYYY');
    var columns = [
      {
        title: `${t('organisation')} *`, field: "Organisation.Name", lookup: lookupOrgs, defaultFilter: this.state.selectedFilters,
        initialEditValue: selectedFilters.length > 0 ? selectedFilters : null,
        render: rowData => (
          rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
        ),
        validate: ({ Organisation }) => Organisation !== undefined && Organisation !== null,
        editComponent: props => (
          <FormControl>
            <Select
              labelId="org-label"
              id="organisation"
              className="organisationSelect"
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
              autoFocus
              error={props.value === null || props.value === undefined}
              onClick={async () => {
                await new Promise(resolve => setTimeout(() => resolve(), 100));
                const { organisations } = this.state;
                var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
                var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
                expandPopupOrganisation(filteredOrgs, "MuiListItem-button");
              }}
            >
              {Object.keys(props.columnDef.lookup).map((key, i) => (
                (selectedFilters.length === 0 || selectedFilters.includes(key)) && filteredLookupOrgs[key] !== undefined ?
                  <MenuItem
                    value={key}
                    key={i}
                  >
                    <em>{props.columnDef.lookup[key]}</em>
                  </MenuItem>
                  : null
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        title: `${t('date-from')} *`, field: "DateFrom", type: "date", filtering: false,
        render: rowData => (<TextField value={formatDate(rowData.DateFrom)} />),
        editComponent: props => (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={locale}>
            <KeyboardDatePicker
              margin="normal"
              format="dd.MM.yyyy"
              value={props.value || null}
              onChange={e => { this.validateDate(props.rowData, moment(e).format("YYYY-MM-DD"), "fromDate") && props.onChange(e) }}
              locale={locale}
              error={
                props.value === null
                || props.value === undefined
                || `${props.value}`.toLowerCase() === "invalid date"
                || (
                  props.rowData.DateTo
                  && moment(props.rowData.DateTo).diff(moment(props.rowData.DateFrom), 'days', true) > 183
                )
                || (
                  props.rowData.DateTo
                  && moment(props.rowData.DateTo).diff(moment(props.rowData.DateFrom), "days") < 0
                )
              }
              okLabel={t('date-picker-label-ok')}
              cancelLabel={t('date-picker-label-cancel')}
              invalidDateMessage={t('date-picker-label-invalid-date-format')}
            />
          </MuiPickersUtilsProvider>
        )
      },
      {
        title: `${t('date-to')} *`, field: "DateTo", type: "date", filtering: false,
        render: rowData => (<TextField value={formatDate(rowData.DateTo)} />),
        validate: ({ DateTo }) => DateTo !== undefined && DateTo !== null,
        editComponent: props => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={locale}>
            <KeyboardDatePicker
              format="dd.MM.yyyy"
              value={props.value || null}
              onChange={e => { this.validateDate(props.rowData, moment(e).format("YYYY-MM-DD"), "toDate") && props.onChange(e) }}
              okLabel={t('date-picker-label-ok')}
              error={
                props.value === null
                || props.value === undefined
                || `${props.value}`.toLowerCase() === "invalid date"
                || (
                  props.rowData.DateFrom
                  && moment(props.rowData.DateTo).diff(moment(props.rowData.DateFrom), 'days', true) > 183
                )
                || (
                  props.rowData.DateFrom
                  && moment(props.rowData.DateTo).diff(moment(props.rowData.DateFrom), "days") < 0
                )
              }
              cancelLabel={t('date-picker-label-cancel')}
              invalidDateMessage={t('date-picker-label-invalid-date-format')}
            />
          </MuiPickersUtilsProvider>
        )
      },
      {
        title: `${t('name')} *`, field: "Name",
        validate: ({ Name }) => Name && Name.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t("is-public-holiday"), field: "IsPublicHoliday", type: 'boolean',
        render: rowData => (<Checkbox checked={rowData.IsPublicHoliday} disabled={true} />),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('construction-crew'), field: 'Crew.Name', filtering: true, lookup: lookupCrews,
        customFilterAndSearch: (term, rowData) =>
          //search
          (
            (typeof term) === "string" &&
            (
              term === '' ||
              (rowData.Crew != null && rowData.Crew.Name.includes(term))
            )
          ) ||
          //filter
          (
            (typeof term) !== "string" &&
            (
              term.length === 0 ||
              (rowData.Crew == null && term.includes('null')) ||
              (rowData.Crew != null && term.includes(rowData.Crew.Name))
            )
          ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Object.keys(props.columnDef.lookup).map(key => (
              filteredLookupCrews[key] !== undefined ?
                <MenuItem
                  value={key}
                >
                  <em>{props.columnDef.lookup[key]}</em>
                </MenuItem>
                : null
            ))}
          </Select>
        )
      },
      {
        title: t('day-partition'), field: 'PartitionIndex', lookup: lookupDayPartitions, filtering: false,
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Object.keys(props.columnDef.lookup).map(key => (
              lookupDayPartitions[key] !== undefined ?
                <MenuItem
                  value={key}
                >
                  <em>{props.columnDef.lookup[key]}</em>
                </MenuItem>
                : null
            ))}
          </Select>
        )
      }
    ];

    if (dayPartitions.length > 0) {
      lookupDayPartitions[-1] = '\xA0';
      dayPartitions.forEach(dp => {
        lookupDayPartitions[dayPartitions.indexOf(dp)] = dp.Name;
      })
    }

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
      var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
      var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
      filteredOrgs.forEach(org => {
        filteredLookupOrgs[org.Name] = org.Name;
      })
    }

    lookupCrews[null] = '\xA0';
    filteredLookupCrews[null] = '\xA0';
    if (crews.length > 0) {
      crews.forEach(crew => {
        lookupCrews[crew.Name] = crew.Name;
      })
      var filteredCrews = filterEntities(crews, this.props.user.organisation, this.props.user.IsParentLogin);
      filteredCrews.forEach(crew => {
        filteredLookupCrews[crew.Name] = crew.Name;
      })
    }

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ initPage, pingedRowIndex: indexOfNewRow, isMounted: true, columns: columns })
    }
    else {
      this.setState({ isMounted: true, columns: columns })
    }
  }

  render() {
    const { organisations, crews, initPage, isMounted, pingedRowIndex, editable } = this.state;
    const { t } = this.props;

    return (
      isMounted ? (
        <MaterialTable
          title={this.state.title}
          columns={this.state.columns}
          data={this.state.data}
          style={{
            paddingLeft: !editable && '2%',
            paddingRight: !editable && '2%',
            fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
            fontSize: `0.875rem`
          }}
          onChangePage={() => this.setState({ pingedRowIndex: undefined })}
          options={{
            pageSize: this.state.data.length > 5 ? 10 : 5,
            pageSizeOptions: this.state.data.length > 5 ? [5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [5],
            paginationType: "normal",
            initialPage: initPage,
            addRowPosition: "first",
            draggable: false,
            filtering: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "left",
            rowStyle: rowData => ({
              backgroundColor: (pingedRowIndex === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
          }}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            body: {
              dateTimePickerLocalization: de,
              emptyDataSourceMessage: t('no-records-to-display'),
              addTooltip: t('add'),
              deleteTooltip: t('delete'),
              editTooltip: t('edit'),
              editRow: {
                saveTooltip: t('save'),
                cancelTooltip: t('cancel'),
                deleteText: t('deleteText')
              }
            },
            pagination: {
              firstTooltip: t('first-page'),
              previousTooltip: t('previous-page'),
              nextTooltip: t('next-page'),
              lastTooltip: t('last-page')
            },
          }}
          editable={editable && {
            isEditable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
            isDeletable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
            onRowAdd: newData =>
              new Promise(resolve => {
                setTimeout(async () => {
                  const { organisations } = this.state;
                  if (organisations) {
                    const crew = crews ? crews.find(c => newData.Crew != null && c.Name === newData.Crew.Name) : null;
                    const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                    const partitionIndex = parseInt(newData.PartitionIndex);
                    const errorOrResult = await this.props.onRowAdd({
                      DateFrom: typeof newData.DateFrom !== "string" ? moment(newData.DateFrom).format("YYYY-MM-DD") : newData.DateFrom,
                      DateTo: typeof newData.DateTo !== "string" ? moment(newData.DateTo).format("YYYY-MM-DD") : newData.DateTo,
                      Name: newData.Name,
                      IsPublicHoliday: newData.IsPublicHoliday === undefined ? false : newData.IsPublicHoliday,
                      Organisation: {
                        ID: org ? org.ID : null,
                        Name: org ? org.Name : null,
                        ShortName: org ? org.ShortName : null
                      },
                      Crew: crew ? crew : null,
                      PartitionIndex: partitionIndex !== NaN && partitionIndex >= 0 ? partitionIndex : null
                    });
                    if (!(errorOrResult instanceof Error)) {
                      const { setNewRow, toggleTable } = this.props
                      const { ID } = errorOrResult
                      var res = { ...errorOrResult, Crew: crew ? crew : null, PartitionIndex: partitionIndex !== NaN && partitionIndex >= 0 ? partitionIndex : null };
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        data.push(res);
                        return { ...prevState, data };
                      });
                      setNewRow(newData.ID)
                    }
                  }
                  resolve();
                }, 100);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  if (oldData && newData && !isEqualMasterData("SpecialDay", oldData, newData)) {
                    const { organisations } = this.state;
                    if (organisations && newData.Organisation) {
                      const crew = crews ? crews.find(c => newData.Crew != null && c.Name === newData.Crew.Name) : null;
                      const org = organisations.find(org => org.Name === newData.Organisation.Name);
                      const partitionIndex = parseInt(newData.PartitionIndex);
                      if (org) {
                        const errorOrResult = await this.props.onRowUpdate({
                          ID: newData.ID,
                          Name: newData.Name,
                          DateFrom: typeof newData.DateFrom !== "string" ? new Date(moment(newData.DateFrom).format("YYYY-MM-DD")) : newData.DateFrom,
                          DateTo: typeof newData.DateTo !== "string" ? new Date(moment(newData.DateTo).format("YYYY-MM-DD")) : newData.DateTo,
                          IsPublicHoliday: newData.IsPublicHoliday === undefined ? false : newData.IsPublicHoliday,
                          Organisation: {
                            ID: org.ID,
                            Name: org ? org.Name : null,
                            ShortName: org ? org.ShortName : null
                          },
                          Crew: crew ? crew : null,
                          PartitionIndex: partitionIndex !== NaN && partitionIndex >= 0 ? partitionIndex : null
                        }, oldData.tableData);
                        if (!(errorOrResult instanceof Error)) {
                          const { setNewRow, toggleTable } = this.props
                          if (org) {
                            newData.Organisation = org;
                          }
                          if (crew) {
                            newData.Crew = crew;
                          }
                          else {
                            newData.Crew = null;
                          }
                          if (partitionIndex !== NaN && partitionIndex >= 0) {
                            newData.PartitionIndex = partitionIndex;
                          }
                          else {
                            newData.PartitionIndex = null;
                          }
                          this.setState(prevState => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                        }
                      }
                    }
                  }
                }, 100);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  const errorOrResult = await this.props.onRowDelete(oldData);
                  if (!(errorOrResult instanceof Error)) {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data, pingedRowIndex: undefined };
                    });
                  }
                }, 100);
              })
          }}
        />
      ) : <></>
    );
  }
}

export default SpecialDayTable;