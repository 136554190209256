import odata from 'odata-client';
import {
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_SUCCESS
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const resetPasswordRequest = () => ({
    type: RESET_PASSWORD_REQUEST,
});

export const resetPasswordError = errorOrResults => ({
    type: RESET_PASSWORD_ERROR,
    error: errorOrResults.message,
});

export const resetPasswordSuccess = payload => ({
    type: RESET_PASSWORD_SUCCESS,
    payload,
});

export default (queryData) => async (dispatch, getState) => {
    dispatch(resetPasswordRequest());

    const query = odata({ service: getServiceUrl() }).resource(RESOURCES.LoggedInUsers).resource(RESOURCES.ResetUserPassword);
    const errorOrResult = await query.post(queryData, {
        headers: {
            ...getDefaultHeader()
        }
    })
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return { error: new Error(errorKey) };
            } else if (!ok) {
                console.log('reset password api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return { error: new Error(errorKey) };
            }
            if (ok) {
                const body = JSON.parse(res.body);
                const { "@odata.context": omit, ...payload } = body;

                payload.isResetPassword = true;
                return payload
            }
        })
        .catch((res) => {
            const errorKey = `Error: ${res.status}, ${res.statusText}`;
            console.log('import mixtutres api error', res.responseText);
            return { error: new Error(errorKey) };
        })

    if (errorOrResult && errorOrResult.error) {
        dispatch(resetPasswordError(errorOrResult));
        console.log("CODE ERROR: ", errorOrResult.error.message);
        dispatch(showNotification(errorOrResult.error.message, "error"))
        return errorOrResult;
    } else {
        dispatch(showNotification("SUCCESSFUL_RESET_PASSWORD", "success"))
    }

    dispatch(resetPasswordSuccess(errorOrResult));

    return errorOrResult;
};