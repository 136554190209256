import React from "react";

import { isEqualMasterData } from "../../utils";

import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
/* import InputLabel from '@material-ui/core/InputLabel'; */
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { InputAdornment } from "@material-ui/core";
import NumberInput from '../../core/components/Inputs/NumberInput/numberInput';

import './index.css';
import { expandPopupOrganisation, filterOrganisations, isEditable, getOrganisation } from "../Common/common";


class OrganisationDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      organisations: props.organisations ? props.organisations : '',
      organisationData: props.organisationData ? props.organisationData : '',
      editable: props.editable ? props.editable : '',
      selectedFilters: [],
      newData: null,
      error: null,
    };

  }

  componentDidMount() {
    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ initPage, pingedRowIndex: indexOfNewRow })
    }

    this.setState({ isMounted: true })
  }

  acceptPositiveDecimal(props, e) {
    const re = /^[0-9.\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      props.onChange(e.target.value);
    }
  }

  render() {
    const {
      organisations,
      selectedFilters,
      initPage,
      isMounted,
      pingedRowIndex,
      editable
    } = this.state;
    const { t } = this.props;
    const lookupOrgs = {};
    const filteredLookupOrgs = {};

    if (organisations.length > 0) {
      organisations.filter(org => org.IsBaseOrganisation).forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
      var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
      var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin).filter(org => org.IsBaseOrganisation);
      filteredOrgs.forEach(org => {
        filteredLookupOrgs[org.Name] = org.Name;
      })
    }

    const columns = [
      {
        title: `${t('organisation')} *`, field: "Organisation.Name", lookup: lookupOrgs, defaultFilter: this.state.selectedFilters,
        initialEditValue: selectedFilters.length > 0 ? selectedFilters : null,
        validate: ({ Organisation }) => Organisation !== undefined && Organisation !== null,
        render: rowData => (
          rowData.Organisation ? (rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>) : null
        ),
        editComponent: props => (
          <FormControl>
            <Select
              labelId="org-label"
              id="organisation"
              className="organisationSelect"
              value={props.value}
              disabled={true}
            >
              {Object.keys(props.columnDef.lookup).map(key => (
                (selectedFilters.length === 0 || selectedFilters.includes(key)) && filteredLookupOrgs[key] !== undefined ?
                  <MenuItem
                    value={key}
                  >
                    <em>{props.columnDef.lookup[key]}</em>
                  </MenuItem>
                  : null
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        title: `${t('employee-cost-per-hour')}`, field: "EmployeeCostPerHour",
        cellStyle: {
          textAlign: "right"
        },
        headerStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="employeeCostPerHour"
              type="number"
              value={props.value}
              handleChange={e => this.acceptPositiveDecimal(props, e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('vehicle-cost-per-hour')}`, field: "VehicleCostPerHour",
        cellStyle: {
          textAlign: "right"
        },
        headerStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="vehicleCostPerHour"
              type="number"
              value={props.value}
              handleChange={e => this.acceptPositiveDecimal(props, e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('vehicle-cost-per-hour-0to13-tonnes')}`, field: "VehicleCostPerHour_0to13Tonnes",
        cellStyle: {
          textAlign: "right"
        },
        headerStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="vehicleCostPerHour_0to13Tonnes"
              type="number"
              value={props.value}
              handleChange={e => this.acceptPositiveDecimal(props, e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('vehicle-cost-per-hour-13to15-tonnes')}`, field: "VehicleCostPerHour_13to15Tonnes",
        cellStyle: {
          textAlign: "right"
        },
        headerStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="vehicleCostPerHour_13to15Tonnes"
              type="number"
              value={props.value}
              handleChange={e => this.acceptPositiveDecimal(props, e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('vehicle-cost-per-hour-15to18-tonnes')}`, field: "VehicleCostPerHour_15to18Tonnes",
        cellStyle: {
          textAlign: "right"
        },
        headerStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="vehicleCostPerHour_15to18Tonnes"
              type="number"
              value={props.value}
              handleChange={e => this.acceptPositiveDecimal(props, e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('vehicle-cost-per-hour-18to28-tonnes')}`, field: "VehicleCostPerHour_18to28Tonnes",
        cellStyle: {
          textAlign: "right"
        },
        headerStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="vehicleCostPerHour_18to28Tonnes"
              type="number"
              value={props.value}
              handleChange={e => this.acceptPositiveDecimal(props, e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: `${t('agk-percentage')}`, field: "AgkPercentage",
        cellStyle: {
          textAlign: "right"
        },
        headerStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="agkPercentage"
              type="number"
              value={props.value}
              handleChange={e => this.acceptPositiveDecimal(props, e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">%</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
    ];

    if (organisations.length > 0) {
      organisations.filter(org => org.IsBaseOrganisation).forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
      var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
      var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin).filter(org => org.IsBaseOrganisation);
      filteredOrgs.forEach(org => {
        filteredLookupOrgs[org.Name] = org.Name;
      })
    }

    var data = this.state.data.filter(d => d.Organisation.IsBaseOrganisation);

    return (
      isMounted ? (
        <MaterialTable
          title={this.state.title}
          columns={columns}
          data={data}
          style={{
            paddingLeft: !editable && '2%',
            paddingRight: !editable && '2%',
            fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
            fontSize: `0.875rem`
          }}
          options={{
            pageSize: data.length > 1 ? 10 : 1,
            pageSizeOptions: data.length > 1 ? [5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [1],
            paginationType: "normal",
            initialPage: initPage,
            addRowPosition: "first",
            draggable: false,
            filtering: true,
            sorting: false,
            paging: false,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "left",
            rowStyle: rowData => ({
              backgroundColor: (pingedRowIndex === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
          }}
          onChangePage={() => this.setState({ pingedRowIndex: undefined })}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            body: {
              emptyDataSourceMessage: t('no-records-to-display'),
              editTooltip: t('edit'),
              editRow: {
                saveTooltip: t('save'),
                cancelTooltip: t('cancel'),
              }
            },
            pagination: {
              firstTooltip: t('first-page'),
              previousTooltip: t('previous-page'),
              nextTooltip: t('next-page'),
              lastTooltip: t('last-page')
            },
          }}
          editable={editable && {
            isEditable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  if (oldData && newData && !isEqualMasterData("OrganisationData", oldData, newData)) {
                    const { organisations } = this.state;
                    if (organisations) {
                      const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                      const errorOrResult = await this.props.onRowUpdate({
                        ID: newData.ID,
                        EmployeeCostPerHour: newData.EmployeeCostPerHour,
                        VehicleCostPerHour: newData.VehicleCostPerHour,
                        VehicleCostPerHour_0to13Tonnes: newData.VehicleCostPerHour_0to13Tonnes,
                        VehicleCostPerHour_13to15Tonnes: newData.VehicleCostPerHour_13to15Tonnes,
                        VehicleCostPerHour_15to18Tonnes: newData.VehicleCostPerHour_15to18Tonnes,
                        VehicleCostPerHour_18to28Tonnes: newData.VehicleCostPerHour_18to28Tonnes,
                        AgkPercentage: newData.AgkPercentage,
                        Organisation: {
                          ID: org ? org.ID : null,
                          Name: org ? org.Name : null,
                          ShortName: org ? org.ShortName : null,
                          IsBaseOrganisation: org ? org.IsBaseOrganisation : null
                        },
                      }, oldData.tableData);
                      if (!(errorOrResult instanceof Error)) {
                        const { setNewRow, toggleTable } = this.props
                        if (org) {
                          newData.Organisation = org;
                        }
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                        setNewRow(newData.ID);
                        toggleTable();
                      }
                    }
                  }
                }, 100);
              }),
          }}
        />
      ) : <></>
    );
  }
}

export default OrganisationDataTable;