import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { resetPassword } from '../../actions/user';
import notificationActions from "../../actions/notification";

import ResetPassword from "./ResetPassword";

const mapStateToProps = ({ user }) => ({
  isResetPassword: user.isResetPassword,
});

const mapDispatchToProps = {
  ...notificationActions,
  resetPassword
};

const connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
export default withNamespaces(["common"])(connected);
