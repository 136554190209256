import odata from 'odata-client';
import { USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_ERROR } from "../types.js";
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';
import { getAppSettings } from '../settings';

const LoginStatus_LoggedIn = 2**2;
const LoginStatus_SetNewPassword = 2**3;
const LoginStatus_PasswordExpired = 2**4;

export const acRequest = type => ({
  type
});

export const acError = (error, type) => ({
  type,
  error
});

export const acSuccess = (payload, type) => ({
  type,
  payload
});

export default ({ organisation, userNumber, password, environment, environmentDisplayName }) => async (dispatch, getState) => {
  dispatch(acRequest(USER_LOGIN_REQUEST));
  const query = odata({ service: getServiceUrl() }).resource(RESOURCES.LoggedInUsers);

  const errorOrResult = await query.get({
    headers: {
      Authorization: `Basic ${Buffer.from(`${environment}|${organisation}||${userNumber}:${password}`).toString('base64')}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;

      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        console.log('login api error', res.toJSON());
        const body = JSON.parse(res.body);
        const { Error: { Code, Message } } = body
        const errorKey = Code === "LoginOrganisationNotFound" || Code === "LoginBaseOrganisationNotFound" ? Code : Message
        return new Error(errorKey)
      } else {
        const body = JSON.parse(res.body);
        const payload = body.value[0];
        const isLoggedIn = payload.LoginStatusCode & LoginStatus_LoggedIn
        const isPasswordExpired = payload.LoginStatusCode & LoginStatus_PasswordExpired
        const isSetNewPassword = payload.LoginStatusCode & LoginStatus_SetNewPassword

        payload.isLoggedIn = isLoggedIn
        payload.isPasswordExpired = isPasswordExpired
        payload.isSetNewPassword = isSetNewPassword
        payload.environmentDisplayName = environmentDisplayName
        payload.companyLogoUrl = getState().user.companyLogoUrl

        sessionStorage.setItem("user", JSON.stringify(payload))

        return payload;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    })

  if (errorOrResult instanceof Error) {
    dispatch(acError(errorOrResult, USER_LOGIN_ERROR));
    dispatch(showNotification(errorOrResult.message, "error"))
    return errorOrResult;
  }
  
  await getAppSettings()
  dispatch(
    acSuccess(
      { ...errorOrResult, DatabaseInstanceDisplayName: environmentDisplayName },
      USER_LOGIN_SUCCESS
    )
  );

  return errorOrResult;
};
