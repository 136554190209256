import React from "react";

import { isValidEmail, isEqualMasterData } from "../../utils";
import { COMMUNICATION_CHANNELS } from "../../config/constants";

import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
/* import InputLabel from '@material-ui/core/InputLabel'; */
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from "@material-ui/core/ListItemText";
import { InputAdornment } from "@material-ui/core";
import NumberInput from '../../core/components/Inputs/NumberInput/numberInput';
import Input from '@material-ui/core/Input';
import './index.css';
import { expandPopupOrganisation, filterOrganisations, isEditable, filterEntities, getOrganisation } from "../Common/common";

class DriverTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      organisations: props.organisations ? props.organisations : '',
      crews: props.crews ? props.crews : '',
      company: props.company ? props.company : '',
      editable: props.editable ? props.editable : '',
      selectedFilters: [],
      newData: null,
      error: null,
    };
  }
  componentDidMount() {
    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }

    const {
      organisations,
      selectedFilters,
    } = this.state;
    const { t } = this.props;

    const lookupOrgs = {};
    var lookupCrews = new Map();
    var lookupCompany = new Map();
    const filteredLookupOrgs = {};
    var filteredLookupCrews = new Map();
    var filteredLookupCompany = new Map();
    const columns = [
      {
        title: `${t('organisation')} *`, field: "Organisation.Name", lookup: lookupOrgs, defaultFilter: this.state.selectedFilters,
        initialEditValue: selectedFilters.length > 0 ? selectedFilters : null,
        validate: ({ Organisation }) => Organisation !== undefined && Organisation !== null,
        render: rowData => (
          rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
        ),
        editComponent: props => (
          <FormControl>
            <Select
              labelId="org-label"
              id="organisation"
              className="organisationSelect"
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
              autoFocus
              error={props.value === null || props.value === undefined}
              onClick={async () => {
                await new Promise(resolve => setTimeout(() => resolve(), 100));
                const { organisations } = this.state;
                var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
                var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
                expandPopupOrganisation(filteredOrgs, "MuiListItem-button");
              }}
            >
              {Object.keys(props.columnDef.lookup).map(key => (
                (selectedFilters.length === 0 || selectedFilters.includes(key)) && filteredLookupOrgs[key] !== undefined ?
                  <MenuItem
                    value={key}
                  >
                    <em>{props.columnDef.lookup[key]}</em>
                  </MenuItem>
                  : null
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        title: t('construction-crew'), field: 'DefaultCrew.Name', filtering: true, lookup: lookupCrews,
        customFilterAndSearch: (term, rowData) =>
          //search
          (
            (typeof term) === "string" &&
            (
              term === '' ||
              (rowData.DefaultCrew != null && rowData.DefaultCrew.Name.includes(term))
            )
          ) ||
          //filter
          (
            (typeof term) !== "string" &&
            (
              term.length === 0 ||
              (rowData.DefaultCrew == null && term.includes(null)) ||
              (rowData.DefaultCrew != null && term.includes(rowData.DefaultCrew.Name))
            )
          ),
        render: rowData => (
          rowData.DefaultCrew != null ? <>{rowData.DefaultCrew.Name}</> : <>{' '}</>
        ),
        filterComponent: props => (
          <Select
            multiple
            value={props.columnDef.tableData.filterValue || []}
            renderValue={(selecteds) => Object.keys(selecteds).map((key) => selecteds[key]).join(", ")}
            onChange={(event) => {
              props.columnDef.tableData.filterValue = event.target.value;
              if (props.columnDef.filterOnItemSelect === true)
                props.onFilterChanged(
                  props.columnDef.tableData.id,
                  event.target.value
                );
              else
                props.onFilterChanged(
                  props.columnDef.tableData.id,
                  event.target.value
                );
            }}
            input={
              <Input id={"select-multiple-checkbox" + props.columnDef.tableData.id} />
            }
          >
            {Array.from(props.columnDef.lookup, ([key, value]) => (
              filteredLookupCrews.get(key) !== undefined ?
                <MenuItem key={key} value={key}>
                  <Checkbox checked={props.columnDef.tableData.filterValue !== undefined ? props.columnDef.tableData.filterValue.indexOf(key) > -1 : false} />
                  <ListItemText primary={value} />
                </MenuItem> : null
            ))}
          </Select>
        ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Array.from(props.columnDef.lookup, ([key, value]) => (
              filteredLookupCrews.get(key) !== undefined ?
                <MenuItem
                  value={key}
                >
                  <em>{value}</em>
                </MenuItem>
                : null
            ))}
          </Select>
        )
      },
      {
        title: t('default-start-time'), field: 'DefaultStartTime', filtering: false,
        render: rowData => {
          const DefaultStartTime = rowData.DefaultStartTime
          let defaultStartTime = ""
          if (DefaultStartTime) {
            defaultStartTime = DefaultStartTime.replace("PT", "").replace("H", ":").replace("M", "")
            if (defaultStartTime.split(":")[0].length === 1) {
              defaultStartTime = `0${defaultStartTime}`
            }
            if (defaultStartTime.split(":")[1].length <= 0) {
              defaultStartTime = `${defaultStartTime}00`
            }
            if (defaultStartTime.split(":")[1].length === 1) {
              defaultStartTime = `${defaultStartTime.split(":")[0]}:0${defaultStartTime.split(":")[1]}`
            }
          }
          return defaultStartTime
        },
        editComponent: props => {
          let { value: startTime } = props
          if (startTime && startTime.includes("PT")) {
            startTime = startTime.replace("PT", "").replace("H", ":").replace("M", "")
            if (startTime.split(":")[0].length === 1) startTime = `0${startTime}`
            if (startTime.split(":")[1].length <= 0) startTime = `${startTime}00`
            if (startTime.split(":")[1].length === 1) startTime = `${startTime.split(":")[0]}:0${startTime.split(":")[1]}`
          }

          return (
            <TextField
              id="time"
              type="time"
              value={startTime}
              defaultValue={startTime}
              onChange={e => e.target.value === "" ? props.onChange() : props.onChange(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: { textAlign: 'right' },
                step: 300, // 5 min
              }}
            />
          )
        }
      },
      {
        title: t('communication-channel-code'), field: 'CommunicationChannelCode', filtering: false,
        render: rowData => (
          t(`communicationChannels.${Object.keys(COMMUNICATION_CHANNELS).find(key => COMMUNICATION_CHANNELS[key] === rowData.CommunicationChannelCode)}`)
        ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Object.entries(COMMUNICATION_CHANNELS).map((channel, i) => {
              const name = t(`communicationChannels.${channel[0]}`)
              const value = channel[1]
              return (
                <MenuItem value={value}>
                  <em>{name}</em>
                </MenuItem>
              )
            })}
          </Select>
        )
      },
      {
        title: t('email-address'), field: 'EmailAddress', filtering: false,
        validate: ({ EmailAddress }) => EmailAddress === null || EmailAddress === undefined || (EmailAddress && isValidEmail(EmailAddress)),
        editComponent: props => (
          <FormControl className="input-short">
            <TextField
              id="email-input"
              value={props.value}
              type="text"
              error={props.value && !isValidEmail(props.value)}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        )
      },
      {
        title: t('phone-number'), field: 'PhoneNumber', filtering: false,
        editComponent: props => (
          <FormControl className="input-short">
            <TextField
              id="phone-input"
              value={props.value}
              type="text"
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        )
      },
      {
        title: `${t('company-name')} *`, field: 'DriverCompany.Name', lookup: lookupCompany,
        render: rowData => (
          rowData.DriverCompany != null ? <>{rowData.DriverCompany.Name}</> : <>{' '}</>
        ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Array.from(props.columnDef.lookup, ([key, value]) => (
              filteredLookupCompany.get(key) !== undefined ?
                <MenuItem
                  value={key}
                >
                  <em>{value}</em>
                </MenuItem>
                : null
            ))}
          </Select>
        ),
        filtering: false
      },
      {
        title: `${t('type')} *`, field: "Name",
        validate: ({ Name }) => Name && Name.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },

      {
        title: t('default-payload'), field: "DefaultPayload", filtering: false,
        editComponent: props => (
          <input className="text-input-short" value={props.value} onChange={e => this.handleOnChange(props, e)} />
        ),
      },
      {
        title: t('license-plate'), field: "VehicleRegistrationNumber", filtering: false,
        editComponent: props => (
          <FormControl className="input-short">
            <TextField
              id="license-plate-input"
              value={props.value}
              type="text"
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ),
      },
      {
        title: `${t('cost-per-hour')}`, field: "CostPerHour",
        cellStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="costPerHour"
              type="number"
              value={props.value}
              handleChange={e => this.acceptPositiveDecimal(props, e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('is-deleted'), field: 'IsDeleted', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsDeleted} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      }
    ];

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
      var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
      var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
      filteredOrgs.forEach(org => {
        filteredLookupOrgs[org.Name] = org.Name;
      })
    }

    const { crews } = this.props;
    lookupCrews.set(null, '\xA0');
    if (crews.length > 0) {
      crews.forEach(crew => {
        lookupCrews.set(crew.Name, crew.Name);
      })
      var filteredCrews = filterEntities(crews, this.props.user.organisation, this.props.user.IsParentLogin);
      filteredLookupCrews.set(null, '\xA0');
      filteredCrews.forEach(crew => {
        filteredLookupCrews.set(crew.Name, crew.Name);
      })
    }

    const { company } = this.props;
    lookupCompany.set(null, '\xA0');
    if (company.length > 0) {
      company.forEach(company => {
        if (company.TypeCode == 2) {
          lookupCompany.set(company.Name, company.Name);
        }
      })
      var filteredCompany = filterEntities(company, this.props.user.organisation, this.props.user.IsParentLogin);
      filteredLookupCompany.set(null, '\xA0');
      filteredCompany.forEach(comp => {
        filteredLookupCompany.set(comp.Name, comp.Name);
      })
    }

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ initPage, pingedRowIndex: indexOfNewRow, isMounted: true, columns: columns })
    }
    else {
      this.setState({ isMounted: true, columns: columns })
    }
  }

  handleOnChange(props, e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      props.onChange(e.target.value);
    }
  }

  acceptPositiveDecimal(props, e) {
    const re = /^[0-9.\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      props.onChange(e.target.value);
    }
  }

  driverCompany = (company) => {
    company.filter((obj) => {
      if (obj.TypeCode == 2) {
        return true;
      }
      else {
        return false;
      }
    })
  }

  render() {
    const {
      organisations,
      initPage,
      isMounted,
      pingedRowIndex,
      editable
    } = this.state;
    const { t } = this.props;

    return (
      isMounted ? (
        <MaterialTable
          title={this.state.title}
          columns={this.state.columns}
          data={this.state.data}
          style={{
            paddingLeft: !editable && '2%',
            paddingRight: !editable && '2%',
            fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
            fontSize: `0.875rem`
          }}
          options={{
            pageSize: this.state.data.length > 5 ? 10 : 5,
            pageSizeOptions: this.state.data.length > 5 ? [5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [5],
            paginationType: "normal",
            initialPage: initPage,
            addRowPosition: "first",
            draggable: false,
            filtering: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "left",
            rowStyle: rowData => ({
              backgroundColor: (pingedRowIndex === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
          }}
          onChangePage={() => this.setState({ pingedRowIndex: undefined })}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            body: {
              emptyDataSourceMessage: t('no-records-to-display'),
              addTooltip: t('add'),
              deleteTooltip: t('delete'),
              editTooltip: t('edit'),
              editRow: {
                saveTooltip: t('save'),
                cancelTooltip: t('cancel'),
                deleteText: t('deleteText')
              }
            },
            pagination: {
              firstTooltip: t('first-page'),
              previousTooltip: t('previous-page'),
              nextTooltip: t('next-page'),
              lastTooltip: t('last-page')
            },
          }}
          editable={editable && {
            isEditable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
            isDeletable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin), onRowAdd: newData =>
              new Promise(resolve => {
                this.setState({
                  newData: newData
                });
                setTimeout(async () => {
                  resolve();
                  const { organisations, crews, company } = this.state;
                  if (organisations) {
                    const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                    const defaultCrew = crews ? crews.find(c => newData.DefaultCrew != null && c.Name === newData.DefaultCrew.Name) : null;
                    const com = company ? company.find(c => newData.DriverCompany != null && c.Name === newData.DriverCompany.Name) : null;
                    let defaultTime
                    if (newData.DefaultStartTime && newData.DefaultStartTime !== "" && newData.DefaultStartTime !== "-") {
                      defaultTime = `PT${newData.DefaultStartTime.split(":")[0]}H${newData.DefaultStartTime.split(":")[1]}M`
                    } else defaultTime = null
                    if (newData.EmailAddress && !isValidEmail(newData.EmailAddress)) {
                      const { t, showNotification } = this.props
                      showNotification(t('email-address-invalid'), "error")
                      return
                    }
                    const errorOrResult = await this.props.onRowAdd({
                      Name: newData.Name,
                      DefaultPayload: newData.DefaultPayload,
                      IsDeleted: newData.IsDeleted,
                      Organisation: {
                        ID: org ? org.ID : null,
                        Name: org ? org.Name : null,
                        ShortName: org ? org.ShortName : null
                      },
                      DefaultCrew: defaultCrew ? { ID: defaultCrew.ID, Name: defaultCrew.Name } : null,
                      DriverCompany: com ? { ID: com.ID, Name: com.Name, TypeCode: com.TypeCode } : null,
                      DefaultStartTime: defaultTime,
                      EmailAddress: newData.EmailAddress,
                      PhoneNumber: newData.PhoneNumber,
                      CommunicationChannelCode: newData.CommunicationChannelCode,
                      VehicleRegistrationNumber: newData.VehicleRegistrationNumber,
                      CostPerHour: newData.CostPerHour !== undefined && newData.CostPerHour != null ? parseFloat(newData.CostPerHour) : newData.CostPerHour
                    });
                    if (errorOrResult && !(errorOrResult instanceof Error)) {
                      const { setNewRow, toggleTable } = this.props
                      const { ID } = errorOrResult
                      if (org) {
                        newData.Organisation = org;
                      }
                      if (defaultTime) {
                        newData.DefaultStartTime = newData.DefaultStartTime
                      }
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        newData.ID = ID;
                        data.push(newData);
                        return { ...prevState, data };
                      });
                      setNewRow(ID)
                      toggleTable()
                    }
                  }
                }, 100);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  if (oldData && newData && !isEqualMasterData("Driver", oldData, newData)) {
                    const { organisations, crews, company } = this.state;
                    if (organisations) {
                      const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                      const defaultCrew = crews ? crews.find(c => newData.DefaultCrew != null && c.Name === newData.DefaultCrew.Name) : null;
                      const com = company ? company.find(c => newData.DriverCompany != null && c.Name === newData.DriverCompany.Name) : null;
                      let defaultTime = newData.DefaultStartTime
                      if (defaultTime && defaultTime !== "") {
                        if (!defaultTime.includes("PT")) {
                          defaultTime = `PT${defaultTime.split(":")[0]}H${defaultTime.split(":")[1]}M`
                        }
                      } else defaultTime = null
                      if (newData.EmailAddress && !isValidEmail(newData.EmailAddress)) {
                        const { t, showNotification } = this.props
                        showNotification(t('email-address-invalid'), "error")
                        return
                      }

                      const errorOrResult = await this.props.onRowUpdate({
                        ID: newData.ID,
                        Name: newData.Name,
                        DefaultPayload: newData.DefaultPayload,
                        IsDeleted: newData.IsDeleted,
                        Organisation: {
                          ID: org ? org.ID : null,
                          Name: org ? org.Name : null,
                          ShortName: org ? org.ShortName : null
                        },
                        DefaultCrew: defaultCrew ? { ID: defaultCrew.ID, Name: defaultCrew.Name } : null,
                        DriverCompany: com ? { ID: com.ID, Name: com.Name, TypeCode: com.TypeCode } : null,
                        DefaultStartTime: defaultTime,
                        EmailAddress: newData.EmailAddress,
                        PhoneNumber: newData.PhoneNumber,
                        CommunicationChannelCode: newData.CommunicationChannelCode,
                        VehicleRegistrationNumber: newData.VehicleRegistrationNumber,
                        CostPerHour: newData.CostPerHour
                      }, oldData.tableData);
                      if (!(errorOrResult instanceof Error)) {
                        const { setNewRow, toggleTable } = this.props
                        if (org) {
                          newData.Organisation = org;
                        }
                        if (defaultTime) {
                          newData.DefaultStartTime = newData.DefaultStartTime
                        }
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }
                  }
                }, 100);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  const errorOrResult = await this.props.onRowDelete(oldData);
                  if (!(errorOrResult instanceof Error)) {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data, pingedRowIndex: undefined };
                    });
                  }
                }, 100);
              })
          }}
        />
      ) : <></>
    );
  }
}

export default DriverTable;