import odata from 'odata-client';
import {
    GET_EQUIPMENT_REQUEST,
    GET_EQUIPMENT_ERROR,
    GET_EQUIPMENT_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const getEquipmentRequest = () => ({
    type: GET_EQUIPMENT_REQUEST,
});

export const getEquipmentError = errorOrResults => ({
    type: GET_EQUIPMENT_ERROR,
    error: errorOrResults.message,
});

export const getEquipmentSuccess = payload => ({
    type: GET_EQUIPMENT_SUCCESS,
    payload,
});

export default (filterQuery) => async (dispatch, getState) => {
    dispatch(getEquipmentRequest());

    const query = odata({ service: getServiceUrl() }).resource(RESOURCES.Equipment);
    query.select("ID, Name, IsDeleted, IsKeyResource, IsIncludedInDailySummary, CostPerHour");
    query.expand("Organisation($select=ID, ShortName, Name),RentalCompany($select=*)");
    query.orderby("Name, ID")
    if (filterQuery !== undefined && filterQuery !== null) {
        query.filter(filterQuery);
    }

    const errorOrResult = await query.get({
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                const response = res.toJSON()
                const error = JSON.parse(response.body).Error
                const errorKey = res.statusCode === 401 ? `401: ${error.Details[0].Message}` : `${error.Details[0].Message}`;
                console.log('get equipment api error', res.toJSON());
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const payload = body.value;
                const storeState = getState()
                if (storeState.equipment.dataIsObject) {
                    const equipmentById = {}
                    payload.forEach(obj => {
                        equipmentById[obj.ID] = obj
                    })
                    return equipmentById;
                }

                return payload;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(getEquipmentError(errorOrResult, GET_EQUIPMENT_ERROR));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
        console.log("CODE ERROR: ", errorOrResult.message);
        return errorOrResult;
    }

    dispatch(getEquipmentSuccess(errorOrResult));

    return errorOrResult;
};