import { DELETE_PENSUM_SUCCESS } from '../types';

export const deletePensumObj = payload => ({
    type: DELETE_PENSUM_SUCCESS,
    payload
});

export default (entityIdObj) => (dispatch) => {
    if (entityIdObj === null || entityIdObj === undefined) {
        return
    }
    dispatch(deletePensumObj(entityIdObj));
};