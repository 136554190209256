import React, { Component } from "react";

import _ from 'lodash';
import Loader from '../../core/components/Loader/LinearProgress'

import MixingPlantTable from "../MixingPlant/MixingPlantTable";
import MixtureOrderTable from "../MixingPlant/MixtureOrderTable";
import MixtureTable from "../MixingPlant/MixtureTable";
import { getMixingPlantClusters } from '../../utils';

import { isEditable, getOrganisation } from "../Common/common";

class MixingPlant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      mixingPlantRow: null,
      onMixingPlantRowAdd: props.createMixingPlant,
      onMixingPlantRowUpdate: props.updateMixingPlant,
      onMixingPlantRowDelete: props.deleteMixingPlant,
      mixtureOrderRow: null,
      onMixtureOrderRowAdd: props.createMixtureOrder,
      onMixtureOrderRowUpdate: props.updateMixtureOrder,
      onMixtureOrderRowDelete: props.deleteMixtureOrder,
      onMixtureRowAdd: props.createMixture,
      onMixtureRowUpdate: props.updateMixture,
      onMixtureRowDelete: props.deleteMixture,
      editable: true,
      isEditable: false,
      showMixingPlantTable: true,
      showMixtureOrderTable: true,
      showMixtureTable: true
    };

    this.onMixingPlantRowChanged = this.onMixingPlantRowChanged.bind(this)
    this.onMixtureOrderRowChanged = this.onMixtureOrderRowChanged.bind(this)
    this.onMixingPlantRowModified = this.onMixingPlantRowModified.bind(this)
  }

  async onMixingPlantRowModified(oldDataRow, dataRow) {
    const { getMixingPlant } = this.props;
    await getMixingPlant();
    const mixingPlantCluster = getMixingPlantClusters(this.props.mixingPlant);
    this.setState({ mixingPlantCluster: mixingPlantCluster });
  }

  async onMixingPlantRowChanged(dataRow) {
    const { getMixtureOrder, getMixture, organisations } = this.props;
    if (dataRow != null) {
      await getMixtureOrder(null, null, "MixingPlant/ID eq " + dataRow.ID.toString());
    }
    else {
      await getMixtureOrder(null, null, "MixingPlant/ID eq 0");
    }

    await getMixture(null, null, "Order/ID eq 0");
    var editable =
      dataRow != null ? isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === dataRow.Organisation.Name), this.props.user.IsParentLogin) : false;
    this.setState({ mixingPlantRow: dataRow, mixtureOrderRow: null, isEditable: editable });
  }

  async onMixtureOrderRowChanged(dataRow) {
    const { getMixture } = this.props;
    if (dataRow != null) {
      await getMixture(null, null, "Order/ID eq " + dataRow.ID.toString());
    }
    else
      await getMixture(null, null, "Order/ID eq 0");

    this.setState({ mixtureOrderRow: dataRow });
  }

  async componentDidMount() {
    const { getMixingPlant, getOrganisations, getMixtureOrder, getMixture, setDataIsObject } = this.props;
    await setDataIsObject(false);
    await getMixingPlant();
    await getOrganisations();
    await getMixtureOrder(null, null, "ID eq 0");
    await getMixture(null, null, "ID eq 0");

    const mixingPlantCluster = getMixingPlantClusters(this.props.mixingPlant);
    this.setState({ isMounted: true, mixingPlantCluster: mixingPlantCluster });

    const organisationFilter = document.getElementsByClassName("MuiSelect-selectMenu")[0];
    const organisationSelect = document.getElementsByClassName("MuiSelect-selectMenu")[1];
    const organisationFilterContainer = document.getElementsByClassName("MuiInputBase-formControl")[1];
    if (organisationFilter !== undefined) {
      organisationFilter.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationSelect !== undefined) {
      organisationSelect.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationFilterContainer !== undefined) {
      organisationFilterContainer.setAttribute("id", "org-select")
    }
    // if (tableHeader !== undefined) {
    //   tableHeader.style.marginBottom = "35px";
    // }
  }

  componentWillUnmount() {
    if (document.getElementsByClassName("MuiSelect-selectMenu")[0] !== undefined) {
      document.getElementsByClassName("MuiSelect-selectMenu")[0].removeEventListener("click", this.expandPopup);
    }
  }

  expandPopup = async () => {
    await new Promise(resolve => setTimeout(() => resolve(), 100));
    const { organisations } = this.props;
    const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
    /*const waitUntilPopupIsMounted = async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 100));
      if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }
    };*/

    if (organisationPopup) {
      const orgsList = organisationPopup.childNodes[0].childNodes;
      if (orgsList) {
        const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
        let i = 0;
        orgsList.forEach(orgElement => {
          const orgObj = organisations.find(item => item.ShortName === orgElement.getAttribute("data-value"));
          const orgText = document.getElementsByClassName("MuiTypography-body1")[i];

          if (orgObj) {
            orgText.setAttribute("data-value", orgObj.ShortName);
            if (orgObj.Level === topLevel) {
              orgText.style.marginLeft = "0px";
            } else if (orgObj.Level > topLevel) {
              const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 5;
              orgText.style.paddingLeft = `${margin}px`;
            }
          }

          i++;
        })
      }
      organisationPopup.setAttribute("style", organisationPopup.getAttribute("style").replace(" width: 250px;", ""));
    } /*else if (!organisationPopup) {
      await waitUntilPopupIsMounted();
    }*/
  }

  sortData = (data, type) => {
    if (data) {
      if (type === "MixingPlant" || type === "Mixture" || type === "MixingPlantCluster") {
        return data.sort((a, b) => {
          const result = a.Name.localeCompare(b.Name, undefined, { sensitivity: 'base' })
          if (result !== 0)
            return result
          return a.ID - b.ID
        })
      }
      if (type === "MixtureOrder") {
        return data.sort((a, b) => {
          const result = a.OrderNumber - b.OrderNumber
          if (result !== 0)
            return result
          return a.ID - b.ID
        })
      }
    }
    return null
  }
  setNewRow = (id) => this.setState({ newRow: id })

  toggleMixingPlantTable = (open) => {
    this.setState({ showMixingPlantTable: open })
    this.setState({ showMixingPlantTable: true })
  }

  toggleMixtureOrderTable = (open) => {
    this.setState({ showMixtureOrderTable: open })
    this.setState({ showMixtureOrderTable: true })
  }

  toggleMixtureTable = (open) => {
    this.setState({ showMixtureTable: open })
    this.setState({ showMixtureTable: true })
  }

  render() {
    const {
      isMounted,
      mixingPlantRow,
      mixtureOrderRow,
      showMixingPlantTable,
      showMixtureOrderTable,
      showMixtureTable,
      isEditable,
      mixingPlantCluster,
      ...rest
    } = this.state;
    const { mixingPlant, organisations, mixtureOrder, mixture, t, isFetching } = this.props;
    const sortedMixingPlant = mixingPlant && mixingPlant.length > 0
      ? this.sortData(mixingPlant, "MixingPlant") : [];
    const sortedMixingPlantCluster = mixingPlantCluster && mixingPlantCluster.length > 0
      ? this.sortData(mixingPlantCluster, "MixingPlantCluster") : [];
    const sortedMixtureOrder = mixtureOrder && mixtureOrder.length > 0
      ? this.sortData(mixtureOrder, "MixtureOrder") : [];
    const sortedMixture = mixture && mixture.length > 0
      ? this.sortData(mixture, "Mixture") : [];

    return (
      <div
        className="mixingPlant"
        style={{
          width: "auto",
          paddingLeft: '8px',
          paddingRight: '20px',
          marginTop: isFetching && !isMounted ? 1 : 1,
          justifyContent: "center"
        }}
      >
        {
          isMounted ? (
            <div>
              {showMixingPlantTable ? (
                <div style={{ padding: "15px 5px 5px 5px", width: "99.5%", margin: "0 auto" }}>
                  <MixingPlantTable
                    title={t('asphalt-mixing-plant')}
                    data={sortedMixingPlant}
                    organisations={organisations}
                    mixingPlantClusters={sortedMixingPlantCluster}
                    onMixingPlantRowModified={this.onMixingPlantRowModified}
                    toggleTable={this.toggleMixingPlantTable}
                    setNewRow={this.setNewRow}
                    t={t}
                    onMixingPlantRowChanged={this.onMixingPlantRowChanged}
                    {...rest}
                    {...this.props}
                  />
                </div>
              ) : null}
              {
                mixingPlantRow != null ?
                  <div className="mixingPlantLabel">
                    {t('asphalt-mixing-plant')}: {mixingPlantRow.Name}
                  </div > : null
              }
              <div className="rowC">
                {showMixtureOrderTable && mixingPlantRow != null ? (
                  <div style={{ padding: "5px", width: "50%", margin: "0 auto", pointerEvents: mixingPlantRow == null ? "none" : "inherit", opacity: mixingPlantRow == null ? "0.4" : "inherit" }}>
                    <MixtureOrderTable
                      title={t('asphalt-mixture-order')}
                      data={sortedMixtureOrder}
                      t={t}
                      mixingPlantRow={mixingPlantRow}
                      onMixtureOrderRowChanged={this.onMixtureOrderRowChanged}
                      toggleTable={this.toggleMixtureOrderTable}
                      setNewRow={this.setNewRow}
                      isEditable={isEditable}
                      {...rest}
                      {...this.props}
                    />
                  </div>
                ) : null}
                {showMixtureTable && mixingPlantRow != null ? (
                  <div style={{ padding: "5px", width: "50%", margin: "0 auto", pointerEvents: mixtureOrderRow == null ? "none" : "inherit", opacity: mixtureOrderRow == null ? "0.4" : "inherit" }}>
                    <MixtureTable
                      title={t('asphalt-mixture')}
                      data={sortedMixture}
                      t={t}
                      mixtureOrderRow={mixtureOrderRow}
                      onMixtureOrderRowReload={this.onMixtureOrderRowChanged}
                      toggleTable={this.toggleMixtureTable}
                      setNewRow={this.setNewRow}
                      isEditable={isEditable}
                      {...rest}
                      {...this.props}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : <Loader />
        }
      </div>
    );
  }
}

export default MixingPlant;
