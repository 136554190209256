import getProjectActivityEntries from "./ProjectActivityEntries/getProjectActivityEntries";
import getEmployeeEntries from "./EmployeeEntries/getEmployeeEntries";
import getEquipmentEntries from "./EquipmentEntries/getEquipmentEntries";
import getDriverEntries from "./DriverEntries/getDriverEntries";
import getMixtureEntries from "./MixtureEntries/getMixtureEntries";
import createEmployeeEntry from "./EmployeeEntries/createEmployeeEntry";
import deleteEmployeeEntry from "./EmployeeEntries/deleteEmployeeEntry";
import createEquipmentEntry from "./EquipmentEntries/createEquipmentEntry";
import deleteEquipmentEntry from "./EquipmentEntries/deleteEquipmentEntry";
import createProjectActivityEntry from "./ProjectActivityEntries/createProjectActivityEntry";
import deleteProjectActivityEntry from "./ProjectActivityEntries/deleteProjectActivityEntry";
import createMixtureEntry from "./MixtureEntries/createMixtureEntry";
import createDriverEntry from "./DriverEntries/createDriverEntry";
import getDriverEntry from "./DriverEntries/getDriverEntry";
import createEntry from "./createEntry";
import deleteEntry from "./deleteEntry";
import editEntry from "./editEntry";
import updateEntry from "./updateEntry";

export {
    getProjectActivityEntries,
    getEmployeeEntries,
    getEquipmentEntries,
    getDriverEntries,
    getMixtureEntries,
    createEmployeeEntry,
    deleteEmployeeEntry,
    createEquipmentEntry,
    deleteEquipmentEntry,
    createProjectActivityEntry,
    deleteProjectActivityEntry,
    createMixtureEntry,
    createDriverEntry,
    createEntry,
    deleteEntry,
    editEntry,
    updateEntry,
    getDriverEntry
};

export default {
    getProjectActivityEntries,
    getEmployeeEntries,
    getMixtureEntries,
    getDriverEntries,
    getEquipmentEntries,
    createEmployeeEntry,
    deleteEmployeeEntry,
    createEquipmentEntry,
    deleteEquipmentEntry,
    createProjectActivityEntry,
    deleteProjectActivityEntry,
    createMixtureEntry,
    createDriverEntry,
    createEntry,
    deleteEntry,
    editEntry,
    updateEntry,
    getDriverEntry
};