import odata from 'odata-client';
import {
    CREATE_EQUIPMENT_REQUEST,
    CREATE_EQUIPMENT_ERROR,
    CREATE_EQUIPMENT_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const createEquipmentRequest = () => ({
    type: CREATE_EQUIPMENT_REQUEST,
});

export const createEquipmentError = errorOrResult => ({
    type: CREATE_EQUIPMENT_ERROR,
    error: errorOrResult.message,
});

export const createEquipmentSuccess = payload => ({
    type: CREATE_EQUIPMENT_SUCCESS,
    payload,
});

export default (equipment) => async (dispatch, getState) => {
    if (!(equipment.Name) || equipment.Name.trim() === "" || !(equipment.Organisation.ID)) {
        dispatch(showNotification("Required fields must NOT be empty", "error"))
        return new Error()
    }

    dispatch(createEquipmentRequest());

    const odataObj = odata({service: getServiceUrl()}).resource(RESOURCES.Equipment);
    const errorOrResult = await odataObj.post({ ...equipment, Organisation: { ID: equipment.Organisation.ID }, RentalCompany: equipment.RentalCompany ? { ID: equipment.RentalCompany.ID } : null }, {
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;
            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                const response = res.toJSON()
                const error = JSON.parse(response.body).Error
                const errorKey = res.statusCode === 401 ? `401: ${error.Details[0].Message}` : `${error.Details[0].Message}`;
                console.log('create equipment api error', response);
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const { "@odata.context": omit, ...payload } = body;
                payload.Organisation = equipment.Organisation;
                payload.RentalCompany = equipment.RentalCompany;
                return payload;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

     if (errorOrResult instanceof Error) {
        dispatch(createEquipmentError(errorOrResult, CREATE_EQUIPMENT_ERROR));
        console.log("CODE ERROR: ", errorOrResult.message);
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
        return errorOrResult;
    } else {
        dispatch(showNotification("SUCCESSFUL_SAVE", "success"))
    }

    dispatch(createEquipmentSuccess(errorOrResult));

    return errorOrResult;
};