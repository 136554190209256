import React from "react";

import { isEqualMasterData } from "../../utils";

import MaterialTable from "material-table";

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
/* import InputLabel from '@material-ui/core/InputLabel'; */
import Checkbox from '@material-ui/core/Checkbox';
import { InputAdornment } from "@material-ui/core";
import NumberInput from '../../core/components/Inputs/NumberInput/numberInput';

import './index.css'
import { expandPopupOrganisation, filterOrganisations, isEditable, getOrganisation, canEditKeyResource } from "../Common/common";

class EquipmentTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      organisations: props.organisations ? props.organisations : '',
      company: props.company ? props.company : '',
      editable: props.editable ? props.editable : '',
      selectedFilters: [],
      orgSelectIsEmpty: true,
      addedRow: false,
      newData: null,
      error: null,
    };
  }

  componentDidMount() {
    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }

    const { organisations, selectedFilters } = this.state;
    const { t } = this.props;
    const lookupOrgs = {};
    const lookupCompany = {};
    const filteredLookupOrgs = {};
    const columns = [
      {
        title: "Organisation *", field: "Organisation.Name", lookup: lookupOrgs, defaultFilter: this.state.selectedFilters,
        initialEditValue: selectedFilters.length > 0 ? selectedFilters : null,
        render: rowData => (
          rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
        ),
        validate: ({ Organisation }) => Organisation !== undefined && Organisation !== null,
        editComponent: props => (
          <FormControl>
            <Select
              labelId="org-label"
              id="organisation"
              className="organisationSelect"
              value={props.value}
              onChange={e => this.handleOnSelectOnChange(props, e)}
              autoFocus
              error={props.value === null || props.value === undefined}
              onClick={async () => {
                await new Promise(resolve => setTimeout(() => resolve(), 100));
                const { organisations } = this.state;
                var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
                var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
                expandPopupOrganisation(filteredOrgs, "MuiListItem-button");
              }}
            >
              {Object.keys(props.columnDef.lookup).map(key => (
                (selectedFilters.length === 0 || selectedFilters.includes(key)) && filteredLookupOrgs[key] !== undefined ?
                  <MenuItem
                    value={key}
                  >
                    <em>{props.columnDef.lookup[key]}</em>
                  </MenuItem>
                  : null
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        title: "Equipment type *", field: "Name",
        validate: ({ Name }) => Name && Name.trim() !== "",
        editComponent: props => (
          <FormControl>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('company-name'), field: 'RentalCompany.Name', lookup: lookupCompany,
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Object.keys(props.columnDef.lookup).map(key => (
              <MenuItem
                value={key}
              >
                <em>{props.columnDef.lookup[key]}</em>
              </MenuItem>
            ))}
          </Select>
        ),
        filtering: false
      },
      {
        title: `${t('cost-per-hour')}`, field: "CostPerHour",
        cellStyle: {
          textAlign: "right"
        },
        editComponent: props => (
          <FormControl>
            <NumberInput
              inputProps={{ maxLength: 15, style: { textAlign: 'right' }, useSeparators: true }}
              id="costPerHour"
              type="number"
              value={props.value}
              handleChange={e => this.acceptPositiveDecimal(props, e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€</InputAdornment>
                )
              }}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('is-key-resource'), field: 'IsKeyResource', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsKeyResource} disabled={!canEditKeyResource(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin)} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
            disabled={!canEditKeyResource(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), props.rowData.Organisation !== undefined ? organisations.find(org => org.Name === props.rowData.Organisation.Name) : null, this.props.user.IsParentLogin)}
          />
        ),
        filtering: true
      },
      {
        title: t('is-included-in-daily-summary'), field: 'IsIncludedInDailySummary', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsIncludedInDailySummary} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('is-deleted'), field: 'IsDeleted', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsDeleted} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      }
    ];

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
      var organisation = getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin);
      var filteredOrgs = filterOrganisations(organisations, organisation, this.props.user.IsParentLogin);
      filteredOrgs.forEach(org => {
        filteredLookupOrgs[org.Name] = org.Name;
      })
    }

    const { company } = this.props;
    lookupCompany[null] = '\xA0';
    if (company.length > 0) {
      company.forEach(company => {
        if (company.TypeCode == 1) {
          lookupCompany[company.Name] = company.Name;
        }
      })
    }

    const { newRow } = this.props
    if (newRow) {
      const { data } = this.state
      const indexOfNewRow = data.findIndex(obj => obj.ID === newRow)
      const initPage = indexOfNewRow !== -1 ? Math.floor(indexOfNewRow / 10) : undefined
      this.setState({ initPage, pingedRowIndex: indexOfNewRow, isMounted: true, columns: columns })
      /*new Promise(resolve => setTimeout(() => {resolve(); this.setState({ pingedRowIndex: undefined })}, 4000));*/
    }
    else {
      this.setState({ isMounted: true, columns: columns })
    }
  }

  handleOnSelectOnChange = (props, e) => {
    props.onChange(e.target.value)
  }

  acceptPositiveDecimal(props, e) {
    const re = /^[0-9.\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      props.onChange(e.target.value);
    }
  }

  render() {
    const {
      data,
      organisations,
      initPage,
      isMounted,
      pingedRowIndex,
      editable
    } = this.state;
    const { t } = this.props;

    return (
      isMounted ? (
        <MaterialTable
          title={this.state.title}
          columns={this.state.columns}
          data={data}
          style={{
            paddingLeft: !editable && '2%',
            paddingRight: !editable && '2%',
            fontFamily: `Roboto, Helvetica, Arial, sans-serif`,
            fontSize: `0.875rem`
          }}
          options={{
            pageSize: this.state.data.length > 5 ? 10 : 5,
            pageSizeOptions: this.state.data.length > 5 ? [5, 10, 20, 50, 100, { value: this.state.data.length, label: t('all-option') }] : [5],
            initialPage: initPage,
            paginationType: "normal",
            addRowPosition: "first",
            draggable: false,
            filtering: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "left",
            maxBodyHeight: 620,
            rowStyle: rowData => ({
              backgroundColor: (pingedRowIndex === rowData.tableData.id) ? '#EEE' : '#FFF'
            })
          }}
          onChangePage={() => this.setState({ pingedRowIndex: undefined })}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            body: {
              emptyDataSourceMessage: t('no-records-to-display'),
              addTooltip: t('add'),
              deleteTooltip: t('delete'),
              editTooltip: t('edit'),
              editRow: {
                saveTooltip: t('save'),
                cancelTooltip: t('cancel'),
                deleteText: t('deleteText')
              }
            },
            pagination: {
              firstTooltip: t('first-page'),
              previousTooltip: t('previous-page'),
              nextTooltip: t('next-page'),
              lastTooltip: t('last-page')
            }
          }}
          editable={editable && {
            isEditable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
            isDeletable: rowData => editable && isEditable(getOrganisation(this.props.user.organisation, this.props.user.baseOrganisation, this.props.user.IsParentLogin), organisations.find(org => org.Name === rowData.Organisation.Name), this.props.user.IsParentLogin),
            onRowAdd: newData =>
              new Promise(resolve => {
                this.setState({
                  newData: newData
                });
                setTimeout(async () => {
                  resolve();
                  const { organisations, company } = this.state;
                  if (organisations) {
                    const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                    const com = company ? company.find(c => newData.RentalCompany != null && c.Name === newData.RentalCompany.Name) : null;
                    const errorOrResult = await this.props.onRowAdd({
                      Name: newData.Name,
                      Organisation: {
                        ID: org ? org.ID : null,
                        Name: org ? org.Name : null,
                        ShortName: org ? org.ShortName : null
                      },
                      IsKeyResource: newData.IsKeyResource,
                      IsIncludedInDailySummary: newData.IsIncludedInDailySummary,
                      RentalCompany: com ? com : null,
                      CostPerHour: newData.CostPerHour !== undefined && newData.CostPerHour != null ? parseFloat(newData.CostPerHour) : newData.CostPerHour
                    });
                    if (!(errorOrResult instanceof Error)) {
                      const { setNewRow, toggleTable } = this.props
                      const { ID } = errorOrResult
                      if (org) {
                        newData.Organisation = org;
                      }
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        newData.ID = ID;
                        data.push(newData);
                        return { ...prevState, data };
                      });
                      setNewRow(ID)
                      toggleTable()
                    }
                  }
                }, 100);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  if (oldData && newData && !isEqualMasterData("Equipment", oldData, newData)) {
                    const { organisations, company } = this.state;
                    if (organisations) {
                      const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                      const com = company ? company.find(c => newData.RentalCompany != null && c.Name === newData.RentalCompany.Name) : null;
                      const errorOrResult = await this.props.onRowUpdate({
                        ID: newData.ID,
                        Name: newData.Name,
                        IsDeleted: newData.IsDeleted,
                        IsKeyResource: newData.IsKeyResource,
                        IsIncludedInDailySummary: newData.IsIncludedInDailySummary,
                        Organisation: {
                          ID: org ? org.ID : null,
                          Name: org ? org.Name : null,
                          ShortName: org ? org.ShortName : null
                        },
                        RentalCompany: com ? { ID: com.ID, Name: com.Name, TypeCode: com.TypeCode } : null,
                        CostPerHour: newData.CostPerHour
                      }, oldData.tableData);
                      if (!(errorOrResult instanceof Error)) {
                        const { setNewRow, toggleTable } = this.props
                        if (org) {
                          newData.Organisation = org;
                        }
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }
                  }
                }, 100);
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(async () => {
                  resolve();
                  const errorOrResult = await this.props.onRowDelete(oldData);
                  if (!(errorOrResult instanceof Error)) {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data, pingedRowIndex: undefined };
                    });
                  }
                }, 100);
              })
          }}
        />
      ) : <></>
    );
  }
}

export default EquipmentTable