import React, { memo, useEffect } from "react";
import "./styles.css";
import { store } from "../../../App";
import { useHistory } from "react-router-dom";
import _ from "lodash";


const generatedData = (data)=>{
  const projects = _.map(data.crew.crews); //crews
  const tasks = _.map(data.pensum.pensumsById); //pensums
  const mappedtask = tasks.map((task) => ({ projectID: task.Crew.ID,stateCode: task.StateCode, ...task }));
  
  const mapped = projects.map((project) => {
    const filteredTasks = mappedtask.filter((task) => project.ID === task.projectID);
    const parent = {
      name: project.Name,
      id: project.Name,
      colapsed: false,
      color: "black",
    }; //bar szin
    const child = filteredTasks.map((t) => ({
      parent: project.Name,
      name: t.Project.Name,
      statusCoooode: t.StateCode,
      start: new Date(t.Day).getTime(),                     //majd listaból azonos pensumok első datuma
      end: new Date(t.Day).getTime()+ 60 * 60 * 24 * 1000, //majd listaból azonos pensumok utolsó datuma
      color: (t.StateCode === 0 ? "#9c0000" : t.StateCode === 20 ? "#017724" : t.StateCode === 10 ? "#d2c000" : t.StateCode === 100 ? "#017184" : "#9c0000"), //bar szinek
    }));
   /*  console.log("child",child) */
    const obj = {
      id: project.ID,
      name: project.Name,
      events: {
        click: () =>{   window.open('/project/' + project.ID , '_blank')}, //navigation cucc
      },
      data: [parent, ...child],
    };
    return obj;
  });
 /*  console.log("mapped",mapped) */
  return mapped;
}


export const HighChart = memo((series, title) => {
  const state = store.getState();
/*   console.log(state) */
  const chartData = generatedData(state)
  const chart = window.Highcharts;
  useEffect(() => {

    chart.ganttChart("container", {
      /* title, series, */
      //idővonal -gomb-manualis start/end datumok
      scrollbar: {
        enabled: true,
        barBorderWidth: 1,
      },
      rangeSelector: {
        inputEnabled: false, //date picker
        allButtonsEnabled: true,
        inputBoxHeight: "1",
        liveRedraw: false,
        buttonPosition: {
          align: "left",
          x: 0,
          y: 0,
        },
        enabled: true,
        dropdown: "responsive",
        buttons: [
          {
            type: "month",
            count: 1,
            text: "1m",
            title: "View 1 months",
          },
          {
            type: "month",
            count: 6,
            text: "6m",
            title: "View 6 months",
          },
          {
            type: "year",
            count: 1,
            text: "1year",
            title: "View 1 year",
          },
        ],
      },
      //tooltip setup
      tooltip: {
        backgroundColor: "white",
        followPointer: true,
      },
      chart: {
        //hatter color
        /*  backgroundColor: '#555', */
      },
      title: {
        text: "Gantt Chart",
        y: 40,
        style: {
          color: "#333",
          fontWeight: "bold",
          fontSize: "40px",
          fontFamily: "Trebuchet MS, Verdana, sans-serif",
        },
      },
      plotOptions: {
        series: {
          // general options for all series
        },
        gantt: {
          // shared options for all gantt series
          //szines csikok bordercolorja
          borderColor: "#FFFFFF",
          clip: true,
          dataLabels: {
            borderColor: "#FF0000",
            backgroundColor: "#FF0000",
            shadow: true,
            //szines csikok textje ??
            textPath: {
              attributes: "sanyi",
              enabled: true,
            },
          },
        },
        xrange: {
          borderColor: "#FF0000",
        },
      },
      //xGrid tulajdonsagai
      xAxis: {
        type: "datetime", // one of linear, logarithmic, datetime or category
        ordinal: false,
        gridLineWidth: 1,
        tickPixelInterval: 100,
        title: {
          text: "DATE",
          y: -3,
          style: {
            color: "#333",
            fontWeight: "bold",
            fontSize: "20px",
            fontFamily: "Trebuchet MS, Verdana, sans-serif",
          },
        },
      },
      yAxis: {
        title: {
          text: "CREW",
          align: "left",
          x: -40,
          y: -5,
          style: {
            color: "#333",
            align: "left",
            fontWeight: "bold",
            fontSize: "20px",
            fontFamily: "Trebuchet MS, Verdana, sans-serif",
          },
        },
      },
      series:chartData,
    });
  }, []);
  return <div id="container" />;
});
