import {
  IMPORT_PROJECT_DOCUMENT_REQUEST,
  IMPORT_PROJECT_DOCUMENT_ERROR,
  IMPORT_PROJECT_DOCUMENT_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader, sendFileRequest, obj_to_map } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const importProjectDocumentRequest = () => ({
  type: IMPORT_PROJECT_DOCUMENT_REQUEST
});

export const importProjectDocumentError = errorOrResult => ({
  type: IMPORT_PROJECT_DOCUMENT_ERROR,
  error: errorOrResult.message
});

export const importProjectDocumentSuccess = payload => ({
  type: IMPORT_PROJECT_DOCUMENT_SUCCESS,
  payload
});

export default (project, files) => async (dispatch, getState) => {

  dispatch(importProjectDocumentRequest());
  const url = getServiceUrl() + RESOURCES.Projects + "(" + project.ID + ")/" + RESOURCES.ImportDocuments;

  const headers = {
    Authorization: `Bearer ${getState().user.token}`,
    ...getDefaultHeader()
  };

  const errorOrResult = await sendFileRequest(url, files, obj_to_map(headers))
    .then(res => {
      const ok = res.status <= 299 && res.status >= 200;
      if (!ok) {
        const errorKey = res.status === 401 ? `401: ${res.responseText}` : `${res.responseText}`;
        console.log('import project document api error', res.responseText);
        return new Error(errorKey);
      } else {
        const body = JSON.parse(res.response);
        const { "@odata.context": omit, ...payload } = body;
        return payload;
      }
    }, res => {
      // error
      const errorKey = `Error: ${res.status}, ${res.statusText}`;
      console.log('import project document api error', res.responseText);
      return new Error(errorKey);
    }
    );

  if (errorOrResult instanceof Error) {
    dispatch(importProjectDocumentError(errorOrResult, IMPORT_PROJECT_DOCUMENT_ERROR));
    console.log("CODE ERROR: ", errorOrResult.message);
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
    }
    dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_IMPORT_DOCUMENT", "success"))
  }

  dispatch(importProjectDocumentSuccess(errorOrResult));

  return errorOrResult;
};