import {
  GET_MIXTURE_REQUEST,
  GET_MIXTURE_ERROR,
  GET_MIXTURE_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/getEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (selectQuery, expandQuery, filterQuery) => (state = initialState, action) => {

  context.init(context);

  context.GET_ENTITY_REQUEST = GET_MIXTURE_REQUEST;
  context.GET_ENTITY_ERROR = GET_MIXTURE_ERROR;
  context.GET_ENTITY_SUCCESS = GET_MIXTURE_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "mixture";
  context.entityLocation = RESOURCES.Mixtures;
  context.selectQuery = "ID, Name, ArticleNumber,TypeCode,CostPerTonne";
  context.expandQuery = "Order($expand=MixingPlant)";
  context.filterQuery = null;
  context.orderByQuery = "Name, ID";

  if (selectQuery !== undefined && selectQuery !== null)
    context.selectQuery = selectQuery;

  if (expandQuery !== undefined && expandQuery !== null)
    context.expandQuery = expandQuery;

  if (filterQuery !== undefined && filterQuery !== null)
    context.filterQuery = filterQuery;

  return context.execute(context)()(state, action);
};