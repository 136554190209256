import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_ERROR,
  GET_COMPANY_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/getEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (state = initialState, action) => {

  context.init(context);

  context.GET_ENTITY_REQUEST = GET_COMPANY_REQUEST;
  context.GET_ENTITY_ERROR = GET_COMPANY_ERROR;
  context.GET_ENTITY_SUCCESS = GET_COMPANY_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "company";
  context.entityLocation = RESOURCES.Companies;
  context.selectQuery = "ID, ShortName, Name, CommunicationChannelCode, EmailAddress, PhoneNumber, TypeCode";
  context.expandQuery = "Organisation($select=ID, ShortName, Name, Left, Right)";
  context.orderByQuery = "Name";

  return context.execute(context)(state, action);
};