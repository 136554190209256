import {
  GET_MIXINGPLANT_REQUEST,
  GET_MIXINGPLANT_ERROR,
  GET_MIXINGPLANT_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/getEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (selectQuery, expandQuery, filterQuery) => async (state = initialState, action) => {

  context.init(context);

  context.GET_ENTITY_REQUEST = GET_MIXINGPLANT_REQUEST;
  context.GET_ENTITY_ERROR = GET_MIXINGPLANT_ERROR;
  context.GET_ENTITY_SUCCESS = GET_MIXINGPLANT_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "mixingPlant";
  context.entityLocation = RESOURCES.MixingPlants;
  context.selectQuery = "ID, Name, CommunicationChannelCode, EmailAddress, PhoneNumber, LocationPlusCode, IsDeleted, MinimumAmountOfOutput, MaximumAmountOfOutput, GroupNames";
  context.expandQuery = "Organisation($select=ID, ShortName, Name, Left, Right),Orders($select=*)";
  context.orderByQuery = "Name, ID";
  //context.expandQuery = "Organisation($select=ID, ShortName),Orders($select=ID,OrderNumber)";

  if (selectQuery !== undefined && selectQuery !== null)
    context.selectQuery = selectQuery;

  if (expandQuery !== undefined && expandQuery !== null)
    context.expandQuery = expandQuery;

  if (filterQuery !== undefined && filterQuery !== null)
    context.filterQuery = filterQuery;

  return context.execute(context)()(state, action);
};