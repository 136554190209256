import React, { Component } from "react";
import CrewTable from "../Crew/CrewTable";

import Loader from '../../core/components/Loader/LinearProgress'

class Crew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      onRowAdd: props.createCrew,
      onRowUpdate: props.updateCrew,
      onRowDelete: props.deleteCrew,
      editable: true,
      showTable: true
    };
  }

  async componentDidMount() {
    const { getCrew, getOrganisations, setDataIsObject } = this.props;
    await setDataIsObject(false);
    await getCrew();
    await getOrganisations();
    this.setState({ isMounted: true });

    const organisationFilter = document.getElementsByClassName("MuiSelect-selectMenu")[0];
    const organisationSelect = document.getElementsByClassName("MuiSelect-selectMenu")[1];
    const organisationFilterContainer = document.getElementsByClassName("MuiInputBase-formControl")[1];
    const tableHeader = document.getElementsByClassName("MuiToolbar-gutters")[1];
    if (organisationFilter !== undefined) {
      organisationFilter.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationSelect !== undefined) {
      organisationSelect.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationFilterContainer !== undefined) {
      organisationFilterContainer.setAttribute("id", "org-select")
    }
    if (tableHeader !== undefined) {
      tableHeader.style.marginBottom = "35px";
    }
  }

  componentWillUnmount() {
    if (document.getElementsByClassName("MuiSelect-selectMenu")[0] !== undefined) {
      document.getElementsByClassName("MuiSelect-selectMenu")[0].removeEventListener("click", this.expandPopup);
    }
  }

  expandPopup = async () => {
    await new Promise(resolve => setTimeout(() => resolve(), 100));
    const { organisations } = this.props;
    const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
    /*const waitUntilPopupIsMounted = async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 100));
      if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }
    };*/

    if (organisationPopup) {
      const orgsList = organisationPopup.childNodes[0].childNodes;
      if (orgsList) {
        const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
        let i = 0;
        orgsList.forEach(orgElement => {
          const orgObj = organisations.find(item => item.ShortName === orgElement.getAttribute("data-value"));
          const orgText = document.getElementsByClassName("MuiTypography-body1")[i];

          if (orgObj) {
            orgText.setAttribute("data-value", orgObj.ShortName);
            if (orgObj.Level === topLevel) {
              orgText.style.marginLeft = "0px";
            } else if (orgObj.Level > topLevel) {
              const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 5;
              orgText.style.paddingLeft = `${margin}px`;
            }
          }

          i++;
        })
      }
      organisationPopup.setAttribute("style", organisationPopup.getAttribute("style").replace(" width: 250px;", ""));
    } /*else if (!organisationPopup) {
      await waitUntilPopupIsMounted();
    }*/
  }

  sortData = (data) => {
    if (data) {
      return data.sort((a, b) => {
        const resultDefaultSortOrder = a.DefaultSortOrder - b.DefaultSortOrder
        if (resultDefaultSortOrder !== 0)
          return resultDefaultSortOrder
        const resultName = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'})
        if (resultName !== 0)
          return resultName
        return a.ID - b.ID
      })
    }
    return null
  }

  setNewRow = (id) => this.setState({ newRow: id })

  toggleTable = (open) => {
    this.setState({ showTable: open })
    this.setState({ showTable: true })
  }

  render() {
    const { isMounted, showTable, ...rest } = this.state;
    const { crew, organisations, t, isFetching } = this.props;
    const sortedCrew = crew && crew.length > 0
      ? this.sortData(crew) : [];

    return (
      <div
        style={{
          width: isFetching && !isMounted ? "100%" : "730px",
          margin: "0 auto",
          marginTop: isFetching && !isMounted ? 0 : 30,
          justifyContent: "center"
        }}
      >
        {
          isMounted ? (
            showTable ?
              <CrewTable
                title={t('construction-crew')}
                data={sortedCrew}
                organisations={organisations}
                toggleTable={this.toggleTable}
                setNewRow={this.setNewRow}
                t={t}
                {...rest}
                {...this.props}
              /> : null
          ) : <Loader />
        }
      </div>
    );
  }
}

export default Crew;
