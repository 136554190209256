import {
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_ERROR,
  CREATE_COMPANY_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/createEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default entity => (state = initialState, action) => {

  context.init(context);

  context.CREATE_ENTITY_REQUEST = CREATE_COMPANY_REQUEST;
  context.CREATE_ENTITY_ERROR = CREATE_COMPANY_ERROR;
  context.CREATE_ENTITY_SUCCESS = CREATE_COMPANY_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "company";
  context.entityLocation = RESOURCES.Companies;
  context.hasOrganisation = true; //???

  return context.execute(context)(entity)(state, action);
};