import odata from 'odata-client';
import {
    CREATE_CHECKPOINT_DEFINITION_REQUEST,
    CREATE_CHECKPOINT_DEFINITION_ERROR,
    CREATE_CHECKPOINT_DEFINITION_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const createCheckpointDefinitionsRequest = () => ({
    type: CREATE_CHECKPOINT_DEFINITION_REQUEST,
});

export const createCheckpointDefinitionsError = errorOrResults => ({
    type: CREATE_CHECKPOINT_DEFINITION_ERROR,
    error: errorOrResults.message,
});

export const createCheckpointDefinitionsSuccess = payload => ({
    type: CREATE_CHECKPOINT_DEFINITION_SUCCESS,
    payload,
});

export default (queryData) => async (dispatch, getState) => {
    dispatch(createCheckpointDefinitionsRequest());

    const query = odata({ service: getServiceUrl() }).resource(RESOURCES.CheckpointDefinitions);

    const errorOrResult = await query.post({
        ...queryData
    }, {
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            "X-IAS-SessionID": `${getState().user.changeMessageToken}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('create checkpoint definition api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const { "@odata.context": omit, ...payload } = body;
                payload.Organisation = queryData.Organisation;
                return payload;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(createCheckpointDefinitionsError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        /*dispatch(showNotification(errorOrResult.message, "error"))*/
        return errorOrResult;
    } else {
        /*dispatch(showNotification("SUCCESSFUL_SAVE", "success"))*/
    }

    dispatch(createCheckpointDefinitionsSuccess(errorOrResult));

    return errorOrResult;
};