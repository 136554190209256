import odata from 'odata-client';
import {
    CREATE_PROJECT_ACTIVITY_ENTRIES_REQUEST,
    CREATE_PROJECT_ACTIVITY_ENTRIES_ERROR,
    CREATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS,
    TOKEN_EXPIRED
} from '../../types';
import { getServiceUrl, getDefaultHeader } from '../../../utils';
import RESOURCES from '../../../api/resources';
import { showNotification } from '../../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const createProjectActivityEntriesRequest = () => ({
    type: CREATE_PROJECT_ACTIVITY_ENTRIES_REQUEST,
});

export const createProjectActivityEntriesError = errorOrResults => ({
    type: CREATE_PROJECT_ACTIVITY_ENTRIES_ERROR,
    error: errorOrResults.message,
});

export const createProjectActivityEntriesSuccess = payload => ({
    type: CREATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS,
    payload,
});

export default (queryData) => async (dispatch, getState) => {
    dispatch(createProjectActivityEntriesRequest());

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.ProjectActivityEntries);

    const errorOrResult = await query.post({
        ...queryData,
        ProjectActivity: { ID: queryData.ProjectActivity.ID }
    }, {
        headers: {
          Authorization: `Bearer ${getState().user.token}`,
          "X-IAS-SessionID": `${getState().user.changeMessageToken}`,
          ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('update pensum api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const { "@odata.context": omit, ...payload } = body;
                const payloadObj = {
                    ID: payload.ID,
                    ProjectActivity: queryData.ProjectActivity,
                    SortOrder: payload.SortOrder
                }
                return payloadObj;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(createProjectActivityEntriesError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        /*dispatch(showNotification(errorOrResult.message, "error"))*/
        return errorOrResult;
    } else {
        /*dispatch(showNotification("SUCCESSFUL_SAVE", "success"))*/
    }

    dispatch(createProjectActivityEntriesSuccess(errorOrResult));

    return errorOrResult;
};