import odata from 'odata-client';
import {
    GET_APP_SETTINGS_REQUEST,
    GET_APP_SETTINGS_ERROR,
    GET_APP_SETTINGS_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';
/*import getToken from '../user/getToken';*/

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const getAppSettingsRequest = () => ({
    type: GET_APP_SETTINGS_REQUEST,
});

export const getAppSettingsError = errorOrResults => ({
    type: GET_APP_SETTINGS_ERROR,
    error: errorOrResults.message,
});

export const getAppSettingsSuccess = payload => ({
    type: GET_APP_SETTINGS_SUCCESS,
    payload,
});

export default () => async (dispatch, getState) => {
    dispatch(getAppSettingsRequest());

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.ApplicationSettings);
    const errorOrResult = await query.get({
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('get equipment api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const settings = body.value;
                let settingsObj = {};
                if (settings) {
                    settings.forEach(item => {
                        if (item.Name === "DatabaseInstanceOptions") {
                            const valueObj = JSON.parse(item.Value);
                            const DBInstances = valueObj["Entries"];
                            settingsObj[item.Name] = DBInstances;
                        } else {
                            settingsObj[item.Name] = item.Value;
                        }
                    })
                }
                
                return settingsObj;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(getAppSettingsError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return;
    }

    dispatch(getAppSettingsSuccess(errorOrResult));

    return errorOrResult;
};